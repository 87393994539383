import { commonValidationRules } from "constants/shared/validationRules";
import { BusinessStructureEntityType } from "components/BusinessOwnersStructure/components";
import { getAllKeyManagers } from "components/BusinessOwnersStructure/helpers";

import {
  GetBusinessStructureResponseDto,
  GetUserIdsByEmailResDto,
} from "utils/swagger_react_query";

import { EBusinessStructureUserSearchWarning } from "./constants";

export const determineUserSearchWarningMessage = (
  user: GetUserIdsByEmailResDto,
  businessStructure: GetBusinessStructureResponseDto,
  selectedEntityType: Omit<BusinessStructureEntityType, "BUSINESS">,
) => {
  const keyManagers = getAllKeyManagers(businessStructure);
  const owners = businessStructure?.owners || [];
  const isUserKeyManager = keyManagers.some((it) => it?.email === user.email);
  const isUserOwner = owners.some((it) => it?.email === user.email);

  if (isUserKeyManager && selectedEntityType === BusinessStructureEntityType.KEY_MANAGER) {
    return EBusinessStructureUserSearchWarning.ALREADY_ASSIGNED_AS_KEY_MANAGER;
  }

  if (isUserOwner && selectedEntityType === BusinessStructureEntityType.OWNER) {
    return EBusinessStructureUserSearchWarning.ALREADY_ASSIGNED_AS_OWNER;
  }

  /* FIXME:::
    if (!isVerified) {
    return EBusinessStructureUserSearchWarning.NOT_VERIFIED;
  } */
};

export const validateInputValue = (value: string): string | undefined => {
  try {
    commonValidationRules.email.validateSync(value);
  } catch (error: any) {
    return error?.message as string;
  }
};
