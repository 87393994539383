import React, { FC } from "react";
import { Trans } from "react-i18next";

import { HELP_EMAIL } from "constants/shared";

import { EmailLink, StyledErrorIcon, StyledModal } from "./styles";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const TooManyAttemptsErrorModal: FC<IProps> = ({ isOpen, onClose }) => {
  const prefix = `auth_pages.shared.error_modals.too_many_attempts`;

  return (
    <StyledModal
      isOpen={isOpen}
      onClose={onClose}
      icon={<StyledErrorIcon />}
      title={<Trans i18nKey={`${prefix}.title`} />}
      mainButton={{
        text: <Trans i18nKey={`components.confirm_action.ok`} />,
        onClick: onClose,
      }}
      message={
        <Trans
          i18nKey={`${prefix}.message`}
          components={{ 1: <EmailLink href={`mailto:${HELP_EMAIL}`} /> }}
        />
      }
    ></StyledModal>
  );
};

export default TooManyAttemptsErrorModal;
