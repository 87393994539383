import { FC } from "react";

import { getName } from "helpers";
import DashboardLayout from "layouts/DashboardLayout";
import { WorkerContextMenuActionType } from "components/WorkersWidgets/WorkerActions/types";

import { FrameContainer, FullScreenLoader, IContextMenuOption } from "uikit";

import useAdminWorkerDetails from "./useAdminWorkerDetails";
import { StyledEmployeeInfo } from "./styles";

interface Props {
  className?: string;
}

const EmptyPage: FC<Props> = ({ className }) => {
  const translationPrefix = `people_page.worker_details`;
  const {
    employeeData,
    isLoading,
    actionsLoading,
    frameId,
    handleBack,
    getControls,
    renderWorkerActionsElements,
    onPaymentReadinessStatusClick,
  } = useAdminWorkerDetails();

  const translationValues = { fullName: getName(employeeData) || "" };
  const controls = getControls();
  const getControlByValue = <T extends WorkerContextMenuActionType>(value: T) =>
    controls.find((item): item is IContextMenuOption<T> => item?.value === value);
  const readinessStatusCallback = onPaymentReadinessStatusClick(employeeData!);

  return (
    <DashboardLayout
      onBackBtnClick={handleBack}
      className={className}
      metaDataLoading={!employeeData}
      showTitle={true}
      translationValues={translationValues}
    >
      {(!!isLoading || !!actionsLoading) && <FullScreenLoader />}
      {!!employeeData && (
        <>
          <StyledEmployeeInfo
            user={employeeData}
            editButton={getControlByValue(WorkerContextMenuActionType.ON_EDIT_WORKER)}
            deleteButton={getControlByValue(WorkerContextMenuActionType.ON_DELETE_WORKER)}
            inviteButton={getControlByValue(WorkerContextMenuActionType.INVITE_WORKER)}
            resendButton={getControlByValue(WorkerContextMenuActionType.RESEND_INVITE)}
            finishSetupButton={getControlByValue(WorkerContextMenuActionType.FINISH_SETUP)}
            onPaymentReadinessStatusClick={readinessStatusCallback}
          />

          <FrameContainer id={frameId} />

          {renderWorkerActionsElements()}
        </>
      )}
    </DashboardLayout>
  );
};

export default EmptyPage;
