import { ChangeEvent, FC } from "react";
import { Trans } from "react-i18next";
import { Formik } from "formik";
import { isNull } from "lodash";

import { HELP_CENTER } from "constants/shared";
import { shouldSetPasswordOnly } from "helpers/shared/guardRedirect";
import AuthLayout from "layouts/AuthLayout";
import { PasswordFormType } from "components/auth";
import PasswordForm from "components/auth/PasswordForm/PasswordForm";
import TooManyAttemptsErrorModal from "components/TooManyAttemptsErrorModal";

import { FullScreenLoader, IndicatorBase } from "uikit";
import { Step } from "uikit/StepIndicator/types";

import { AuthForm, BusinessDetails, ContactDetails, SignUpNestedFormName } from "../_shared";
import { AuthFormType } from "../_shared/components/AuthForm/types";
import {
  BtnsWrapper,
  Footer,
  FooterLinkExternal,
  StyledPrimaryBtn,
  StyledSecondaryBtn,
  TabsContainer,
} from "../_shared/components/styles";
import { authAdditionalSectionParams } from "../_shared/constants";
import { useSignUpPage } from "./useSignUpPage";
import { ParentFormType, ParentFormValidationSchema } from "./validationSchema";
import { Wrapper } from "./styles";

const SignUpPage: FC = () => {
  const translationPrefixCommon = `auth_pages`;
  const translationPrefix = `${translationPrefixCommon}.admin.sign_up_page`;
  const { metadata, actions, formData, modals } = useSignUpPage({
    errorModalsTranslationsPrefix: `auth_pages.shared.error_modals`,
  });
  const shouldShowAdditionalLayoutSection = isNull(metadata.currentStep);
  const isSignUpCompleted = shouldSetPasswordOnly(metadata.currentUser);
  const shouldShowTabs = !isSignUpCompleted && !shouldShowAdditionalLayoutSection;

  const getNestedFormPrefix = (prefix: string, fieldName: string) => {
    if (fieldName.includes(prefix)) return fieldName;

    return `${prefix}.${fieldName}`;
  };

  const initialValues = {
    [SignUpNestedFormName.SetPassword]: formData.setPasswordInitValues,
    [SignUpNestedFormName.BusinessDetails]: formData.businessInitialValues,
    [SignUpNestedFormName.ContactDetails]: formData.contactDetailsInitialValues,
  };
  const getValidationSchema = () => {
    if (metadata.currentStep === 0)
      return ParentFormValidationSchema.pick([SignUpNestedFormName.SetPassword]);
    if (metadata.currentStep === 1)
      return ParentFormValidationSchema.pick([SignUpNestedFormName.BusinessDetails]);
    if (metadata.currentStep === 2)
      return ParentFormValidationSchema.pick([SignUpNestedFormName.ContactDetails]);

    return ParentFormValidationSchema;
  };
  const getPrimaryBtnText = () => {
    if (metadata.currentStep === 0)
      return `${translationPrefixCommon}.shared.components.password_form.button_labels.set_password`;
    return `buttons.apply`;
  };

  const steps: Step[] = Array.from({ length: 3 }, (_, i) => ({ index: i }) as Step);

  if (metadata.pageLoader) return <FullScreenLoader />;

  return (
    <AuthLayout
      isLoading={metadata.contentLoader}
      showLogo={shouldShowAdditionalLayoutSection}
      additionalSection={
        shouldShowAdditionalLayoutSection ? authAdditionalSectionParams() : undefined
      }
      mainSectionTopContent={
        shouldShowTabs && (
          <TabsContainer>
            <IndicatorBase steps={steps} currentStep={metadata.currentStep as number} />
          </TabsContainer>
        )
      }
    >
      {isNull(metadata.currentStep) ? (
        <AuthForm
          isLoading={metadata.contentLoader}
          handleGoogleAuth={actions.handleGoogleAuth}
          translationPrefix={`${translationPrefix}.sign_up_step`}
          initValues={formData.magicLinkStepInitValues}
          handleFormSubmit={actions.onSendMagicLink}
          svoc={metadata.svoc}
          type={AuthFormType.SIGN_UP}
        />
      ) : (
        <Formik<ParentFormType>
          initialValues={initialValues}
          validationSchema={getValidationSchema()}
          validateOnChange={metadata.svoc}
          onSubmit={(values, formikActions) =>
            actions.submitHandler(values, formikActions.validateForm)
          }
        >
          {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
            <Wrapper onSubmit={handleSubmit}>
              {metadata.currentStep === 0 && (
                <PasswordForm
                  type={PasswordFormType.CREATE_PASSWORD}
                  fieldPrefix={SignUpNestedFormName.SetPassword}
                />
              )}
              {metadata.currentStep === 1 && (
                <BusinessDetails
                  values={values.businessDetails}
                  errors={errors?.businessDetails || {}}
                  handleChange={(e: ChangeEvent<HTMLInputElement>) => {
                    e.currentTarget.name = getNestedFormPrefix(
                      SignUpNestedFormName.BusinessDetails,
                      e.currentTarget.name,
                    );
                    return handleChange(e);
                  }}
                />
              )}
              {metadata.currentStep === 2 && (
                <ContactDetails
                  values={values.contactDetails}
                  errors={errors?.contactDetails || {}}
                  setFieldValue={async (field: string, value: string) => {
                    setFieldValue(`${SignUpNestedFormName.ContactDetails}.${field}`, value);
                    return;
                  }}
                  handleChange={(e: ChangeEvent<HTMLInputElement>) => {
                    e.currentTarget.name = getNestedFormPrefix(
                      SignUpNestedFormName.ContactDetails,
                      e.currentTarget.name,
                    );
                    return handleChange(e);
                  }}
                />
              )}

              {!metadata.contentLoader && !isNull(metadata?.currentStep) && (
                <BtnsWrapper>
                  {metadata?.currentStep > 1 && (
                    <StyledSecondaryBtn
                      type={"button"}
                      onClick={() => actions.onBackClick()}
                      data-testid="back-btn"
                    >
                      <Trans i18nKey={`buttons.back`} />
                    </StyledSecondaryBtn>
                  )}
                  <StyledPrimaryBtn type="submit">
                    <Trans i18nKey={getPrimaryBtnText()} />
                  </StyledPrimaryBtn>
                </BtnsWrapper>
              )}

              {!isNull(metadata?.currentStep) && (
                <Footer style={{ marginTop: "20px" }}>
                  <FooterLinkExternal href={HELP_CENTER} target="_blank">
                    <Trans i18nKey={`buttons.need_help_btn`} />
                  </FooterLinkExternal>
                </Footer>
              )}
            </Wrapper>
          )}
        </Formik>
      )}

      <TooManyAttemptsErrorModal
        isOpen={modals.tooManyAttemptsErrorModalIsOpen}
        onClose={() => modals.setOpenTooManyAttemptsErrorModal(false)}
      />
    </AuthLayout>
  );
};

export default SignUpPage;
