import { FC } from "react";
import { Trans } from "react-i18next";
import { LogoSimple } from "assets/svg";

import { getFormattedSum } from "helpers";
import DashboardLayout from "layouts/DashboardLayout";
import EmptyList from "components/EmptyList";

import { Accordion, Emphasized, FullScreenLoader, Loader, SectionTitle, TableList } from "uikit";

import { RewardPointsTransactionDto } from "utils/swagger_react_query";

import useRewardsPage from "./useRewardsPage";
import {
  HistoryContainer,
  HistoryTableContainer,
  IIContent,
  IICPCParagraph,
  IICPCParagraphAlt,
  IICPCTextContainer,
  IICPointIconBlock,
  IICPointsContainer,
  IICPointsIconsContainer,
  IICPointsTitle,
  IISeparator,
  IITitle,
  InfoContainer,
  InfoItem,
  InfoItemInner,
  InfoItemsContainer,
  MainContainer,
  MCLeft,
  MCRight,
  RedeemPointsButton,
  RewardPointsContainer,
  RPBalanceContainer,
  RPBLeft,
  RPBPendingLabel,
  RPBPendingPointsContainer,
  RPBPendingValue,
  RPBPLabel,
  RPBPointsContainer,
  RPBPValue,
  RPBRight,
  RPBTitle,
  RPBValue,
  RPBValueCurrency,
  RPTitle,
  RPTitleContainer,
  StyledLogo,
  TermsAndConditionsContainer,
  TermsAndConditionsContent,
  TermsParagraph,
} from "./styles";

interface Props {
  className?: string;
}

const RewardsPage: FC<Props> = ({ className }) => {
  const translationPrefix = `banking_pages.employee.rewards_page`;

  const {
    isLoading,
    isDataLoading,
    isTransactionListLoading,
    rewardsData,
    transactionsData,
    transactionsColumns,
    transactionsPerPage,
    handleRedeemPoints,
    handleTransactionsPageChange,
  } = useRewardsPage();

  const renderInfoPointsLabel = (count: number) => {
    return (
      <IICPointsContainer className={`count-${count}`}>
        <IICPointsIconsContainer>
          {[...Array(count)].map((item, index) => (
            <IICPointIconBlock key={`point_icon_block_${index}`}>
              <LogoSimple />
            </IICPointIconBlock>
          ))}
        </IICPointsIconsContainer>

        <IICPointsTitle>
          <Trans
            i18nKey={`${translationPrefix}.info.options.title`}
            count={count}
            values={{
              pointsCount: count,
            }}
          />
        </IICPointsTitle>
      </IICPointsContainer>
    );
  };

  return (
    <DashboardLayout className={className}>
      {isDataLoading ? (
        <Loader />
      ) : (
        <>
          {isLoading && <FullScreenLoader />}

          <MainContainer>
            <MCLeft>
              {/*NOTE::: Reward Points */}
              <RewardPointsContainer>
                <RPTitleContainer>
                  <StyledLogo />
                  <RPTitle>
                    <Trans i18nKey={`${translationPrefix}.reward_points.logo_title`} />
                  </RPTitle>
                </RPTitleContainer>

                <RPBalanceContainer>
                  <RPBLeft>
                    <RPBTitle>
                      <Trans i18nKey={`${translationPrefix}.reward_points.balance_label`} />
                    </RPBTitle>

                    <RPBPointsContainer>
                      <RPBPValue>
                        {getFormattedSum(rewardsData?.pointsAmount || 0, {
                          symbol: "",
                          precision: 0,
                        })}
                      </RPBPValue>

                      <RPBPLabel>
                        <Trans i18nKey={`${translationPrefix}.reward_points.reward_points_label`} />
                      </RPBPLabel>
                    </RPBPointsContainer>

                    {!!rewardsData?.pendingPointsAmount && (
                      <RPBPendingPointsContainer>
                        <RPBPendingValue>
                          +
                          {getFormattedSum(rewardsData?.pendingPointsAmount || 0, {
                            symbol: "",
                            precision: 0,
                          })}
                        </RPBPendingValue>
                        <RPBPendingLabel>
                          <Trans
                            i18nKey={`${translationPrefix}.reward_points.reward_pending_points_label`}
                          />
                        </RPBPendingLabel>
                      </RPBPendingPointsContainer>
                    )}

                    <RPBValue>
                      <Trans
                        i18nKey={`${translationPrefix}.reward_points.reward_points_currency_value`}
                      />
                      <RPBValueCurrency>
                        {getFormattedSum((rewardsData?.dollarsAmount || 0) / 100)}
                      </RPBValueCurrency>
                    </RPBValue>
                  </RPBLeft>
                  <RPBRight>
                    <RedeemPointsButton
                      onClick={handleRedeemPoints}
                      data-testid="rewards_redeem_points_button"
                    >
                      <Trans i18nKey={`${translationPrefix}.reward_points.redeem_points_button`} />
                    </RedeemPointsButton>
                  </RPBRight>
                </RPBalanceContainer>
              </RewardPointsContainer>

              {/*NOTE::: History */}
              <HistoryContainer>
                <SectionTitle>
                  <Trans i18nKey={`${translationPrefix}.history.title`} />
                </SectionTitle>

                <HistoryTableContainer>
                  <TableList<RewardPointsTransactionDto>
                    data={transactionsData?.transactions || []}
                    isLoading={isTransactionListLoading}
                    columns={transactionsColumns}
                    paginationProps={{
                      page: transactionsData?.page || 1,
                      limit: transactionsPerPage,
                      onPageChange: handleTransactionsPageChange,
                      totalCount: transactionsData?.total || 1,
                    }}
                    emptyListComponent={
                      <EmptyList
                        title={<Trans i18nKey={`${translationPrefix}.history.empty_list_title`} />}
                        message={
                          <Trans i18nKey={`${translationPrefix}.history.empty_list_description`} />
                        }
                      />
                    }
                  />
                </HistoryTableContainer>
              </HistoryContainer>
            </MCLeft>
            <MCRight>
              <InfoContainer>
                <SectionTitle>
                  <Trans i18nKey={`${translationPrefix}.info.title`} />
                </SectionTitle>
                <InfoItemsContainer>
                  <InfoItem>
                    <IITitle>
                      <Trans i18nKey={`${translationPrefix}.info.options.option_1.title`} />
                    </IITitle>

                    <InfoItemInner>
                      <IIContent>
                        {renderInfoPointsLabel(3)}
                        <IICPCTextContainer>
                          <IICPCParagraph>
                            <Trans i18nKey={`${translationPrefix}.info.options.option_1.text_1`} />
                          </IICPCParagraph>
                        </IICPCTextContainer>
                      </IIContent>

                      <IISeparator>
                        <Trans i18nKey={`${translationPrefix}.info.options.or_separator`} />
                      </IISeparator>

                      <IIContent>
                        {renderInfoPointsLabel(1)}
                        <IICPCTextContainer>
                          <IICPCParagraph>
                            <Trans
                              i18nKey={`${translationPrefix}.info.options.option_1.text_2_1`}
                            />
                          </IICPCParagraph>
                        </IICPCTextContainer>
                      </IIContent>
                    </InfoItemInner>
                  </InfoItem>

                  <InfoItem>
                    <IITitle>
                      <Trans i18nKey={`${translationPrefix}.info.options.option_2.title`} />
                    </IITitle>

                    <IIContent>
                      {renderInfoPointsLabel(3)}
                      <IICPCTextContainer>
                        <IICPCParagraph>
                          <Trans i18nKey={`${translationPrefix}.info.options.option_2.text_1_1`} />
                        </IICPCParagraph>
                        <IICPCParagraphAlt>
                          <Trans
                            i18nKey={`${translationPrefix}.info.options.option_2.text_1_2`}
                            components={{
                              1: <Emphasized />,
                            }}
                          />
                        </IICPCParagraphAlt>
                      </IICPCTextContainer>
                    </IIContent>
                  </InfoItem>
                </InfoItemsContainer>
              </InfoContainer>

              {/* NOTE:::Terms and Conditions */}
              <TermsAndConditionsContainer>
                <Accordion
                  id={"rewards_terms_and_conditions"}
                  title={<Trans i18nKey={`${translationPrefix}.terms_and_conditions.title`} />}
                >
                  <TermsAndConditionsContent>
                    {[...Array(4)].map((item, index) => (
                      <TermsParagraph key={`term_${index}`}>
                        <Trans
                          i18nKey={`${translationPrefix}.terms_and_conditions.text.${index}`}
                        />
                      </TermsParagraph>
                    ))}
                  </TermsAndConditionsContent>
                </Accordion>
              </TermsAndConditionsContainer>
            </MCRight>
          </MainContainer>
        </>
      )}
    </DashboardLayout>
  );
};

export default RewardsPage;
