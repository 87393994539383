import styled from "styled-components";

import { EComprehensibleUserStatuses } from "helpers/employee/userStatusMap";

import { labelMixin, stringWrapperMixin, withBgMixin } from "../styles";

export const Container = styled.div`
  display: flex;
  width: fit-content;
`;

export const Label = styled.span<{ withBg: boolean }>`
  ${({ withBg }) => (withBg ? labelMixin : stringWrapperMixin)};
  ${({ withBg }) => withBg && withBgMixin}

  &.${EComprehensibleUserStatuses.EMPLOYED} {
    color: ${({ theme }) => theme.colors.userStatusEmployedText};
    background-color: ${({ withBg, theme }) =>
      withBg ? theme.colors.userStatusEmployedBg : "none"};
  }

  &.${EComprehensibleUserStatuses.INVITED} {
    color: ${({ theme }) => theme.colors.userStatusInvitedText};
    background-color: ${({ withBg, theme }) =>
      withBg ? theme.colors.userStatusInvitedBg : "none"};
  }

  &.${EComprehensibleUserStatuses.PROSPECT} {
    color: ${({ theme }) => theme.colors.userStatusProspectText};
    background-color: ${({ withBg, theme }) =>
      withBg ? theme.colors.userStatusProspectBg : "none"};
  }

  //NOTE::: OLD
  &.${EComprehensibleUserStatuses.PENDING_EXIT} {
    color: ${({ withBg, theme }) =>
      withBg
        ? theme.colors.userStatusTextColorWithBg
        : theme.colors.userStatusPendingExitTextAndBg};
    background-color: ${({ withBg, theme }) =>
      withBg ? theme.colors.userStatusPendingExitTextAndBg : "none"};
    font-style: italic;
  }

  &.${EComprehensibleUserStatuses.EXITED} {
    color: ${({ withBg, theme }) =>
      withBg ? theme.colors.userStatusTextColorWithBg : theme.colors.userStatusExitedTextAndBg};
    background-color: ${({ withBg, theme }) =>
      withBg ? theme.colors.userStatusExitedTextAndBg : "none"};
  }

  &.${EComprehensibleUserStatuses.IN_DEFAULT} {
    color: ${({ withBg, theme }) =>
      withBg ? theme.colors.userStatusTextColorWithBg : theme.colors.userStatusInDefaultTextAndBg};
    background-color: ${({ withBg, theme }) =>
      withBg ? theme.colors.userStatusInDefaultTextAndBg : "none"};
  }

  &.${EComprehensibleUserStatuses.NOT_READY} {
    color: ${({ withBg, theme }) =>
      withBg ? theme.colors.userStatusTextColorWithBg : theme.colors.userStatusNotReadyTextAndBg};
    background-color: ${({ withBg, theme }) =>
      withBg ? theme.colors.userStatusNotReadyTextAndBg : "none"};
  }
`;
