import { FC, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Formik, FormikProps, useFormikContext } from "formik";
import routes from "routes/routes";

import { HELP_CENTER } from "constants/shared";
import { PasswordFormType } from "components/auth";
import PasswordForm from "components/auth/PasswordForm/PasswordForm";

import { FormLabel } from "uikit";

import {
  BtnsWrapper,
  Description,
  Footer,
  FooterLinkExternal,
  FooterLinkInner,
  FooterText,
  FormItem,
  StyledPrimaryBtn,
  StyledSecondaryBtn,
  Title,
} from "../styles";
import { AuthFormType } from "./types";
import { AuthFormSchemaType, authFormTypeValidationSchema } from "./validationSchema";
import { ButtonsSeparatorText, Container, EmailInput, StyledGoogleSignInButton } from "./styles";

interface IProps {
  handleGoogleAuth: (e: any) => void;
  isLoading: boolean;
  initValues: AuthFormSchemaType["SIGN_IN"] | AuthFormSchemaType["SIGN_UP"];
  translationPrefix: string;
  handleFormSubmit: (
    data: FormikProps<AuthFormSchemaType["SIGN_IN"] | AuthFormSchemaType["SIGN_UP"]>,
    withPassword?: boolean,
  ) => Promise<void>;
  svoc: boolean;
  type: AuthFormType;
}

interface IPropsInner extends IProps {
  showPasswordField: boolean;
  setShowPasswordField: (value: boolean) => void;
}

const AuthForm: FC<IPropsInner> = ({
  handleGoogleAuth,
  isLoading,
  handleFormSubmit,
  translationPrefix,
  type = AuthFormType.SIGN_IN,
  showPasswordField,
  setShowPasswordField,
}) => {
  const { t } = useTranslation();

  const formikProps = useFormikContext<AuthFormSchemaType[typeof type]>();
  const { values, handleChange, errors, setFieldValue, setErrors } = formikProps;
  const navigationLink = type === AuthFormType.SIGN_IN ? routes.SIGN_UP : routes.SIGN_IN;
  const isSignUpFlow = type === AuthFormType.SIGN_UP;

  const handleShowPassword = () => {
    setShowPasswordField(true);
  };

  const renderButtons = useMemo(() => {
    const primaryBtnText = `${translationPrefix}.${showPasswordField ? "primary_btn_alt" : "primary_btn"}`;
    const onPrimaryBtnClick = () => {
      if (isSignUpFlow) return handleFormSubmit(formikProps);
      if (!showPasswordField) {
        handleShowPassword();
      } else {
        handleFormSubmit(formikProps, true);
      }
    };
    const onSecondaryBtnClick = () => {
      setErrors({});
      setShowPasswordField(false);
      setFieldValue("password", "");
      setTimeout(() => {
        handleFormSubmit(formikProps);
      });
    };

    return (
      <>
        <BtnsWrapper columned={showPasswordField}>
          {!isSignUpFlow && (
            <StyledSecondaryBtn
              type="button"
              disabled={isLoading}
              tabIndex={3}
              onClick={onSecondaryBtnClick}
              data-testid="secondary-btn"
            >
              <Trans i18nKey={`${translationPrefix}.secondary_btn`} />
            </StyledSecondaryBtn>
          )}

          {showPasswordField && (
            <ButtonsSeparatorText>
              <Trans i18nKey={`${translationPrefix}.or_text`} />
            </ButtonsSeparatorText>
          )}

          <StyledPrimaryBtn
            onClick={onPrimaryBtnClick}
            type={"button"}
            disabled={isLoading}
            tabIndex={2}
            data-testid="primary-btn"
          >
            <Trans i18nKey={primaryBtnText} />
          </StyledPrimaryBtn>
        </BtnsWrapper>

        {!showPasswordField && (
          <ButtonsSeparatorText>
            <Trans i18nKey={`${translationPrefix}.or_text`} />
          </ButtonsSeparatorText>
        )}

        <StyledGoogleSignInButton handleGoogleAuth={handleGoogleAuth} tabIndex={3} />
      </>
    );
  }, [type, showPasswordField, formikProps.values, isLoading, formikProps.errors]);

  return (
    <Container>
      <Title className={"center"}>
        <Trans i18nKey={`${translationPrefix}.title`} />
      </Title>

      {isSignUpFlow && (
        <Description className={"center"}>
          <Trans i18nKey={`${translationPrefix}.description`} />
        </Description>
      )}

      <FormItem>
        <FormLabel>
          <Trans i18nKey={`${translationPrefix}.email_field.title`} />
        </FormLabel>
        <EmailInput
          tabIndex={1}
          name={`email`}
          placeholder={t(`${translationPrefix}.email_field.placeholder`)}
          value={values.email}
          onChange={(e) => {
            e.currentTarget.value = e.currentTarget.value.trim();
            handleChange(e);
          }}
          error={
            errors?.email && (
              <Trans
                i18nKey={errors.email}
                values={{ field: t(`${translationPrefix}.email_field.title`) }}
              />
            )
          }
          errorDataTestId="error-test-id"
        />
      </FormItem>

      {showPasswordField && !isSignUpFlow && (
        <FormItem>
          <PasswordForm type={PasswordFormType.SIGN_IN} />
        </FormItem>
      )}

      {renderButtons}

      <Footer>
        <FooterText>
          <Trans i18nKey={`${translationPrefix}.navigate_text`} />{" "}
          <FooterLinkInner to={navigationLink} tabIndex={4}>
            <Trans i18nKey={`${translationPrefix}.navigate_btn`} />
          </FooterLinkInner>
        </FooterText>
        <FooterLinkExternal href={HELP_CENTER} target="_blank" tabIndex={5}>
          <Trans i18nKey={`buttons.need_help_btn`} />
        </FooterLinkExternal>
      </Footer>
    </Container>
  );
};

const AuthFormWrapper: FC<IProps> = (props) => {
  const { type, initValues, svoc } = props;
  const [showPasswordField, setShowPasswordField] = useState<boolean>(false);
  useEffect(() => {
    return () => {
      setShowPasswordField(false);
    };
  }, []);

  return (
    <Formik<AuthFormSchemaType[typeof type]>
      initialValues={initValues}
      validationSchema={authFormTypeValidationSchema(showPasswordField).fields[type]}
      validateOnChange={svoc}
      onSubmit={() => {}}
    >
      <AuthForm
        showPasswordField={showPasswordField}
        setShowPasswordField={setShowPasswordField}
        {...props}
      />
    </Formik>
  );
};

export default AuthFormWrapper;
