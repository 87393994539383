import { FC, ReactNode } from "react";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import routes from "routes/routes";

import useAuth from "hooks/useAuth";

import {
  Container,
  Content,
  ContentInner,
  Header,
  HeaderInner,
  LogoutButton,
  StyledLogo,
} from "./styles";

interface Props {
  children?: ReactNode | string;
}

const ErrorLayout: FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleLogout = async () => {
    await logout();
    setTimeout(() => {
      navigate(routes.SIGN_IN);
    });
  };

  return (
    <Container>
      <Header>
        <HeaderInner>
          <StyledLogo />
          <LogoutButton onClick={handleLogout}>
            <Trans i18nKey={"error_layout.logout"} />
          </LogoutButton>
        </HeaderInner>
      </Header>
      <Content>
        <ContentInner>{children}</ContentInner>
      </Content>
    </Container>
  );
};

export default ErrorLayout;
