import { FC, useEffect, useState } from "react";
import { RouteProps, useLocation, useNavigate } from "react-router-dom";

import { EMagicLinkFlow } from "types/AuthFlowTypes";
import useAuth from "hooks/useAuth";
import { useCompleteSignIn } from "pages/Auth/_shared";

import { FullScreenLoader } from "uikit";

const UnauthorizedRoute: FC<RouteProps> = ({ children }) => {
  const [userLoaded, setUserLoaded] = useState<boolean>(false);
  const { getCurrentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    actions: { redirectByRoleAndSalsaOnboardingStatus },
  } = useCompleteSignIn({ autoLogin: false, mode: EMagicLinkFlow.SIGN_IN });

  useEffect(() => {
    async function checkUser() {
      const user = await getCurrentUser();
      if (user) {
        redirectByRoleAndSalsaOnboardingStatus(user);
      }
      setUserLoaded(true);
    }

    checkUser();
  }, [location]);

  return <>{!userLoaded ? <FullScreenLoader /> : <>{children}</>}</>;
};

export default UnauthorizedRoute;
