import { isPhysicalCardActiveOrUnactivated } from "helpers/bankCards";
import { isKycAccepted } from "helpers/shared/kyc";

import { BankCardResDto, MfaFactorDto, UserProfileResponseDto } from "utils/swagger_react_query";

export const isEmployeeSetupComplete = (
  userDetails: UserProfileResponseDto | undefined | null,
  bankCards: BankCardResDto[] | undefined,
  factors: MfaFactorDto[] | undefined,
): boolean => {
  const kycIsAccepted = isKycAccepted(userDetails);
  const hasVerifiedFactor = !!factors?.some((factor) => factor.isVerified);
  const hasUserAlreadyOrderedPhysicalCard = bankCards?.some(isPhysicalCardActiveOrUnactivated);

  return !!kycIsAccepted && !!hasVerifiedFactor && !!hasUserAlreadyOrderedPhysicalCard;
};
