declare global {
  interface Window {
    dataLayer: Array<object>;
  }
}

export enum GTMCustomEvent {
  EMPLOYER_SIGN_UP = "employer-sign-up",
}

export function emitGTMEvent(name: GTMCustomEvent) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: name });
}
