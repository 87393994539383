import styled from "styled-components";

import { Link } from "uikit";

import { Description, StyledPrimaryBtn } from "../_shared/components/styles";

export const Container = styled.div``;

export const PasswordFormContainer = styled.form`
  ${StyledPrimaryBtn} {
    width: 100%;
    max-width: 100%;
  }
`;

export const BackButton = styled(Link)`
  color: ${({ theme }) => theme.colors.textInactiveAlt2};
  margin-bottom: 32px;
  display: flex;
  align-items: center;

  &,
  &:link,
  &:active,
  &:hover,
  &:visited {
    color: ${({ theme }) => theme.colors.textInactiveAlt2};
    outline: none;
  }

  svg {
    transform: rotate(180deg);
    margin-right: 8px;

    path {
      stroke: ${({ theme }) => theme.colors.textInactiveAlt2};
    }
  }
`;

export const SecurityDisclaimer = styled(Description)`
  text-align: center;
  margin-top: 8px;
  color: ${({ theme }) => theme.colors.textInactiveAlt2};
`;
