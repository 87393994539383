import { Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { KeyIcon, LockAccessIcon } from "assets/svg";
import routes from "routes/routes";
import { useAppSelector } from "store/hooks";
import { companyMetadataSelector, userMetadataSelector } from "store/selectors";

import { hasAdminRights } from "permissions/helpers/shared";
import { IProps as NavigationCardProps } from "components/NavigationCardWithDetails";

export const useSettingsMFAPage = () => {
  const translationPrefix = `settings_pages.shared.security_settings_page`;
  const currentUser = useAppSelector(userMetadataSelector);
  const currentCompany = useAppSelector(companyMetadataSelector);
  const navigate = useNavigate();
  const isAdmin = hasAdminRights(currentUser);

  const handlePasswordManagementCardClick = () => {
    const route = isAdmin
      ? routes.ADMIN_SETTINGS_PASSWORD_MANAGEMENT
      : routes.EMPLOYEE_SETTINGS_PASSWORD_MANAGEMENT;
    navigate(route);
  };
  const handleTwoFactorAuthenticationCardClick = () => {
    const route = isAdmin
      ? routes.ADMIN_SETTINGS_MFA_AUTHENTICATION
      : routes.EMPLOYEE_SETTINGS_MFA_AUTHENTICATION;
    navigate(route);
  };

  const cardsList: NavigationCardProps[] = [
    {
      id: "password_management",
      icon: <KeyIcon />,
      title: <Trans i18nKey={`${translationPrefix}.cards_list.password_management.title`} />,
      onClick: handlePasswordManagementCardClick,
    },
    {
      id: "two_factor_authentication",
      icon: <LockAccessIcon />,
      title: <Trans i18nKey={`${translationPrefix}.cards_list.two_factor_authentication.title`} />,
      onClick: handleTwoFactorAuthenticationCardClick,
    },
  ];

  return {
    metadata: {
      currentUser,
      currentCompany,
      translationPrefix,
    },
    pageData: {
      cardsList,
    },
  };
};

export default useSettingsMFAPage;
