import { FC } from "react";
import { Trans } from "react-i18next";

import DashboardLayout from "layouts/DashboardLayout";

import { Dud, FullScreenLoader } from "uikit";

import { InviteTeamMemberFormType, InviteTeamMemberModal } from "./components";
import { useColumns } from "./useColumns";
import { useInviteTeamMemberModal } from "./useInviteTeamMemberModal";
import { useTeamMembers } from "./useTeamMembers";
import { Container, Header, InviteTeamMemberBtn, StyledTableList } from "./styles";

const AdminTeamMembersPage: FC = () => {
  const { metadata, pageData, actions } = useTeamMembers();
  const { translationPrefix } = metadata;
  const inviteTeamMemberModal = useInviteTeamMemberModal({
    setPageLoader: metadata.setLoading,
    refetchTeamMembersList: () => actions.refetchTeamMembersList(),
  });

  const { columns } = useColumns({
    translationPrefix,
    currentUser: metadata.currentUser || undefined,
    resendInvitationPermission: metadata.resendInvitationPermission,
    actions: {
      onDisabledAccount: actions.showConfirmationDisableAccountModal,
      onResendInvitation: actions.handleResendInvitation,
      handleUpdateResendInvitationTimeout: actions.handleUpdateResendInvitationTimeout,
    },
  });

  return (
    <DashboardLayout onBackBtnClick={actions.onBackBtnClick}>
      {metadata.isLoading && <FullScreenLoader />}
      <Container>
        <Header>
          <Dud></Dud>
          <InviteTeamMemberBtn
            onClick={() => inviteTeamMemberModal.handleOpenInviteTeamMemberModal()}
          >
            <Trans i18nKey={`${translationPrefix}.invite_btn`} />
          </InviteTeamMemberBtn>
        </Header>
      </Container>

      <StyledTableList
        data={pageData.teamMembersList.data}
        isLoading={metadata.isListLoading}
        columns={columns}
        emptyListComponent={<></>}
        paginationProps={{
          ...pageData.teamMembersList.pagination,
        }}
      />

      <InviteTeamMemberModal
        translationPrefix={translationPrefix}
        isOpen={inviteTeamMemberModal.inviteTeamMemberModalIsOpen}
        onClose={() => inviteTeamMemberModal.handleCloseInviteTeamMemberModal()}
        onSubmit={(values: InviteTeamMemberFormType) =>
          inviteTeamMemberModal.onInviteTeamMember(values)
        }
        employeesList={inviteTeamMemberModal.employeesList}
        handleSearch={inviteTeamMemberModal.handleSearchEmployee}
        fetchMoreUsers={inviteTeamMemberModal.fetchMoreUsers}
        hasMore={inviteTeamMemberModal.hasMore}
        isLoading={inviteTeamMemberModal.isLoading}
      />
    </DashboardLayout>
  );
};

export default AdminTeamMembersPage;
