import { FC } from "react";
import { FormikProps } from "formik";
import { isEmpty } from "lodash";

import AuthLayout from "layouts/AuthLayout";
import TooManyAttemptsErrorModal from "components/TooManyAttemptsErrorModal";

import { FullScreenLoader } from "uikit";

import { AuthForm, AuthFormSchemaType } from "../_shared";
import { AuthFormType } from "../_shared/components/AuthForm/types";
import { useSignInPage } from "./useSignInPage";

const SignInPage: FC = () => {
  const translationPrefix = `auth_pages.shared.sign_in_page`;
  const { metadata, actions, modals, pageLoader } = useSignInPage({
    errorModalsTranslationsPrefix: `auth_pages.shared.error_modals`,
  });

  const onSubmit = async (
    data: FormikProps<AuthFormSchemaType["SIGN_IN"]>,
    withPassword: boolean = false,
  ) => {
    const { validateForm, values } = data;
    const errors = await validateForm(values);
    if (!isEmpty(errors)) return;

    actions.handleSubmit(values, withPassword);
  };

  if (pageLoader) return <FullScreenLoader />;
  return (
    <AuthLayout isLoading={metadata.isLoading} showLogo>
      <AuthForm
        isLoading={metadata.isLoading}
        handleGoogleAuth={actions.handleGoogleAuth}
        translationPrefix={`${translationPrefix}`}
        initValues={metadata.formInitialValues}
        handleFormSubmit={onSubmit}
        svoc={metadata.svoc}
        type={AuthFormType.SIGN_IN}
      />

      <TooManyAttemptsErrorModal
        isOpen={modals.tooManyAttemptsErrorModalIsOpen}
        onClose={() => modals.setOpenTooManyAttemptsErrorModal(false)}
      />
    </AuthLayout>
  );
};

export default SignInPage;
