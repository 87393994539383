import { UserStatus } from "types/BETypes";

export const settingsPages = {
  "en-US": {
    admin: {
      index: {
        options: {
          team_management: {
            title: "Team Management",
            description: "Invite team members to help create and manage payroll.",
            count_one: "{{count}} Team Member",
            count_other: "{{count}} Team Members",
          },
          payroll_and_benefits: {
            title: "Payroll and Benefits Setup",
            description: "View and update all of your Company payroll setup information.",
          },
          documents: {
            title: "Documents",
            description: "Upload and view all of your Company documents.",
          },
          bank_account: {
            title: "Bank Accounts",
            description: "The corporate bank account you wish to use to fund your Payroll.",
            count_one: "{{count}} Bank Account",
            count_other: "{{count}} Bank Accounts",
          },
          security: {
            title: "Security Settings",
          },
        },
      },
      team_members_page: {
        back_btn: "Settings",
        description:
          "It’s lonely running an entire organization by yourself. Invite fellow team members to join you on your quest to distribute and manage payroll programs for your workers.",
        invite_btn: "Invite Team Member",
        table: {
          remaining_time_to_resend_email: "Resend email in <1>{{time}} Seconds</1>",
          you_label: "You",
          columns: {
            name: "Name",
            email: "Email",
            created: "Created",
            last_sign_in: "Last signed in",
            role: "Role",
            status: "Status",
          },
          status: {
            [UserStatus.ACTIVE]: "Active",
            [UserStatus.PENDING_EXIT]: "Active",
            [UserStatus.INVITED]: "Invited",
          },
          context_menu: {
            disable_account_btn: "Disable Account",
            resend_invitation_btn: "Resend Invitation",
          },
        },
        modals: {
          invite_team_member: {
            title: "Invite New Users",
            submit_btn: "Invite Team Member",
            email: "Email",
          },
          invitation_resent_success: {
            title: "Invitation resent",
            message: "An invitation has been resent to {{email}}",
          },
          disable_user_confirmation: {
            message: "Please confirm you wish to disable {{fullName}} account.",
            submit_btn: "Yes, Disable Account",
          },
          disable_user_success: {
            title: "Account Disabled",
            message: "{{fullName}} account has been disabled.",
          },
        },
      },
      documents_page: {
        index: {},
      },
      payroll_and_benefits_page: {
        index: {},
      },
    },
    employee: {
      settings_page: {
        options: {
          my_profile: {
            title: "My Profile",
          },
          bank_account: {
            title: "Bank Accounts",
          },
          pay_distribution: {
            title: "Pay Distribution",
          },
          security: {
            title: "Security Settings",
          },
        },
      },
      bank_accounts_page: {
        connect_bank_account_btn: "Connect Bank Account",
      },
      two_factor_authentication_page: {
        cards_list: {
          sms_text_message: {
            title: "SMS/Text Message",
            description: "Have a temporary code sent to your mobile phone.",
          },
        },
      },
    },
    shared: {
      bank_accounts_page: {},
      security_settings_page: {
        cards_list: {
          two_factor_authentication: {
            title: "Two-Factor Authentication",
          },
          password_management: {
            title: "Password Management",
          },
        },
      },
    },
  },
};

export default settingsPages;
