import { FC, ReactNode } from "react";

import { IndicatorBaseProps, Step } from "./types";
import {
  Container,
  StepContainer,
  StepProgress,
  StepProgressContainer,
  SubstepProgress,
} from "./styles";

const IndicatorBase: FC<IndicatorBaseProps> = ({
  className,
  steps,
  currentStep,
  currentSubstep,
  renderStepContent,
  onStepClick,
  ContainerComponent = Container,
}) => {
  const renderSubStep = (substep: Step, parentStep: Step) => {
    const isActive = parentStep.index === currentStep && substep.index <= (currentSubstep || 0);
    const isLastActive =
      parentStep.index === currentStep && substep.index === (currentSubstep || 0);
    const substepClassName = `${isActive ? "active" : ""} ${
      isLastActive ? "last-active" : ""
    }`.trim();
    return <SubstepProgress key={substep.index} className={substepClassName} />;
  };

  const defaultRenderStepContent = (step: Step): ReactNode => {
    const showSubstepProgress = !!step.substeps?.length && step.index === currentStep;
    return (
      <StepProgressContainer>
        <StepProgress>
          {showSubstepProgress && step.substeps?.map((substep) => renderSubStep(substep, step))}
        </StepProgress>
      </StepProgressContainer>
    );
  };

  const renderStep = (step: Step) => {
    const isCompleted = step.isCompleted ?? currentStep > step.index;
    const isActive = step.index === currentStep;

    const stepClassName = `
      ${isActive ? "active" : ""}
      ${isCompleted ? "completed" : ""}
      ${!!step.substeps?.length && isActive ? "with-active-substeps" : ""}
      ${step.onClick ? "clickable" : ""}
    `.trim();

    const defaultContent = defaultRenderStepContent(step);

    return (
      <StepContainer
        data-testid={`step-indicator-step_${step.index}`}
        key={step.index}
        className={stepClassName}
        onClick={() => onStepClick && onStepClick(step)}
      >
        {renderStepContent
          ? renderStepContent(step, defaultContent, isActive, isCompleted)
          : defaultContent}
      </StepContainer>
    );
  };

  return <ContainerComponent className={className}>{steps.map(renderStep)}</ContainerComponent>;
};

export default IndicatorBase;
