import { BonusStatus } from "types/BETypes";
import { APPLICATION_NAME } from "constants/systemConstants";
import { ExtensionBonusStatuses } from "helpers";

export const bonusStatusTooltipTranslations = {
  "en-US": {
    bonus_one: "bonus",
    bonus_other: "bonuses",
    be_one: "is",
    be_other: "are",
    challenges: {
      [BonusStatus.NOT_COMPLETED]: {
        short:
          "A missed goal is one where the employer responsible for reviewing this goal has determined that the goal has not been met and a reward payment will not be paid.",
        details:
          "A missed goal is one where the employer responsible for reviewing this goal has determined that the goal has not been met and a reward payment will not be paid.",
      },
      [BonusStatus.COMPLETED]: {
        short:
          "Goal achieved means that one or more goals have been reached for this challenge and reward payment has been issued",
        details:
          "Goal achieved means that one or more goals have been reached for this challenge and reward payment has been issued",
      },
      [BonusStatus.ACTIVE]: {
        short:
          "An Active Performance Challenge is considered live until the administrator of the challenge records it as complete or incomplete upon reaching the due date of the challenge.",
        details:
          "An Active Performance Challenge is considered live until the administrator of the challenge records it as complete or incomplete upon reaching the due date of the challenge.",
      },
      [BonusStatus.DRAFT]: {
        short:
          "The bonus is in a draft state and can be modified before sending it to an employee.",
        details:
          "This employee has <1>{{count}}</1> draft {{bonus}}. The bonus will remain in a draft state until it is sent to the employee.",
      },
      [BonusStatus.VOIDED]: {
        short: "The bonus was sent to the employee and was voided by the employer.",
        details:
          "This employee has <1>{{count}}</1> {{bonus}} that has been voided. The bonus was sent to the employee and was voided by the employer.",
      },
      [ExtensionBonusStatuses.PAST_DUE]: {
        short: "The date deadline to complete all the work required has passed.",
        details:
          "This employee has <1>{{count}}</1> {{bonus}} that {{be}} past due date. <br/> <br/>The date deadline to complete all the work required has passed. You may update the recorded progress to show the goal has been completed or select Stop Vesting and call a balance due. ",
      },
      [BonusStatus.VESTING_STOPPED]: {
        //TODO::: For some reason, we have VESTING_STOPPED challenges. Investigate later
        short:
          "The bonus stopped vesting because the employee has not met the terms of the bonus or the employee has left the company. <br/> <br/>Any unvested amount taken by the employee is owed back to the employer.",
        details:
          "This employee has <1>{{count}}</1> {{bonus}} that stopped vesting. The bonus stopped vesting because the employee has not met the terms of the bonus or the employee has left the company. <br/> <br/>Any unvested amount taken by the employee is owed back to the employer.",
      },
    },
    bonuses: {
      [BonusStatus.DRAFT]: {
        short:
          "The bonus is in a draft state and can be modified before sending it to an employee.",
        details:
          "This employee has <1>{{count}}</1> draft {{bonus}}. The bonus will remain in a draft state until it is sent to the employee.",
      },
      [BonusStatus.ACTIVE]: {
        short:
          "The bonus has been accepted by the employee and has been fully funded. <br/> <br/>The employee can access the total bonus amount when it is Active.",
        details:
          "This employee has <1>{{count}}</1> {{bonus}} that has been accepted and has been fully funded. <br/> <br/>The employee can access the total bonus amount when it is Active.",
      },
      [BonusStatus.PENDING]: {
        short: "The bonus has been sent to the employee but the employee has not accepted it.",
        details:
          "This employee has <1>{{count}}</1> pending {{bonus}}. A pending bonus is one that has been sent to the employee but the employee has not accepted it.",
      },
      [BonusStatus.ACCEPTED]: {
        short:
          "An employee has accepted a bonus, but the funds have not been yet been approved by Keep.",
        details: `This employee has <1>{{count}}</1> accepted {{bonus}}. An accepted bonus is one where the employee has accepted the terms of the bonus, but the funds have not been reviewed and approved by ${APPLICATION_NAME}.`,
      },
      [BonusStatus.COMPLETED]: {
        short:
          "A fully vested bonus is one where the employee has met all the milestones for the Keep bonus. <br/> <br/>The employee has earned the bonus and can keep the full amount.",
        details:
          "This employee has <1>{{count}}</1> fully vested {{bonus}}. A fully vested bonus is one where the employee has met all the milestones for the Keep bonus. <br/> <br/>The employee has earned the bonus and can keep the full amount.",
      },
      [BonusStatus.VESTING_STOPPED]: {
        short:
          "The bonus stopped vesting because the employee has not met the terms of the bonus or the employee has left the company. <br/> <br/>Any unvested amount taken by the employee is owed back to the employer.",
        details:
          "This employee has <1>{{count}}</1> {{bonus}} that stopped vesting. The bonus stopped vesting because the employee has not met the terms of the bonus or the employee has left the company. <br/> <br/>Any unvested amount taken by the employee is owed back to the employer.",
      },
      [BonusStatus.VOIDED]: {
        short: "The bonus was sent to the employee and was voided by the employer.",
        details:
          "This employee has <1>{{count}}</1> {{bonus}} that has been voided. The bonus was sent to the employee and was voided by the employer .",
      },
      [BonusStatus.DECLINED]: {
        short: "The employee declined the bonus that was sent to them.",
        details:
          "This employee has <1>{{count}}</1> {{bonus}} that has been declined. The employee declined the bonus terms that was sent to them.",
      },
      [ExtensionBonusStatuses.PAST_DUE]: {
        short: "The date deadline to complete all the work required has passed.",
        details:
          "This employee has <1>{{count}}</1> {{bonus}} that {{be}} past due date. <br/> <br/>The date deadline to complete all the work required has passed. You may update the recorded progress to show the goal has been completed or select Stop Vesting and call a balance due. ",
      },
    },
  },
};

export default bonusStatusTooltipTranslations;
