import styled from "styled-components";

import { breakpoint } from "helpers";

import { LinkTextSmallMixin } from "uikit";
import { NoBorderButton } from "uikit/Buttons/Buttons";

import { BoxMixin } from "../_shared/components/styles";

export const Container = styled.div``;

export const Title = styled.p``;

export const Text = styled.p``;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const EmailAppsSection = styled.div`
  ${BoxMixin}
`;

export const TimeDownContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${breakpoint("xs", "lg")`
    justify-content: space-between;
    align-items: center;
    padding: 0 36px;  
  `}

  ${breakpoint("xs", "md")`
    padding: 0; 
  `}
`;

export const TimeDownText = styled.p`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.textAlt3};
  line-height: 20px;
  margin-bottom: 8px;

  ${breakpoint("xs", "lg")`
    font-size: ${({ theme }) => theme.size.caption};
    width: 152px;
  `}
`;

export const ResendEmailButton = styled(NoBorderButton)`
  ${LinkTextSmallMixin}
  padding: 0;
  margin: 0 0 0 20px;
  height: auto;
  width: auto;
  display: inline;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};

  &:active {
    cursor: pointer;
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.noBorderButtonBgAlt};
    color: ${({ theme }) => theme.colors.noBorderButtonTextDisabled};
  }

  ${breakpoint("xs", "lg")`
    font-size: ${({ theme }) => theme.size.caption};
    margin-left: 8px;
  `}
`;
