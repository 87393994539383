import { FC, ReactNode } from "react";
import { Trans } from "react-i18next";
import { components as reactSelectComponents, ValueContainerProps } from "react-select";
import { PlusThin } from "assets/svg";

import { IMappedBankAccount } from "types/BankAccounts";
import { PayDistributionType } from "types/BETypes";

import CustomSelect from "uikit/Input/Select";

import { AddBankBtn, BankAccountInfoText, OptionsListItem } from "./styles";

export interface IBankAccountsSelectProps {
  bankAccountsList: IMappedBankAccount[];
  selectedBankAccount?: IMappedBankAccount;
  onChange: (account: IMappedBankAccount) => void;
  onAddAccount?: () => void;
  className?: string;
  alwaysShowErrorBlock?: boolean;
  error?: ReactNode;
  name?: string;
  showAddAccountButton?: boolean;
  dataTestId?: string;
}

const BankAccountSelect: FC<IBankAccountsSelectProps> = ({
  bankAccountsList,
  selectedBankAccount,
  onChange,
  onAddAccount,
  className,
  alwaysShowErrorBlock,
  error,
  name,
  dataTestId,
  showAddAccountButton = true,
}) => {
  const commonTranslationPrefix = `components.bank_account_components`;
  const companyAccountBankName = (
    <Trans i18nKey={`${commonTranslationPrefix}.paid_bank_account_name`} />
  );

  const {
    MenuList: RSMenuList,
    Option: RSOption,
    ValueContainer: RSValueContainer,
  } = reactSelectComponents;

  const renderInfo = (name: string = "", last4Digits: string = "", type?: PayDistributionType) => {
    const bankName = name || <Trans i18nKey={`${commonTranslationPrefix}.bank_name_placeholder`} />;
    return (
      <>
        <BankAccountInfoText>
          {type === PayDistributionType.PARTNER_ACCOUNT ? companyAccountBankName : bankName}
        </BankAccountInfoText>
        <BankAccountInfoText>****{last4Digits}</BankAccountInfoText>
      </>
    );
  };

  const renderMenuList = ({ children, ...props }: any) => {
    return (
      <RSMenuList {...props}>
        {children}
        {!!showAddAccountButton && (
          <RSOption {...props}>
            <OptionsListItem>
              <AddBankBtn onClick={onAddAccount} data-testid="add_new_bank_button">
                <PlusThin />
                <Trans i18nKey={`${commonTranslationPrefix}.buttons.add_new_bank`} />
              </AddBankBtn>
            </OptionsListItem>
          </RSOption>
        )}
      </RSMenuList>
    );
  };
  const renderOption = (props: any) => {
    return (
      <RSOption {...props}>
        <OptionsListItem className="between">
          {renderInfo(
            props.data?.institution?.name,
            props.data.last4Digits,
            props.data._accountType,
          )}
        </OptionsListItem>
      </RSOption>
    );
  };
  const renderValueContainer = ({
    children,
    ...props
  }: ValueContainerProps<IMappedBankAccount>) => {
    const value = props.getValue()[0];
    const placeholder = (children as ReactNode[])?.[0];
    const controller = (children as ReactNode[])?.[1];
    return (
      <RSValueContainer {...props}>
        <OptionsListItem>
          {value
            ? renderInfo(value?.institution?.name, value?.last4Digits, value._accountType)
            : placeholder}
          {controller}
        </OptionsListItem>
      </RSValueContainer>
    );
  };

  return (
    <CustomSelect<IMappedBankAccount>
      className={className}
      name={name}
      onChange={(option) => {
        onChange(option as unknown as IMappedBankAccount);
      }}
      value={selectedBankAccount}
      options={bankAccountsList}
      components={{
        MenuList: renderMenuList,
        Option: renderOption,
        ValueContainer: renderValueContainer,
      }}
      error={error}
      alwaysShowErrorBlock={alwaysShowErrorBlock}
      fieldDataTestId={dataTestId}
    />
  );
};

export default BankAccountSelect;
