import { FC } from "react";
import { Trans } from "react-i18next";
import TestingContainer from "_testing/TestingContainer";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { CardForm, CardReissueReason } from "types/CardShipping";
import { AllowedEnvForTestingContent } from "constants/systemConstants";
import { hasAdminRights } from "permissions/helpers/shared";
import {
  CardAdditionalStatus,
  EComprehensibleCardStatus,
  getComprehensibleCardStatus,
} from "helpers/assets/bankCards";
import BankCardStatus from "pages/Banking/shared/components/BankCardStatus";
import {
  canActivateCard,
  canCancelCard,
  canReissueCard,
  canRevealCardDetails,
  canSetCardPin,
} from "pages/Banking/shared/permissions";

import { Loader, Switch } from "uikit";

import { BankCardResDto } from "utils/swagger_react_query";

import PermissionBlocker from "../../../components/PermissionBlocker";
import {
  CardControl,
  getCardControlsByTypeAndComprehensibleCardStatus,
  MARQETA_ELEMENT_IDS,
  PinModalType,
} from "../../constants";
import {
  ActionBtnsSection,
  CardNumber,
  CardNumberContainer,
  CardNumberLabel,
  CardOwnerContainer,
  CardOwnerLabel,
  CardUserInfo,
  CardViewBtn,
  CardViewContainer,
  CardViewContainerInner,
  Container,
  CVCardCvvArea,
  CVCardEmbossNameArea,
  CVCardExpirationDateArea,
  CVCardInfoLabel,
  CVCardNumberArea,
  DangerBtn,
  Header,
  MainContent,
  MarqetaContentContainer,
  MCLeft,
  MContent,
  MCRight,
  MessageSection,
  MIcon,
  MText,
  MTitle,
  PrimaryActionBtn,
  PrivacyCardInfo,
  SecondaryActionBtn,
  StatusWrapper,
  StyledLoaderContainer,
  StyledPaidCardBack,
  StyledPaidCardFront,
  SwitchContainer,
  Title,
} from "./styles";

export interface ICardDetailsActions {
  onActivateCard?: () => void;
  onSetPin?: (type: PinModalType) => void;
  onReissueCard?: () => void;
  onCancelCard?: () => void;
}

interface IProps {
  originTranslationPrefix: string;
  cardDetails: BankCardResDto;
  actions?: ICardDetailsActions;
  cardView?: {
    isRevealed: boolean;
    onReveal: () => void;
    onHide: () => void;
    isLoading?: boolean;
  };
}

enum CardMessageType {
  SET_PIN = "SET_PIN",
  REISSUE = "REISSUE",
}

type ICardMessages = Array<{ type: CardMessageType; translationPrefix: string }>;

export const CardDetails: FC<IProps> = ({
  originTranslationPrefix,
  cardDetails,
  actions,
  cardView,
}) => {
  const translationPrefix = `${originTranslationPrefix}.card_details_component`;
  const cardStatuses = getComprehensibleCardStatus(cardDetails);
  const currentUser = useAppSelector(userMetadataSelector);

  //NOTE:::Permissions
  const userIsAdmin = hasAdminRights(currentUser);
  const userCanSetPin = canSetCardPin(currentUser, cardDetails);
  const userCanCancelCard = canCancelCard(currentUser, cardDetails);
  const userCanReissueCard = canReissueCard(currentUser, cardDetails);
  const userCanRevealCardDetails = canRevealCardDetails(currentUser, cardDetails);
  const userCanActivateCard = canActivateCard(currentUser, cardDetails);

  const allowedActions = getCardControlsByTypeAndComprehensibleCardStatus(
    CardForm[cardDetails.form as CardForm],
    cardStatuses,
    {
      onActivate: actions?.onActivateCard,
      onSetPin: () => actions?.onSetPin && actions?.onSetPin(PinModalType.SET_PIN),
      onResetPin: () => actions?.onSetPin && actions?.onSetPin(PinModalType.RESET_PIN),
      onReissue: actions?.onReissueCard,
      onRevealDetails: cardView?.onReveal,
      onCancel: actions?.onCancelCard,
    },
  );
  const shouldShowCardView = !!allowedActions?.[CardControl.REVEAL_DETAILS] && cardView;
  const cardMessages: ICardMessages = [];

  if (cardStatuses.status === EComprehensibleCardStatus.ACTIVE_WITHOUT_PIN) {
    cardMessages.push({
      type: CardMessageType.SET_PIN,
      translationPrefix: `${translationPrefix}.set_pin_message`,
    });
  }

  if (
    cardStatuses.additionalStatus === CardAdditionalStatus.REISSUED &&
    cardDetails.form === CardForm.PHYSICAL
  ) {
    const reissuedCardMessagePrefix =
      cardDetails.reissueReason === CardReissueReason.EXPIRATION
        ? "reissued_expired_card_message"
        : "reissued_lost_stolen_card_message";
    cardMessages.push({
      type: CardMessageType.REISSUE,
      translationPrefix: `${translationPrefix}.${reissuedCardMessagePrefix}`,
    });
  }

  const onCardViewBtnClick = () => {
    if (cardView?.isRevealed) {
      cardView.onHide();
      return;
    }

    shouldShowCardView && allowedActions?.[CardControl.REVEAL_DETAILS]?.();
  };

  const renderCardControl = () => {
    return (
      <>
        {allowedActions?.[CardControl.ACTIVATE] && (
          <PermissionBlocker id={"activate_button_blocker"} isBlocked={!userCanActivateCard}>
            <PrimaryActionBtn
              onClick={() => allowedActions[CardControl.ACTIVATE]?.()}
              data-testid={"activate-card-btn"}
              disabled={!userCanActivateCard}
            >
              <Trans i18nKey={`${translationPrefix}.action_btns.activate_card`} />
            </PrimaryActionBtn>
          </PermissionBlocker>
        )}

        {allowedActions?.[CardControl.SET_PIN] && (
          <PermissionBlocker id={"set_pin_button_blocker"} isBlocked={!userCanSetPin}>
            <PrimaryActionBtn
              onClick={() => allowedActions[CardControl.SET_PIN]?.()}
              data-testid={"set-pin-btn"}
              disabled={!userCanSetPin}
            >
              <Trans i18nKey={`${translationPrefix}.action_btns.set_pin`} />
            </PrimaryActionBtn>
          </PermissionBlocker>
        )}

        {allowedActions?.[CardControl.RESET_PIN] && (
          <PermissionBlocker id={"reset_pin_button_blocker"} isBlocked={!userCanSetPin}>
            <SecondaryActionBtn
              onClick={() => allowedActions[CardControl.RESET_PIN]?.()}
              data-testid={"reset-pin-btn"}
              disabled={!userCanSetPin}
            >
              <Trans i18nKey={`${translationPrefix}.action_btns.reset_pin`} />
            </SecondaryActionBtn>
          </PermissionBlocker>
        )}
        {allowedActions?.[CardControl.REISSUE] && (
          <PermissionBlocker id={"reissue_card_button_blocker"} isBlocked={!userCanReissueCard}>
            <SecondaryActionBtn
              onClick={() => allowedActions[CardControl.REISSUE]?.()}
              data-testid={"reissue-card-btn"}
              disabled={!userCanReissueCard}
            >
              <Trans i18nKey={`${translationPrefix}.action_btns.reissue_card`} />
            </SecondaryActionBtn>
          </PermissionBlocker>
        )}
        {allowedActions?.[CardControl.CANCEL] && (
          <PermissionBlocker id={"cancel_card_button_blocker"} isBlocked={!userCanCancelCard}>
            <DangerBtn
              onClick={() => allowedActions[CardControl.CANCEL]?.()}
              data-testid={"cancel-card-btn"}
              disabled={!userCanCancelCard}
            >
              <Trans i18nKey={`${translationPrefix}.action_btns.cancel_card`} />
            </DangerBtn>
          </PermissionBlocker>
        )}
      </>
    );
  };

  const renderCardMessages = () =>
    cardMessages?.map((it) => (
      <MessageSection key={it.type} data-testid={`card-notification-${it.type}`}>
        <MIcon />
        <MContent>
          <MTitle>
            <Trans i18nKey={`${it.translationPrefix}.title`} />
          </MTitle>
          <MText>
            <Trans i18nKey={`${it.translationPrefix}.text`} />
          </MText>
        </MContent>
      </MessageSection>
    ));

  return (
    <>
      <Container>
        <Header>
          <Title>
            <Trans i18nKey={`${translationPrefix}.title.${cardDetails.form}`} />
          </Title>

          <StatusWrapper>
            <BankCardStatus data={cardDetails} />
          </StatusWrapper>
        </Header>

        <MainContent>
          <MCLeft>
            <CardNumberContainer>
              <CardNumberLabel>
                <Trans i18nKey={`${translationPrefix}.card_number_label`} />
              </CardNumberLabel>
              <CardNumber>**** **** **** {cardDetails.lastFour}</CardNumber>
            </CardNumberContainer>

            {userIsAdmin && (
              <CardOwnerContainer>
                <CardOwnerLabel>
                  <Trans i18nKey={`${translationPrefix}.card_owner_label`} />
                </CardOwnerLabel>
                <CardUserInfo {...cardDetails.ownerInfo} />
              </CardOwnerContainer>
            )}

            {cardMessages && renderCardMessages()}

            {<ActionBtnsSection>{renderCardControl()}</ActionBtnsSection>}
          </MCLeft>
          <MCRight>
            {shouldShowCardView && (
              <>
                <CardViewContainer>
                  <CardViewContainerInner>
                    {cardView.isLoading ? (
                      <StyledLoaderContainer>
                        <Loader />
                      </StyledLoaderContainer>
                    ) : cardView.isRevealed ? (
                      <StyledPaidCardBack />
                    ) : (
                      <StyledPaidCardFront />
                    )}

                    {cardView?.isRevealed ? (
                      <PrivacyCardInfo isLoading={!!cardView.isLoading}>
                        <CVCardNumberArea>
                          <CVCardInfoLabel>
                            <Trans i18nKey={`${translationPrefix}.card_view.pan_code`} />
                          </CVCardInfoLabel>
                          <MarqetaContentContainer
                            id={`${MARQETA_ELEMENT_IDS.CARD_PAN}_${cardDetails.id}`}
                          />
                        </CVCardNumberArea>

                        <CVCardExpirationDateArea>
                          <CVCardInfoLabel>
                            <Trans i18nKey={`${translationPrefix}.card_view.exp_date`} />
                          </CVCardInfoLabel>
                          <MarqetaContentContainer
                            id={`${MARQETA_ELEMENT_IDS.CARD_EXP}_${cardDetails.id}`}
                          />
                        </CVCardExpirationDateArea>

                        <CVCardCvvArea>
                          <CVCardInfoLabel>
                            <Trans i18nKey={`${translationPrefix}.card_view.cvv`} />
                          </CVCardInfoLabel>
                          <MarqetaContentContainer
                            id={`${MARQETA_ELEMENT_IDS.CARD_CVV}_${cardDetails.id}`}
                          />
                        </CVCardCvvArea>
                      </PrivacyCardInfo>
                    ) : (
                      <CVCardEmbossNameArea>{cardDetails?.embossNameLine1}</CVCardEmbossNameArea>
                    )}
                  </CardViewContainerInner>
                </CardViewContainer>

                <SwitchContainer>
                  <PermissionBlocker
                    id={"reveal_card_details_switcher_blocker"}
                    isBlocked={!userCanRevealCardDetails}
                  >
                    <Switch
                      checked={cardView?.isRevealed}
                      onChange={() => onCardViewBtnClick()}
                      data-testid={"reveal-hide-card-details-switcher"}
                      childrenAfterSwitch={false}
                      disabled={!userCanRevealCardDetails}
                    >
                      {cardView?.isRevealed ? (
                        <Trans i18nKey={`${translationPrefix}.card_view.hide_card_details_btn`} />
                      ) : (
                        <Trans i18nKey={`${translationPrefix}.card_view.reveal_card_details_btn`} />
                      )}
                    </Switch>
                  </PermissionBlocker>
                </SwitchContainer>
              </>
            )}
          </MCRight>
        </MainContent>
      </Container>

      {/* NOTE:::Testing Only Content */}
      {!shouldShowCardView && (
        <TestingContainer allowedEnvs={AllowedEnvForTestingContent}>
          {cardView?.isRevealed && (
            <PrivacyCardInfo isLoading={!!cardView?.isLoading}>
              <CVCardNumberArea>
                <CVCardInfoLabel>
                  <Trans i18nKey={`${translationPrefix}.card_view.pan_code`} />
                </CVCardInfoLabel>
                <MarqetaContentContainer
                  data-testid={"card-pan-code"}
                  id={`${MARQETA_ELEMENT_IDS.CARD_PAN}_${cardDetails.id}`}
                />
              </CVCardNumberArea>

              <CVCardExpirationDateArea>
                <CVCardInfoLabel>
                  <Trans i18nKey={`${translationPrefix}.card_view.exp_date`} />
                </CVCardInfoLabel>
                <MarqetaContentContainer id={`${MARQETA_ELEMENT_IDS.CARD_EXP}_${cardDetails.id}`} />
              </CVCardExpirationDateArea>

              <CVCardCvvArea>
                <CVCardInfoLabel>
                  <Trans i18nKey={`${translationPrefix}.card_view.cvv`} />
                </CVCardInfoLabel>
                <MarqetaContentContainer id={`${MARQETA_ELEMENT_IDS.CARD_CVV}_${cardDetails.id}`} />
              </CVCardCvvArea>
            </PrivacyCardInfo>
          )}

          <PermissionBlocker
            id={"reveal_card_details_button_blocker"}
            isBlocked={!userCanRevealCardDetails}
          >
            <CardViewBtn
              data-testid={"reveal-hide-card-details-btn"}
              onClick={() => {
                if (!userCanRevealCardDetails) {
                  return;
                }
                if (cardView?.isRevealed) {
                  cardView.onHide();
                  return;
                }

                cardView?.onReveal();
              }}
            >
              {cardView?.isRevealed ? (
                <Trans i18nKey={`${translationPrefix}.card_view.hide_card_details_btn`} />
              ) : (
                <Trans i18nKey={`${translationPrefix}.card_view.reveal_card_details_btn`} />
              )}
            </CardViewBtn>
          </PermissionBlocker>
        </TestingContainer>
      )}
    </>
  );
};
