import { NavLink } from "react-router-dom";
import { DoubleChevrons, Logo, LogoFullAlt } from "assets/svg";
import styled, { css, ExecutionContext, keyframes } from "styled-components";

import { UserRole } from "types/BETypes";
import { breakpoint } from "helpers/shared/breakpoint";

interface IMenuBlockProps extends ExecutionContext {
  needRenderMenuForResponsive?: boolean;
}

interface IMenu extends ExecutionContext {
  isOpen?: boolean;
}

const menuBlockWidth = 220;

const menuBlockWidthCollapsed = 68;
const menuContentWidthCollapsed = 36;

const menuItemHeight = 36;
const collapsedMenuItemWidth = 36;

const menuBlockCollapsedStyles = css`
  width: ${menuBlockWidthCollapsed}px;
`;

export const commonTransitionDuration = 250;

const MenuLinkMixin = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: ${menuItemHeight}px;
  padding: 6px 0px 6px 8px;
  border-radius: 8px;
  margin-bottom: 12px;
  transition: all ${commonTransitionDuration}ms linear;

  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  &.collapsed {
    padding: 6px;
  }

  &:active,
  &:focus {
    outline: 0;
    border: none;
    -moz-outline-style: none;
  }

  &,
  &:visited {
    color: ${({ theme }) => theme.colors.mainMenuText};
    background-color: ${({ theme }) => theme.colors.mainMenuButtonBg};

    svg {
      path {
        stroke: ${({ theme }) => theme.colors.mainMenuText};
      }
    }
  }

  &:hover,
  &:active,
  &.active {
    color: ${({ theme }) => theme.colors.mainMenuTextActive};
    background-color: ${({ theme }) => theme.colors.mainMenuButtonBgActive};

    svg {
      path {
        stroke: ${({ theme }) => theme.colors.mainMenuTextActive};
      }
    }

    &.role-${UserRole.EMPLOYEE} {
      color: ${({ theme }) => theme.colors.mainMenuTextActiveEmployee};
      background-color: ${({ theme }) => theme.colors.mainMenuButtonBgActiveEmployee};

      svg {
        path {
          stroke: ${({ theme }) => theme.colors.mainMenuTextActiveEmployee};
        }
      }
    }
  }

  path {
    transition: all ${commonTransitionDuration}ms ease;
  }

  .tooltip {
    display: none;
    ${breakpoint("xs", "xlm")`
      &.collapsed, & {
        display: block;
      }
    `}

    &.collapsed {
      display: block;
    }
  }
`;

const collapseBtnOutAnimation = keyframes`
  0% {
    opacity: 0;
    display: none;
  }
  90% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
    display: flex;
  }
`;
const collapseBtnInAnimation = keyframes`
  0% {
    display: none;
    opacity: 0;
  }
  90% {
    display: none;
    opacity: 0;
  }
  100% {
    display: flex;
    opacity: 1;
  }
`;

export const CollapseBtn = styled.div`
  display: flex;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.mainMenuCollapseBtnBg};
  width: ${collapsedMenuItemWidth}px;
  justify-content: center;
  align-items: center;

  &.animate {
    &.collapsed {
      animation: ${collapseBtnInAnimation} ${commonTransitionDuration}ms forwards linear;
    }

    animation: ${collapseBtnOutAnimation} ${commonTransitionDuration}ms forwards linear;
  }

  &:hover {
    opacity: 0.7;
  }

  ${breakpoint("xs", "xlm")`
    display: none;
  `}
`;

export const CollapseBtnIcon = styled(DoubleChevrons)`
  transform: rotate(180deg);

  &.collapsed {
    transform: none;
  }
`;

export const LogoContainer = styled.div`
  position: relative;

  &.collapsed {
    width: ${menuContentWidthCollapsed}px;
    height: ${menuContentWidthCollapsed}px;
    transition-property: width, height;
    transition-duration: ${commonTransitionDuration}ms;
    transition-timing-function: linear;
  }
`;

const collapseLogoFull = keyframes`
  0% {
    opacity: 1;
  }
  30% {
    z-index: -1;
    opacity: 0.2;
  }
  80% {
    opacity: 0;
    width: ${collapsedMenuItemWidth}px;
    position: absolute;
    visibility: hidden;
    z-index: -1;
  }
  100% {
    opacity: 0;
    position: absolute;
    width: ${collapsedMenuItemWidth}px;
    visibility: hidden;
    z-index: -1;
  }
`;

const expandLogoFull = keyframes`
  0% {
    width: 0;
    opacity: 0;
  }
  50% {
    opacity: 0;
    width: 0;
  }
  100% {
    opacity: 1;
  }
`;

const showLogoSmall = keyframes`
  0% {
    opacity: 0;
    visibility: visible;
  }
  60% {
    opacity: 0.3;
    position: absolute;
    top: 0;
    height: ${menuItemHeight}px;
    visibility: visible;
    z-index: 5;
  }
  100% {
    opacity: 1;
    position: relative;
    visibility: visible;
    z-index: 5;
  }
`;
const hideLogoSmall = keyframes`
  0% {
    opacity: 1;
  }
  5% {
    opacity: 0;
    position: absolute;
    visibility: hidden;
  }
  100% {
    opacity: 0;
  }
`;

export const StyledLogoFullAlt = styled(LogoFullAlt)`
  &.animate {
    animation: ${expandLogoFull} ${commonTransitionDuration}ms forwards linear;

    &.collapsed {
      animation: ${collapseLogoFull} ${commonTransitionDuration}ms forwards linear;
      min-height: ${menuItemHeight}px;
      min-width: ${collapsedMenuItemWidth}px;
    }
  }

  &.collapsed:not(&.animate) {
    position: absolute;
    visibility: hidden;
  }

  ${breakpoint("xs", "xlm")`
    position: absolute;
    visibility: hidden;
  `}
`;

export const StyledLogo = styled(Logo)`
  &.animate {
    &.collapsed {
      animation: ${showLogoSmall} ${commonTransitionDuration}ms forwards linear;
    }

    &:not(.collapsed) {
      animation: ${hideLogoSmall} ${commonTransitionDuration}ms forwards linear;
    }
  }

  &:not(.collapsed:not(&.animate)) {
    position: absolute;
    visibility: hidden;
  }

  ${breakpoint("xs", "xlm")`
    position: relative !important;
    visibility: visible !important;
  `}
`;

export const MenuBlock = styled.div<IMenuBlockProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${menuBlockWidth}px;
  max-height: 100vh;
  box-sizing: border-box;
  padding: 16px;
  background: ${({ theme }) => theme.colors.mainMenuBg};
  transition-property: width, background, padding;
  transition-duration: ${commonTransitionDuration}ms;
  transition-timing-function: linear;

  &.role-${UserRole.EMPLOYEE} {
    background: ${({ theme }) => theme.colors.mainMenuEmployeeBg};
  }

  &.collapsed {
    ${menuBlockCollapsedStyles};
  }

  ${breakpoint("xs", "xlm")`
    ${menuBlockCollapsedStyles}
  `}
`;

export const MBTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 38px;
  height: 51px;

  &.collapsed {
    gap: 10px;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    height: 70px;
    margin-bottom: 20px;
    transition: all ${commonTransitionDuration}ms linear;
  }
`;

export const List = styled.p`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const MenuLink = styled(NavLink)`
  ${MenuLinkMixin}
`;

export const MenuBaseLink = styled.a`
  ${MenuLinkMixin}
`;

export const IconContainer = styled.div`
  width: 24px;
  height: 24px;

  svg {
    width: inherit;
    height: inherit;
  }
`;

export const LabelContainer = styled.span`
  display: block;
  margin-left: 12px;
  overflow: hidden;
  line-height: 24px;
  transition: ${commonTransitionDuration}ms linear;

  &.collapsed {
    margin-left: 0;
    visibility: hidden;
  }

  ${breakpoint("lg", "xlm")`
      display: none;
  `}
`;

export const MBMainContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint("lg", "xl")`
      padding: 0;
  `}
`;

//NOTE:::RESPONSIVE MENU

export const ResponsiveMenuWrapper = styled.div`
  display: none;

  ${breakpoint("xs", "lg")`
    display: flex;
    align-items: center;
 `}
`;

export const PageName = styled.p`
  color: ${({ theme }) => theme.colors.titleMobile};
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  ${breakpoint("md", "lg")`
      font-size: ${({ theme }) => theme.size.subtitle};
   `}

  ${breakpoint("xs", "md")`
      font-size: ${({ theme }) => theme.size.body2};
   `}
`;

export const ShowBtn = styled.span`
  margin-right: 20px;
  cursor: pointer;

  svg {
    path {
      stroke: ${({ theme }) => theme.colors.mainMenuText};
    }
  }
`;

export const MenuBackground = styled.div<IMenu>`
  display: none;
`;

export const SideMenuWrapper = styled.div<IMenu>`
  display: none;
`;

export const SideMenuHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 28px 0;
`;

export const CloseBtn = styled.span`
  position: absolute;
  top: 14px;
  right: 14px;
  cursor: pointer;

  svg {
    path {
      fill: ${({ theme }) => theme.colors.mainMenuText};
    }
  }
`;

export const SideMenuListWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  right: -8px;
  width: 224px;
  flex: 1 0 auto;
`;
