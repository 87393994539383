import PromoBgPba from "assets/png/promo_man_alt.png";
import PromoBg from "assets/png/promo_woman.png";
import { LogoFull } from "assets/svg";
import styled from "styled-components";

import { breakpoint } from "helpers/";
import { NavigationCardWithDetailsLarge } from "components/NavigationCardWithDetails";
import {
  AdditionalContent as NCAdditionalContent,
  ContainerLarge as NCLargeCardContainer,
  ContainerLargeInner as NCLargeCardContainerInner,
  hContainerPadding,
  MainContent as NCMainContent,
  ViewButton as NCViewButton,
  vLargeContainerPadding,
} from "components/NavigationCardWithDetails/styles";

import { NoBorderButton, PrimaryButtonMixin } from "uikit";

export const largeCardContentHeight = 460;
export const largeCardContentHeightSmall = 360;

export const largeCard1ContentWidth = 700;

export const Container = styled(NavigationCardWithDetailsLarge)`
  ${NCLargeCardContainer} {
    background-image: url("${PromoBg}");
    background-repeat: no-repeat;
    background-size: auto ${largeCardContentHeight}px;
    background-position: top right;
  }

  ${NCLargeCardContainerInner} {
    height: ${largeCardContentHeight}px;
  }

  ${NCViewButton} {
    ${PrimaryButtonMixin}
    min-width: 0;
    width: auto;
  }

  &.card_1 {
    ${breakpoint("xxl")`
      ${NCMainContent} {
        width: 50%;
      }
      
      ${NCAdditionalContent} {
        width: 50%;
      }
    `}
  }
`;

export const ContainerAlt = styled(Container)`
  ${NCLargeCardContainerInner} {
    height: ${largeCardContentHeightSmall}px;
  }
`;

export const ContainerPba = styled(Container)`
  ${NCLargeCardContainer} {
    background-image: url("${PromoBgPba}");
  }
`;

export const CompanyEmphasized = styled.span`
  text-transform: uppercase;
`;

//NOTE:::Large company bank account card main content
export const CBACardMainContent = styled.div``;

export const CBCMATop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const CBCMATopLogo = styled.div`
  margin-right: 30px;
  svg {
    height: 53px;
    width: auto;
  }
`;

export const CBCMATopTitle = styled.div`
  font-size: ${({ theme }) => theme.size.title};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.paymentMethodsLargeContentTitleAlt};
  line-height: 1.26;
  margin-bottom: 10px;
`;

export const CBCMATitle = styled.div`
  margin-top: 14px;
  font-size: ${({ theme }) => theme.size.title};
  font-weight: ${({ theme }) => theme.fontWeight.extraBold};
  line-height: 1.26;
`;

export const CBCMATitleAlt = styled(CBCMATitle)`
  margin-top: 32px;
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  margin-bottom: 8px;
`;

export const CBCMATextContainer = styled.div`
  margin-top: 44px;
  margin-bottom: 64px;
  max-width: 330px;
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 1.26;
`;

export const CBCMATextContainerCard1 = styled(CBCMATextContainer)`
  width: 100%;
  max-width: 100%;
`;

export const CBCMATextEmphasized = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.extraBold};
`;

export const CBCMAText = styled.div`
  margin-top: 16px;
`;

export const CBCMAAmountText = styled.span`
  display: inline-block;
  font-size: ${({ theme }) => theme.size.subtitleLarge};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.primary};
`;

export const CBCMAAmountTextSmall = styled.span`
  display: inline-block;
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.primary};
`;

//NOTE:::Large company bank account card additional content
export const CBACardContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: ${vLargeContainerPadding}px;
  padding-bottom: ${vLargeContainerPadding}px;
`;

export const CBALogo = styled(LogoFull)`
  position: absolute;
  top: 32px;
  right: 450px;
`;

export const CBAMainContent = styled.div`
  padding: 0 143px 0 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const CBAMainContentPba = styled.div`
  padding: 75px 292px 0 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const CBATextCloud = styled.div`
  position: relative;
  width: 171px;
  height: 77px;

  ${ContainerPba} & {
    height: 73px;
  }
`;

export const CBATCBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const CBATCInner = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 12px;
  z-index: 1;
`;

export const CBATCLogoContainer = styled.div`
  margin-right: 8px;
`;

export const CBATCText = styled.div`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 1.36;
`;

//NOTE:::Large company bank account card footer content

export const CBACardFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px 24px 60px 82px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.paymentMethodsLargeFooterBg};
  border-radius: 0 0 16px 16px;
`;

export const CBACFPromoPointsContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;

  ${ContainerPba} & {
    max-width: 800px;
  }
`;

export const CBACFPromoPoint = styled.div`
  text-align: center;
`;

export const CBACFPPTitle = styled.div`
  font-size: ${({ theme }) => theme.size.titleLarge};
  font-weight: ${({ theme }) => theme.fontWeight.extraBold};
  line-height: 1.26;
`;

export const CBACFPPSubitle = styled.div`
  font-size: ${({ theme }) => theme.size.subtitleLarge};
  font-weight: ${({ theme }) => theme.fontWeight.extraBold};
  line-height: 1.26;
`;

export const CBACFPPText = styled.div`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 1.46;
`;

export const CBACFDisclaimer = styled.div`
  margin-top: 32px;
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 1.47;
  color: ${({ theme }) => theme.colors.paymentMethodsLargeFooterTextAlt};
  text-align: center;
`;

export const CardBottomContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  top: -10px;
  height: 68px;
  width: 100%;
  border-radius: 0 0 8px 8px;
  padding: 10px ${hContainerPadding}px 0 ${hContainerPadding}px;
  box-sizing: border-box;
`;

//NOTE:::Bottom content
export const CBCText = styled.p`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 1.75;
`;

export const CBCIconContainer = styled.div`
  margin-right: 12px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

//NOTE:::Company Account below warning card
export const CBCWarning = styled(CardBottomContainer)`
  justify-content: center;
  background: ${({ theme }) => theme.colors.paymentMethodsSubcardBelowContentWarningBg};
`;

export const CBCWIconContainer = styled(CBCIconContainer)`
  svg {
    path {
      fill: ${({ theme }) => theme.colors.paymentMethodsSubcardBelowContentWarningText};
    }
  }
`;

export const CBCWText = styled(CBCText)`
  color: ${({ theme }) => theme.colors.paymentMethodsSubcardBelowContentWarningText};
`;

//NOTE:::Company Account below error card
export const CBCError = styled(CardBottomContainer)`
  justify-content: center;
  background: ${({ theme }) => theme.colors.paymentMethodsSubcardBelowContentErrorBg};
`;

export const CBCEIconContainer = styled(CBCIconContainer)`
  svg {
    path {
      fill: ${({ theme }) => theme.colors.paymentMethodsSubcardBelowContentErrorText};
    }
  }
`;

export const CBCEErrorText = styled(CBCText)`
  color: ${({ theme }) => theme.colors.paymentMethodsSubcardBelowContentErrorText};
  margin-right: 20px;
`;

export const CBCEButton = styled(NoBorderButton)`
  width: auto;
  min-width: auto;
  padding: 0;
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.paymentMethodsSubcardBelowContentErrorTextAlt};

  text-decoration: underline;
  line-height: 1.5;

  &:hover,
  &:active {
    color: ${({ theme }) => theme.colors.paymentMethodsSubcardBelowContentErrorTextAltHover};
  }
`;

//NOTE:::Card 2
export const CBCPromoCheckListContainer = styled.div`
  margin-bottom: 40px;
`;

export const CBCPromoCheckListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const CBCPromoCheckListCheck = styled.div`
  margin-right: 8px;
  svg {
    height: 20px;
    width: auto;

    circle {
      fill: ${({ theme }) => theme.colors.success};
    }
  }
`;

export const CBCPromoCheckListCheckLabel = styled.div`
  min-height: 20px;
`;
