import { FC } from "react";
import { Trans } from "react-i18next";
import { LogoFull } from "assets/svg";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { Emphasized } from "uikit";

import { IProps } from "./types";
import useCBACards from "./useCBACards";
import {
  CBACardFooter,
  CBACardMainContent,
  CBACFDisclaimer,
  CBACFPPSubitle,
  CBACFPPText,
  CBACFPPTitle,
  CBACFPromoPoint,
  CBACFPromoPointsContainer,
  CBCMAAmountText,
  CBCMAAmountTextSmall,
  CBCMAText,
  CBCMATextContainerCard1,
  CBCMATextEmphasized,
  CBCMATitle,
  CBCMATop,
  CBCMATopLogo,
  CBCMATopTitle,
  CompanyEmphasized,
  Container,
} from "./styles";

const CBACard1: FC<IProps> = (props) => {
  const { className } = props;

  const commonTranslationPrefix = `components.company_bank_account_promo_cards.shared`;
  const translationPrefix = `components.company_bank_account_promo_cards.card1`;

  const currentUser = useAppSelector(userMetadataSelector);

  const { baseConfig } = useCBACards(props);

  const renderLargeCompanyBankAccountCardContent = () => {
    return (
      <CBACardMainContent>
        <CBCMATop>
          <CBCMATopLogo>
            <LogoFull />
          </CBCMATopLogo>
          <CBCMATopTitle>
            <Trans i18nKey={`${commonTranslationPrefix}.title_common`} />
          </CBCMATopTitle>
        </CBCMATop>
        <CBCMATitle>
          <Trans i18nKey={`${translationPrefix}.title`} />
        </CBCMATitle>
        <CBCMATextContainerCard1>
          <Trans
            i18nKey={`${translationPrefix}.description`}
            values={{
              firstName: currentUser?.user?.firstName || "",
            }}
            components={{
              1: <CBCMATextEmphasized />,
              2: <CBCMAText />,
              3: <Emphasized />,
              4: <CBCMAAmountText />,
              5: <CBCMAAmountTextSmall />,
            }}
          />
        </CBCMATextContainerCard1>
      </CBACardMainContent>
    );
  };

  const renderLargeCompanyBankAccountCardFooterContent = () => {
    return (
      <CBACardFooter>
        <CBACFPromoPointsContainer>
          <CBACFPromoPoint>
            <Trans
              i18nKey={`${commonTranslationPrefix}.promo_points.p1_alt`}
              components={{
                1: <CBACFPPTitle />,
                2: <CBACFPPSubitle />,
                3: <CBACFPPText />,
              }}
            />
          </CBACFPromoPoint>

          <CBACFPromoPoint>
            <Trans
              i18nKey={`${commonTranslationPrefix}.promo_points.p2`}
              components={{
                1: <CBACFPPTitle />,
                2: <CBACFPPSubitle />,
                3: <CBACFPPText />,
              }}
            />
          </CBACFPromoPoint>

          <CBACFPromoPoint>
            <Trans
              i18nKey={`${commonTranslationPrefix}.promo_points.p3_alt`}
              components={{
                1: <CBACFPPTitle />,
                2: <CBACFPPSubitle />,
                3: <CBACFPPText />,
              }}
            />
          </CBACFPromoPoint>
        </CBACFPromoPointsContainer>
        <CBACFDisclaimer>
          <Trans i18nKey={`${translationPrefix}.disclaimer`} />
        </CBACFDisclaimer>
      </CBACardFooter>
    );
  };

  const cardConfig = {
    title: (
      <Trans
        i18nKey={`${translationPrefix}.title`}
        components={{
          1: <CompanyEmphasized />,
        }}
      />
    ),
    description: (
      <Trans
        i18nKey={`${translationPrefix}.description`}
        components={{
          1: <CompanyEmphasized />,
        }}
      />
    ),
    largeContent: renderLargeCompanyBankAccountCardContent(),
    largeFooterContent: renderLargeCompanyBankAccountCardFooterContent(),
  };

  const config: IProps = {
    ...baseConfig,
    ...cardConfig,
    ...props,
  };

  return <Container className={`card_1 ${className}`} {...config} />;
};

export default CBACard1;
