import CardTemplateImageAlt from "assets/png/card_template_alt.png";
import { PaidCardFront } from "assets/svg";
import styled from "styled-components";

import { H2 } from "uikit";

import { FormSection, HDescription } from "../components/styles";

const cardTemplateSectionWidth = 382;

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`;

export const ContainerInner = styled.div`
  display: flex;
  gap: 24px;
  flex: 1;
  overflow: auto;
`;

export const FormContainer = styled(FormSection)`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
`;

export const FormContainerInner = styled.div``;

export const CardTemplateSection = styled.div`
  width: ${cardTemplateSectionWidth}px;
`;

export const CTSHeader = styled.div``;

export const CTSHTitle = styled(H2)`
  font-size: ${({ theme }) => theme.size.subtitleLarge};
`;

export const CTSHDescription = styled(HDescription)``;

export const CardTemplateContainer = styled.div`
  position: relative;
  margin-top: 24px;
`;

export const CardTemplateContainerAdminWithBg = styled(CardTemplateContainer)`
  background-image: url(${CardTemplateImageAlt});
  background-size: auto ${cardTemplateSectionWidth};
  background-repeat: no-repeat;
  background-position: top right;
  width: ${cardTemplateSectionWidth}px;
  height: 244px;
`;

export const CardTemplateIcon = styled(PaidCardFront)`
  width: ${cardTemplateSectionWidth}px;
  height: 240px;
`;

export const CardOwnerTextContainer = styled.div`
  position: absolute;
  gap: 6px;
  display: flex;
  flex-wrap: wrap;
  width: 240px;
  left: 30px;
  bottom: 19px;
`;

export const CardOwnerText = styled.span`
  font-size: ${({ theme }) => theme.size.body1};
  color: ${({ theme }) => theme.colors.inputPlaceholder};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;
//NOTE:::ADMIN
export const FormHeader = styled.div`
  margin-bottom: 16px;
  flex: 1;
`;
export const CardTypeSection = styled.div``;

export const FHTitle = styled.p`
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.text};
  line-height: 23.4px;
  margin-bottom: 4px;
`;

export const FHDescription = styled.p`
  color: ${({ theme }) => theme.colors.textAlt};
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 21px;
`;

export const SelectUserContainer = styled.div`
  ${FHDescription} {
    margin-bottom: 16px;
  }
`;
