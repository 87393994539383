import { useEffect, useMemo, useState } from "react";
import { FormikProps } from "formik";
import { isEmpty } from "lodash";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { UserRole, UserStatus } from "types/BETypes";
import { CardForm } from "types/CardShipping";
import { hasEmployeeRights } from "permissions/helpers/shared";
import { isPhysicalCardActiveOrUnactivated } from "helpers/bankCards";
import { getErrorMessageFromResponse } from "helpers/shared/errors";
import { convertBusinessStructurePersonToUserResponseDto } from "helpers/shared/userData";
import useAuth from "hooks/useAuth";
import { getBusinessStructureMembers } from "components/BusinessOwnersStructure/helpers";

import {
  mutationAdminsControllerInviteTeamMember,
  mutationBankCardsControllerIssueBankCard,
  UserResponseDto,
} from "utils/swagger_react_query";

import { CompanyCardShippingDetailsFormType } from "./components/CompanyCardShippingDetailsForm";
import {
  convertShippingFormDataToReqPhysicalCardPayload,
  convertShippingFormDataToReqVirtualCardPayload,
  getSelectedCardholderIssuedCards,
  userDetailsToShippingDetailsFormInitialValues,
} from "./helpers";
import { IUseCompanyCardShippingDetailsProps } from "./types";

type IHandleSubmitParams = Pick<
  FormikProps<CompanyCardShippingDetailsFormType>,
  "values" | "validateForm"
>;

export const useCompanyCardShippingDetails = ({
  onSubmitSuccess,
  defaultSelectedCardType,
  businessOwnersStructure,
  bankCardsList,
  handleRefetchBusinessOwnersStructure,
  reissueFlow,
  adminCardholderReissueData,
}: IUseCompanyCardShippingDetailsProps) => {
  const currentUser = useAppSelector(userMetadataSelector);
  const { getCurrentUser } = useAuth();
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const selectedCardTypeInitValue = () => {
    if (defaultSelectedCardType) {
      return defaultSelectedCardType;
    }
    if (hasEmployeeRights(currentUser)) {
      return [CardForm.PHYSICAL];
    }
    return [];
  };
  const [selectedCardType, setSelectedCardType] = useState<CardForm[]>(selectedCardTypeInitValue());
  const [selectedCardholderData, setSelectedCardholderData] = useState<Partial<UserResponseDto>>();
  const [isInviteToCardholderSent, setIsInviteToCardholderSent] = useState<boolean>(false);
  const [isInviteToCardholderSending, setIsInviteToCardholderSending] = useState<boolean>(false);

  useEffect(() => {
    setIsInviteToCardholderSent(false);
  }, [selectedCardholderData]);

  const getInitValues = () => {
    if (currentUser?.user?.lastActiveRole === UserRole.EMPLOYEE) {
      return userDetailsToShippingDetailsFormInitialValues(currentUser);
    }

    if (reissueFlow) {
      return userDetailsToShippingDetailsFormInitialValues(adminCardholderReissueData);
    }

    return userDetailsToShippingDetailsFormInitialValues(selectedCardholderData);
  };

  const handleSetCardType = (cardType: CardForm) => {
    const alreadySelected = selectedCardType.includes(cardType);
    if (alreadySelected) {
      setSelectedCardType(selectedCardType.filter((type) => type !== cardType));
    } else {
      setSelectedCardType([...selectedCardType, cardType]);
    }
  };

  const handleSetCardholderData = (data: Partial<UserResponseDto>) => {
    const isCardholderStatusActive = data.status === UserStatus.ACTIVE;
    const isPhysicalCardAlreadyIssued = getSelectedCardholderIssuedCards(
      data.userId || "",
      bankCardsList,
    ).some((it) => it.form === CardForm.PHYSICAL);
    if (isPhysicalCardAlreadyIssued || !isCardholderStatusActive) {
      setSelectedCardType([]);
    } else {
      setSelectedCardType([CardForm.PHYSICAL]);
    }
    setSelectedCardholderData(data);
  };

  const handleSubmit = async ({ values, validateForm }: IHandleSubmitParams) => {
    try {
      setIsSubmitting(true);
      const isPhysicalCardSelected = selectedCardType.includes(CardForm.PHYSICAL);
      const isVirtualCardSelected = selectedCardType.includes(CardForm.VIRTUAL);
      const isPhysicalCardAlreadyIssued = selectedCardholderExistingCardForms?.includes(
        CardForm.PHYSICAL,
      );

      if (isPhysicalCardSelected) {
        const errors = await validateForm(values);
        if (!isEmpty(errors)) return;
      }

      const shouldRefetchUser = selectedCardholderData?.email === currentUser?.user?.email;
      const isEmployee = currentUser?.user?.lastActiveRole === UserRole.EMPLOYEE;
      const userId = isEmployee ? currentUser?.user?.userId : selectedCardholderData?.userId;
      const role = isEmployee ? UserRole.EMPLOYEE : UserRole.ADMIN;

      if (isPhysicalCardSelected && !isPhysicalCardAlreadyIssued) {
        const payload = convertShippingFormDataToReqPhysicalCardPayload(userId || "", role, values);
        await mutationBankCardsControllerIssueBankCard()(payload);
      }
      if (isVirtualCardSelected) {
        const payload = convertShippingFormDataToReqVirtualCardPayload(userId || "", role);
        await mutationBankCardsControllerIssueBankCard()(payload);
      }

      await onSubmitSuccess?.(selectedCardType);

      if (shouldRefetchUser) {
        await getCurrentUser();
      }
    } catch (error) {
      setErrorMessage(getErrorMessageFromResponse(error));
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSendInviteToCardholder = async (email: string) => {
    try {
      setIsInviteToCardholderSending(true);
      await mutationAdminsControllerInviteTeamMember()({
        email: email,
        companyRole: UserRole.ADMIN,
      });
      await handleRefetchBusinessOwnersStructure?.();
      setIsInviteToCardholderSent(true);
    } catch (error) {
      setErrorMessage(getErrorMessageFromResponse(error));
    } finally {
      setIsInviteToCardholderSending(false);
    }
  };

  const potentialCardholdersList: Partial<UserResponseDto>[] = useMemo(
    () =>
      getBusinessStructureMembers(businessOwnersStructure || null, true).map((it) =>
        convertBusinessStructurePersonToUserResponseDto(it),
      ),
    [businessOwnersStructure],
  );

  const selectedCardholderExistingCardForms = useMemo(() => {
    const issuedCards = getSelectedCardholderIssuedCards(
      selectedCardholderData?.userId || "",
      bankCardsList,
    );
    const hasUserActiveOrInactiveCard = issuedCards.filter(isPhysicalCardActiveOrUnactivated);
    const result = hasUserActiveOrInactiveCard?.map((it) => it.form as CardForm);

    return result;
  }, [selectedCardholderData, bankCardsList]);

  return {
    errorMessage,
    isSubmitting,
    getInitValues,
    handleSetCardType,
    handleSetCardholderData,
    handleSubmit,
    selectedCardType,
    selectedCardholderData,
    potentialCardholdersList,
    businessOwnersStructure,
    selectedCardholderExistingCardForms,
    handleSendInviteToCardholder,
    isInviteToCardholderSent,
    isInviteToCardholderSending,
  };
};
