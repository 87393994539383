//NOTE:::Test enums and interfaces, set the ones from schema later;
export enum IHistoryDataType {
  DEBIT = "DEBIT",
  CREDIT = "CREDIT",
  DEBIT_PENDING = "DEBIT_PENDING",
  DEBIT_CANCELLED = "DEBIT_CANCELLED",
}
export interface IHistoryData {
  id: string;
  date: Date;
  type: IHistoryDataType;
  amount: number;
  points: number;
}
