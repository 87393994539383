import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormikContextType } from "formik";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { MFAFactorType } from "types/BETypes";
import { HELP_EMAIL } from "constants/shared";
import { showErrorModal } from "helpers";

import { Loader } from "uikit";

import {
  MfaChallengeDto,
  MfaFactorDto,
  mutationAuthControllerChallengeMfaFactor,
  mutationAuthControllerEnrollMfaFactor,
  mutationAuthControllerVerifyMfaFactor,
  queryAuthControllerListMfaFactors,
} from "utils/swagger_react_query";

import { MFAWidgetType } from "../../types";
import EnterPhoneStep from "./steps/EnterPhone";
import { IEnterPhoneStepProps } from "./steps/EnterPhone/types";
import { FormType as EnterPhoneFormType } from "./steps/EnterPhone/validationSchema";
import SuccessState from "./steps/SuccessState";
import VerifyCodeStep from "./steps/VerifyCode";
import { IVerifyCodeStepProps } from "./steps/VerifyCode/types";
import { FormType as VerifyCodeFormType } from "./steps/VerifyCode/validationSchema";
import { ESMSWidgetSteps, IProps } from "./types";
import { ContainerInner, ContainerInnerLoader, StepContainer } from "./styles";

const useMFASMS = (props: IProps) => {
  const {
    widgetType,
    defaultPhone,
    onEnterPhoneSubmit,
    onEnterPhoneCancel,
    onVerifyCodeSubmit,
    onVerifyCodeCancel,
    onSubmitSuccess,
    fetchDataOnMount = true,
  } = props;

  const defaultFactorType = MFAFactorType.SMS;

  const { t } = useTranslation();
  const currentUser = useAppSelector(userMetadataSelector);
  const [currentStep, setCurrentStep] = useState<ESMSWidgetSteps>(ESMSWidgetSteps.ENTER_PHONE);
  const [factorsData, setFactorsData] = useState<MfaFactorDto[] | undefined>(undefined);
  const [lastEnteredPhone, setLastEnteredPhone] = useState<string | undefined>(undefined);
  const [currentFactor, setCurrentFactor] = useState<MfaFactorDto | undefined>(undefined);
  const [currentFactorChallenge, setCurrentFactorChallenge] = useState<MfaChallengeDto | undefined>(
    undefined,
  );

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isDataLoading, setDataLoading] = useState<boolean>(true);

  const translationPrefix = `components.multi_factor_authorization.sms`;
  const errorsPrefix = `errors`;

  const fetchData = async () => {
    try {
      setDataLoading(true);
      const result = await queryAuthControllerListMfaFactors();
      setFactorsData(result.factors);
      return result.factors;
    } catch (error) {
      showErrorModal(error);
    } finally {
      setDataLoading(false);
    }
  };

  const selectFactor = (factor: MfaFactorDto) => {
    setCurrentFactor(factor);
  };

  const selectDefaultFactor = (factors: MfaFactorDto[]) => {
    const data = factors || factorsData;

    if (!data?.length) return;

    if (widgetType === MFAWidgetType.MISC) {
      const defaultFactor = data.find((item) => item.type === defaultFactorType && item.isDefault);
      if (defaultFactor) {
        selectFactor(defaultFactor);
      }
    }
  };

  const createFactorChallenge = async (factorId: string) => {
    try {
      setLoading(true);
      const challenge = await mutationAuthControllerChallengeMfaFactor({
        factorId: factorId || "",
      })();

      setCurrentFactorChallenge(challenge);
      setLoading(false);
      return {
        challenge,
      };
    } catch (error) {
      setLoading(false);
      return {
        error,
      };
    }
  };

  const submitEnterPhone = async (formikContext: FormikContextType<EnterPhoneFormType>) => {
    const { values, setFieldError, validateForm } = formikContext;

    validateForm(values).then(async (errors) => {
      const errorList = Object.values(errors);
      const hasErrors = !!errorList.length;

      if (!hasErrors) {
        if (onEnterPhoneSubmit) {
          onEnterPhoneSubmit(formikContext);
        } else {
          try {
            setLoading(true);
            let factor = factorsData?.find(
              (item) => item.type === defaultFactorType && item.phoneNumber === values.phoneNumber,
            );

            if (!factor) {
              factor = await mutationAuthControllerEnrollMfaFactor()({
                type: defaultFactorType,
                accountName: currentUser?.user?.email,
                phoneNumber: values.phoneNumber,
              });
              await fetchData(); //NOTE:::Need to get an up-to-date factors array after changing phone
            }
            const challengeResult = await createFactorChallenge(factor.factorId);

            if (challengeResult.error) {
              setFieldError(
                "phoneNumber",
                t(`${errorsPrefix}.${(challengeResult.error as any)?.data?.error}`),
              );
              return;
            }

            selectFactor(factor);
            setLastEnteredPhone(values.phoneNumber);
            setCurrentStep(ESMSWidgetSteps.VERIFY_CODE);
          } catch (error) {
            setFieldError(
              "phoneNumber",
              t(`${errorsPrefix}.${(error as any)?.data?.error}`, { helpEmail: HELP_EMAIL }),
            );
          } finally {
            setLoading(false);
          }
        }
      }
    });
  };

  const cancelEnterPhone = () => {
    onEnterPhoneCancel?.();
  };

  const submitVerificationCode = async (formikContext: FormikContextType<VerifyCodeFormType>) => {
    const { values, validateForm, setFieldError } = formikContext;

    validateForm(values).then(async (errors) => {
      const errorList = Object.values(errors);
      const hasErrors = !!errorList.length;

      if (!hasErrors) {
        if (onVerifyCodeSubmit) {
          onVerifyCodeSubmit(formikContext);
        } else {
          try {
            setLoading(true);
            await mutationAuthControllerVerifyMfaFactor({
              factorId: currentFactor?.factorId || "",
            })({
              challengeId: currentFactorChallenge?.challengeId || "",
              code: values.code || "",
            });

            if (widgetType === MFAWidgetType.SETUP) {
              setCurrentStep(ESMSWidgetSteps.SUCCESS);
            }
            onSubmitSuccess?.();
          } catch (error) {
            setFieldError(
              "code",
              t(`${errorsPrefix}.${(error as any)?.data?.error}`, { helpEmail: HELP_EMAIL }),
            );
          } finally {
            setLoading(false);
          }
        }
      }
    });
  };

  const cancelVerificationCode = () => {
    onVerifyCodeCancel?.();
  };

  const resendVerificationCode = async (callback: () => void) => {
    if (!currentFactor?.factorId) return;
    const challengeResult = await createFactorChallenge(currentFactor.factorId);
    if (!challengeResult.error) {
      callback?.();
    }
  };

  const editVerificationPhone = async () => {
    setCurrentStep(ESMSWidgetSteps.ENTER_PHONE);
  };

  const renderEnterPhoneStep = (props?: IEnterPhoneStepProps) => {
    const baseProps: IEnterPhoneStepProps = {
      widgetType,
      defaultPhone: lastEnteredPhone || defaultPhone || currentUser?.user?.phone || "",
      onSubmit: submitEnterPhone,
      onCancel: cancelEnterPhone,
    };

    const widgetProps = { ...baseProps, ...(props || {}) };

    return (
      <StepContainer>
        <EnterPhoneStep {...widgetProps} />
      </StepContainer>
    );
  };

  const renderVerifyCodeStep = (props?: IVerifyCodeStepProps) => {
    const baseProps: IVerifyCodeStepProps = {
      widgetType,
      onSubmit: submitVerificationCode,
      onCancel: cancelVerificationCode,
      selectedMfaFactor: currentFactor,
      onResend: resendVerificationCode,
      onEditPhone: editVerificationPhone,
    };

    const widgetProps = { ...baseProps, ...(props || {}) };

    return (
      <StepContainer>
        <VerifyCodeStep {...widgetProps} />
      </StepContainer>
    );
  };

  const renderSuccessStateStep = () => {
    return (
      <StepContainer>
        <SuccessState />
      </StepContainer>
    );
  };

  const renderCurrentStep = () => {
    return (
      <>
        {isDataLoading ? (
          <Loader />
        ) : (
          <ContainerInner>
            {!!isLoading && <ContainerInnerLoader />}
            {currentStep === ESMSWidgetSteps.ENTER_PHONE && renderEnterPhoneStep()}
            {currentStep === ESMSWidgetSteps.VERIFY_CODE && renderVerifyCodeStep()}
            {currentStep === ESMSWidgetSteps.SUCCESS && renderSuccessStateStep()}
          </ContainerInner>
        )}
      </>
    );
  };

  useEffect(() => {
    const initializeData = async () => {
      const data = await fetchData();
      if (data) {
        selectDefaultFactor(data);
      }
      setDataLoading(false);
    };

    if (fetchDataOnMount) {
      initializeData();
    }
  }, []);

  return {
    currentStep,
    isLoading,
    isDataLoading,
    factorsData,
    currentFactor,
    currentFactorChallenge,
    translationPrefix,
    errorsPrefix,
    renderEnterPhoneStep,
    renderVerifyCodeStep,
    renderSuccessStateStep,
    renderCurrentStep,
    createFactorChallenge,
    resendVerificationCode,
    submitVerificationCode,
    cancelVerificationCode,
    cancelEnterPhone,
    submitEnterPhone,
    selectFactor,
    fetchData,
  };
};

export default useMFASMS;
