import { FC, ReactNode } from "react";

import { BaseInputProps } from "./types";
import { Container, InputBase, InputWrapper, SearchIconBtn, Units } from "./styles";

export type Props = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
  label?: ReactNode | string; //NOTE:::Move this garbage in common props or smth
  readOnly?: boolean; //NOTE:::Move this garbage in common props or smth
  alwaysShowErrorBlock?: boolean; //NOTE:::Move this garbage in common props or smth
  optional?: boolean; //NOTE:::Move this garbage in common props or smth
} & BaseInputProps;

export const SearchInput: FC<Props> = ({
  className,
  label,
  error,
  readOnly,
  value,
  placeholder,
  onChange,
  alwaysShowErrorBlock = true,
  optional = false,
  ...rest
}) => {
  return (
    <Container className={`search-input ${className} ${optional ? "optional" : ""}`}>
      <InputWrapper>
        <Units>
          <SearchIconBtn />
        </Units>
        <InputBase
          value={value}
          onChange={(e) => onChange(e.currentTarget.value)}
          placeholder={placeholder}
          {...rest}
        />
      </InputWrapper>
    </Container>
  );
};
