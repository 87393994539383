import { FC } from "react";
import { CheckmarkRoundFilled } from "assets/svg";

import IndicatorBase from "./IndicatorBase";
import { IStepIndicatorProps, Step } from "./types";
import { StepCompletedIcon, StepLabel, StepLabelContainer } from "./styles";

const StepIndicator: FC<IStepIndicatorProps> = ({
  className,
  steps,
  currentStep,
  currentSubstep,
}) => {
  const renderStepContent = (
    step: Step,
    defaultContent: React.ReactNode,
    isActive: boolean,
    isCompleted: boolean,
  ) => (
    <>
      <StepLabelContainer>
        {isCompleted && (
          <StepCompletedIcon data-testid={`step-indicator-complete-step-icon_${step.index}`}>
            <CheckmarkRoundFilled />
          </StepCompletedIcon>
        )}
        <StepLabel>{step.label}</StepLabel>
      </StepLabelContainer>
      {defaultContent}
    </>
  );

  const onStepClick = (step: Step) => {
    if (step.onClick) {
      step.onClick();
    }
  };

  return (
    <IndicatorBase
      className={className}
      steps={steps}
      currentStep={currentStep}
      currentSubstep={currentSubstep}
      renderStepContent={renderStepContent}
      onStepClick={onStepClick}
    />
  );
};

export default StepIndicator;
