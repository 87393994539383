import actions from "store/actions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { salsaSelector } from "store/selectors";

import { getSalsaAuthToken } from "services/salsa";
import { UserRole } from "types/BETypes";
import { ICreateInstanceParams, SalsaExtendedWindow } from "types/Salsa";

import { UserProfileResponseDto } from "utils/swagger_react_query";

import { useScript } from "./useScript";

export const useConnectSalsa = () => {
  const salsaData = useAppSelector(salsaSelector);
  const dispatch = useAppDispatch();
  useScript(process.env.REACT_APP_SALSA_SCRIPT_URL || "", "Salsa");

  const connectSalsa = async (user: UserProfileResponseDto, forceDispatchSalsaData?: boolean) => {
    if (user) {
      let _salsa = null;
      if ((window as unknown as SalsaExtendedWindow).Salsa) {
        if (!salsaData?.salsa || !salsaData?.authToken || forceDispatchSalsaData) {
          const _payload: ICreateInstanceParams = {
            env: process.env.REACT_APP_SALSA_ENV_KEY || "",
          };
          _salsa = (window as unknown as SalsaExtendedWindow).Salsa(
            process.env.REACT_APP_SALSA_PUBLIC_CLIENT_KEY || "",
            _payload,
          );

          const _tokenRes = await getSalsaAuthToken(user?.user?.lastActiveRole as UserRole);
          if (_tokenRes) {
            dispatch(
              actions.salsaData.setSalsa({
                salsa: _salsa || salsaData?.salsa,
                authToken: _tokenRes,
              }),
            );
          }
        }
      }
    }
  };

  return {
    salsa: salsaData?.salsa,
    connectSalsa,
  };
};
