import styled from "styled-components";

import { breakpoint } from "helpers/shared/breakpoint";
import Avatar from "components/Avatar";
import { Image as AvatarWrapper } from "components/Avatar/styles";

import { SecondaryButtonIconed } from "uikit";

const avatarSize = 110;

//NOTE:::employee info alt

export const Container = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.contentBorder};
  border-radius: 8px;
  padding: 24px;

  ${breakpoint("xs", "md")`
    flex-direction: column;
    width: 100%;
  `}
`;

export const LeftSection = styled.div`
  display: flex;
  margin-right: 32px;
`;

export const StyledAvatar = styled(Avatar)`
  ${AvatarWrapper} {
    width: ${avatarSize}px;
    height: ${avatarSize}px;
    font-size: ${({ theme }) => theme.size.titleSemiLarge} !important;
  }
`;

export const MainContent = styled.div`
  display: flex;
  flex: 1;

  ${breakpoint("xs", "md")`
    box-sizing: border-box;
    width: 100%;
    padding: 16px 16px 6px 16px;
    border-radius: 0 0 12px 12px;
  `}
`;

export const MCLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const MCLeftTop = styled.div`
  display: flex;

  div {
    margin-right: 12px;
  }
`;

export const FullUserNameText = styled.span`
  font-size: ${({ theme }) => theme.size.subtitleLarge};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.text};
  line-height: 26px;
  margin-right: 12px;
  margin-bottom: 5px;
`;

export const ValueText = styled.span`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.textAlt};
  margin-bottom: 8px;
  line-height: 18.2px;

  ${breakpoint("xs", "md")`
    font-size: ${({ theme }) => theme.size.caption};
  `}

  svg {
    margin-right: 4px;
    width: 16px;
    height: 16px;

    path {
      stroke: ${({ theme }) => theme.colors.textAlt4};
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ValueTextAlt = styled.span`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.textInactiveAlt2};
  line-height: 18.2px;
  margin-bottom: 8px;
`;

export const MCRight = styled.div`
  flex: 0.6;
  display: flex;
  justify-content: flex-end;
`;

export const ControlsContainer = styled.div`
  display: flex;

  button {
    font-size: ${({ theme }) => theme.size.body2};
    width: fit-content;
    min-width: auto;
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const StyledSecondaryButtonIconed = styled(SecondaryButtonIconed)`
  svg {
    path {
      fill: none;
      stroke: ${({ theme }) => theme.colors.primary};
    }
  }
`;
