export enum OldRoutesForRedirect {
  /** This is a list of old routes that we want to redirect to new routes */
  /** EMPLOYEE_BANKING_MONTHLY_STATEMENTS - the previous route was specific to employees, the new route is general for all users */
  EMPLOYEE_BANKING_MONTHLY_STATEMENTS = "/employee/banking/monthly-statements",
  /** EMPLOYEE_CARD_EXTERNAL - external link used in physical letters to employees */
  EMPLOYEE_CARD_EXTERNAL = "/card",
  /** EMPLOYEE_CARD_DETAILS - the previous route was specific to employees, the new route is general for all users */
  EMPLOYEE_CARD_DETAILS = "/employee/banking/cards",
  /** REWARDS - the route has changed */
  REWARDS = "/rewards",
}
