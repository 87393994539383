export enum PasswordFormType {
  SIGN_IN = "SIGN_IN",
  CREATE_PASSWORD = "CREATE_PASSWORD",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  RESET_PASSWORD = "RESET_PASSWORD",
}

export interface IPasswordFormProps {
  type: PasswordFormType;
  fieldPrefix?: string;
}
