import { FC, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { UploadIconSmall } from "assets/svg";
import { FormikProps } from "formik";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { SyncteraAccountBalanceType } from "types/BETypes";
import { MAX_INPUT_LENGTH_LONG } from "constants/form";
import { getFieldError, getHiddenNumber } from "helpers";
import { isBankAccountVerified } from "helpers/bankCards";
import PermissionBlocker from "pages/Banking/shared/components/PermissionBlocker";
import { canTransferFunds } from "pages/Banking/shared/permissions";
import BankAccountsSelect from "components/BankAccountsSelect";
import { IBankAccountsSelectProps } from "components/BankAccountsSelect/BankAccountsSelect";

import { FormLabel, Input, InputContainerMargined, MoneyInput } from "uikit";

import { SyncteraAccountDto } from "utils/swagger_react_query";

import { TransferFundsFormType } from "./validationSchema";
import { ButtonContainer, Container, FromInput, Row, RowDud, TransferFundsButton } from "./styles";

interface IProps {
  originTranslationPrefix: string;
  bankAccountSelectProps: IBankAccountsSelectProps;
  companyBankAccount: SyncteraAccountDto | undefined;
  formikProps: FormikProps<TransferFundsFormType>;
  setSvoc: (value: boolean) => void;
}

export const TransferFundsForm: FC<IProps> = ({
  originTranslationPrefix,
  bankAccountSelectProps,
  companyBankAccount,
  formikProps,
  setSvoc,
}) => {
  const { t } = useTranslation();
  const translationPrefix = `${originTranslationPrefix}.payment_details_section.transfer_funds_form`;
  const { values, handleChange, setFieldValue, handleSubmit, setFieldError, validateOnChange } =
    formikProps;
  const { selectedBankAccount } = bankAccountSelectProps;
  const currentUser = useAppSelector(userMetadataSelector);

  //NOTE:::Permissions
  const userCanTransferFunds = canTransferFunds(currentUser);

  const isSubmitBtnDisabled =
    !isBankAccountVerified(selectedBankAccount) ||
    !values.amount ||
    values.amount === "0" ||
    !bankAccountSelectProps.selectedBankAccount;

  useEffect(() => {
    if (validateOnChange) {
      handleSetAmountInputError();
    }
  }, [values.amount, companyBankAccount?.balances, validateOnChange]);

  const handleSetAmountInputError = () => {
    const availableBalance =
      companyBankAccount?.balances?.find(
        (it) => it.type === SyncteraAccountBalanceType.AVAILABLE_BALANCE,
      )?.balance || 0;

    const isInputValueHigherThanAvailableBalance = Number(values.amount) > availableBalance / 100;
    if (isInputValueHigherThanAvailableBalance) {
      setFieldError("amount", `${translationPrefix}.input_errors.amount_higher_than_balance`);
      return true;
    } else {
      setFieldError("amount", "");
      return false;
    }
  };

  return (
    <Container
      onSubmit={(e) => {
        e.preventDefault();
        setSvoc(true);
        const isInputValueHigherThanAvailableBalance = handleSetAmountInputError();
        if (!isInputValueHigherThanAvailableBalance) {
          handleSubmit(e);
        }
      }}
    >
      <Row>
        <InputContainerMargined>
          <FormLabel>
            <Trans i18nKey={`${translationPrefix}.from`} />
          </FormLabel>
          <FromInput
            name={"from"}
            value={getHiddenNumber(companyBankAccount?.accountNumber || "", 3)}
            alwaysShowErrorBlock={false}
            disabled
          />
        </InputContainerMargined>

        <InputContainerMargined>
          <FormLabel>
            <Trans i18nKey={`${translationPrefix}.to`} />
          </FormLabel>
          <BankAccountsSelect {...bankAccountSelectProps} />
        </InputContainerMargined>
      </Row>

      <Row>
        <InputContainerMargined>
          <FormLabel>
            <Trans i18nKey={`${translationPrefix}.amount`} />
          </FormLabel>
          <MoneyInput
            decimalScale={2}
            name={"amount"}
            value={values.amount}
            onChange={(val) => setFieldValue("amount", val)}
            error={getFieldError("amount", formikProps, {
              field: t(`${translationPrefix}.amount`),
            })}
            alwaysShowErrorBlock={false}
          />
        </InputContainerMargined>

        <InputContainerMargined>
          <FormLabel>
            <Trans i18nKey={`${translationPrefix}.description`} /> (
            <Trans i18nKey={"common.input.optional"} />)
          </FormLabel>
          <Input
            name={"description"}
            value={values.description}
            onChange={handleChange}
            error={getFieldError("description", formikProps, {
              field: t(`${translationPrefix}.description`),
              maxInputLength: MAX_INPUT_LENGTH_LONG,
            })}
            alwaysShowErrorBlock={false}
          />
        </InputContainerMargined>
      </Row>

      <Row>
        <RowDud></RowDud>
        <ButtonContainer>
          <PermissionBlocker
            id={"transfer_funds_submit_button_blocker"}
            isBlocked={!userCanTransferFunds}
          >
            <TransferFundsButton
              type="submit"
              disabled={!!isSubmitBtnDisabled || !userCanTransferFunds}
            >
              <UploadIconSmall />
              <Trans i18nKey={`${translationPrefix}.submit_btn`} />
            </TransferFundsButton>
          </PermissionBlocker>
        </ButtonContainer>
      </Row>
    </Container>
  );
};
