import { FC } from "react";

import PayDistribution from "components/PayDistribution";
import { PayDistributionWidgetType } from "components/PayDistribution/types";

import { Container } from "./styles";

interface Props {
  className?: string;
  onBack: () => void;
  onNoAccountsModalConfirm: () => void;
  onBankAccountAdd: () => void;
}

const PayDistributionStep: FC<Props> = ({
  className,
  onBack,
  onNoAccountsModalConfirm,
  onBankAccountAdd,
}) => {
  return (
    <Container className={className}>
      <PayDistribution
        widgetType={PayDistributionWidgetType.ONBOARDING}
        onBack={onBack}
        onNoAccountsModalConfirm={onNoAccountsModalConfirm}
        onBankAccountAdd={onBankAccountAdd}
      />
    </Container>
  );
};

export default PayDistributionStep;
