import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormikErrors, FormikProps } from "formik";
import { isEmpty, isNull } from "lodash";
import { useRouteHelpers } from "routes/helpers";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { EMagicLinkFlow } from "types/AuthFlowTypes";
import { ErrorConst } from "types/BETypes";
import { showErrorModal } from "helpers";
import { getBusinessDetails, getSuperAdminDetails } from "helpers/form/authForms";
import { getShouldOwnerCompleteSignUp } from "helpers/shared/guardRedirect";
import useAuth from "hooks/useAuth";
import { PasswordFormSchemasType } from "components/auth";

import {
  CreateCompanyAndSuperadminDto,
  CreateCompanyResponseDto,
  mutationUsersControllerAcceptTermsAndConditions,
  mutationUsersControllerCreateOrUpdatePassword,
  useMutationCompaniesControllerCreateCompany,
  UserProfileResponseDto,
} from "utils/swagger_react_query";

import { emitGTMEvent, GTMCustomEvent } from "../../../helpers/shared/gtm";
import {
  AuthFormSchemaType,
  BusinessDetailsFormType,
  ContactDetailsFormType,
  useCompleteSignIn,
  useSingIn,
} from "../_shared";
import { ParentFormType } from "./validationSchema";

interface IParams {
  errorModalsTranslationsPrefix: string;
}

interface IFormValues {
  companyInfo?: CreateCompanyAndSuperadminDto["companyInfo"];
  contactInfo?: CreateCompanyAndSuperadminDto["userInfo"];
}

export const useSignUpPage = ({ errorModalsTranslationsPrefix }: IParams) => {
  const navigate = useNavigate();
  const signInStep = useSingIn(EMagicLinkFlow.SIGN_UP);
  const currentUser = useAppSelector(userMetadataSelector);
  const { getCurrentUser, isAuthenticated } = useAuth();
  const { getDefaultRoute } = useRouteHelpers();

  const auth = isAuthenticated();
  const [currentStep, setCurrentStep] = useState<number | null>(auth ? 0 : null);
  const [contentLoader, setContentLoader] = useState<boolean>(false);
  const [svoc, setSvoc] = useState<boolean>(signInStep.metadata.svoc);

  const {
    metadata: { isLoading: pageLoader },
  } = useCompleteSignIn({ mode: EMagicLinkFlow.SIGN_UP });

  useEffect(() => {
    if (auth && currentUser && !pageLoader) {
      determineInitialStep(currentUser);
    }
  }, [pageLoader]);

  const determineInitialStep = (user: UserProfileResponseDto | null | undefined) => {
    if (!user?.isPasswordSet) {
      setCurrentStep(0);
    } else {
      setCurrentStep(1);
    }
  };

  const [formValues, setFormValues] = useState<IFormValues>({
    companyInfo: undefined,
    contactInfo: undefined,
  });

  const magicLinkStepInitValues = signInStep.metadata.formInitialValues;

  const setPasswordInitValues: PasswordFormSchemasType["CREATE_PASSWORD"] = {
    newPassword: "",
    confirmPassword: "",
  };
  const businessInitialValues: BusinessDetailsFormType = {
    businessName: "",
  };

  const contactDetailsInitialValues: ContactDetailsFormType = {
    firstName: "",
    lastName: "",
    jobTitle: "",
    phone: "",
  };

  const getFormInitValues = () => {
    if (currentStep === 0) {
      return businessInitialValues;
    } else if (currentStep === 1) {
      return contactDetailsInitialValues;
    }
  };

  const { mutate: submitOnboarding } = useMutationCompaniesControllerCreateCompany({
    onSuccess: async (data: CreateCompanyResponseDto) => {
      await mutationUsersControllerAcceptTermsAndConditions()();

      if (!data) throw new Error(ErrorConst.INTERNAL_ERROR);

      emitGTMEvent(GTMCustomEvent.EMPLOYER_SIGN_UP);

      setContentLoader(false);
      navigate(getDefaultRoute());
    },
    onError: (error: any) => {
      setContentLoader(false);
      showErrorModal(error);
    },
  });

  const handleSetPassword = async (values: ParentFormType["setPassword"]) => {
    try {
      setContentLoader(true);
      await mutationUsersControllerCreateOrUpdatePassword()({
        newPassword: values.newPassword,
      });
      const userDetails = await getCurrentUser();
      const isSignUpCompleted = !getShouldOwnerCompleteSignUp(
        userDetails,
        !!userDetails?.user?.companyId,
      );
      if (isSignUpCompleted) {
        navigate(getDefaultRoute());
      } else {
        setCurrentStep(1);
      }
    } catch (error) {
      showErrorModal(error);
    } finally {
      setContentLoader(false);
    }
  };

  const handleSubmitBusinessDetails = (values: BusinessDetailsFormType) => {
    setSvoc(true);
    setFormValues((prev) => ({ ...prev, companyInfo: getBusinessDetails(values) }));
    setCurrentStep((currentStep || 0) + 1);
    setSvoc(false);
  };

  const handleSubmitContactDetails = (
    values: ParentFormType,
    validateForm: (values?: ParentFormType) => Promise<FormikErrors<ParentFormType>>,
  ) => {
    setSvoc(true);
    validateForm(values).then((errors) => {
      setContentLoader(true);
      const errorList = Object.values(errors);
      const hasErrors = !!errorList.length;
      if (!hasErrors) {
        const _values: IFormValues = {
          ...formValues,
          contactInfo: getSuperAdminDetails(values.contactDetails),
        };
        setFormValues(_values);
        submitOnboarding({
          userInfo: _values.contactInfo,
          companyInfo: _values.companyInfo,
        });
      } else {
        setContentLoader(false);
      }
    });
  };

  const submitHandler = (values: ParentFormType, validateForm: any) => {
    if (currentStep === 0) {
      handleSetPassword(values.setPassword);
    } else if (currentStep === 1) {
      handleSubmitBusinessDetails(values.businessDetails);
    } else if (currentStep === 2) {
      handleSubmitContactDetails(values, validateForm);
    }
  };

  const onSendMagicLink = async (
    data: FormikProps<AuthFormSchemaType["SIGN_IN"] | AuthFormSchemaType["SIGN_UP"]>,
  ) => {
    const { validateForm, values } = data;
    const errors = await validateForm(values);
    if (!isEmpty(errors)) return;

    signInStep.actions.handleSubmit(values);
  };

  const onBackClick = () =>
    !isNull(currentStep) && currentStep > 0 ? setCurrentStep(currentStep - 1) : undefined;

  return {
    metadata: {
      currentUser,
      svoc,
      currentStep,
      getFormInitValues,
      pageLoader: pageLoader,
      contentLoader: contentLoader || signInStep.metadata.isLoading,
    },
    actions: {
      submitHandler,
      handleGoogleAuth: signInStep.actions.handleGoogleAuth,
      onBackClick,
      onSendMagicLink,
    },
    formData: {
      magicLinkStepInitValues,
      businessInitialValues,
      contactDetailsInitialValues,
      setPasswordInitValues,
    },
    modals: {
      ...signInStep.modals,
    },
  };
};
