import { FC } from "react";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import routes from "routes/routes";
import { useAppSelector } from "store/hooks";
import { companyMetadataSelector, userMetadataSelector } from "store/selectors";

import { IMappedBankAccount } from "types/BankAccounts";
import { UserRole } from "types/BETypes";
import { hasAdminRights } from "permissions/helpers/shared";
import { mapSyncteraAccountsToPlaidAccount } from "helpers/bankCards";
import { isKybPending, isKybRejected } from "helpers/shared/kyb";
import { isKycPending, isKycRejected } from "helpers/shared/kyc";
import { CBACard1, CBACard2, CBACard3, CBACard4 } from "components/CompanyBankAccountPromoCards";
import useCBACards from "components/CompanyBankAccountPromoCards/useCBACards";
import { IProps as NavigationCardProps } from "components/NavigationCardWithDetails";
import { EOptionIds, EWidgetType } from "components/PaymentMethods/types";

import { SyncteraAccountDto } from "utils/swagger_react_query";

import { BankAccountInfoRow } from "../BankAccountInfoRows";
import { renderBankInUseLink } from "../BankAccountInfoRows/renderElements";
import { ExternalBankAccountInfoRowColumn } from "../BankAccountInfoRows/types";
import {
  BankAccountListRow,
  BankAccountsListWrapper,
  BankAccountsListWrapperOuter,
  ContinueSetupBtn,
  MoreInfoBtn,
  StyledH2,
  Wrapper,
} from "./styles";

export interface IPaidBankAccountSectionProps {
  bankAccounts?: SyncteraAccountDto[];
  widgetType?: EWidgetType;
  setupCompleted?: boolean;
  onBankInUseClick?: (bankAccountId: string) => void;
  onBannerBtnClick: () => void;
  onContinueSetupClick?: () => void;
  onChangeDefaultBank?: (id: string) => void;
  defaultBankAccountId?: string;
}

const PaidBankAccountSection: FC<IPaidBankAccountSectionProps> = ({
  bankAccounts = [],
  widgetType = EWidgetType.SETTINGS,
  setupCompleted,
  onBankInUseClick,
  onBannerBtnClick,
  onContinueSetupClick,
  onChangeDefaultBank,
  defaultBankAccountId,
}) => {
  const currentUser = useAppSelector(userMetadataSelector);
  const currentCompany = useAppSelector(companyMetadataSelector);
  const commonTranslationPrefix = `components.bank_account_components`;
  const translationPrefix = `${commonTranslationPrefix}.paid_accounts_list_section`;
  const navigate = useNavigate();
  const { renderCompanyAccountErrorBelowContent } = useCBACards({});
  const userIsAdmin = hasAdminRights(currentUser);
  const mappedBankAccounts = mapSyncteraAccountsToPlaidAccount(bankAccounts);
  const shouldShowRejectedKybOnExistingPBA =
    userIsAdmin && isKybRejected(currentCompany) && currentCompany?.existsPaidBankAccount;

  const getEmptyStateBannerBtn = () => {
    const _buttonTranslationPrefix = `components.payment_methods`;
    const buttonLabelByRole =
      currentUser?.user?.lastActiveRole === UserRole.EMPLOYEE ? "create" : "create_business";

    const config: NavigationCardProps = {
      buttonText: (
        <Trans
          i18nKey={`${_buttonTranslationPrefix}.options.${EOptionIds.COMPANY_BANK_ACCOUNT}.buttons.${buttonLabelByRole}`}
        />
      ),
      onButtonClick: onBannerBtnClick,
    };

    return config;
  };

  const renderBanner = (props: NavigationCardProps, type: EWidgetType) => {
    const isKycPendingOrRejected = isKycPending(currentUser) || isKycRejected(currentUser);
    const isKybPendingOrRejected = isKybPending(currentCompany) || isKybRejected(currentCompany);

    const componentEmployee = {
      [EWidgetType.ONBOARDING]:
        !!currentUser?.user?.kycStatus && isKycPendingOrRejected ? (
          <CBACard2 {...props} />
        ) : (
          <CBACard1 {...props} />
        ),
      [EWidgetType.SETTINGS]: <CBACard2 {...props} />,
    };

    const componentAdmin = {
      [EWidgetType.ONBOARDING]: isKybPendingOrRejected ? (
        <CBACard4 {...props} />
      ) : (
        <CBACard3 {...props} />
      ),
      [EWidgetType.SETTINGS]: <CBACard3 {...props} />,
    };

    if (currentUser?.user?.lastActiveRole === UserRole.EMPLOYEE) return componentEmployee[type];

    return componentAdmin[type];
  };

  const _actionsColumn = (bankAccount: IMappedBankAccount) => {
    const shouldShowBankInUseBtn =
      !!onBankInUseClick &&
      !!bankAccount.bankAccountId &&
      currentUser?.user?.defaultPayDistribution?.some((it) => it.id === bankAccount.bankAccountId);
    const shouldShowContinueSetupBtn = onContinueSetupClick && !setupCompleted;
    const shouldShowInfoButton =
      widgetType !== EWidgetType.ONBOARDING && !shouldShowContinueSetupBtn;
    return (
      <>
        {shouldShowBankInUseBtn &&
          renderBankInUseLink(() => onBankInUseClick(bankAccount?.bankAccountId || ""))}
        {shouldShowInfoButton && (
          <MoreInfoBtn onClick={() => navigate(routes.BANKING)} data-testid="more_info_btn">
            <Trans i18nKey={`${translationPrefix}.more_info_btn`} />
          </MoreInfoBtn>
        )}
        {shouldShowContinueSetupBtn && (
          <ContinueSetupBtn onClick={onContinueSetupClick} data-testid="continue_setup_btn">
            <Trans i18nKey={`${translationPrefix}.continue_setup_btn`} />
          </ContinueSetupBtn>
        )}
      </>
    );
  };

  const renderList = () => {
    const columns = [
      ExternalBankAccountInfoRowColumn.NAME,
      ExternalBankAccountInfoRowColumn.BANK_ACCOUNT_NAME,
      ExternalBankAccountInfoRowColumn.ACCOUNT_NUMBER,
    ];

    if (currentUser?.user?.lastActiveRole !== UserRole.EMPLOYEE) {
      columns.unshift(ExternalBankAccountInfoRowColumn.DEFAULT_BANK_RADIO_BTN);
    }
    return mappedBankAccounts?.map((item) => {
      return (
        <BankAccountListRow key={item.bankAccountId}>
          <BankAccountInfoRow
            bankAccountInfo={item}
            actionsColumn={_actionsColumn(item)}
            displayedColumns={columns}
            onChangeDefaultBank={(id) => onChangeDefaultBank?.(id)}
            defaultBankAccountId={defaultBankAccountId}
          />
        </BankAccountListRow>
      );
    });
  };

  return (
    <Wrapper>
      <StyledH2>
        <Trans i18nKey={`${translationPrefix}.title`} />
      </StyledH2>
      {isEmpty(mappedBankAccounts) ? (
        <>{renderBanner(getEmptyStateBannerBtn(), widgetType)}</>
      ) : (
        <BankAccountsListWrapperOuter>
          <BankAccountsListWrapper>{renderList()}</BankAccountsListWrapper>
          {shouldShowRejectedKybOnExistingPBA && <>{renderCompanyAccountErrorBelowContent()}</>}
        </BankAccountsListWrapperOuter>
      )}
    </Wrapper>
  );
};

export default PaidBankAccountSection;
