import { FC } from "react";
import { Trans } from "react-i18next";

import { BankAccountInfoRow } from "components/BankAccountComponents";
import { ExternalBankAccountInfoRowColumn } from "components/BankAccountComponents/BankAccountInfoRows/types";
import { getCompanyDefaultBankAccountBenefitsList } from "components/PayDistribution/constants";

import { Emphasized, PrimaryButton } from "uikit";

import { companyBankAccountDataTemplate } from "./constants";
import {
  BankAccountTemplateSection,
  BenefitsList,
  BLIcon,
  BLTextWithIcon,
  Container,
  FooterMessage,
  InnerContainer,
  Title,
} from "./styles";

interface IProps {
  onSetDefault?: () => void;
  className?: string;
}

const DefaultCompanyBankAccountBenefitsBanner: FC<IProps> = ({ className, onSetDefault }) => {
  const translationPrefix = `components.pay_distribution.default_company_bank_account_benefits_banner`;
  const benefitsList = getCompanyDefaultBankAccountBenefitsList(`${translationPrefix}.benefits`);

  const columns = [
    ExternalBankAccountInfoRowColumn.NAME,
    ExternalBankAccountInfoRowColumn.BANK_ACCOUNT_NAME,
    ExternalBankAccountInfoRowColumn.ACCOUNT_NUMBER,
  ];

  const renderBankAccountInfoButton = () => (
    <PrimaryButton onClick={onSetDefault} data-testid="set-default-company-bank-account-button">
      <Trans i18nKey={`${translationPrefix}.set_default_button`} />
    </PrimaryButton>
  );

  return (
    <Container className={className}>
      <BankAccountTemplateSection>
        <BankAccountInfoRow
          bankAccountInfo={companyBankAccountDataTemplate}
          actionsColumn={renderBankAccountInfoButton()}
          displayedColumns={columns}
        />
      </BankAccountTemplateSection>
      <InnerContainer>
        <Title>
          <Trans
            i18nKey={`${translationPrefix}.title`}
            components={{
              1: <Emphasized />,
            }}
          />
        </Title>

        <BenefitsList>
          {benefitsList.map((item) => (
            <BLTextWithIcon key={item.index.toString()}>
              <BLIcon>{item.icon}</BLIcon>
              {item.text}
            </BLTextWithIcon>
          ))}
        </BenefitsList>

        <FooterMessage>
          <Trans i18nKey={`${translationPrefix}.footer_message`} />
        </FooterMessage>
      </InnerContainer>
    </Container>
  );
};

export default DefaultCompanyBankAccountBenefitsBanner;
