import { FormikProps } from "formik";

import { UserProfileResponseDto } from "utils/swagger_react_query";

import { KYCProfileFormType } from "./KYCProfileForm";

export enum EKycResponseType {
  SUCCESS = "success",
  VERIFICATION_IS_TAKING_TOO_LONG = "verification_is_taking_too_long",
  ERROR = "error",
  INCORRECT_INFO = "incorrect_info",
}

export interface IKYCVerificationProps {
  className?: string;
  currentUser?: UserProfileResponseDto | null | undefined;
  onKybSubmitSuccess?: () => void;
  onKybStatusSectionPrimaryBtnClick: (status: EKycResponseType) => void;
  onBackBtnClick: () => void;
}

export type IUseKYCVerificationParams = Pick<
  IKYCVerificationProps,
  "onKybSubmitSuccess" | "onKybStatusSectionPrimaryBtnClick"
>;

export type IHandleSubmitParams = Pick<FormikProps<KYCProfileFormType>, "values" | "validateForm">;
