import styled from "styled-components";

import { LinkTextMixin } from "uikit";

export const Container = styled.div``;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const ExternalBankAccountsContainer = styled.div`
  margin-bottom: 48px;
`;

export const ExternalBankAccountsButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const AddExternalAccountButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    height: 16px;
    width: auto;
    margin-right: 8px;
    path {
      fill: ${({ theme }) => theme.colors.accentMain};
    }
  }
`;

export const AddExternalAccountButtonText = styled.div`
  ${LinkTextMixin}
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 1.4;
`;
