import React, { ReactNode, useState } from "react";
import Select, { components, Props as SelectProps, StylesConfig } from "react-select";
import { ChevronBottom } from "assets/svg";
import { theme } from "styles/theme";

import { renderLabel } from "./helpers";
import { BaseInputProps } from "./types";
import { Container, ErrorContainer, InputDescription, SelectWrapper, TopLabel } from "./styles";

import "./styles.scss";

export type Props<OptionType> = {
  className?: string;
  topLabel?: string;
  label?: ReactNode;
  selectStyles?: StylesConfig;
  readOnly?: boolean;
  alwaysShowErrorBlock?: boolean;
  errorDataTestId?: string;
  fieldDataTestId?: string;
  placeholder?: string | ReactNode;
} & SelectProps<OptionType> &
  BaseInputProps;

function CustomSelect<OptionType>({
  className,
  topLabel,
  label,
  error,
  selectStyles,
  onFocus,
  onBlur,
  readOnly,
  alwaysShowErrorBlock = true,
  value,
  isSearchable = false,
  placeholder,
  optional,
  fieldDataTestId,
  name,
  id,
  description,
  descriptionPosition,
  isDisabled,
  ...selectProps
}: React.HTMLAttributes<HTMLInputElement> & Props<OptionType>) {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    onFocus?.(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    onBlur?.(e);
  };

  const isFloatingLabel = !readOnly && label && (isFocused || (value as any)?.value);

  const colorStyles: StylesConfig<any> = {
    option: (styles, { isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? theme.colors.activeOptionBg : theme.colors.optionBg,
        color: "inherit",
      };
    },
  };

  return (
    <Container
      className={`custom-select ${className} ${isFloatingLabel ? "with-floating-label" : ""}`}
    >
      <SelectWrapper
        isDisabled={!!isDisabled}
        data-testid={fieldDataTestId}
        className={`${isDisabled ? "disabled" : ""}`}
      >
        {topLabel && <TopLabel>{topLabel}</TopLabel>}
        <Select
          isSearchable={isSearchable}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={value}
          styles={colorStyles}
          placeholder={placeholder}
          isDisabled={!!isDisabled}
          {...selectProps}
          inputId={id || name || undefined}
          components={{
            DropdownIndicator: (props) => (
              <components.DropdownIndicator {...props}>
                <ChevronBottom />
              </components.DropdownIndicator>
            ),
            ...selectProps.components,
          }}
        />
        {renderLabel(label, optional)}
      </SelectWrapper>
      {!!description && (
        <InputDescription className={`${descriptionPosition}`}>{description}</InputDescription>
      )}
      {(alwaysShowErrorBlock || error) && (
        <ErrorContainer data-testid={selectProps.errorDataTestId}>{error} </ErrorContainer>
      )}
    </Container>
  );
}

export default CustomSelect;
