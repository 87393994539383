import * as yup from "yup";

import { commonValidationRules } from "constants/shared/validationRules";
import {
  PasswordFormSchemasType,
  PasswordFormType,
  passwordFormValidationSchemas,
} from "components/auth";

import { AuthFormType } from "./types";

export const authFormTypeValidationSchema = (isPasswordRequired: boolean = false) => {
  const signInPasswordValidationSchema = passwordFormValidationSchemas.fields[
    PasswordFormType.SIGN_IN
  ] as yup.ObjectSchema<PasswordFormSchemasType["SIGN_IN"]>;
  const passwordField = signInPasswordValidationSchema.fields.password as yup.StringSchema;

  return yup.object().shape({
    [AuthFormType.SIGN_IN]: yup.object().shape({
      email: commonValidationRules.email,
      password: isPasswordRequired ? passwordField : yup.string().optional(),
    }),

    [AuthFormType.SIGN_UP]: yup.object({
      email: commonValidationRules.email,
    }),
  });
};
const schema = authFormTypeValidationSchema(false);
export type AuthFormSchemaType = yup.InferType<typeof schema>;
