import { hasAdminRights, hasEmployeeRights } from "permissions/helpers/shared";

import { BankCardResDto, UserProfileResponseDto } from "utils/swagger_react_query";

export const hasBankingManagerRights = (user?: UserProfileResponseDto | null): boolean => {
  if (!user) {
    return false;
  }
  return hasAdminRights(user) && !!user?.user?.businessStructurePerson?.isKeyManager;
};

export const hasIndividualOwnerRights = (user?: UserProfileResponseDto | null): boolean => {
  if (!user) {
    return false;
  }

  return hasAdminRights(user) && !!user?.user?.businessStructurePerson?.isOwner;
};

export const hasBusinessOwnerRights = (user?: UserProfileResponseDto | null): boolean => {
  if (!user) {
    return false;
  }

  return hasAdminRights(user) && !!user?.user?.businessStructurePerson?.isKeyManagerHoldingCompany;
};

export const hasAdvancedAdminBankingRights = (user?: UserProfileResponseDto | null): boolean => {
  if (!user) {
    return false;
  }

  return (
    hasAdminRights(user) &&
    (hasBankingManagerRights(user) ||
      hasIndividualOwnerRights(user) ||
      hasBusinessOwnerRights(user))
  );
};

export const hasCardholderRights = (
  user?: UserProfileResponseDto | null,
  card?: Partial<BankCardResDto>,
): boolean => {
  if (!user || !card) {
    return false;
  }

  return user.user?.userId === card.ownerInfo?.userId;
};

export const canIssueCard = (user?: UserProfileResponseDto | null): boolean => {
  if (!user) {
    return false;
  }
  return hasEmployeeRights(user) || hasAdminRights(user);
};

export const canSetCardPin = (
  user?: UserProfileResponseDto | null,
  card?: Partial<BankCardResDto>,
): boolean => {
  if (!user || !card) {
    return false;
  }
  return !!hasCardholderRights(user, card);
};

export const canCancelCard = (
  user?: UserProfileResponseDto | null,
  card?: Partial<BankCardResDto>,
): boolean => {
  if (!user || !card) {
    return false;
  }
  const userHasCardholderRights = hasCardholderRights(user, card);
  return (
    hasEmployeeRights(user) ||
    hasBankingManagerRights(user) ||
    (userHasCardholderRights && hasAdvancedAdminBankingRights(user))
  );
};

export const canReissueCard = (
  user?: UserProfileResponseDto | null,
  card?: Partial<BankCardResDto>,
): boolean => {
  if (!user || !card) {
    return false;
  }
  const userHasCardholderRights = hasCardholderRights(user, card);
  return (
    hasEmployeeRights(user) ||
    hasBankingManagerRights(user) ||
    (userHasCardholderRights && hasAdvancedAdminBankingRights(user))
  );
};

export const canRevealCardDetails = (
  user?: UserProfileResponseDto | null,
  card?: Partial<BankCardResDto>,
): boolean => {
  if (!user || !card) {
    return false;
  }
  const userHasCardholderRights = hasCardholderRights(user, card);
  return (
    hasEmployeeRights(user) || (userHasCardholderRights && hasAdvancedAdminBankingRights(user))
  );
};

export const canActivateCard = (
  user?: UserProfileResponseDto | null,
  card?: Partial<BankCardResDto>,
): boolean => {
  if (!user || !card) {
    return false;
  }
  const userHasCardholderRights = hasCardholderRights(user, card);
  return (
    hasEmployeeRights(user) || (userHasCardholderRights && hasAdvancedAdminBankingRights(user))
  );
};

export const canManageCard = (
  user?: UserProfileResponseDto | null,
  card?: Partial<BankCardResDto>,
): boolean => {
  if (!user) {
    return false;
  }
  const userHasCardholderRights = hasCardholderRights(user, card);
  return (
    hasEmployeeRights(user) ||
    hasBankingManagerRights(user) ||
    (userHasCardholderRights && hasAdvancedAdminBankingRights(user))
  );
};

export const canTransferFunds = (user?: UserProfileResponseDto | null): boolean => {
  if (!user) {
    return false;
  }
  return hasEmployeeRights(user) || hasAdvancedAdminBankingRights(user);
};
