import { useEffect, useState } from "react";
import moment from "moment";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { IMappedBankAccount } from "types/BankAccounts";
import { PayDistributionRuleType, PayDistributionType } from "types/BETypes";
import { showErrorModal } from "helpers";
import {
  getSerializedPlaidBankAccounts,
  isBankAccountVerified,
  mapSyncteraAccountsToPlaidAccount,
} from "helpers/bankCards";
import { isKycAccepted } from "helpers/shared/kyc";
import { useBankAccountLink } from "hooks";
import { getIsDefaultCompanyBankAccountEnabledFromLocalStorage } from "components/CompanyBankAccountComponents/employee/EarlyPay";

import {
  GetPlaidBankAccountsResponseDto,
  queryBankCardsControllerGetSyncteraAccount,
  SyncteraAccountDto,
} from "utils/swagger_react_query";

import { FormType } from "./components/BankAccountsForm/validationSchema";
import { convertServerPayDistributionDataToFormData } from "./helpers";
import { IInitialBankAccountsDataProps, PayDistributionWidgetType } from "./types";
import useCommonModals from "./useCommonModals";

export const useBankAccountsInitialValues = (props: IInitialBankAccountsDataProps) => {
  const { onNoAccountsModalConfirm, onNoAccountsModalCancel, widgetType } = props;
  const currentUser = useAppSelector(userMetadataSelector);
  const firstPayDistributionMethod = currentUser?.user?.defaultPayDistribution?.[0];
  const { showNoAccountsModal } = useCommonModals();
  const shouldRequestcompanyAccount = isKycAccepted(currentUser);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [bankAccountsLoading, setBankAccountsLoading] = useState<boolean>(false);
  const [companyAccountLoading, setCompanyAccountLoading] = useState<boolean>(false);
  const [companyAccount, setCompanyAccount] = useState<SyncteraAccountDto | undefined | null>(
    undefined,
  );

  const [combinedAccounts, setCombinedAccounts] = useState<IMappedBankAccount[] | undefined>(
    undefined,
  );

  const [initialFormData, setInitialFormData] = useState<FormType | undefined>(undefined);

  const { data: BankAccountsLinkData } = useBankAccountLink({
    loadingCallback: setBankAccountsLoading,
    shouldFetchBankAccountsListOnMount: true,
  });

  const bankAccounts = BankAccountsLinkData?.bankAccounts;

  const fetchCompanyAccount = async () => {
    if (shouldRequestcompanyAccount) {
      try {
        setCompanyAccountLoading(true);
        const result = await queryBankCardsControllerGetSyncteraAccount();
        setCompanyAccount(result);
      } catch (error) {
        showErrorModal(error);
      } finally {
        setCompanyAccountLoading(false);
      }
    } else {
      setCompanyAccount(null);
    }
  };

  const getInitialFormData = (
    _bankAccounts: IMappedBankAccount[] = combinedAccounts || [],
    _companyAccount: SyncteraAccountDto | null | undefined = companyAccount,
  ): FormType => {
    const isEarlyPayEnabled = !!currentUser?.user?.isEarlyPayEnabled;
    const isCompanyBankAccountChosenAsDefault =
      getIsDefaultCompanyBankAccountEnabledFromLocalStorage();
    const result = {
      isEarlyPayEnabled,
    };

    if (
      !_bankAccounts?.length &&
      widgetType !== PayDistributionWidgetType.ONBOARDING &&
      firstPayDistributionMethod?.type !== PayDistributionType.CHECK
    ) {
      return {
        ...result,
        payDistributionRules: [],
      };
    } else {
      if (!_bankAccounts?.length) {
        return {
          ...result,
          payDistributionRules: [],
        };
      }

      if (
        !firstPayDistributionMethod ||
        firstPayDistributionMethod?.type === PayDistributionType.CHECK
      ) {
        const bankAccountsWithoutCompanyAccount = _bankAccounts.filter(
          (it) => it._accountType !== PayDistributionType.PARTNER_ACCOUNT,
        );
        let _payDistributionRules = [
          {
            id: bankAccountsWithoutCompanyAccount?.[0]?.bankAccountId,
            type: bankAccountsWithoutCompanyAccount?.[0]?._accountType,
            ruleType: PayDistributionRuleType.REMAINDER,
          },
        ];

        if (isCompanyBankAccountChosenAsDefault || !bankAccountsWithoutCompanyAccount.length) {
          const companyAccount = (_bankAccounts || []).find(
            (item) => item._accountType === PayDistributionType.PARTNER_ACCOUNT,
          );

          if (companyAccount) {
            _payDistributionRules = [
              {
                id: companyAccount.bankAccountId,
                type: companyAccount._accountType,
                ruleType: PayDistributionRuleType.REMAINDER,
              },
            ];
          }
        }

        return {
          ...result,
          payDistributionRules: _payDistributionRules,
        };
      } else {
        return {
          ...result,
          payDistributionRules: convertServerPayDistributionDataToFormData(
            currentUser?.user?.defaultPayDistribution || [],
            _companyAccount?.id,
          ),
        };
      }
    }
  };

  const initializeFormData = (
    _bankAccounts: IMappedBankAccount[] = combinedAccounts || [],
    _companyAccount: SyncteraAccountDto | null | undefined = companyAccount,
  ) => {
    const data = getInitialFormData(_bankAccounts, _companyAccount);
    if (data) {
      setInitialFormData(data);
    } else {
      showNoAccountsModal(onNoAccountsModalConfirm, onNoAccountsModalCancel);
    }
  };

  const getAllDataAccounts = (
    bankAccounts: GetPlaidBankAccountsResponseDto[] | undefined,
    companyAccount: SyncteraAccountDto | undefined | null,
  ) => {
    const companyBankAccounts =
      isKycAccepted(currentUser) && companyAccount
        ? mapSyncteraAccountsToPlaidAccount([companyAccount])
        : undefined;
    const filteredBankAccountsData = getSerializedPlaidBankAccounts(
      (bankAccounts || []).filter((item) => isBankAccountVerified(item)) || [],
    );

    return [...filteredBankAccountsData, ...(companyBankAccounts || [])].sort(
      (a, b) => moment(b.verifiedAt).valueOf() - moment(a.verifiedAt).valueOf(),
    );
  };

  useEffect(() => {
    fetchCompanyAccount();
  }, []);

  useEffect(() => {
    if (bankAccounts !== undefined && companyAccount !== undefined) {
      const combinedAccounts = getAllDataAccounts(bankAccounts, companyAccount);
      setCombinedAccounts(combinedAccounts);
      initializeFormData(combinedAccounts);
    }
  }, [bankAccounts, companyAccount]);

  return {
    isLoading: isLoading || bankAccountsLoading || companyAccountLoading,
    combinedAccounts,
    bankAccounts,
    companyAccount,
    initialFormData,
    getInitialFormData,
    getAllDataAccounts,
  };
};

export default useBankAccountsInitialValues;
