import { FC, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { ModalAlert } from "assets/svg";

import { renderLabel } from "./helpers";
import { BaseInputProps } from "./types";
import { Container, ErrorContainer, InputBase, InputDescription, InputWrapper } from "./styles";

export type Props = {
  type?: string;
  label?: ReactNode | string;
  value?: string;
  name?: string;
  readOnly?: boolean;
  alwaysShowErrorBlock?: boolean;
  optional?: boolean;
  disabled?: boolean;
  autoComplete?: string;
} & React.InputHTMLAttributes<HTMLInputElement> &
  BaseInputProps;

const Input: FC<Props> = (props) => {
  const {
    className,
    label,
    description,
    descriptionPosition,
    error,
    onFocus,
    onBlur,
    readOnly,
    value,
    autoComplete,
    disabled,
    type,
    errorDataTestId,
    alwaysShowErrorBlock = true,
    optional = false,
    ...rest
  } = props;
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    onFocus?.(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    onBlur?.(e);
  };

  const isFloatingLabel = !readOnly && label && (isFocused || value);

  const renderInputByType = (type: string = "text") => {
    switch (type) {
      default:
        return (
          <InputBase
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={value}
            autoComplete={autoComplete}
            disabled={disabled}
            type={type}
            //NOTE::: If we use react-hook-form, propValue would be undefined and lib would take control over input via ref
            {...rest}
          />
        );
    }
  };

  return (
    <Container
      className={`${className} ${isFloatingLabel ? "with-floating-label" : ""} ${
        optional ? "optional" : ""
      }`}
    >
      <InputWrapper disabled={disabled}>
        {renderInputByType(type)}
        {renderLabel(label, optional)}
      </InputWrapper>
      {!!description && (
        <InputDescription className={`${descriptionPosition}`}>{description}</InputDescription>
      )}
      {(alwaysShowErrorBlock || error) && (
        <ErrorContainer data-testid={errorDataTestId}>
          {error && <ModalAlert />}
          {error}
        </ErrorContainer>
      )}
    </Container>
  );
};

export default Input;
