import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRouteHelpers } from "routes/helpers";
import routes from "routes/routes";

import { EMagicLinkFlow } from "types/AuthFlowTypes";
import { ErrorConst, MFAFactorType } from "types/BETypes";
import { AUTH_SEARCH_PARAMS_EMAIL } from "constants/auth";
import { showErrorModal } from "helpers/index";
import useAuth from "hooks/useAuth";
import { ISignInMfaPageNavigationParams } from "pages/Auth/SignInMfaPage/types";
import useMFASMS from "components/MultiFactorAuthorization/components/sms/useMFASMS";
import { MFAWidgetType } from "components/MultiFactorAuthorization/types";

import { UserProfileResponseDto } from "utils/swagger_react_query";

import { AuthFormSchemaType } from "../components";
import { ICheckEmailPageLocationState } from "../types";
import { useCompleteSignIn } from "./useCompleteSignIn";

export const useSingIn = (mode: EMagicLinkFlow) => {
  const navigate = useNavigate();
  const [svoc, setSvoc] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const emailParam = searchParams.get(AUTH_SEARCH_PARAMS_EMAIL);
  const { getDefaultRoute } = useRouteHelpers();
  const { fetchData: fetchMFAData } = useMFASMS({
    widgetType: MFAWidgetType.MISC,
    fetchDataOnMount: false,
  });

  const {
    actions: { redirectByRoleAndSalsaOnboardingStatus },
  } = useCompleteSignIn({ autoLogin: false, mode });

  const [tooManyAttemptsErrorModalIsOpen, setOpenTooManyAttemptsErrorModal] =
    useState<boolean>(false);

  const { logout, googleAuth, sendMagicLink, loginWithPassword, getCurrentUser } = useAuth();

  const formInitialValues: AuthFormSchemaType["SIGN_IN"] | AuthFormSchemaType["SIGN_UP"] = {
    email: emailParam || "",
    password: "",
  };

  const handleSubmit = async (
    values: AuthFormSchemaType["SIGN_IN"] | AuthFormSchemaType["SIGN_UP"],
    withPassword: boolean = false,
  ) => {
    setLoading(true);
    setSvoc(true);
    const _email = values.email.trim();
    if (withPassword) {
      const _password = (values as AuthFormSchemaType["SIGN_IN"])?.password?.trim() || "";
      await handleLoginWithPassword(_email, _password);
    } else {
      await handleSendMagicLink(_email);
    }
  };

  const handleLoginWithPassword = async (email: string, password: string) => {
    try {
      setLoading(true);
      if (!password) throw new Error(ErrorConst.INVALID_CREDENTIALS);

      const result = await loginWithPassword(email, password);
      if (!result?.success) throw result.message;
      const isUserExisted = result.data?.companyUsers?.[0];
      let userDetailsRes: Partial<UserProfileResponseDto> | null | undefined = {
        user: result.data?.companyUsers?.[0],
      };

      if (isUserExisted) {
        const mfaDataRes = await fetchMFAData();
        const shouldShowMfa = mfaDataRes?.find(
          (factor) => factor.type === MFAFactorType.SMS && !!factor.isVerified,
        );
        if (shouldShowMfa) {
          return navigate(routes.SIGN_IN_COMPLETE_MFA, {
            replace: true,
            state: {
              code: "pass",
            } as ISignInMfaPageNavigationParams,
          });
        }
        userDetailsRes = await getCurrentUser();
      }
      redirectByRoleAndSalsaOnboardingStatus(userDetailsRes);
    } catch (error) {
      if (error === ErrorConst.TOO_MANY_ATTEMPTS) {
        setOpenTooManyAttemptsErrorModal(true);
      } else if (error === ErrorConst.INVALID_CREDENTIALS) {
        if (mode === EMagicLinkFlow.SIGN_IN) {
          showErrorModal(`${ErrorConst.INVALID_CREDENTIALS}_SIGN_IN`);
        } else {
          showErrorModal(`${ErrorConst.INVALID_CREDENTIALS}_SIGN_UP`);
        }
      } else {
        showErrorModal(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSendMagicLink = async (email: string, withRedirect: boolean = true) => {
    setLoading(true);
    try {
      const result = await sendMagicLink(email, mode);
      if (!result?.success) throw result.errorMessage;
      if (result?.success) {
        if (withRedirect) {
          navigate(routes.CHECK_EMAIL, {
            state: { email: email, magicLinkFlow: mode } as ICheckEmailPageLocationState,
          });
        }
      }
    } catch (error: any) {
      if (error === ErrorConst.TOO_MANY_ATTEMPTS) {
        setOpenTooManyAttemptsErrorModal(true);
      } else if (error === ErrorConst.INVALID_CREDENTIALS) {
        if (mode === EMagicLinkFlow.SIGN_IN) {
          showErrorModal(`${ErrorConst.INVALID_CREDENTIALS}_SIGN_IN`);
        } else {
          showErrorModal(`${ErrorConst.INVALID_CREDENTIALS}_SIGN_UP`);
        }
      } else {
        showErrorModal(error);
      }
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const handleGoogleAuth = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    try {
      e.preventDefault();
      const result = await googleAuth(mode);
      await logout(false, false);
      if (result.success) {
        const link = document.createElement("a");
        link.href = result.url!;
        link.click();
      } else {
        throw new Error();
      }
    } catch (error) {
      showErrorModal(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    metadata: {
      svoc,
      formInitialValues,
      isLoading,
      setLoading,
    },
    actions: {
      handleSubmit,
      handleGoogleAuth,
      handleSendMagicLink,
    },
    modals: {
      tooManyAttemptsErrorModalIsOpen,
      setOpenTooManyAttemptsErrorModal,
    },
  };
};
