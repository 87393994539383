import styled from "styled-components";

import { breakpoint } from "helpers";

import { PrimaryButton, SecondaryButton } from "uikit";
import { SectionAlt, SectionInner } from "uikit/Containers/Containers";

export const Container = styled.div``;

export const BankAccountInfoSection = styled(SectionAlt)`
  margin-bottom: 0;
`;

export const PaymentSection = styled(SectionAlt)``;

export const StyledSectionInner = styled(SectionInner)``;

export const BankAccountEmptyStateSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const BankAccountEmptyStateMessage = styled.p`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 1.5;
  text-align: center;
  margin-bottom: 16px;

  ${breakpoint("xs", "md")`
    font-size: ${({ theme }) => theme.size.body2};
  `}
`;

export const BankAccountEmptyStateButton = styled(PrimaryButton)`
  width: auto;

  svg {
    margin: 0 10px;
    path {
      fill: ${({ theme }) => theme.colors.contactInfoBg};
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const BackButton = styled(SecondaryButton)`
  margin-top: 32px;
  width: auto;

  ${breakpoint("xs", "md")`
    display: none;
  `}
`;
