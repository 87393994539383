import * as yup from "yup";

import { passwordRegExp } from "constants/shared";
import { commonValidationRules } from "constants/shared/validationRules";

import { PasswordFormType } from "./types";

const translationPrefixAuth = `auth_pages.shared.components.password_form.validation_errors`;
const translationPrefixCommon = `validationErrors.common`;

const passwordBase = commonValidationRules.inputStringBase.required(
  `${translationPrefixCommon}.is_required`,
);

const currentPasswordValidationSchema = {
  password: passwordBase,
};
const createPasswordValidationSchema = {
  newPassword: passwordBase.matches(passwordRegExp, `${translationPrefixCommon}.invalid_value`),
  confirmPassword: passwordBase.oneOf(
    [yup.ref("newPassword")],
    `${translationPrefixAuth}.password_mismatch`,
  ),
};

const changePasswordValidationSchema = {
  password: passwordBase.required(`${translationPrefixCommon}.is_required`),
  ...createPasswordValidationSchema,
};

export const passwordFormValidationSchemas = yup.object({
  [PasswordFormType.SIGN_IN]: yup.object({
    ...currentPasswordValidationSchema,
  }),
  [PasswordFormType.CREATE_PASSWORD]: yup.object({
    ...createPasswordValidationSchema,
  }),
  [PasswordFormType.CHANGE_PASSWORD]: yup.object({
    ...changePasswordValidationSchema,
  }),
  [PasswordFormType.RESET_PASSWORD]: yup.object({
    ...createPasswordValidationSchema,
  }),
});

export type PasswordFormSchemasType = yup.InferType<typeof passwordFormValidationSchemas>;
