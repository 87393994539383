import { FC } from "react";

import RouterPrompt from "components/RouterPrompt";

import { FullScreenLoader } from "uikit";

import BankAccountsForm from "./components/BankAccountsForm";
import CheckForm from "./components/CheckForm";
import { EDistributionOptions, IProps } from "./types";
import usePayDistribution from "./usePayDistribution";
import { Container, MainContainer, MainFormContainer, MethodSelectionContainer } from "./styles";

const PayDistribution: FC<IProps> = (props) => {
  const { className, widgetType, onBack, onNoAccountsModalConfirm, onBankAccountAdd } = props;
  const {
    currentStep,
    distributionOptions,
    bankAccountFormRef,
    isInEditMode,
    isLoading,
    initialFormData,
    combinedAccounts,
    shouldShowRedirectModal,
    renderMethodSelectionItem,
    handleBankAccountFormConfirmPayDistribution,
    handleSetCompanyBankAccountAsDefault,
    handleCancelInitedEditMode,
  } = usePayDistribution(props);

  return (
    <Container className={className}>
      {isLoading && <FullScreenLoader />}
      <MethodSelectionContainer>
        {distributionOptions.map((item) => {
          return <>{renderMethodSelectionItem(item)}</>;
        })}
      </MethodSelectionContainer>

      <MainContainer>
        {/* NOTE::: Main Content */}
        <MainFormContainer>
          {currentStep === EDistributionOptions.BANK_ACCOUNT && (
            <BankAccountsForm
              ref={bankAccountFormRef}
              initialFormData={initialFormData}
              bankAccounts={combinedAccounts}
              widgetType={widgetType}
              onBack={onBack}
              onNoAccountsModalConfirm={onNoAccountsModalConfirm}
              onBankAccountAdd={onBankAccountAdd}
              onConfirmPayDistribution={handleBankAccountFormConfirmPayDistribution}
              isInEditModeOnInit={isInEditMode}
              onSetCompanyBankAccountAsDefault={handleSetCompanyBankAccountAsDefault}
              onCancelInitedEditMode={handleCancelInitedEditMode}
            />
          )}
          {currentStep === EDistributionOptions.CHECK && (
            <CheckForm
              widgetType={widgetType}
              onBack={onBack}
              onSetCompanyBankAccountAsDefault={handleSetCompanyBankAccountAsDefault}
            />
          )}
        </MainFormContainer>

        <RouterPrompt when={shouldShowRedirectModal} />
      </MainContainer>
    </Container>
  );
};

export default PayDistribution;
