import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

import SelectOption from "types/SelectOption";
import { TStates } from "types/States";
import { MAX_INPUT_LENGTH } from "constants/form";
import { StateSelectOptions, zipInputFormat } from "constants/shared";
import { getFieldError, getPhoneNumberOrNull } from "helpers";

import {
  FormLabelSmall,
  Input,
  InputContainer,
  InputGroupContainerHalved,
  NumberInput,
  PhoneInput,
} from "uikit";
import { renderExternalLabel } from "uikit/Input/helpers";
import CustomSelect from "uikit/Input/Select";

import { CompanyCardShippingDetailsFormType } from "./validationSchema";
import { Container } from "./styles";

interface IProps {
  className?: string;
}

const CompanyCardShippingDetailsForm: FC<IProps> = ({ className }) => {
  const { t } = useTranslation();
  const originTranslationPrefix = "components.company_bank_account_components";
  const formLabelTranslationPrefix = `${originTranslationPrefix}.shared.profile_form.form_labels`;

  const formikProps = useFormikContext<CompanyCardShippingDetailsFormType>();
  const { setFieldValue, handleChange, values } = formikProps;

  return (
    <Container className={className}>
      <InputGroupContainerHalved>
        <InputContainer>
          <FormLabelSmall>
            <Trans i18nKey={`${formLabelTranslationPrefix}.first_name`} />
          </FormLabelSmall>
          <Input
            name="firstName"
            onChange={(e) => setFieldValue("firstName", e.currentTarget.value.trimStart())}
            onBlur={(e) => setFieldValue("firstName", e.currentTarget.value.trimEnd())}
            value={values.firstName}
            error={getFieldError("firstName", formikProps, {
              field: t(`${formLabelTranslationPrefix}.first_name`),
              maxInputLength: MAX_INPUT_LENGTH,
            })}
            errorDataTestId="error-first-name"
          />
        </InputContainer>
        <InputContainer>
          <FormLabelSmall>
            <Trans i18nKey={`${formLabelTranslationPrefix}.last_name`} />
          </FormLabelSmall>
          <Input
            name="lastName"
            onChange={(e) => setFieldValue("lastName", e.currentTarget.value.trimStart())}
            onBlur={(e) => setFieldValue("lastName", e.currentTarget.value.trimEnd())}
            value={values.lastName}
            error={getFieldError("lastName", formikProps, {
              field: t(`${formLabelTranslationPrefix}.last_name`),
              maxInputLength: MAX_INPUT_LENGTH,
            })}
            errorDataTestId="error-last-name"
          />
        </InputContainer>
      </InputGroupContainerHalved>

      <InputGroupContainerHalved>
        <InputContainer>
          <FormLabelSmall>
            <Trans i18nKey={`${formLabelTranslationPrefix}.address`} />
          </FormLabelSmall>
          <Input
            name="address"
            onChange={handleChange}
            value={values.address}
            error={getFieldError("address", formikProps, {
              field: t(`${formLabelTranslationPrefix}.address`),
              maxInputLength: MAX_INPUT_LENGTH,
            })}
            errorDataTestId="error-address"
          />
        </InputContainer>

        <InputContainer>
          <FormLabelSmall>
            {renderExternalLabel(<Trans i18nKey={`${formLabelTranslationPrefix}.suite`} />, true)}
          </FormLabelSmall>
          <Input
            name="suite"
            onChange={handleChange}
            value={values.suite || ""}
            error={getFieldError("suite", formikProps, {
              field: t(`${formLabelTranslationPrefix}.suite`),
              maxInputLength: MAX_INPUT_LENGTH,
            })}
            errorDataTestId="error-suit"
            optional
          />
        </InputContainer>
      </InputGroupContainerHalved>

      <InputGroupContainerHalved>
        <InputContainer>
          <FormLabelSmall>
            <Trans i18nKey={`${formLabelTranslationPrefix}.state`} />
          </FormLabelSmall>
          <CustomSelect<SelectOption<TStates>>
            name="state"
            onChange={(option) => setFieldValue("state", option)}
            value={values.state as any}
            options={StateSelectOptions}
            placeholder={""}
            error={getFieldError("state.value", formikProps, {
              field: t(`${formLabelTranslationPrefix}.state`),
            })}
            isSearchable={true}
            errorDataTestId="error-state"
          />
        </InputContainer>
        <InputContainer>
          <FormLabelSmall>
            <Trans i18nKey={`${formLabelTranslationPrefix}.city`} />
          </FormLabelSmall>
          <Input
            name="city"
            onChange={handleChange}
            value={values.city || ""}
            error={getFieldError("city", formikProps, {
              field: t(`${formLabelTranslationPrefix}.city`),
              maxInputLength: MAX_INPUT_LENGTH,
            })}
            errorDataTestId="error-city"
          />
        </InputContainer>
      </InputGroupContainerHalved>

      <InputGroupContainerHalved>
        <InputContainer>
          <FormLabelSmall>
            <Trans i18nKey={`${formLabelTranslationPrefix}.zip`} />
          </FormLabelSmall>
          <NumberInput
            name="zip"
            onChange={(val) => setFieldValue("zip", val.toString().split(" ").join(""))}
            value={values.zip}
            error={getFieldError("zip", formikProps, {
              field: t(`${formLabelTranslationPrefix}.zip`),
            })}
            errorDataTestId="error-zip"
            format={zipInputFormat}
          />
        </InputContainer>

        <InputContainer>
          <FormLabelSmall>
            <Trans i18nKey={`${formLabelTranslationPrefix}.phone`} />
          </FormLabelSmall>
          <PhoneInput
            name="phone"
            onChange={(value) => setFieldValue("phone", getPhoneNumberOrNull(value as string))}
            value={values.phone || undefined}
            error={getFieldError("phone", formikProps, {
              field: t(`${formLabelTranslationPrefix}.phone`),
            })}
            errorDataTestId="error-phone"
          />
        </InputContainer>
      </InputGroupContainerHalved>
    </Container>
  );
};

export default CompanyCardShippingDetailsForm;
