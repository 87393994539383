import { PayDistributionRuleType, PayDistributionType } from "types/BETypes";

import { DefaultPayDistributionDto } from "utils/swagger_react_query";

import { BankItemType } from "./components/BankAccountsForm/validationSchema";

export const convertServerPayDistributionDataToFormData = (
  data: DefaultPayDistributionDto[],
  companyAccountId?: string | null,
): BankItemType[] => {
  return data.map((item) => {
    let _amount = item.amount;
    if (_amount !== undefined && item.ruleType === PayDistributionRuleType.FIXED_AMOUNT) {
      _amount = (item.amount || 0) / 100;
    }

    return {
      ...item,
      id: item.type === PayDistributionType.PARTNER_ACCOUNT ? companyAccountId : item.id,
      amount: _amount,
    };
  });
};

export const convertFormDataToServerPayDistributionData = (
  data: BankItemType[],
): DefaultPayDistributionDto[] => {
  return data.map((item) => {
    let _amount = item.amount;
    if (_amount !== undefined && item.ruleType === PayDistributionRuleType.FIXED_AMOUNT) {
      _amount = (item.amount || 0) * 100;
    }

    return {
      ...item,
      id: item.type === PayDistributionType.PARTNER_ACCOUNT ? undefined : item.id,
      amount: _amount,
      type: item.type as PayDistributionType,
      ruleType: item.ruleType as PayDistributionRuleType,
    };
  });
};

export const isPayDistributionOnlyPartnerAccount = (
  data: DefaultPayDistributionDto[] | BankItemType[] | undefined,
): boolean => {
  return data?.length === 1 && data[0].type === PayDistributionType.PARTNER_ACCOUNT;
};

export const isPayDistributionIncludesPartnerAccount = (
  data: DefaultPayDistributionDto[] | BankItemType[] | undefined,
): boolean => {
  if (!data) return false;

  return data?.some((item) => item.type === PayDistributionType.PARTNER_ACCOUNT);
};
