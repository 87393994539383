import { FC } from "react";

import IndicatorBase from "./IndicatorBase";
import { IStepIndicatorProps } from "./types";
import { StepIndicatorContainerAlt } from "./styles";

const StepIndicatorAlt: FC<IStepIndicatorProps> = (props) => {
  return <IndicatorBase ContainerComponent={StepIndicatorContainerAlt} {...props} />;
};

export default StepIndicatorAlt;
