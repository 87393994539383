import * as yup from "yup";

import {
  PasswordFormSchemasType,
  PasswordFormType,
  passwordFormValidationSchemas,
} from "components/auth";

import {
  BusinessDetailsFormValidationSchema,
  ContactDetailsFormTypeValidationSchema,
  SignUpNestedFormName,
} from "../_shared";

export const ParentFormValidationSchema = yup.object({
  [SignUpNestedFormName.SetPassword]: passwordFormValidationSchemas.fields[
    PasswordFormType.CREATE_PASSWORD
  ] as yup.ObjectSchema<PasswordFormSchemasType["CREATE_PASSWORD"]>,
  [SignUpNestedFormName.BusinessDetails]: BusinessDetailsFormValidationSchema,
  [SignUpNestedFormName.ContactDetails]: ContactDetailsFormTypeValidationSchema,
});

export type ParentFormType = yup.InferType<typeof ParentFormValidationSchema>;
