import { SalsaWorkerCategoryEnum } from "types/BETypes";
import { APPLICATION_NAME } from "constants/systemConstants";

export const peoplePage = {
  "en-US": {
    index: {
      table: {
        columns: {
          user_info: "Name",
          status: "Status",
          email: "Email",
          payment_readiness_status: "Pay Readiness",
          employment_type: "Employment Type",
          contextMenu: "",
        },
        footer: {
          people: "People",
        },
        menu_actions: {
          finish_setup: "Setup Payroll",
          invite_worker: "Invite Worker",
          resend_invite: "Resend Invite",
          edit_worker: "Edit Worker",
          delete_worker: "Delete Worker",
        },
      },
      modals: {
        invite_sent: {
          title: "Invite sent",
          message: "Invite has been successfully sent to <1>{{fullName}}</1>.",
        },
        worker_added: {
          title: "Worker Added",
          message: "Worker has been successfully added and invited",
          primary_btn: "Finish Setup",
        },
        worker_edited: {
          title: "Worker's information is updated",
          message: "Worker's information has been successfully updated.",
        },
        delete_worker: {
          title: "Are you sure?",
          message: "This action can not be undone.",
        },
      },
      search_placeholder: "Search",
      add_worker_btn: "Add Worker",

      add_worker_form: {
        title_new: "Add Individual Worker",
        title_edit: "Edit Individual Worker",

        labels: {
          employee_type: "Employee Type",
          first_name: "First Name",
          last_name: "Last Name",
          email: "Primary Email Address",
          phone: "Phone",
          overtime_elligible: "Overtime Eligibility",
        },

        overtime_elligible_options: {
          yes: "Yes",
          no: "No",
        },

        employee_types: {
          [SalsaWorkerCategoryEnum.EMPLOYEE]: "Full-time employee",
          [SalsaWorkerCategoryEnum.CONTRACTOR]: "Contractor",
        },
        add_worker_btn: {
          [SalsaWorkerCategoryEnum.EMPLOYEE]: "Add Employee",
          [SalsaWorkerCategoryEnum.CONTRACTOR]: "Add Contractor",
        },
        edit_worker_btn: {
          [SalsaWorkerCategoryEnum.EMPLOYEE]: "Update",
          [SalsaWorkerCategoryEnum.CONTRACTOR]: "Update",
        },
      },

      add_worker_modal: {
        title: "Add Individual",
        message: `${APPLICATION_NAME} gives you two easy ways to add your people. Once added you may include them in payroll.`,
        uploadEmployeeListBtn: "Upload CSV",
        addIndividualEmployeeBtn: "Add Worker",
        connect_directory: "Connect Directory",
      },

      csv_import_modal: {
        //FIXME:::Refactor camelcase later
        uploadingFileState: {
          title: "Importing Employee Records",
          message: "This may take a few moments. Thank you for your patience.",
        },
        upload: {
          title: "Upload Worker CSV List",
          message: `${APPLICATION_NAME} makes it easy for you to upload a CSV file of your workers. Please download the following CSV template. Add your workers and upload to ${APPLICATION_NAME}.`,
          step_1: {
            title: "Step 1",
            message: "Download the CSV worker template. Add your workers to the template. ",
            btnTitle: "Download CSV Template",
          },
          step_2: {
            title: "Step 2",
            message: "Upload the CSV file with your workers added.",
            btnTitle: "Locate File",
            errors: {
              wrong_file_format: "Incorrect file format. Please upload a {{formatList}} file.",
            },
          },
          cancelBtn: "Cancel",
          importBtn: "Import",
        },
        congrats: {
          title: "Congratulations!",
          message: `You have successfully uploaded your CSV file. Please ensure any new workers added to {{companyName}} are added to ${APPLICATION_NAME} as well.`,
          addedSuccessfully: "Workers Added Successfully",
          failed: "user imports <1>failed</1>.",
          downloadErrorFile: "<1>Download File</1> to view errors",
          okBtn: "OK",
        },
      },
    },
    edit_worker: {
      email: "Email: {{email}}",
    },
    worker_details: {},
  },
};

export default peoplePage;
