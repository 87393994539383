import { FC } from "react";
import { Formik, FormikProps } from "formik";

import AuthLayout from "layouts/AuthLayout";

import { FullScreenLoader } from "uikit";

import { MfaSignInFormType, mfaSignInValidationSchema, SignInMfaForm } from "./components";
import { useSignInMfaPage } from "./useSignInMfaPage";
import { Container } from "./styles";

const SignInMfaPage: FC = () => {
  const translationPrefix = `auth_pages.shared.sign_in_mfa_page`;
  const { metadata, actions, pageData } = useSignInMfaPage();

  if (metadata.isLoading) return <FullScreenLoader />;

  return (
    <AuthLayout isLoading={metadata.isSubmitting}>
      <Formik<MfaSignInFormType>
        initialValues={metadata.initialValues}
        onSubmit={actions.handleSubmit}
        validationSchema={mfaSignInValidationSchema}
        validateOnChange={metadata.svoc}
      >
        {(formikProps: FormikProps<MfaSignInFormType>) => (
          <Container
            onSubmit={(e) => {
              e.preventDefault();
              metadata.setSvoc(true);
              formikProps.handleSubmit();
            }}
          >
            <SignInMfaForm
              factor={pageData.currentFactor}
              onResend={actions.handleVerifyCodeResend}
            />
          </Container>
        )}
      </Formik>
    </AuthLayout>
  );
};

export default SignInMfaPage;
