import { Trans } from "react-i18next";

import { AppEvents } from "services/events";

import { EButtonsFlex, EModalTypes } from "uikit/Modal";

import { PayDistributionWidgetType } from "./types";

export const useCommonModals = () => {
  const commonPrefix = `components.pay_distribution.shared_modals`;

  const showNoAccountsModal = (callback?: () => void, cancelCallback?: () => void) => {
    const translationPrefix = `${commonPrefix}.no_accounts_modal`;

    AppEvents.emit("SetGlobalModal", {
      className: "no-bank-accounts-warning-modal",
      isOpen: true,
      type: EModalTypes.WARNING,
      title: <Trans i18nKey={`${translationPrefix}.title`} />,
      message: <Trans i18nKey={`${translationPrefix}.message`} />,
      mainButton: {
        text: <Trans i18nKey={`${translationPrefix}.submit_button`} />,
        onClick: callback,
        autoWidth: true,
      },
      secondaryButton: {
        text: <Trans i18nKey={`common.modal.cancel`} />,
        onClick: cancelCallback,
        autoWidth: true,
      },
      buttonsFlex: EButtonsFlex.ROW_REVERSE,
      onClose: cancelCallback,
    });
  };

  const showPayDistributionSettingsSaveSuccessModal = (callback?: () => void) => {
    const translationPrefix = `${commonPrefix}.pay_distribution_save_success_modals.${PayDistributionWidgetType.SETTINGS}`;

    AppEvents.emit("SetGlobalModal", {
      className: "pay-distribution-save-sucess-modal",
      isOpen: true,
      type: EModalTypes.SUCCESS,
      title: <Trans i18nKey={`${translationPrefix}.title`} />,
      message: <Trans i18nKey={`${translationPrefix}.message`} />,
      mainButton: {
        text: <Trans i18nKey={`common.modal.done`} />,
        onClick: callback,
      },
      dataTestId: `pay-distribution-settings-save-success-modal`,
    });
  };

  const showPayDistributionOnboardingSaveSuccessModal = (callback?: () => void) => {
    const translationPrefix = `${commonPrefix}.pay_distribution_save_success_modals.${PayDistributionWidgetType.ONBOARDING}`;

    AppEvents.emit("SetGlobalModal", {
      className: "pay-distribution-save-sucess-modal",
      isOpen: true,
      type: EModalTypes.SUCCESS,
      title: <Trans i18nKey={`${translationPrefix}.title`} />,
      message: <Trans i18nKey={`${translationPrefix}.message`} />,
      mainButton: {
        text: <Trans i18nKey={`common.modal.done`} />,
      },
      onClose: callback,
      dataTestId: `pay-distribution-onboarding-save-success-modal`,
    });
  };

  const showNavigationWarningModal = (callback?: () => void, cancelCallback?: () => void) => {
    const translationPrefix = `components.navigation_prompt`;

    AppEvents.emit("SetGlobalModal", {
      className: "pay-distribution-redirection-warning-modal",
      isOpen: true,
      type: EModalTypes.WARNING,
      title: <Trans i18nKey={`${translationPrefix}.title`} />,
      message: <Trans i18nKey={`${translationPrefix}.message`} />,
      mainButton: {
        text: <Trans i18nKey={`${translationPrefix}.confirm`} />,
        onClick: callback,
        autoWidth: true,
      },
      secondaryButton: {
        text: <Trans i18nKey={`common.modal.cancel`} />,
        onClick: cancelCallback,
        autoWidth: true,
      },
      buttonsFlex: EButtonsFlex.ROW_REVERSE,
      dataTestId: `pay-distribution-redirection-warning-modal`,
    });
  };

  return {
    showNoAccountsModal,
    showPayDistributionSettingsSaveSuccessModal,
    showPayDistributionOnboardingSaveSuccessModal,
    showNavigationWarningModal,
  };
};

export default useCommonModals;
