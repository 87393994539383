import { ReactNode } from "react";

import { Pagination } from "uikit/Pagination";

import { Table } from "./components";
import {
  IColumn,
  IFooterColumn,
  IInfiniteScrollProps,
  IScrollProps,
  ITablePaginationProps,
} from "./types";
import { PaginationWrapper, Wrapper } from "./styles";

export interface IProps<T> {
  className?: string;
  data: T[];
  isLoading: boolean;
  columns: IColumn[];
  onRowClick?: (item?: T) => void;
  rowClass?: (item?: T) => string;
  emptyListComponent: ReactNode;
  paginationProps?: ITablePaginationProps;
  scrollProps?: IScrollProps;
  infiniteScrollProps?: IInfiniteScrollProps;
  additionalHeaderContent?: React.ReactNode;
  footer?: ReactNode; //TODO::: Make common props interface for TableList and Table compoent
  footerColumns?: IFooterColumn[];
  minRowsCount?: number;
}

export const TableList = <T,>(props: IProps<T>) => {
  const renderTable = (props: IProps<T>) => {
    return (
      <Table<T>
        data={props.data}
        isLoading={props.isLoading}
        columns={props.columns}
        emptyListComponent={props.emptyListComponent}
        scrollProps={props.scrollProps}
        rowClass={props.rowClass}
        onRowClick={props.onRowClick}
        additionalHeaderContent={props.additionalHeaderContent}
        footer={props.footer}
        footerColumns={props.footerColumns}
        minRowsCount={props.minRowsCount}
      />
    );
  };

  return (
    <Wrapper className={props.className}>
      <>{renderTable(props)}</>
      {props.paginationProps && props.data[0] && (
        <PaginationWrapper>
          <Pagination {...props.paginationProps} />
        </PaginationWrapper>
      )}
    </Wrapper>
  );
};
