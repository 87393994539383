import * as Sentry from "@sentry/react";
import { stop } from "ky/distribution/core/constants";
import routes from "routes/routes";
import { v4 as uuidv4 } from "uuid";

import { clearSalsaData, saveSalsaData } from "services/salsa";
import { EMagicLinkFlow } from "types/AuthFlowTypes";
import { CURRENT_ROLE_KEY, LOCALHOST_AUTH_TOKEN_KEY, SESSION_ID_KEY } from "constants/auth";
import { LOCAL_STORAGE_KEY as ACTIVITY_TRACKER_TIME_KEY } from "components/ActivityTracker/constants";
import { deleteIsDefaultCompanyBankAccountEnabledFromLocalStorage } from "components/CompanyBankAccountComponents/employee/EarlyPay";
import { removeFailedKycUserDataFromLocalStorage } from "components/CompanyBankAccountComponents/employee/KYCVerification/helpers";

import {
  mutationAuthControllerAuthorize,
  mutationAuthControllerAuthPasswordless,
  mutationAuthControllerAuthWithPassword,
  mutationAuthControllerGetGoogleOAuthUrl,
  mutationAuthControllerLogout,
  queryUsersControllerGetProfile,
} from "../utils/swagger_react_query";

export const setLocalhostAuthToken = (token: string) => {
  const isLocalhost = (window.location.origin || "").includes("http://localhost");
  if (isLocalhost) localStorage.setItem(LOCALHOST_AUTH_TOKEN_KEY, token);
};
export const getLocalhostAuthToken = () => localStorage.getItem(LOCALHOST_AUTH_TOKEN_KEY);
export const removeLocalhostAuthToken = () => localStorage.removeItem(LOCALHOST_AUTH_TOKEN_KEY);

export const setSessionId = () => localStorage.setItem(SESSION_ID_KEY, uuidv4());
export const getSessionId = () => localStorage.getItem(SESSION_ID_KEY);
export const removeSessionId = () => localStorage.removeItem(SESSION_ID_KEY);
export const removeActivityTracker = () => localStorage.removeItem(ACTIVITY_TRACKER_TIME_KEY);

export const sendMagicLink = async (email: string, mode: EMagicLinkFlow) => {
  try {
    const res = await mutationAuthControllerAuthPasswordless()({ email, mode });
    return { success: true };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      message: (err as any).message,
      code: (err as any).code,
      errorMessage: (err as any)?.data?.error,
    };
  }
};

export const login = async (
  code: string,
  mode: EMagicLinkFlow,
  invitationId?: string,
  impersonateId?: string,
  fetchCompany: boolean = true,
) => {
  try {
    removeFailedKycUserDataFromLocalStorage();
    deleteIsDefaultCompanyBankAccountEnabledFromLocalStorage();

    const response = await mutationAuthControllerAuthorize()({
      code,
      invitationId,
      mode,
      impersonateId,
    });
    setLocalhostAuthToken(response.token);

    setSessionId();
    saveSalsaData({
      salsaEmployerToken: response.salsaEmployerToken,
      salsaWorkerToken: response.salsaWorkerToken,
    });

    return { success: true, data: response };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      message: (err as any)?.data?.debugError || (err as any)?.data?.error || (err as any).message,
      code: (err as any).code,
    };
  }
};

export const loginWithPassword = async (email: string, password: string) => {
  try {
    removeFailedKycUserDataFromLocalStorage();
    deleteIsDefaultCompanyBankAccountEnabledFromLocalStorage();

    const response = await mutationAuthControllerAuthWithPassword()({
      email,
      password,
    });
    setLocalhostAuthToken(response.token);

    setSessionId();
    saveSalsaData({
      salsaEmployerToken: response.salsaEmployerToken,
      salsaWorkerToken: response.salsaWorkerToken,
    });

    return { success: true, data: response };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      message: (err as any)?.data?.debugError || (err as any)?.data?.error || (err as any).message,
      code: (err as any).code,
    };
  }
};

export const googleAuth = async (mode: EMagicLinkFlow) => {
  try {
    const response = await mutationAuthControllerGetGoogleOAuthUrl()({ mode });
    return { success: true, url: response.oAuthUrl };
  } catch (err) {
    console.log(err);
    return { success: false };
  }
};

export const cleanUp = () => {
  removeLocalhostAuthToken();
  removeSessionId();
  removeActivityTracker();
  localStorage.removeItem(CURRENT_ROLE_KEY);
  removeFailedKycUserDataFromLocalStorage();
  deleteIsDefaultCompanyBankAccountEnabledFromLocalStorage();
};

export const logout = async (
  withRequest: boolean = true,
  withNavigate: boolean = true,
  params?: string,
): Promise<void | typeof stop> => {
  try {
    if (withRequest) {
      await mutationAuthControllerLogout()();
    }

    cleanUp();
    clearSalsaData();

    Sentry.setUser(null);

    if (withNavigate) {
      window.location.href = routes.SIGN_IN;
    }
    if (params) window.location.href = routes.SIGN_IN + `?${params}`;
    return;
  } catch (err) {
    console.log(err);
    return;
  }
};

export const getUser = async () => {
  try {
    const response = await queryUsersControllerGetProfile();
    return { success: true, user: response };
  } catch (err) {
    console.log(err);
    return { success: false };
  }
};
