import { FC, useEffect, useRef, useState } from "react";
import { Trans } from "react-i18next";
import { BlockerFunction, useBlocker } from "react-router-dom";

import { useEventsListener } from "hooks";

import { EButtonsFlex, EModalTypes } from "uikit/Modal";

import { PromptModal } from "./styles";

interface IProps {
  when: BlockerFunction;
  children?: (data: {
    isActive: boolean;
    reset: () => void;
    proceed: () => void;
  }) => React.ReactNode;
  defaultPromptModalActions?: {
    onMainButtonClick?: () => void;
    onSecondaryButtonClick?: () => void;
    onClose?: () => void;
  };
}

const RouterPrompt: FC<IProps> = ({ when, children, defaultPromptModalActions }) => {
  const [renderPrompt, setRenderPrompt] = useState<boolean>(true);
  const innerPromptDisable = useRef<boolean>(false);

  const blocker = useBlocker((args) => {
    return !innerPromptDisable.current && renderPrompt && when(args);
  });
  const isBlockerActive = blocker?.state === "blocked";

  const handleStorageEvent = (data: StorageEvent) => {
    const { key } = data;
    if (key === "currentRole") {
      setRenderPrompt(false);
    }
  };

  const resetBlocker = () => {
    innerPromptDisable.current = false;
    setRenderPrompt(true);
    blocker?.reset?.();
  };

  const proceedBlocker = () => {
    innerPromptDisable.current = true;
    setRenderPrompt(false);
    blocker?.proceed?.();
  };

  const preventBrowserPop = (event: PopStateEvent) => {
    /* NOTE:::
    I have no idea how that works. Probably, at first, window "popstate" event is fired
    It redirects us, but blocker's state hasn't yet changed, so it redirects us back to our page
    Then it shows us it's modal only once, and we can roceed with blocker calbacks */
    //TODO::: Right now, if you cancel modal once, blocker will not appera gain. Try to fix it later
    innerPromptDisable.current = true;
  };

  useEffect(() => {
    window.addEventListener("storage", handleStorageEvent);
    window.addEventListener("popstate", preventBrowserPop);

    return () => {
      window.removeEventListener("storage", handleStorageEvent);
      window.removeEventListener("popstate", preventBrowserPop);
      innerPromptDisable.current = false;
    };
  }, []);

  useEventsListener<"RoleHasUpdated">(
    "RoleHasUpdated",
    () => {
      setRenderPrompt(false);
      setTimeout(() => {
        setRenderPrompt(true);
      }, 400);
    },
    [],
  );

  useEventsListener<"PromptsWereDisabled">(
    "PromptsWereDisabled",
    (promptsWereDisabled) => {
      setRenderPrompt(!promptsWereDisabled);
    },
    [],
  );

  if (children) {
    return (
      <>
        {isBlockerActive &&
          renderPrompt &&
          children({
            isActive: isBlockerActive,
            reset: resetBlocker,
            proceed: proceedBlocker,
          })}
      </>
    );
  }

  return (
    <PromptModal
      isOpen={isBlockerActive && renderPrompt}
      type={EModalTypes.WARNING}
      title={<Trans i18nKey={"components.navigation_prompt.title"} />}
      message={<Trans i18nKey={"components.navigation_prompt.message"} />}
      mainButton={{
        text: <Trans i18nKey={"components.navigation_prompt.confirm"} />,
        onClick: () => {
          proceedBlocker();
          defaultPromptModalActions?.onMainButtonClick?.();
        },
        autoWidth: true,
      }}
      secondaryButton={{
        text: <Trans i18nKey={"common.modal.cancel"} />,
        onClick: () => {
          resetBlocker();
          defaultPromptModalActions?.onSecondaryButtonClick?.();
        },
        autoWidth: true,
      }}
      onCloseOnButtons={false}
      onClose={() => {
        resetBlocker();
        defaultPromptModalActions?.onClose?.();
      }}
      buttonsFlex={EButtonsFlex.ROW_REVERSE}
    />
  );
};

export default RouterPrompt;
