import { HELP_EMAIL } from "constants/shared";
import { AuthFormType } from "pages/Auth/_shared/components/AuthForm/types";
import { PasswordFormType } from "components/auth";

import { PasswordInputStrengthType } from "uikit/Input/PasswordInput";

export const authPages = {
  "en-US": {
    admin: {
      sign_up_page: {
        sign_up_step: {
          title: "Get Started",
          description: "Please Enter Your Details",
          email_field: {
            placeholder: "Enter your Email",
            title: "Email",
          },
          primary_btn: "Sign Up",
          or_text: "Or",
          navigate_text: "Already have an account?",
          navigate_btn: "Log in",
        },
        business_details_step: {
          title: "Company Information",
          description: "Let's set up your company account.",
          company_name_field: {
            title: "Company Name",
            placeholder: "Enter your Company Name",
          },
        },
        contact_details_step: {
          title: "Personal Information",
          description: "Tell us a little about yourself.",
          first_name: "First Name",
          last_name: "Last Name",
          job_title: "Title",
          phone: "Phone Number",
        },
      },
    },
    employee: {
      sign_up_page: {
        title: "Get Started ",
        description: "Welcome to Paid. Let’s get you set up.",
        email_label: "Create an account using the email:",
        accept_invitation_btn: "Accept Invitation",
      },
      layout_left_section: {
        title: "Keep more of the money you earn.",
        description:
          "Paid is a financial wellness resource for employees - payroll deposits, seamless banking services, and more",
        features: {
          0: "Get your pay up to 2 days early",
          1: "Free Paid account, debit & virtual Cards",
          2: "Earn money on your deposits",
        },
      },
    },

    shared: {
      error_modals: {
        account_was_disabled: {
          title: "Your account was disabled",
        },
        session_has_expired: {
          title: "Session has expired.",
          message: "Your session has expired. Please Login again to access your account.",
        },
        too_many_attempts: {
          title: "Too many log-in attempts",
          message: `Please check your email including your spam and trash folders for the login link. You can try again in 30 mins or contact us at <1>${HELP_EMAIL}</1> for help.`,
        },
      },
      terms_and_policy_disclaimer:
        "By continuing you are accepting our <1>Terms of Services</1>, <2>Privacy Policy</2> and <3>eSign agreement</3>.",
      layout_promo_section: {
        [AuthFormType.SIGN_UP]: {
          title: "Sign Up for Free Payroll from Paid",
          description:
            "A no-fuss payroll platform built for small business owners. Get started in minutes!",
        },
        features: {
          0: "Reliable, user-friendly payroll",
          1: "Automated tax filing",
          2: "High-yield checking and rewards",
        },
      },
      sign_in_page: {
        title: "Welcome to Paid",
        email_field: {
          placeholder: "Enter your Email",
          title: "Email",
        },
        primary_btn: "Enter Password",
        primary_btn_alt: "Sign In",
        secondary_btn: "Send Magic Link",
        or_text: "Or",
        navigate_text: "Need a Company Account?",
        navigate_btn: "Create Account",
      },
      check_email_page: {
        title: "Check your Email",
        description:
          "We’ve sent you a magic link to your email. Open your inbox and click the link to proceed.",
        email_label: "Your Email:",

        instructions: "Didn’t receive an email? Check your spam folder or",
        resend_email: "Resend Email {{time}}",
        accept_invitation_btn: "Accept Invitation",
      },
      sign_in_mfa_page: {
        title: "Enter Verification Code",
        description: "Please enter one-time code sent to ****{{phoneLast4Digits}}.",
        input_placeholder: "Enter 6-digit code here",
        verify_button: "Verify Code",
        layout_left_section: {
          title: "Two-factor Authentication",
          description: "Please enter the code sent via SMS text to your registered number.",
        },
      },
      reset_password_page: {
        back_btn: "Back to Log In",
        send_email_form: {
          title: "Reset Password",
          description:
            "Enter the email address you used to sign up to Paid and we'll send you instructions to reset your password.",
          email_field: {
            title: "Email",
          },
          email_sent_message:
            "If an account exists with this email address, you'll receive a password reset link shortly. Please check your email:",
          primary_btn: "Reset My Password",
        },
        reset_password_form: {
          security_disclaimer:
            "Please note that for security reasons, this will log you out from any existing session you might have.",
        },
      },
      components: {
        google_sign_in_button: {
          text: "Continue with Google",
        },
        password_form: {
          title: {
            [PasswordFormType.SIGN_IN]: "Create New Password",
            [PasswordFormType.CREATE_PASSWORD]: "Create New Password",
            [PasswordFormType.CHANGE_PASSWORD]: "",
            [PasswordFormType.RESET_PASSWORD]: "Create New Password",
          },
          description: {
            [PasswordFormType.SIGN_IN]: "",
            [PasswordFormType.CREATE_PASSWORD]:
              "Creating a password adds an extra layer of security and let’s you access your account without relying only on email.",
            [PasswordFormType.CHANGE_PASSWORD]: "",
            [PasswordFormType.RESET_PASSWORD]: "",
          },
          forgot_password_link: {
            [PasswordFormType.SIGN_IN]: "Forgot password or don't have any?",
            [PasswordFormType.CHANGE_PASSWORD]: "Forgot password?",
          },
          password_requirements: [
            "A minimum of 10 characters.",
            "Have both uppercase and lowercase letters.",
            "At least one number.",
          ],
          input_labels: {
            password: "Password",
            current_password: "Current Password",
            enter_password: "Enter Password",
            new_password: "New Password",
            confirm_password: "Confirm Password",
          },
          validation_errors: {
            password_mismatch: "Passwords doesn't match",
          },
          password_strength_labels: {
            [PasswordInputStrengthType.LOW]: "Low",
            [PasswordInputStrengthType.MODERATE]: "Moderate",
            [PasswordInputStrengthType.STRONG]: "Strong",
          },
          password_input_placeholder: "Enter your password",
          changing_password_footer_disclaimer:
            "Please note that for security reasons, this will log you out from any existing session you might have.",
          button_labels: {
            reset_my_password: "Reset My Password",
            set_password: "Set Password",
          },
          modals: {
            password_changed_successfully: {
              title: "Password Changed",
              message: "Your password has been changed successfully.",
            },
          },
        },
      },
    },
  },
};

export default authPages;
