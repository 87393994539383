import { FC, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ErrorCircledCrossLarge } from "assets/svg";
import moment from "moment";

import { TAppEvents } from "services/events/index";
import { defaultDateFormat } from "constants/shared";
import { getComprehensibleStatus } from "helpers/employee/userStatusMap";
import { useEventsListener } from "hooks";

import {
  InfoSection,
  ISBDate,
  ISBottom,
  ISBText,
  ISBTitle,
  ISTEmail,
  ISTop,
  PrimaryBtn,
  StyledUIModal,
  StyledUserInfo,
  Text,
} from "./styles";

export const ValidationModal: FC = () => {
  const { t } = useTranslation();
  const prefix = "components.validation_modal";
  const [modal, setModal] = useState<boolean>(false);

  const modalRef = useRef<TAppEvents["SetValidationModal"]>({
    userInfo: null,
    exitDate: null,
    onClose: undefined,
  });

  const exitDate = moment(modalRef.current.exitDate).format(defaultDateFormat);

  useEventsListener<"SetValidationModal">(
    "SetValidationModal",
    (data) => {
      try {
        modalRef.current = {
          userInfo: data.userInfo,
          exitDate: data.exitDate,
          onClose: data.onClose ? data.onClose : undefined,
        };
        setModal(!modal);
      } catch (e) {
        console.log(e);
      }
    },
    [modalRef],
  );

  const handleCloseModal = () => {
    modalRef.current?.onClose && modalRef.current?.onClose();
    setModal(false);
  };

  const terminationStatus = modalRef.current.userInfo
    ? getComprehensibleStatus(modalRef.current.userInfo)
    : undefined;

  return (
    <StyledUIModal
      isOpen={modal}
      onClose={handleCloseModal}
      title={<Trans i18nKey={`${prefix}.title`} />}
      icon={<ErrorCircledCrossLarge />}
    >
      <Text>
        <Trans
          i18nKey={`${prefix}.text`}
          values={{ status: t(`${prefix}.${terminationStatus}`) }}
          components={{
            1: <span />,
            2: <br />,
          }}
        />
      </Text>

      <InfoSection>
        <ISTop>
          <StyledUserInfo
            avatar={modalRef.current?.userInfo?.avatarUrl || ""}
            firstName={modalRef.current?.userInfo?.firstName}
            lastName={modalRef.current?.userInfo?.lastName}
            contentAfterName={<ISTEmail>{modalRef.current?.userInfo?.email}</ISTEmail>}
          />
        </ISTop>
        <ISBottom>
          <ISBTitle>
            <Trans i18nKey={`${prefix}.${terminationStatus}`} />
          </ISBTitle>
          <ISBText>
            <Trans i18nKey={`${prefix}.messages.${terminationStatus}`} />
          </ISBText>
          <ISBDate>{exitDate}</ISBDate>
        </ISBottom>
      </InfoSection>

      <PrimaryBtn onClick={() => handleCloseModal()}>
        <Trans i18nKey={"components.confirm_action.ok"} />
      </PrimaryBtn>
    </StyledUIModal>
  );
};
