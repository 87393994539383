import { FC } from "react";

import useAuth from "../../hooks/useAuth";
import { AppEvents } from "../../services/events";
import { useAppSelector } from "../../store/hooks";
import { userMetadataSelector } from "../../store/selectors";
import { CenterContainer, LogOutBtn, Title, Wrapper } from "./styles";

const ImpersonateAlert: FC = () => {
  const currentUser = useAppSelector(userMetadataSelector);
  const { logout } = useAuth();

  if (!currentUser?.impersonatedBy) return;

  const handleLogout = async () => {
    AppEvents.emit("RoleHasUpdated", { roleHasUpdated: true });
    AppEvents.emit("PromptsWereDisabled", { promptsWereDisabled: false });

    await logout(true, true);
  };

  return (
    <Wrapper>
      <CenterContainer>
        <Title>
          Impersonated by: {currentUser?.impersonatedBy?.email}{" "}
          <LogOutBtn onClick={handleLogout}>Log Out</LogOutBtn>
        </Title>
      </CenterContainer>
    </Wrapper>
  );
};

export default ImpersonateAlert;
