import { ReactNode } from "react";

import { NumberType } from "helpers/index";

interface IDetailedEdges {
  visible?: boolean;
  startText?: string;
  endText?: string;
}

export interface Props {
  className?: string;
  items?: IProgressItem[];
  start: number | Date;
  end: number | Date;
  showLegend?: boolean;
  showStartPoint?: boolean;
  showEndPoint?: boolean;
  numbersFormat?: NumberType;
  detailedEdges?: IDetailedEdges;
}

export enum ProgressItemType {
  RANGE = "range",
  POINT = "point",
}

export enum ProgressItemMarkerType {
  DEFAULT = "DEFAULT",
  EMPHASIZED = "EMPHASIZED",
  LEADING = "LEADING",
  BOOSTER_REWARD = "BOOSTER_REWARD",
}

export interface IProgressItem {
  id?: string;
  startPoint?: number | Date | null; //NOTE:::For range items
  endPoint?: number | Date | null; //NOTE:::For range items
  value?: number | Date | null; //NOTE:::For point items
  tooltip?: string | ReactNode | null;
  label?: string | ReactNode | null;
  isPercentage?: boolean | null;
  color?: string | null;
  icon?: ReactNode | null;
  type: ProgressItemType;
  className?: string;
  markerType?: ProgressItemMarkerType;
  isMainProgress?: boolean;
  isBooster?: boolean;
  inactive?: boolean; //NOTE:::Just for styling. For a point: It determines how to display item on progress range (white/gray by default for points, green/gray for ranges)
}

export interface IProgressItemExtended extends IProgressItem {
  //NOTE:::Is used by progress bar itself, not passed as props
  width?: number;
  offset: number;
}
