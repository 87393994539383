import { FC } from "react";
import { Trans } from "react-i18next";
import { Formik } from "formik";

import DashboardLayout from "layouts/DashboardLayout";
import { PasswordFormType, passwordFormValidationSchemas } from "components/auth";
import PasswordForm from "components/auth/PasswordForm/PasswordForm";

import { ButtonsContainer, FullSectionLoader } from "uikit";

import usePasswordManagement from "./usePasswordManagement";
import { FormContainer, StyledPrimaryButton, StyledSecondaryButton, StyledSection } from "./styles";

interface IProps {}

const PasswordManagement: FC<IProps> = () => {
  const { metadata, pageData, actions } = usePasswordManagement();

  return (
    <DashboardLayout showTitle showBreadcrumbs>
      <Formik
        validateOnChange={metadata.svoc}
        initialValues={pageData.initFormValues}
        validationSchema={passwordFormValidationSchemas.fields[PasswordFormType.CHANGE_PASSWORD]}
        onSubmit={() => {}}
      >
        {(formikProps) => (
          <FormContainer
            onSubmit={(e) => {
              e.preventDefault();
              actions.handleSubmit(formikProps);
            }}
          >
            <StyledSection>
              {metadata.isLoading && <FullSectionLoader />}
              <PasswordForm type={PasswordFormType.CHANGE_PASSWORD} />
            </StyledSection>

            <ButtonsContainer>
              <StyledSecondaryButton onClick={actions.onBackButtonClick} data-testid="back-button">
                <Trans i18nKey={`buttons.back`} />
              </StyledSecondaryButton>

              <StyledPrimaryButton
                type="submit"
                disabled={metadata.isLoading}
                data-testid="save-changes-button"
              >
                <Trans i18nKey={`buttons.save_changes`} />
              </StyledPrimaryButton>
            </ButtonsContainer>
          </FormContainer>
        )}
      </Formik>
    </DashboardLayout>
  );
};

export default PasswordManagement;
