import { AttentionTriangleFilledIcon } from "assets/svg";
import styled from "styled-components";

import { Body2, Body3 } from "uikit";

export const Container = styled.div<{ alt?: boolean }>`
  display: flex;
  background-color: ${({ theme }) => theme.colors.warningAlt3};
  border-radius: 8px;
  padding: 16px;
  gap: 16px;

  ${({ theme, alt }) =>
    alt &&
    `
    background-color: ${theme.colors.sectionContainerWarning};
    border: 1px solid ${theme.colors.sectionBorderWarning};
    `}
`;

export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
`;

export const WarningIcon = styled(AttentionTriangleFilledIcon)``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled(Body2)`
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.text};
`;

export const Message = styled(Body3)`
  color: ${({ theme }) => theme.colors.textAlt};
`;
