import styled from "styled-components";

import { breakpoint } from "helpers";

import UIModal from "uikit/Modal/Modal";

export const Modal = styled(UIModal)`
  overflow: hidden;
  outline: none;
  padding: 20px;
  width: 565px !important;
  height: 400px;

  ${breakpoint("md", "xl")`
    width: 500px!important;
    height: min-content;
  `}

  ${breakpoint("xs", "md")`
    max-width: 450px;
    width: 90%!important;
    min-width: 312px!important;
    height: min-content;
    padding: 20px!important;

    & > div {
      padding: 0;
    }
  `}
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 300px;

  ${breakpoint("md", "xl")`
    height: min-content;
  `}
`;

export const Content = styled.div`
  margin-top: 32px;
  margin-bottom: 90px;
`;

export const FormContainer = styled.form``;
