import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { FormikProps } from "formik";

import { COMPANY_ESIGN_CONSENT_DISCLOSURE } from "constants/assets";
import { MAX_INPUT_LENGTH } from "constants/form";
import { PRIVACY_POLICY, TERMS_AND_CONDITIONS } from "constants/shared";
import { getFieldPlaceholderBase } from "helpers";

import { FormLabel, Input, PhoneInput } from "uikit";

import {
  Description,
  FooterLinkExternal,
  FormItem,
  TermsAndPrivacyDisclaimer,
  Title,
} from "../styles";
import { ContactDetailsFormType } from "./validationSchema";
import { Container } from "./styles";

interface Props {
  values: FormikProps<ContactDetailsFormType>["values"];
  errors: FormikProps<ContactDetailsFormType>["errors"];
  handleChange: FormikProps<ContactDetailsFormType>["handleChange"];
  setFieldValue: FormikProps<ContactDetailsFormType>["setFieldValue"];
}

export const ContactDetails: FC<Props> = ({ values, errors, handleChange, setFieldValue }) => {
  const { t } = useTranslation();
  const translationPrefix = "auth_pages.admin.sign_up_page.contact_details_step";

  return (
    <Container>
      <Title>
        <Trans i18nKey={`${translationPrefix}.title`} />
      </Title>
      <Description>
        <Trans i18nKey={`${translationPrefix}.description`} />
      </Description>

      <FormItem>
        <FormLabel>
          <Trans i18nKey={`${translationPrefix}.first_name`} />
        </FormLabel>
        <Input
          alwaysShowErrorBlock={false}
          placeholder={getFieldPlaceholderBase(`${translationPrefix}.first_name`)}
          name={`firstName`}
          value={values.firstName}
          onChange={handleChange}
          error={
            errors?.firstName && (
              <Trans
                i18nKey={errors.firstName}
                values={{
                  field: t(`${translationPrefix}.first_name`),
                  maxInputLength: MAX_INPUT_LENGTH,
                }}
              />
            )
          }
          autoComplete={"first-name"}
          errorDataTestId="error-firstName"
        />
      </FormItem>

      <FormItem>
        <FormLabel>
          <Trans i18nKey={`${translationPrefix}.last_name`} />
        </FormLabel>
        <Input
          alwaysShowErrorBlock={false}
          placeholder={getFieldPlaceholderBase(`${translationPrefix}.last_name`)}
          name={`lastName`}
          value={values.lastName}
          onChange={handleChange}
          error={
            errors?.lastName && (
              <Trans
                i18nKey={errors.lastName}
                values={{
                  field: t(`${translationPrefix}.last_name`),
                  maxInputLength: MAX_INPUT_LENGTH,
                }}
              />
            )
          }
          autoComplete={"family-name"}
          errorDataTestId="error-lastName"
        />
      </FormItem>

      <FormItem>
        <FormLabel>
          <Trans i18nKey={`${translationPrefix}.job_title`} /> (
          <Trans i18nKey={`common.input.optional`} />)
        </FormLabel>
        <Input
          alwaysShowErrorBlock={false}
          placeholder={getFieldPlaceholderBase(`${translationPrefix}.job_title`)}
          name={`jobTitle`}
          value={values.jobTitle}
          onChange={handleChange}
          error={
            errors?.jobTitle && (
              <Trans
                i18nKey={errors.jobTitle}
                values={{
                  field: t(`${translationPrefix}.job_title`),
                  maxInputLength: MAX_INPUT_LENGTH,
                }}
              />
            )
          }
          errorDataTestId="error-jobTitle"
        />
      </FormItem>

      <FormItem>
        <FormLabel>
          <Trans i18nKey={`${translationPrefix}.phone`} /> (
          <Trans i18nKey={`common.input.optional`} />)
        </FormLabel>
        <PhoneInput
          alwaysShowErrorBlock={false}
          placeholder={getFieldPlaceholderBase(`${translationPrefix}.phone`)}
          name={`phone`}
          value={values.phone}
          onChange={(value) => setFieldValue(`phone`, value)}
          error={
            errors?.phone && (
              <Trans i18nKey={errors.phone} values={{ field: t(`${translationPrefix}.phone`) }} />
            )
          }
          autoComplete={"tel"}
          errorDataTestId="error-phone"
        />
      </FormItem>

      <TermsAndPrivacyDisclaimer>
        <Trans
          i18nKey={`auth_pages.shared.terms_and_policy_disclaimer`}
          components={{
            1: <FooterLinkExternal href={TERMS_AND_CONDITIONS} target="_blank" rel="noreferrer" />,
            2: <FooterLinkExternal href={PRIVACY_POLICY} target="_blank" rel="noreferrer" />,
            3: (
              <FooterLinkExternal
                href={`${COMPANY_ESIGN_CONSENT_DISCLOSURE}?t=${Date.now()}`}
                target="_blank"
                rel="noreferrer"
              />
            ),
          }}
        />
      </TermsAndPrivacyDisclaimer>
    </Container>
  );
};
