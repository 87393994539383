import { IMappedBankAccount } from "types/BankAccounts";
import { PayDistributionType } from "types/BETypes";
import { APPLICATION_NAME } from "constants/systemConstants";

export const companyBankAccountDataTemplate: IMappedBankAccount = {
  last4Digits: "1234",
  institution: {
    name: APPLICATION_NAME,
    bankInstitutionId: "",
    plaidInstitutionId: "",
    logo: "",
  },
  _accountType: PayDistributionType.PARTNER_ACCOUNT,
};
