import { FC, ReactNode, SyntheticEvent } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion as AccordionBase,
  AccordionDetails,
  AccordionDetailsProps,
  AccordionSummary,
  AccordionSummaryTypeMap,
  AccordionTypeMap,
} from "@mui/material";

import { Container, Header } from "./styles";

interface Props {
  id: string;
  className?: string;
  isExpanded?: boolean;
  title?: ReactNode | string;
  titleComponent?: ReactNode | null;
  expandIcon?: ReactNode | null;
  collapseIcon?: ReactNode | null;
  children?: ReactNode | null;
  onChange?: (event: SyntheticEvent, expanded: boolean) => void;

  //NOTE:::Base Accordion Props
  baseProps?: AccordionTypeMap<{}, "div">;
  summaryProps?: AccordionSummaryTypeMap<{}, "div">;
  detailsProps?: AccordionDetailsProps;
}

const Logo: FC<Props> = ({
  id,
  className,
  isExpanded,
  title,
  titleComponent,
  expandIcon = <ExpandMoreIcon />,
  children,
  onChange,

  baseProps,
  summaryProps,
  detailsProps,
}) => {
  return (
    <Container className={className}>
      <AccordionBase
        {...baseProps}
        className={"accordion-component-base"}
        expanded={isExpanded}
        onChange={onChange}
      >
        <AccordionSummary
          {...summaryProps}
          className={"accordion-component-summary"}
          expandIcon={expandIcon || summaryProps?.props?.expandIcon}
          aria-controls="panel1-content"
          id={id}
        >
          {titleComponent || <Header>{title}</Header>}
        </AccordionSummary>
        <AccordionDetails {...detailsProps} className={"accordion-component-details"}>
          {children}
        </AccordionDetails>
      </AccordionBase>
    </Container>
  );
};

export default Logo;
