import { FC } from "react";
import { Trans } from "react-i18next";

import DashboardLayout from "layouts/DashboardLayout";
import PayDistribution from "components/PayDistribution";
import { PayDistributionWidgetType } from "components/PayDistribution/types";

import useEmployeePayDistributionPage from "./useEmployeePayDistributionPage";
import { ManageBankAccountButton } from "./styles";

interface IProps {
  className?: string;
}

const EmployeePayDistributionPage: FC<IProps> = ({ className }) => {
  const translationPrefix = `pay_distribution_page.employee.index`;

  const { redirectToBankAccounts } = useEmployeePayDistributionPage();

  return (
    <DashboardLayout
      className={className}
      titleRightContent={
        <ManageBankAccountButton
          onClick={redirectToBankAccounts}
          data-testid="manage-bank-account-button"
        >
          <Trans i18nKey={`${translationPrefix}.manage_bank_accounts_button`} />{" "}
        </ManageBankAccountButton>
      }
    >
      <>
        <PayDistribution widgetType={PayDistributionWidgetType.SETTINGS} />
      </>
    </DashboardLayout>
  );
};

export default EmployeePayDistributionPage;
