/* eslint-disable */
/* tslint:disable */
import ky from "ky";
import { useQuery, useMutation } from "react-query";
import { getLocalhostAuthToken } from "services/auth";

let api = ky.create({
  prefixUrl: process.env.REACT_APP_API_HOST,
  throwHttpErrors: false,
  //credentials: "same-origin",
  credentials: "include",
  hooks: {
    beforeRequest: [],
    beforeRetry: [],
  },
  timeout: 5 * 60 * 1000,
});

export const getApi = () => api;

export const setApi = (newApi) => {
  api = newApi;
};

export const extendApi = (options) => {
  api = getApi().extend(options);
};

const getKyOptions = () => {
  const isLocalhost = (process.env.REACT_APP_FRONTEND_HOST || "").includes("https://localhost");
  const localhostAuthToken = getLocalhostAuthToken();
  const accessToken = localhostAuthToken ? localhostAuthToken : undefined;

  return isLocalhost
    ? {
        headers: {
          Authorization: accessToken,
        },
      }
    : {};
};

const requestFn = async ({ url, method, pathParams, queryParams, ...rest }) => {
  if (typeof url === "object") {
    pathParams = url.queryKey[1];
    queryParams = url.queryKey[2] ?? {};
    url = url.queryKey[0];
  }

  extendApi(getKyOptions());

  const urlPathParams = url.match(/{([^}]+)}/g);

  if (urlPathParams) {
    url = urlPathParams.reduce(
      (acc, param) => acc.replace(param, pathParams[param.replace(/{|}/g, "")]),
      url,
    );
  } else {
    queryParams = { ...queryParams, ...pathParams };
  }

  if (url.charAt(0) === "/") {
    url = url.replace("/", "");
  }

  const searchParams = new URLSearchParams({
    ...(rest.searchParams || {}),
    ...queryParams,
  });

  rest?.searchParams?.forEach((value, key) => {
    searchParams?.append(key, value);
  });

  const response = await api(url, {
    method,
    ...rest,
    searchParams,
  });

  let data;

  try {
    const contentType = (response.headers.get("content-type") || "").split(";")[0];

    const responseType =
      {
        "application/json": "json",
        "application/pdf": "blob",
      }[contentType] || "text";

    data = await response[responseType]();
  } catch (e) {
    data = e.message;
  }

  if (!response || !response.ok) {
    const error = {
      data,
      status: response.status,
      message: `Failed to fetch: ${response.status} ${response.statusText}`,
    };

    throw error;
  }

  return data;
};

const queryFn =
  (options = {}) =>
  (url, pathParams = {}, queryParams = {}) => {
    const controller = new AbortController();
    const { signal } = controller;

    const promise = requestFn({
      url,
      method: "get",
      pathParams,
      queryParams,
      signal,
      ...options,
    });

    // cancel the request if React Query calls the 'promise.cancel' method
    promise.cancel = () => {
      controller.abort("Query was cancelled by React Query");
    };

    return promise;
  };

const mutationFn =
  (method, url, pathParams = {}, queryParams = {}, options = {}) =>
  (body = {}) => {
    if (Array.isArray(body)) {
      pathParams = { ...pathParams, ...(body[0] || {}) };
      queryParams = { ...queryParams, ...(body[1] || {}) };
      options = { ...options, ...(body[3] || {}) };
      body = body[2];
    }

    const request = {
      url,
      method,
      pathParams,
      queryParams,
      ...options,
    };

    if (method !== "delete") {
      try {
        request[body.toString() === "[object FormData]" ? "body" : "json"] = body;
      } catch (e) {}
    }

    return requestFn(request);
  };

export const mutationAuthControllerGetGoogleOAuthUrl = (options) =>
  mutationFn("post", "/auth/google-oauth", {}, {}, options);
export const useMutationAuthControllerGetGoogleOAuthUrl = (config, options) =>
  useMutation(mutationFn("post", "/auth/google-oauth", {}, {}, options), config);

export const mutationAuthControllerAuthPasswordless = (options) =>
  mutationFn("post", "/auth/passwordless", {}, {}, options);
export const useMutationAuthControllerAuthPasswordless = (config, options) =>
  useMutation(mutationFn("post", "/auth/passwordless", {}, {}, options), config);

export const mutationAuthControllerAuthWithPassword = (options) =>
  mutationFn("post", "/auth/password", {}, {}, options);
export const useMutationAuthControllerAuthWithPassword = (config, options) =>
  useMutation(mutationFn("post", "/auth/password", {}, {}, options), config);

export const mutationAuthControllerSendResetPasswordEmail = (options) =>
  mutationFn("post", "/auth/send-reset-password-email", {}, {}, options);
export const useMutationAuthControllerSendResetPasswordEmail = (config, options) =>
  useMutation(mutationFn("post", "/auth/send-reset-password-email", {}, {}, options), config);

export const mutationAuthControllerResetPassword = (options) =>
  mutationFn("post", "/auth/reset-password", {}, {}, options);
export const useMutationAuthControllerResetPassword = (config, options) =>
  useMutation(mutationFn("post", "/auth/reset-password", {}, {}, options), config);

export const mutationAuthControllerAuthorize = (options) =>
  mutationFn("post", "/auth", {}, {}, options);
export const useMutationAuthControllerAuthorize = (config, options) =>
  useMutation(mutationFn("post", "/auth", {}, {}, options), config);

export const queryAuthControllerCheckUserInvitationStatus = (queryParams, options) =>
  queryFn(options)("/auth/user-invitation-status", queryParams);
export const useQueryAuthControllerCheckUserInvitationStatus = (queryParams, config, options) =>
  useQuery(["/auth/user-invitation-status", queryParams], queryFn(options), config);
useQueryAuthControllerCheckUserInvitationStatus.queryKey = "/auth/user-invitation-status";

export const queryAuthControllerGetCompanyAuthToken = (pathParams, options) =>
  queryFn(options)("/auth/companies/{companyId}/token", pathParams);
export const useQueryAuthControllerGetCompanyAuthToken = (pathParams, config, options) =>
  useQuery(
    pathParams && pathParams.companyId && ["/auth/companies/{companyId}/token", pathParams],
    queryFn(options),
    config,
  );
useQueryAuthControllerGetCompanyAuthToken.queryKey = "/auth/companies/{companyId}/token";

export const mutationAuthControllerLogout = (options) =>
  mutationFn("post", "/auth/logout", {}, {}, options);
export const useMutationAuthControllerLogout = (config, options) =>
  useMutation(mutationFn("post", "/auth/logout", {}, {}, options), config);

export const queryAuthControllerSsoLogin = (queryParams, options) =>
  queryFn(options)("/auth/sso-oauth", queryParams);
export const useQueryAuthControllerSsoLogin = (queryParams, config, options) =>
  useQuery(["/auth/sso-oauth", queryParams], queryFn(options), config);
useQueryAuthControllerSsoLogin.queryKey = "/auth/sso-oauth";

export const mutationAuthControllerGetSalsaTokens = (options) =>
  mutationFn("post", "/auth/salsa-tokens", {}, {}, options);
export const useMutationAuthControllerGetSalsaTokens = (config, options) =>
  useMutation(mutationFn("post", "/auth/salsa-tokens", {}, {}, options), config);

export const mutationAuthControllerMfaLogin = (options) =>
  mutationFn("post", "/auth/mfa/login", {}, {}, options);
export const useMutationAuthControllerMfaLogin = (config, options) =>
  useMutation(mutationFn("post", "/auth/mfa/login", {}, {}, options), config);

export const queryAuthControllerListMfaFactors = (options) => queryFn(options)("/auth/mfa/factors");
export const useQueryAuthControllerListMfaFactors = (config, options) =>
  useQuery(["/auth/mfa/factors"], queryFn(options), config);
useQueryAuthControllerListMfaFactors.queryKey = "/auth/mfa/factors";

export const mutationAuthControllerEnrollMfaFactor = (options) =>
  mutationFn("post", "/auth/mfa/factors", {}, {}, options);
export const useMutationAuthControllerEnrollMfaFactor = (config, options) =>
  useMutation(mutationFn("post", "/auth/mfa/factors", {}, {}, options), config);

export const mutationAuthControllerChallengeMfaFactor = (pathParams, options) =>
  mutationFn("post", "/auth/mfa/factors/{factorId}/challenge", pathParams, {}, options);
export const useMutationAuthControllerChallengeMfaFactor = (pathParams, config, options) =>
  useMutation(
    mutationFn("post", "/auth/mfa/factors/{factorId}/challenge", pathParams, {}, options),
    config,
  );

export const mutationAuthControllerVerifyMfaFactor = (pathParams, options) =>
  mutationFn("post", "/auth/mfa/factors/{factorId}/verify", pathParams, {}, options);
export const useMutationAuthControllerVerifyMfaFactor = (pathParams, config, options) =>
  useMutation(
    mutationFn("post", "/auth/mfa/factors/{factorId}/verify", pathParams, {}, options),
    config,
  );

export const mutationAuthControllerUpdateMfaFactor = (pathParams, options) =>
  mutationFn("patch", "/auth/mfa/factors/{factorId}", pathParams, {}, options);
export const useMutationAuthControllerUpdateMfaFactor = (pathParams, config, options) =>
  useMutation(mutationFn("patch", "/auth/mfa/factors/{factorId}", pathParams, {}, options), config);

export const mutationAuthControllerDeleteMfaFactor = (pathParams, queryParams, options) =>
  mutationFn("delete", "/auth/mfa/factors/{factorId}", pathParams, queryParams, options);
export const useMutationAuthControllerDeleteMfaFactor = (
  pathParams,
  queryParams,
  config,
  options,
) =>
  useMutation(
    mutationFn("delete", "/auth/mfa/factors/{factorId}", pathParams, queryParams, options),
    config,
  );

export const mutationUsersControllerUpdateProfile = (options) =>
  mutationFn("patch", "/user", {}, {}, options);
export const useMutationUsersControllerUpdateProfile = (config, options) =>
  useMutation(mutationFn("patch", "/user", {}, {}, options), config);

export const queryUsersControllerGetProfile = (options) => queryFn(options)("/user");
export const useQueryUsersControllerGetProfile = (config, options) =>
  useQuery(["/user"], queryFn(options), config);
useQueryUsersControllerGetProfile.queryKey = "/user";

export const mutationUsersControllerUpdateLastActiveRole = (options) =>
  mutationFn("put", "/user/last-active-role", {}, {}, options);
export const useMutationUsersControllerUpdateLastActiveRole = (config, options) =>
  useMutation(mutationFn("put", "/user/last-active-role", {}, {}, options), config);

export const mutationUsersControllerUpdateMaritalStatus = (options) =>
  mutationFn("put", "/user/marital-status", {}, {}, options);
export const useMutationUsersControllerUpdateMaritalStatus = (config, options) =>
  useMutation(mutationFn("put", "/user/marital-status", {}, {}, options), config);

export const mutationUsersControllerAcceptTermsAndConditions = (options) =>
  mutationFn("put", "/user/accept-terms", {}, {}, options);
export const useMutationUsersControllerAcceptTermsAndConditions = (config, options) =>
  useMutation(mutationFn("put", "/user/accept-terms", {}, {}, options), config);

export const mutationUsersControllerUpdateEarlyPay = (options) =>
  mutationFn("put", "/user/early-pay", {}, {}, options);
export const useMutationUsersControllerUpdateEarlyPay = (config, options) =>
  useMutation(mutationFn("put", "/user/early-pay", {}, {}, options), config);

export const mutationUsersControllerUpdateDefaultPayDistribution = (options) =>
  mutationFn("put", "/user/default-pay-distribution", {}, {}, options);
export const useMutationUsersControllerUpdateDefaultPayDistribution = (config, options) =>
  useMutation(mutationFn("put", "/user/default-pay-distribution", {}, {}, options), config);

export const queryUsersControllerGetHomeLocation = (options) =>
  queryFn(options)("/user/locations/home");
export const useQueryUsersControllerGetHomeLocation = (config, options) =>
  useQuery(["/user/locations/home"], queryFn(options), config);
useQueryUsersControllerGetHomeLocation.queryKey = "/user/locations/home";

export const mutationUsersControllerGetUserIdsByEmail = (options) =>
  mutationFn("post", "/user/email-exists/{email}", {}, {}, options);
export const useMutationUsersControllerGetUserIdsByEmail = (config, options) =>
  useMutation(mutationFn("post", "/user/email-exists/{email}", {}, {}, options), config);

export const queryUsersControllerGetPersonalInfo = (queryParams, options) =>
  queryFn(options)("/user/personal-info", queryParams);
export const useQueryUsersControllerGetPersonalInfo = (queryParams, config, options) =>
  useQuery(["/user/personal-info", queryParams], queryFn(options), config);
useQueryUsersControllerGetPersonalInfo.queryKey = "/user/personal-info";

export const queryUsersControllerGetRewardPoints = (options) =>
  queryFn(options)("/user/reward-points");
export const useQueryUsersControllerGetRewardPoints = (config, options) =>
  useQuery(["/user/reward-points"], queryFn(options), config);
useQueryUsersControllerGetRewardPoints.queryKey = "/user/reward-points";

export const mutationUsersControllerCreateOrUpdatePassword = (options) =>
  mutationFn("put", "/user/password", {}, {}, options);
export const useMutationUsersControllerCreateOrUpdatePassword = (config, options) =>
  useMutation(mutationFn("put", "/user/password", {}, {}, options), config);

export const queryRewardPointsControllerListTransactions = (queryParams, options) =>
  queryFn(options)("/reward-points/transactions", queryParams);
export const useQueryRewardPointsControllerListTransactions = (queryParams, config, options) =>
  useQuery(["/reward-points/transactions", queryParams], queryFn(options), config);
useQueryRewardPointsControllerListTransactions.queryKey = "/reward-points/transactions";

export const mutationRewardPointsControllerRedeemRewardPoints = (options) =>
  mutationFn("post", "/reward-points/redeem", {}, {}, options);
export const useMutationRewardPointsControllerRedeemRewardPoints = (config, options) =>
  useMutation(mutationFn("post", "/reward-points/redeem", {}, {}, options), config);

export const queryDocumentsControllerGetServiceAgreement = (options) =>
  queryFn(options)("/documents/service-agreement");
export const useQueryDocumentsControllerGetServiceAgreement = (config, options) =>
  useQuery(["/documents/service-agreement"], queryFn(options), config);
useQueryDocumentsControllerGetServiceAgreement.queryKey = "/documents/service-agreement";

export const queryDocumentsControllerGetDocument = (pathParams, options) =>
  queryFn(options)("/documents/{documentId}", pathParams);
export const useQueryDocumentsControllerGetDocument = (pathParams, config, options) =>
  useQuery(
    pathParams && pathParams.documentId && ["/documents/{documentId}", pathParams],
    queryFn(options),
    config,
  );
useQueryDocumentsControllerGetDocument.queryKey = "/documents/{documentId}";

export const queryDocumentsControllerGetDocumentStatus = (pathParams, options) =>
  queryFn(options)("/documents/{documentId}/status", pathParams);
export const useQueryDocumentsControllerGetDocumentStatus = (pathParams, config, options) =>
  useQuery(
    pathParams && pathParams.documentId && ["/documents/{documentId}/status", pathParams],
    queryFn(options),
    config,
  );
useQueryDocumentsControllerGetDocumentStatus.queryKey = "/documents/{documentId}/status";

export const mutationDocumentsControllerUpdateDocumentStatus = (pathParams, options) =>
  mutationFn("patch", "/documents/{documentId}/status", pathParams, {}, options);
export const useMutationDocumentsControllerUpdateDocumentStatus = (pathParams, config, options) =>
  useMutation(
    mutationFn("patch", "/documents/{documentId}/status", pathParams, {}, options),
    config,
  );

export const mutationDocumentsControllerSyncDocumentStatus = (pathParams, options) =>
  mutationFn("post", "/documents/{documentId}/sync-docusign", pathParams, {}, options);
export const useMutationDocumentsControllerSyncDocumentStatus = (pathParams, config, options) =>
  useMutation(
    mutationFn("post", "/documents/{documentId}/sync-docusign", pathParams, {}, options),
    config,
  );

export const mutationDocumentsControllerSubmitServiceAgreement = (options) =>
  mutationFn("post", "/documents/submit-service-agreement", {}, {}, options);
export const useMutationDocumentsControllerSubmitServiceAgreement = (config, options) =>
  useMutation(mutationFn("post", "/documents/submit-service-agreement", {}, {}, options), config);

export const queryDocumentsControllerGetDocumentRecipientView = (
  pathParams,
  queryParams,
  options,
) => queryFn(options)("/documents/{documentId}/view", pathParams, queryParams);
export const useQueryDocumentsControllerGetDocumentRecipientView = (
  pathParams,
  queryParams,
  config,
  options,
) =>
  useQuery(
    pathParams &&
      pathParams.documentId && ["/documents/{documentId}/view", pathParams, queryParams],
    queryFn(options),
    config,
  );
useQueryDocumentsControllerGetDocumentRecipientView.queryKey = "/documents/{documentId}/view";

export const queryDocumentsControllerDownloadDocument = (pathParams, options) =>
  queryFn(options)("/documents/{documentId}/download", pathParams);
export const useQueryDocumentsControllerDownloadDocument = (pathParams, config, options) =>
  useQuery(
    pathParams && pathParams.documentId && ["/documents/{documentId}/download", pathParams],
    queryFn(options),
    config,
  );
useQueryDocumentsControllerDownloadDocument.queryKey = "/documents/{documentId}/download";

export const mutationDocumentsControllerSendDocument = (pathParams, options) =>
  mutationFn("post", "/documents/{documentId}/send", pathParams, {}, options);
export const useMutationDocumentsControllerSendDocument = (pathParams, config, options) =>
  useMutation(mutationFn("post", "/documents/{documentId}/send", pathParams, {}, options), config);

export const mutationCompaniesControllerCreateCompany = (options) =>
  mutationFn("post", "/companies", {}, {}, options);
export const useMutationCompaniesControllerCreateCompany = (config, options) =>
  useMutation(mutationFn("post", "/companies", {}, {}, options), config);

export const queryCompaniesControllerListCompanies = (options) => queryFn(options)("/companies");
export const useQueryCompaniesControllerListCompanies = (config, options) =>
  useQuery(["/companies"], queryFn(options), config);
useQueryCompaniesControllerListCompanies.queryKey = "/companies";

export const mutationCompaniesControllerUpdateCompany = (pathParams, options) =>
  mutationFn("patch", "/companies/{companyId}", pathParams, {}, options);
export const useMutationCompaniesControllerUpdateCompany = (pathParams, config, options) =>
  useMutation(mutationFn("patch", "/companies/{companyId}", pathParams, {}, options), config);

export const queryCompaniesControllerGetCompany = (pathParams, options) =>
  queryFn(options)("/companies/{companyId}", pathParams);
export const useQueryCompaniesControllerGetCompany = (pathParams, config, options) =>
  useQuery(
    pathParams && pathParams.companyId && ["/companies/{companyId}", pathParams],
    queryFn(options),
    config,
  );
useQueryCompaniesControllerGetCompany.queryKey = "/companies/{companyId}";

export const queryCompaniesControllerBusinessInfo = (pathParams, options) =>
  queryFn(options)("/companies/business-info/{companyId}", pathParams);
export const useQueryCompaniesControllerBusinessInfo = (pathParams, config, options) =>
  useQuery(
    pathParams && pathParams.companyId && ["/companies/business-info/{companyId}", pathParams],
    queryFn(options),
    config,
  );
useQueryCompaniesControllerBusinessInfo.queryKey = "/companies/business-info/{companyId}";

export const queryCompaniesControllerLogoUploadUrl = (pathParams, queryParams, options) =>
  queryFn(options)("/companies/{companyId}/logo-upload-url", pathParams, queryParams);
export const useQueryCompaniesControllerLogoUploadUrl = (
  pathParams,
  queryParams,
  config,
  options,
) =>
  useQuery(
    pathParams &&
      pathParams.companyId && ["/companies/{companyId}/logo-upload-url", pathParams, queryParams],
    queryFn(options),
    config,
  );
useQueryCompaniesControllerLogoUploadUrl.queryKey = "/companies/{companyId}/logo-upload-url";

export const mutationCompaniesControllerUpdateContactEmail = (pathParams, options) =>
  mutationFn("put", "/companies/{companyId}/contact-email", pathParams, {}, options);
export const useMutationCompaniesControllerUpdateContactEmail = (pathParams, config, options) =>
  useMutation(
    mutationFn("put", "/companies/{companyId}/contact-email", pathParams, {}, options),
    config,
  );

export const mutationCompaniesControllerUpdateDefaultTerminationSettings = (pathParams, options) =>
  mutationFn("put", "/companies/{companyId}/termination-settings", pathParams, {}, options);
export const useMutationCompaniesControllerUpdateDefaultTerminationSettings = (
  pathParams,
  config,
  options,
) =>
  useMutation(
    mutationFn("put", "/companies/{companyId}/termination-settings", pathParams, {}, options),
    config,
  );

export const mutationCompaniesControllerGetAdminPortalLink = (options) =>
  mutationFn("post", "/companies/admin-portal-link", {}, {}, options);
export const useMutationCompaniesControllerGetAdminPortalLink = (config, options) =>
  useMutation(mutationFn("post", "/companies/admin-portal-link", {}, {}, options), config);

export const mutationCompaniesControllerDeleteIdp = (pathParams, options) =>
  mutationFn("delete", "/companies/idp-connections/{connectionId}", pathParams, {}, options);
export const useMutationCompaniesControllerDeleteIdp = (pathParams, config, options) =>
  useMutation(
    mutationFn("delete", "/companies/idp-connections/{connectionId}", pathParams, {}, options),
    config,
  );

export const mutationCompaniesControllerSaveIdpConnections = (options) =>
  mutationFn("post", "/companies/idp-connections", {}, {}, options);
export const useMutationCompaniesControllerSaveIdpConnections = (config, options) =>
  useMutation(mutationFn("post", "/companies/idp-connections", {}, {}, options), config);

export const queryCompaniesControllerGetIdpConnections = (pathParams, options) =>
  queryFn(options)("/companies/idp-connections/{companyId}", pathParams);
export const useQueryCompaniesControllerGetIdpConnections = (pathParams, config, options) =>
  useQuery(
    pathParams && pathParams.companyId && ["/companies/idp-connections/{companyId}", pathParams],
    queryFn(options),
    config,
  );
useQueryCompaniesControllerGetIdpConnections.queryKey = "/companies/idp-connections/{companyId}";

export const mutationCompaniesControllerAddCompanyDomain = (pathParams, options) =>
  mutationFn("post", "/companies/{companyId}/domains", pathParams, {}, options);
export const useMutationCompaniesControllerAddCompanyDomain = (pathParams, config, options) =>
  useMutation(
    mutationFn("post", "/companies/{companyId}/domains", pathParams, {}, options),
    config,
  );

export const queryCompaniesControllerListCompanyDomains = (pathParams, options) =>
  queryFn(options)("/companies/{companyId}/domains", pathParams);
export const useQueryCompaniesControllerListCompanyDomains = (pathParams, config, options) =>
  useQuery(
    pathParams && pathParams.companyId && ["/companies/{companyId}/domains", pathParams],
    queryFn(options),
    config,
  );
useQueryCompaniesControllerListCompanyDomains.queryKey = "/companies/{companyId}/domains";

export const mutationCompaniesControllerDeleteCompanyDomain = (pathParams, options) =>
  mutationFn("delete", "/companies/{companyId}/domains/{domainId}", pathParams, {}, options);
export const useMutationCompaniesControllerDeleteCompanyDomain = (pathParams, config, options) =>
  useMutation(
    mutationFn("delete", "/companies/{companyId}/domains/{domainId}", pathParams, {}, options),
    config,
  );

export const mutationCompaniesControllerSendCompanyDomainVerificationLink = (pathParams, options) =>
  mutationFn(
    "post",
    "/companies/{companyId}/domains/{domainId}/send-verification-link",
    pathParams,
    {},
    options,
  );
export const useMutationCompaniesControllerSendCompanyDomainVerificationLink = (
  pathParams,
  config,
  options,
) =>
  useMutation(
    mutationFn(
      "post",
      "/companies/{companyId}/domains/{domainId}/send-verification-link",
      pathParams,
      {},
      options,
    ),
    config,
  );

export const mutationCompaniesControllerVerifyCompanyDomain = (options) =>
  mutationFn("post", "/companies/verify-domain", {}, {}, options);
export const useMutationCompaniesControllerVerifyCompanyDomain = (config, options) =>
  useMutation(mutationFn("post", "/companies/verify-domain", {}, {}, options), config);

export const mutationCompaniesControllerSetEmployerDefaultBankAccount = (pathParams, options) =>
  mutationFn("put", "/companies/{companyId}/default-bank-account", pathParams, {}, options);
export const useMutationCompaniesControllerSetEmployerDefaultBankAccount = (
  pathParams,
  config,
  options,
) =>
  useMutation(
    mutationFn("put", "/companies/{companyId}/default-bank-account", pathParams, {}, options),
    config,
  );

export const queryPlaidControllerGetLinkToken = (queryParams, options) =>
  queryFn(options)("/plaid/link/token", queryParams);
export const useQueryPlaidControllerGetLinkToken = (queryParams, config, options) =>
  useQuery(["/plaid/link/token", queryParams], queryFn(options), config);
useQueryPlaidControllerGetLinkToken.queryKey = "/plaid/link/token";

export const mutationPlaidControllerChangeToken = (options) =>
  mutationFn("post", "/plaid/change/token", {}, {}, options);
export const useMutationPlaidControllerChangeToken = (config, options) =>
  useMutation(mutationFn("post", "/plaid/change/token", {}, {}, options), config);

export const queryPlaidControllerListBankAccounts = (options) => queryFn(options)("/plaid");
export const useQueryPlaidControllerListBankAccounts = (config, options) =>
  useQuery(["/plaid"], queryFn(options), config);
useQueryPlaidControllerListBankAccounts.queryKey = "/plaid";

export const mutationPlaidControllerDeleteBankAccount = (pathParams, options) =>
  mutationFn("delete", "/plaid/{accountId}", pathParams, {}, options);
export const useMutationPlaidControllerDeleteBankAccount = (pathParams, config, options) =>
  useMutation(mutationFn("delete", "/plaid/{accountId}", pathParams, {}, options), config);

export const mutationPlaidControllerRenewLoginBankAccount = (pathParams, options) =>
  mutationFn("post", "/plaid/bank-accounts/{accountId}/renew-login", pathParams, {}, options);
export const useMutationPlaidControllerRenewLoginBankAccount = (pathParams, config, options) =>
  useMutation(
    mutationFn("post", "/plaid/bank-accounts/{accountId}/renew-login", pathParams, {}, options),
    config,
  );

export const mutationPlaidControllerAcceptDisclosure = (options) =>
  mutationFn("post", "/plaid/accept-disclosure", {}, {}, options);
export const useMutationPlaidControllerAcceptDisclosure = (config, options) =>
  useMutation(mutationFn("post", "/plaid/accept-disclosure", {}, {}, options), config);

export const mutationPlaidControllerVerifyBankAccount = (pathParams, options) =>
  mutationFn("put", "/plaid/verify/{accountId}", pathParams, {}, options);
export const useMutationPlaidControllerVerifyBankAccount = (pathParams, config, options) =>
  useMutation(mutationFn("put", "/plaid/verify/{accountId}", pathParams, {}, options), config);

export const queryDashboardControllerGetCompanyOnboardingProgress = (options) =>
  queryFn(options)("/dashboard/company-onboarding-progress");
export const useQueryDashboardControllerGetCompanyOnboardingProgress = (config, options) =>
  useQuery(["/dashboard/company-onboarding-progress"], queryFn(options), config);
useQueryDashboardControllerGetCompanyOnboardingProgress.queryKey =
  "/dashboard/company-onboarding-progress";

export const mutationDashboardControllerSetCompanyOnboarded = (options) =>
  mutationFn("post", "/dashboard/set-company-onboarded", {}, {}, options);
export const useMutationDashboardControllerSetCompanyOnboarded = (config, options) =>
  useMutation(mutationFn("post", "/dashboard/set-company-onboarded", {}, {}, options), config);

export const queryDashboardControllerGetEmployeeOnboardingProgress = (options) =>
  queryFn(options)("/dashboard/employee-onboarding-progress");
export const useQueryDashboardControllerGetEmployeeOnboardingProgress = (config, options) =>
  useQuery(["/dashboard/employee-onboarding-progress"], queryFn(options), config);
useQueryDashboardControllerGetEmployeeOnboardingProgress.queryKey =
  "/dashboard/employee-onboarding-progress";

export const mutationDashboardControllerSetUserOnboarded = (options) =>
  mutationFn("post", "/dashboard/set-user-onboarded", {}, {}, options);
export const useMutationDashboardControllerSetUserOnboarded = (config, options) =>
  useMutation(mutationFn("post", "/dashboard/set-user-onboarded", {}, {}, options), config);

export const queryDashboardControllerGetEmployerWidgetBonusesData = (options) =>
  queryFn(options)("/dashboard/widgets/employer/bonuses");
export const useQueryDashboardControllerGetEmployerWidgetBonusesData = (config, options) =>
  useQuery(["/dashboard/widgets/employer/bonuses"], queryFn(options), config);
useQueryDashboardControllerGetEmployerWidgetBonusesData.queryKey =
  "/dashboard/widgets/employer/bonuses";

export const queryDashboardControllerGetEmployerWidgetBalanceDueData = (options) =>
  queryFn(options)("/dashboard/widgets/employer/balance-due");
export const useQueryDashboardControllerGetEmployerWidgetBalanceDueData = (config, options) =>
  useQuery(["/dashboard/widgets/employer/balance-due"], queryFn(options), config);
useQueryDashboardControllerGetEmployerWidgetBalanceDueData.queryKey =
  "/dashboard/widgets/employer/balance-due";

export const queryDashboardControllerGetEmployerWidgetEmployeesData = (options) =>
  queryFn(options)("/dashboard/widgets/employer/employees");
export const useQueryDashboardControllerGetEmployerWidgetEmployeesData = (config, options) =>
  useQuery(["/dashboard/widgets/employer/employees"], queryFn(options), config);
useQueryDashboardControllerGetEmployerWidgetEmployeesData.queryKey =
  "/dashboard/widgets/employer/employees";

export const mutationPerformanceRewardControllerUpsertPerformanceReward = (pathParams, options) =>
  mutationFn("put", "/rewards/{bonusId}", pathParams, {}, options);
export const useMutationPerformanceRewardControllerUpsertPerformanceReward = (
  pathParams,
  config,
  options,
) => useMutation(mutationFn("put", "/rewards/{bonusId}", pathParams, {}, options), config);

export const mutationPerformanceRewardControllerDeletePerformanceReward = (pathParams, options) =>
  mutationFn("delete", "/rewards/{rewardId}", pathParams, {}, options);
export const useMutationPerformanceRewardControllerDeletePerformanceReward = (
  pathParams,
  config,
  options,
) => useMutation(mutationFn("delete", "/rewards/{rewardId}", pathParams, {}, options), config);

export const mutationPerformanceRewardControllerUpdateRewardStatus = (pathParams, options) =>
  mutationFn("patch", "/rewards/{rewardId}/status", pathParams, {}, options);
export const useMutationPerformanceRewardControllerUpdateRewardStatus = (
  pathParams,
  config,
  options,
) =>
  useMutation(mutationFn("patch", "/rewards/{rewardId}/status", pathParams, {}, options), config);

export const queryPerformanceRewardControllerGetNotViewedRewards = (options) =>
  queryFn(options)("/rewards/not-viewed");
export const useQueryPerformanceRewardControllerGetNotViewedRewards = (config, options) =>
  useQuery(["/rewards/not-viewed"], queryFn(options), config);
useQueryPerformanceRewardControllerGetNotViewedRewards.queryKey = "/rewards/not-viewed";

export const mutationPerformanceRewardControllerSetRewardsViewed = (options) =>
  mutationFn("post", "/rewards/set-viewed", {}, {}, options);
export const useMutationPerformanceRewardControllerSetRewardsViewed = (config, options) =>
  useMutation(mutationFn("post", "/rewards/set-viewed", {}, {}, options), config);

export const mutationAdminsControllerCreateAdminProfile = (options) =>
  mutationFn("post", "/admins", {}, {}, options);
export const useMutationAdminsControllerCreateAdminProfile = (config, options) =>
  useMutation(mutationFn("post", "/admins", {}, {}, options), config);

export const mutationAdminsControllerInviteTeamMember = (options) =>
  mutationFn("post", "/admins/invite", {}, {}, options);
export const useMutationAdminsControllerInviteTeamMember = (config, options) =>
  useMutation(mutationFn("post", "/admins/invite", {}, {}, options), config);

export const mutationAdminsControllerResendInvitation = (options) =>
  mutationFn("post", "/admins/resend-invitation", {}, {}, options);
export const useMutationAdminsControllerResendInvitation = (config, options) =>
  useMutation(mutationFn("post", "/admins/resend-invitation", {}, {}, options), config);

export const queryAdminsControllerGetAdminsList = (queryParams, options) =>
  queryFn(options)("/admins/list", queryParams);
export const useQueryAdminsControllerGetAdminsList = (queryParams, config, options) =>
  useQuery(["/admins/list", queryParams], queryFn(options), config);
useQueryAdminsControllerGetAdminsList.queryKey = "/admins/list";

export const queryAdminsControllerGetRecentlyAddedAdmins = (options) =>
  queryFn(options)("/admins/recently-added");
export const useQueryAdminsControllerGetRecentlyAddedAdmins = (config, options) =>
  useQuery(["/admins/recently-added"], queryFn(options), config);
useQueryAdminsControllerGetRecentlyAddedAdmins.queryKey = "/admins/recently-added";

export const queryAdminsControllerGetAdminProfile = (pathParams, options) =>
  queryFn(options)("/admins/{adminId}", pathParams);
export const useQueryAdminsControllerGetAdminProfile = (pathParams, config, options) =>
  useQuery(
    pathParams && pathParams.adminId && ["/admins/{adminId}", pathParams],
    queryFn(options),
    config,
  );
useQueryAdminsControllerGetAdminProfile.queryKey = "/admins/{adminId}";

export const mutationAdminsControllerUpdateAdminProfile = (pathParams, options) =>
  mutationFn("patch", "/admins/{adminId}", pathParams, {}, options);
export const useMutationAdminsControllerUpdateAdminProfile = (pathParams, config, options) =>
  useMutation(mutationFn("patch", "/admins/{adminId}", pathParams, {}, options), config);

export const queryAdminsControllerAvatarUploadUrl = (pathParams, queryParams, options) =>
  queryFn(options)("/admins/{adminId}/avatar-upload-url", pathParams, queryParams);
export const useQueryAdminsControllerAvatarUploadUrl = (pathParams, queryParams, config, options) =>
  useQuery(
    pathParams &&
      pathParams.adminId && ["/admins/{adminId}/avatar-upload-url", pathParams, queryParams],
    queryFn(options),
    config,
  );
useQueryAdminsControllerAvatarUploadUrl.queryKey = "/admins/{adminId}/avatar-upload-url";

export const mutationAdminsControllerUpdateAdminPermission = (pathParams, options) =>
  mutationFn("patch", "/admins/{adminId}/permission", pathParams, {}, options);
export const useMutationAdminsControllerUpdateAdminPermission = (pathParams, config, options) =>
  useMutation(mutationFn("patch", "/admins/{adminId}/permission", pathParams, {}, options), config);

export const mutationAdminsControllerDisableAccount = (pathParams, options) =>
  mutationFn("delete", "/admins/{adminId}/disable", pathParams, {}, options);
export const useMutationAdminsControllerDisableAccount = (pathParams, config, options) =>
  useMutation(mutationFn("delete", "/admins/{adminId}/disable", pathParams, {}, options), config);

export const queryEmployeesControllerListDepartments = (options) =>
  queryFn(options)("/employees/departments");
export const useQueryEmployeesControllerListDepartments = (config, options) =>
  useQuery(["/employees/departments"], queryFn(options), config);
useQueryEmployeesControllerListDepartments.queryKey = "/employees/departments";

export const mutationEmployeesControllerListEmployeesByFilter = (queryParams, options) =>
  mutationFn("post", "/employees/search", {}, queryParams, options);
export const useMutationEmployeesControllerListEmployeesByFilter = (queryParams, config, options) =>
  useMutation(mutationFn("post", "/employees/search", {}, queryParams, options), config);

export const mutationEmployeesControllerListEmployeesByFilterLite = (queryParams, options) =>
  mutationFn("post", "/employees/lite-search", {}, queryParams, options);
export const useMutationEmployeesControllerListEmployeesByFilterLite = (
  queryParams,
  config,
  options,
) => useMutation(mutationFn("post", "/employees/lite-search", {}, queryParams, options), config);

export const queryEmployeesControllerListSyncteraPersons = (queryParams, options) =>
  queryFn(options)("/employees/persons", queryParams);
export const useQueryEmployeesControllerListSyncteraPersons = (queryParams, config, options) =>
  useQuery(["/employees/persons", queryParams], queryFn(options), config);
useQueryEmployeesControllerListSyncteraPersons.queryKey = "/employees/persons";

export const mutationEmployeesControllerInviteEmployees = (options) =>
  mutationFn("post", "/employees/invite", {}, {}, options);
export const useMutationEmployeesControllerInviteEmployees = (config, options) =>
  useMutation(mutationFn("post", "/employees/invite", {}, {}, options), config);

export const queryEmployeesControllerListEmployees = (queryParams, options) =>
  queryFn(options)("/employees", queryParams);
export const useQueryEmployeesControllerListEmployees = (queryParams, config, options) =>
  useQuery(["/employees", queryParams], queryFn(options), config);
useQueryEmployeesControllerListEmployees.queryKey = "/employees";

export const mutationEmployeesControllerCreateEmployee = (options) =>
  mutationFn("put", "/employees", {}, {}, options);
export const useMutationEmployeesControllerCreateEmployee = (config, options) =>
  useMutation(mutationFn("put", "/employees", {}, {}, options), config);

export const queryEmployeesControllerGetEmployee = (pathParams, options) =>
  queryFn(options)("/employees/{employeeId}", pathParams);
export const useQueryEmployeesControllerGetEmployee = (pathParams, config, options) =>
  useQuery(
    pathParams && pathParams.employeeId && ["/employees/{employeeId}", pathParams],
    queryFn(options),
    config,
  );
useQueryEmployeesControllerGetEmployee.queryKey = "/employees/{employeeId}";

export const mutationEmployeesControllerUpdateEmployee = (pathParams, options) =>
  mutationFn("patch", "/employees/{employeeId}", pathParams, {}, options);
export const useMutationEmployeesControllerUpdateEmployee = (pathParams, config, options) =>
  useMutation(mutationFn("patch", "/employees/{employeeId}", pathParams, {}, options), config);

export const mutationEmployeesControllerDeleteEmployee = (pathParams, options) =>
  mutationFn("delete", "/employees/{employeeId}", pathParams, {}, options);
export const useMutationEmployeesControllerDeleteEmployee = (pathParams, config, options) =>
  useMutation(mutationFn("delete", "/employees/{employeeId}", pathParams, {}, options), config);

export const queryEmployeesControllerAvatarUploadUrl = (pathParams, queryParams, options) =>
  queryFn(options)("/employees/{employeeId}/avatar-upload-url", pathParams, queryParams);
export const useQueryEmployeesControllerAvatarUploadUrl = (
  pathParams,
  queryParams,
  config,
  options,
) =>
  useQuery(
    pathParams &&
      pathParams.employeeId && [
        "/employees/{employeeId}/avatar-upload-url",
        pathParams,
        queryParams,
      ],
    queryFn(options),
    config,
  );
useQueryEmployeesControllerAvatarUploadUrl.queryKey = "/employees/{employeeId}/avatar-upload-url";

export const mutationEmployeesControllerAddPersonalEmail = (pathParams, options) =>
  mutationFn("put", "/employees/{employeeId}/personal-email", pathParams, {}, options);
export const useMutationEmployeesControllerAddPersonalEmail = (pathParams, config, options) =>
  useMutation(
    mutationFn("put", "/employees/{employeeId}/personal-email", pathParams, {}, options),
    config,
  );

export const queryBonusesControllerGetBonusTaxCalculations = (queryParams, options) =>
  queryFn(options)("/tax-calculations", queryParams);
export const useQueryBonusesControllerGetBonusTaxCalculations = (queryParams, config, options) =>
  useQuery(["/tax-calculations", queryParams], queryFn(options), config);
useQueryBonusesControllerGetBonusTaxCalculations.queryKey = "/tax-calculations";

export const mutationBonusesControllerListBonusesByFilter = (queryParams, options) =>
  mutationFn("post", "/bonuses/search", {}, queryParams, options);
export const useMutationBonusesControllerListBonusesByFilter = (queryParams, config, options) =>
  useMutation(mutationFn("post", "/bonuses/search", {}, queryParams, options), config);

export const queryBonusesControllerListEmployeeBonuses = (pathParams, options) =>
  queryFn(options)("/employees/{employeeId}/bonuses", pathParams);
export const useQueryBonusesControllerListEmployeeBonuses = (pathParams, config, options) =>
  useQuery(
    pathParams && pathParams.employeeId && ["/employees/{employeeId}/bonuses", pathParams],
    queryFn(options),
    config,
  );
useQueryBonusesControllerListEmployeeBonuses.queryKey = "/employees/{employeeId}/bonuses";

export const queryBonusesControllerGetBonus = (pathParams, options) =>
  queryFn(options)("/employees/{employeeId}/bonuses/{bonusId}", pathParams);
export const useQueryBonusesControllerGetBonus = (pathParams, config, options) =>
  useQuery(
    pathParams &&
      pathParams.employeeId &&
      pathParams.bonusId && ["/employees/{employeeId}/bonuses/{bonusId}", pathParams],
    queryFn(options),
    config,
  );
useQueryBonusesControllerGetBonus.queryKey = "/employees/{employeeId}/bonuses/{bonusId}";

export const mutationBonusesControllerDeleteBonusAssignment = (pathParams, options) =>
  mutationFn("delete", "/employees/{employeeId}/bonuses/{bonusId}", pathParams, {}, options);
export const useMutationBonusesControllerDeleteBonusAssignment = (pathParams, config, options) =>
  useMutation(
    mutationFn("delete", "/employees/{employeeId}/bonuses/{bonusId}", pathParams, {}, options),
    config,
  );

export const mutationBonusesControllerResendBonusInvitation = (pathParams, options) =>
  mutationFn("post", "/employees/{employeeId}/bonuses/{bonusId}/send", pathParams, {}, options);
export const useMutationBonusesControllerResendBonusInvitation = (pathParams, config, options) =>
  useMutation(
    mutationFn("post", "/employees/{employeeId}/bonuses/{bonusId}/send", pathParams, {}, options),
    config,
  );

export const mutationBonusesControllerAcceptBonusAcknowledgements = (pathParams, options) =>
  mutationFn(
    "post",
    "/employees/{employeeId}/bonuses/{bonusId}/accept-acknowledgements",
    pathParams,
    {},
    options,
  );
export const useMutationBonusesControllerAcceptBonusAcknowledgements = (
  pathParams,
  config,
  options,
) =>
  useMutation(
    mutationFn(
      "post",
      "/employees/{employeeId}/bonuses/{bonusId}/accept-acknowledgements",
      pathParams,
      {},
      options,
    ),
    config,
  );

export const mutationBonusesControllerAcceptBonusInvitation = (pathParams, options) =>
  mutationFn("post", "/employees/{employeeId}/bonuses/{bonusId}/accept", pathParams, {}, options);
export const useMutationBonusesControllerAcceptBonusInvitation = (pathParams, config, options) =>
  useMutation(
    mutationFn("post", "/employees/{employeeId}/bonuses/{bonusId}/accept", pathParams, {}, options),
    config,
  );

export const mutationBonusesControllerDeclineBonusInvitation = (pathParams, options) =>
  mutationFn("post", "/employees/{employeeId}/bonuses/{bonusId}/decline", pathParams, {}, options);
export const useMutationBonusesControllerDeclineBonusInvitation = (pathParams, config, options) =>
  useMutation(
    mutationFn(
      "post",
      "/employees/{employeeId}/bonuses/{bonusId}/decline",
      pathParams,
      {},
      options,
    ),
    config,
  );

export const mutationBonusesControllerVoidBonusInvitation = (pathParams, options) =>
  mutationFn("post", "/employees/{employeeId}/bonuses/{bonusId}/void", pathParams, {}, options);
export const useMutationBonusesControllerVoidBonusInvitation = (pathParams, config, options) =>
  useMutation(
    mutationFn("post", "/employees/{employeeId}/bonuses/{bonusId}/void", pathParams, {}, options),
    config,
  );

export const mutationBonusesControllerUpdateBonusUnitGoals = (pathParams, options) =>
  mutationFn(
    "put",
    "/employees/{employeeId}/bonuses/{bonusId}/unit-goals",
    pathParams,
    {},
    options,
  );
export const useMutationBonusesControllerUpdateBonusUnitGoals = (pathParams, config, options) =>
  useMutation(
    mutationFn(
      "put",
      "/employees/{employeeId}/bonuses/{bonusId}/unit-goals",
      pathParams,
      {},
      options,
    ),
    config,
  );

export const mutationBonusesControllerCompleteVestingUnitBonus = (pathParams, options) =>
  mutationFn(
    "post",
    "/employees/{employeeId}/bonuses/{bonusId}/complete-vesting",
    pathParams,
    {},
    options,
  );
export const useMutationBonusesControllerCompleteVestingUnitBonus = (pathParams, config, options) =>
  useMutation(
    mutationFn(
      "post",
      "/employees/{employeeId}/bonuses/{bonusId}/complete-vesting",
      pathParams,
      {},
      options,
    ),
    config,
  );

export const mutationBonusesControllerRequestBalanceDue = (pathParams, options) =>
  mutationFn(
    "post",
    "/employees/{employeeId}/bonuses/{bonusId}/request-balance-due",
    pathParams,
    {},
    options,
  );
export const useMutationBonusesControllerRequestBalanceDue = (pathParams, config, options) =>
  useMutation(
    mutationFn(
      "post",
      "/employees/{employeeId}/bonuses/{bonusId}/request-balance-due",
      pathParams,
      {},
      options,
    ),
    config,
  );

export const mutationBonusesControllerUserKYCValidate = (pathParams, options) =>
  mutationFn(
    "post",
    "/employees/{employeeId}/bonuses/{bonusId}/kyc-validate",
    pathParams,
    {},
    options,
  );
export const useMutationBonusesControllerUserKYCValidate = (pathParams, config, options) =>
  useMutation(
    mutationFn(
      "post",
      "/employees/{employeeId}/bonuses/{bonusId}/kyc-validate",
      pathParams,
      {},
      options,
    ),
    config,
  );

export const mutationBonusesControllerEndIndividualBonusChallenge = (pathParams, options) =>
  mutationFn(
    "post",
    "/employees/{employeeId}/bonuses/{bonusId}/end-challenge",
    pathParams,
    {},
    options,
  );
export const useMutationBonusesControllerEndIndividualBonusChallenge = (
  pathParams,
  config,
  options,
) =>
  useMutation(
    mutationFn(
      "post",
      "/employees/{employeeId}/bonuses/{bonusId}/end-challenge",
      pathParams,
      {},
      options,
    ),
    config,
  );

export const mutationBonusesControllerUpdateShowDashboardWidget = (pathParams, options) =>
  mutationFn(
    "put",
    "/employees/{employeeId}/bonuses/{bonusId}/show-dashboard-widget",
    pathParams,
    {},
    options,
  );
export const useMutationBonusesControllerUpdateShowDashboardWidget = (
  pathParams,
  config,
  options,
) =>
  useMutation(
    mutationFn(
      "put",
      "/employees/{employeeId}/bonuses/{bonusId}/show-dashboard-widget",
      pathParams,
      {},
      options,
    ),
    config,
  );

export const queryWithdrawalControllerListUserTransactions = (queryParams, options) =>
  queryFn(options)("/withdrawal/transactions", queryParams);
export const useQueryWithdrawalControllerListUserTransactions = (queryParams, config, options) =>
  useQuery(["/withdrawal/transactions", queryParams], queryFn(options), config);
useQueryWithdrawalControllerListUserTransactions.queryKey = "/withdrawal/transactions";

export const mutationWithdrawalControllerCreateWithdrawalTransaction = (options) =>
  mutationFn("post", "/withdrawal/transactions", {}, {}, options);
export const useMutationWithdrawalControllerCreateWithdrawalTransaction = (config, options) =>
  useMutation(mutationFn("post", "/withdrawal/transactions", {}, {}, options), config);

export const queryBankAccountsControllerListBankAccounts = (options) =>
  queryFn(options)("/bank-accounts");
export const useQueryBankAccountsControllerListBankAccounts = (config, options) =>
  useQuery(["/bank-accounts"], queryFn(options), config);
useQueryBankAccountsControllerListBankAccounts.queryKey = "/bank-accounts";

export const mutationBankAccountsControllerCreateBankAccount = (options) =>
  mutationFn("post", "/bank-accounts", {}, {}, options);
export const useMutationBankAccountsControllerCreateBankAccount = (config, options) =>
  useMutation(mutationFn("post", "/bank-accounts", {}, {}, options), config);

export const queryBankAccountsControllerGetBankAccount = (pathParams, options) =>
  queryFn(options)("/bank-accounts/{accountId}", pathParams);
export const useQueryBankAccountsControllerGetBankAccount = (pathParams, config, options) =>
  useQuery(
    pathParams && pathParams.accountId && ["/bank-accounts/{accountId}", pathParams],
    queryFn(options),
    config,
  );
useQueryBankAccountsControllerGetBankAccount.queryKey = "/bank-accounts/{accountId}";

export const mutationBankAccountsControllerRemoveBankAccount = (pathParams, options) =>
  mutationFn("delete", "/bank-accounts/{accountId}", pathParams, {}, options);
export const useMutationBankAccountsControllerRemoveBankAccount = (pathParams, config, options) =>
  useMutation(mutationFn("delete", "/bank-accounts/{accountId}", pathParams, {}, options), config);

export const mutationBankAccountsControllerUpdateBankAccount = (pathParams, options) =>
  mutationFn("patch", "/bank-accounts/{accountId}", pathParams, {}, options);
export const useMutationBankAccountsControllerUpdateBankAccount = (pathParams, config, options) =>
  useMutation(mutationFn("patch", "/bank-accounts/{accountId}", pathParams, {}, options), config);

export const queryBankAccountsControllerGetBankAccountBalance = (pathParams, options) =>
  queryFn(options)("/bank-accounts/{accountId}/balance", pathParams);
export const useQueryBankAccountsControllerGetBankAccountBalance = (pathParams, config, options) =>
  useQuery(
    pathParams && pathParams.accountId && ["/bank-accounts/{accountId}/balance", pathParams],
    queryFn(options),
    config,
  );
useQueryBankAccountsControllerGetBankAccountBalance.queryKey = "/bank-accounts/{accountId}/balance";

export const mutationBankAccountsControllerGetLinkToken = (options) =>
  mutationFn("put", "/bank-accounts/link-token", {}, {}, options);
export const useMutationBankAccountsControllerGetLinkToken = (config, options) =>
  useMutation(mutationFn("put", "/bank-accounts/link-token", {}, {}, options), config);

export const mutationBankAccountsControllerExchangePublicToken = (options) =>
  mutationFn("put", "/bank-accounts/access-token", {}, {}, options);
export const useMutationBankAccountsControllerExchangePublicToken = (config, options) =>
  useMutation(mutationFn("put", "/bank-accounts/access-token", {}, {}, options), config);

export const mutationBankAccountsControllerLinkPlaidBankAccount = (options) =>
  mutationFn("post", "/bank-accounts/link", {}, {}, options);
export const useMutationBankAccountsControllerLinkPlaidBankAccount = (config, options) =>
  useMutation(mutationFn("post", "/bank-accounts/link", {}, {}, options), config);

export const mutationBonusTemplatesControllerSearchAssignment = (queryParams, options) =>
  mutationFn("post", "/bonus-templates/search-assignment", {}, queryParams, options);
export const useMutationBonusTemplatesControllerSearchAssignment = (queryParams, config, options) =>
  useMutation(
    mutationFn("post", "/bonus-templates/search-assignment", {}, queryParams, options),
    config,
  );

export const mutationBonusTemplatesControllerSearchAssignmentBySelections = (
  queryParams,
  options,
) =>
  mutationFn("post", "/bonus-templates/search-assignment-by-selections", {}, queryParams, options);
export const useMutationBonusTemplatesControllerSearchAssignmentBySelections = (
  queryParams,
  config,
  options,
) =>
  useMutation(
    mutationFn(
      "post",
      "/bonus-templates/search-assignment-by-selections",
      {},
      queryParams,
      options,
    ),
    config,
  );

export const mutationBonusTemplatesControllerGetNamedAssignmentSelections = (options) =>
  mutationFn("post", "/bonus-templates/get-named-assignment-selections", {}, {}, options);
export const useMutationBonusTemplatesControllerGetNamedAssignmentSelections = (config, options) =>
  useMutation(
    mutationFn("post", "/bonus-templates/get-named-assignment-selections", {}, {}, options),
    config,
  );

export const queryBonusTemplatesControllerListBonusTemplates = (queryParams, options) =>
  queryFn(options)("/bonus-templates", queryParams);
export const useQueryBonusTemplatesControllerListBonusTemplates = (queryParams, config, options) =>
  useQuery(["/bonus-templates", queryParams], queryFn(options), config);
useQueryBonusTemplatesControllerListBonusTemplates.queryKey = "/bonus-templates";

export const mutationBonusTemplatesControllerCreateBonusTemplate = (options) =>
  mutationFn("post", "/bonus-templates", {}, {}, options);
export const useMutationBonusTemplatesControllerCreateBonusTemplate = (config, options) =>
  useMutation(mutationFn("post", "/bonus-templates", {}, {}, options), config);

export const queryBonusTemplatesControllerGetBonusTemplate = (pathParams, options) =>
  queryFn(options)("/bonus-templates/{bonusTemplateId}", pathParams);
export const useQueryBonusTemplatesControllerGetBonusTemplate = (pathParams, config, options) =>
  useQuery(
    pathParams && pathParams.bonusTemplateId && ["/bonus-templates/{bonusTemplateId}", pathParams],
    queryFn(options),
    config,
  );
useQueryBonusTemplatesControllerGetBonusTemplate.queryKey = "/bonus-templates/{bonusTemplateId}";

export const mutationBonusTemplatesControllerUpdateBonusTemplate = (pathParams, options) =>
  mutationFn("patch", "/bonus-templates/{bonusTemplateId}", pathParams, {}, options);
export const useMutationBonusTemplatesControllerUpdateBonusTemplate = (
  pathParams,
  config,
  options,
) =>
  useMutation(
    mutationFn("patch", "/bonus-templates/{bonusTemplateId}", pathParams, {}, options),
    config,
  );

export const mutationBonusTemplatesControllerDeleteBonusTemplate = (pathParams, options) =>
  mutationFn("delete", "/bonus-templates/{bonusTemplateId}", pathParams, {}, options);
export const useMutationBonusTemplatesControllerDeleteBonusTemplate = (
  pathParams,
  config,
  options,
) =>
  useMutation(
    mutationFn("delete", "/bonus-templates/{bonusTemplateId}", pathParams, {}, options),
    config,
  );

export const mutationBonusTemplatesControllerUpdateBonusTemplateAssignments = (
  pathParams,
  options,
) => mutationFn("patch", "/bonus-templates/{bonusTemplateId}/assignments", pathParams, {}, options);
export const useMutationBonusTemplatesControllerUpdateBonusTemplateAssignments = (
  pathParams,
  config,
  options,
) =>
  useMutation(
    mutationFn("patch", "/bonus-templates/{bonusTemplateId}/assignments", pathParams, {}, options),
    config,
  );

export const queryBonusTemplatesControllerListAssignedUsers = (pathParams, queryParams, options) =>
  queryFn(options)("/bonus-templates/{bonusTemplateId}/assignments/users", pathParams, queryParams);
export const useQueryBonusTemplatesControllerListAssignedUsers = (
  pathParams,
  queryParams,
  config,
  options,
) =>
  useQuery(
    pathParams &&
      pathParams.bonusTemplateId && [
        "/bonus-templates/{bonusTemplateId}/assignments/users",
        pathParams,
        queryParams,
      ],
    queryFn(options),
    config,
  );
useQueryBonusTemplatesControllerListAssignedUsers.queryKey =
  "/bonus-templates/{bonusTemplateId}/assignments/users";

export const mutationBonusTemplatesControllerUpdateBonusChallengeProgress = (pathParams, options) =>
  mutationFn(
    "patch",
    "/bonus-templates/{bonusTemplateId}/challenge-progress",
    pathParams,
    {},
    options,
  );
export const useMutationBonusTemplatesControllerUpdateBonusChallengeProgress = (
  pathParams,
  config,
  options,
) =>
  useMutation(
    mutationFn(
      "patch",
      "/bonus-templates/{bonusTemplateId}/challenge-progress",
      pathParams,
      {},
      options,
    ),
    config,
  );

export const mutationBonusTemplatesControllerEndBonusChallenge = (pathParams, options) =>
  mutationFn("post", "/bonus-templates/{bonusTemplateId}/end-challenge", pathParams, {}, options);
export const useMutationBonusTemplatesControllerEndBonusChallenge = (pathParams, config, options) =>
  useMutation(
    mutationFn("post", "/bonus-templates/{bonusTemplateId}/end-challenge", pathParams, {}, options),
    config,
  );

export const queryTerminationControllerCalculateTerminationAmounts = (
  pathParams,
  queryParams,
  options,
) => queryFn(options)("/employees/{employeeId}/termination/amounts", pathParams, queryParams);
export const useQueryTerminationControllerCalculateTerminationAmounts = (
  pathParams,
  queryParams,
  config,
  options,
) =>
  useQuery(
    pathParams &&
      pathParams.employeeId && [
        "/employees/{employeeId}/termination/amounts",
        pathParams,
        queryParams,
      ],
    queryFn(options),
    config,
  );
useQueryTerminationControllerCalculateTerminationAmounts.queryKey =
  "/employees/{employeeId}/termination/amounts";

export const mutationTerminationControllerCreateOrUpdateTermination = (pathParams, options) =>
  mutationFn("put", "/employees/{employeeId}/termination", pathParams, {}, options);
export const useMutationTerminationControllerCreateOrUpdateTermination = (
  pathParams,
  config,
  options,
) =>
  useMutation(
    mutationFn("put", "/employees/{employeeId}/termination", pathParams, {}, options),
    config,
  );

export const queryTerminationControllerGetTermination = (pathParams, options) =>
  queryFn(options)("/employees/{employeeId}/termination", pathParams);
export const useQueryTerminationControllerGetTermination = (pathParams, config, options) =>
  useQuery(
    pathParams && pathParams.employeeId && ["/employees/{employeeId}/termination", pathParams],
    queryFn(options),
    config,
  );
useQueryTerminationControllerGetTermination.queryKey = "/employees/{employeeId}/termination";

export const mutationTerminationControllerCancelTermination = (pathParams, options) =>
  mutationFn("post", "/employees/{employeeId}/termination/cancel", pathParams, {}, options);
export const useMutationTerminationControllerCancelTermination = (pathParams, config, options) =>
  useMutation(
    mutationFn("post", "/employees/{employeeId}/termination/cancel", pathParams, {}, options),
    config,
  );

export const mutationBankCardsControllerKycVerify = (options) =>
  mutationFn("post", "/bank-cards/kyc-verify", {}, {}, options);
export const useMutationBankCardsControllerKycVerify = (config, options) =>
  useMutation(mutationFn("post", "/bank-cards/kyc-verify", {}, {}, options), config);

export const queryBankCardsControllerGetKycVerifications = (options) =>
  queryFn(options)("/bank-cards/verifications");
export const useQueryBankCardsControllerGetKycVerifications = (config, options) =>
  useQuery(["/bank-cards/verifications"], queryFn(options), config);
useQueryBankCardsControllerGetKycVerifications.queryKey = "/bank-cards/verifications";

export const mutationBankCardsControllerIssueBankCard = (options) =>
  mutationFn("post", "/bank-cards", {}, {}, options);
export const useMutationBankCardsControllerIssueBankCard = (config, options) =>
  useMutation(mutationFn("post", "/bank-cards", {}, {}, options), config);

export const queryBankCardsControllerListBankCards = (queryParams, options) =>
  queryFn(options)("/bank-cards", queryParams);
export const useQueryBankCardsControllerListBankCards = (queryParams, config, options) =>
  useQuery(["/bank-cards", queryParams], queryFn(options), config);
useQueryBankCardsControllerListBankCards.queryKey = "/bank-cards";

export const mutationBankCardsControllerCreateSyncteraAccount = (options) =>
  mutationFn("post", "/bank-cards/account", {}, {}, options);
export const useMutationBankCardsControllerCreateSyncteraAccount = (config, options) =>
  useMutation(mutationFn("post", "/bank-cards/account", {}, {}, options), config);

export const queryBankCardsControllerGetSyncteraAccount = (queryParams, options) =>
  queryFn(options)("/bank-cards/account", queryParams);
export const useQueryBankCardsControllerGetSyncteraAccount = (queryParams, config, options) =>
  useQuery(["/bank-cards/account", queryParams], queryFn(options), config);
useQueryBankCardsControllerGetSyncteraAccount.queryKey = "/bank-cards/account";

export const mutationBankCardsControllerUpdateAccountSpendControls = (queryParams, options) =>
  mutationFn("put", "/bank-cards/account/spend-controls", {}, queryParams, options);
export const useMutationBankCardsControllerUpdateAccountSpendControls = (
  queryParams,
  config,
  options,
) =>
  useMutation(
    mutationFn("put", "/bank-cards/account/spend-controls", {}, queryParams, options),
    config,
  );

export const queryBankCardsControllerListSyncteraTransactions = (queryParams, options) =>
  queryFn(options)("/bank-cards/transactions", queryParams);
export const useQueryBankCardsControllerListSyncteraTransactions = (queryParams, config, options) =>
  useQuery(["/bank-cards/transactions", queryParams], queryFn(options), config);
useQueryBankCardsControllerListSyncteraTransactions.queryKey = "/bank-cards/transactions";

export const queryBankCardsControllerGetBankCard = (pathParams, queryParams, options) =>
  queryFn(options)("/bank-cards/{cardId}", pathParams, queryParams);
export const useQueryBankCardsControllerGetBankCard = (pathParams, queryParams, config, options) =>
  useQuery(
    pathParams && pathParams.cardId && ["/bank-cards/{cardId}", pathParams, queryParams],
    queryFn(options),
    config,
  );
useQueryBankCardsControllerGetBankCard.queryKey = "/bank-cards/{cardId}";

export const mutationBankCardsControllerUpdateCard = (pathParams, queryParams, options) =>
  mutationFn("patch", "/bank-cards/{cardId}", pathParams, queryParams, options);
export const useMutationBankCardsControllerUpdateCard = (
  pathParams,
  queryParams,
  config,
  options,
) =>
  useMutation(
    mutationFn("patch", "/bank-cards/{cardId}", pathParams, queryParams, options),
    config,
  );

export const mutationBankCardsControllerGetCardClientToken = (pathParams, options) =>
  mutationFn("post", "/bank-cards/{cardId}/client-token", pathParams, {}, options);
export const useMutationBankCardsControllerGetCardClientToken = (pathParams, config, options) =>
  useMutation(
    mutationFn("post", "/bank-cards/{cardId}/client-token", pathParams, {}, options),
    config,
  );

export const queryBankCardsControllerGetCardWidgetUrl = (pathParams, queryParams, options) =>
  queryFn(options)("/bank-cards/{cardId}/widget-url", pathParams, queryParams);
export const useQueryBankCardsControllerGetCardWidgetUrl = (
  pathParams,
  queryParams,
  config,
  options,
) =>
  useQuery(
    pathParams && pathParams.cardId && ["/bank-cards/{cardId}/widget-url", pathParams, queryParams],
    queryFn(options),
    config,
  );
useQueryBankCardsControllerGetCardWidgetUrl.queryKey = "/bank-cards/{cardId}/widget-url";

export const queryBankCardsControllerListDigitalWalletTokens = (pathParams, options) =>
  queryFn(options)("/bank-cards/{cardId}/digital-wallet-tokens", pathParams);
export const useQueryBankCardsControllerListDigitalWalletTokens = (pathParams, config, options) =>
  useQuery(
    pathParams && pathParams.cardId && ["/bank-cards/{cardId}/digital-wallet-tokens", pathParams],
    queryFn(options),
    config,
  );
useQueryBankCardsControllerListDigitalWalletTokens.queryKey =
  "/bank-cards/{cardId}/digital-wallet-tokens";

export const mutationBankCardsControllerCreateApplePayDigitalWalletToken = (pathParams, options) =>
  mutationFn(
    "post",
    "/bank-cards/{cardId}/digital-wallet-tokens/applepay",
    pathParams,
    {},
    options,
  );
export const useMutationBankCardsControllerCreateApplePayDigitalWalletToken = (
  pathParams,
  config,
  options,
) =>
  useMutation(
    mutationFn(
      "post",
      "/bank-cards/{cardId}/digital-wallet-tokens/applepay",
      pathParams,
      {},
      options,
    ),
    config,
  );

export const mutationBankCardsControllerCreateGooglePayDigitalWalletToken = (pathParams, options) =>
  mutationFn(
    "post",
    "/bank-cards/{cardId}/digital-wallet-tokens/googlepay",
    pathParams,
    {},
    options,
  );
export const useMutationBankCardsControllerCreateGooglePayDigitalWalletToken = (
  pathParams,
  config,
  options,
) =>
  useMutation(
    mutationFn(
      "post",
      "/bank-cards/{cardId}/digital-wallet-tokens/googlepay",
      pathParams,
      {},
      options,
    ),
    config,
  );

export const mutationBankCardsControllerUpdateDigitalWalletToken = (pathParams, options) =>
  mutationFn(
    "patch",
    "/bank-cards/{cardId}/digital-wallet-tokens/{digitalWalletTokenId}",
    pathParams,
    {},
    options,
  );
export const useMutationBankCardsControllerUpdateDigitalWalletToken = (
  pathParams,
  config,
  options,
) =>
  useMutation(
    mutationFn(
      "patch",
      "/bank-cards/{cardId}/digital-wallet-tokens/{digitalWalletTokenId}",
      pathParams,
      {},
      options,
    ),
    config,
  );

export const queryFundingControllerListFundingTransactions = (queryParams, options) =>
  queryFn(options)("/funding/transactions", queryParams);
export const useQueryFundingControllerListFundingTransactions = (queryParams, config, options) =>
  useQuery(["/funding/transactions", queryParams], queryFn(options), config);
useQueryFundingControllerListFundingTransactions.queryKey = "/funding/transactions";

export const mutationFundingControllerCreateFundingTransaction = (options) =>
  mutationFn("post", "/funding/transactions", {}, {}, options);
export const useMutationFundingControllerCreateFundingTransaction = (config, options) =>
  useMutation(mutationFn("post", "/funding/transactions", {}, {}, options), config);

export const queryFundingControllerGetFundingTransaction = (pathParams, options) =>
  queryFn(options)("/funding/transactions/{transactionId}", pathParams);
export const useQueryFundingControllerGetFundingTransaction = (pathParams, config, options) =>
  useQuery(
    pathParams && pathParams.transactionId && ["/funding/transactions/{transactionId}", pathParams],
    queryFn(options),
    config,
  );
useQueryFundingControllerGetFundingTransaction.queryKey = "/funding/transactions/{transactionId}";

export const queryFundingControllerListWaitingBonuses = (options) =>
  queryFn(options)("/funding/waiting");
export const useQueryFundingControllerListWaitingBonuses = (config, options) =>
  useQuery(["/funding/waiting"], queryFn(options), config);
useQueryFundingControllerListWaitingBonuses.queryKey = "/funding/waiting";

export const queryMonthlyStatementControllerGetAllMonthlyStatements = (queryParams, options) =>
  queryFn(options)("/monthly-statements", queryParams);
export const useQueryMonthlyStatementControllerGetAllMonthlyStatements = (
  queryParams,
  config,
  options,
) => useQuery(["/monthly-statements", queryParams], queryFn(options), config);
useQueryMonthlyStatementControllerGetAllMonthlyStatements.queryKey = "/monthly-statements";

export const queryMonthlyStatementControllerDownloadPdf = (pathParams, options) =>
  queryFn(options)("/monthly-statements/{monthlyStatementId}/download", pathParams);
export const useQueryMonthlyStatementControllerDownloadPdf = (pathParams, config, options) =>
  useQuery(
    pathParams &&
      pathParams.monthlyStatementId && [
        "/monthly-statements/{monthlyStatementId}/download",
        pathParams,
      ],
    queryFn(options),
    config,
  );
useQueryMonthlyStatementControllerDownloadPdf.queryKey =
  "/monthly-statements/{monthlyStatementId}/download";

export const queryBusinessBankAccountsControllerGetBusinessStructure = (options) =>
  queryFn(options)("/business-bank-accounts/structure");
export const useQueryBusinessBankAccountsControllerGetBusinessStructure = (config, options) =>
  useQuery(["/business-bank-accounts/structure"], queryFn(options), config);
useQueryBusinessBankAccountsControllerGetBusinessStructure.queryKey =
  "/business-bank-accounts/structure";

export const mutationBusinessBankAccountsControllerCreateBusinessBankAccount = (options) =>
  mutationFn("post", "/business-bank-accounts/main-business", {}, {}, options);
export const useMutationBusinessBankAccountsControllerCreateBusinessBankAccount = (
  config,
  options,
) =>
  useMutation(mutationFn("post", "/business-bank-accounts/main-business", {}, {}, options), config);

export const mutationBusinessBankAccountsControllerCreateBusinessOwnerBusiness = (options) =>
  mutationFn("post", "/business-bank-accounts/holding-company", {}, {}, options);
export const useMutationBusinessBankAccountsControllerCreateBusinessOwnerBusiness = (
  config,
  options,
) =>
  useMutation(
    mutationFn("post", "/business-bank-accounts/holding-company", {}, {}, options),
    config,
  );

export const mutationBusinessBankAccountsControllerCreateBusinessOwnerPerson = (options) =>
  mutationFn("post", "/business-bank-accounts/owner-person", {}, {}, options);
export const useMutationBusinessBankAccountsControllerCreateBusinessOwnerPerson = (
  config,
  options,
) =>
  useMutation(mutationFn("post", "/business-bank-accounts/owner-person", {}, {}, options), config);

export const mutationBusinessBankAccountsControllerCreateBusinessKeyManager = (options) =>
  mutationFn("post", "/business-bank-accounts/key-manager", {}, {}, options);
export const useMutationBusinessBankAccountsControllerCreateBusinessKeyManager = (
  config,
  options,
) =>
  useMutation(mutationFn("post", "/business-bank-accounts/key-manager", {}, {}, options), config);

export const mutationBusinessBankAccountsControllerRunKyb = (options) =>
  mutationFn("post", "/business-bank-accounts/kyb", {}, {}, options);
export const useMutationBusinessBankAccountsControllerRunKyb = (config, options) =>
  useMutation(mutationFn("post", "/business-bank-accounts/kyb", {}, {}, options), config);

export const mutationBusinessBankAccountsControllerUpdatePerson = (pathParams, options) =>
  mutationFn(
    "patch",
    "/business-bank-accounts/person/{businessStructurePersonId}",
    pathParams,
    {},
    options,
  );
export const useMutationBusinessBankAccountsControllerUpdatePerson = (
  pathParams,
  config,
  options,
) =>
  useMutation(
    mutationFn(
      "patch",
      "/business-bank-accounts/person/{businessStructurePersonId}",
      pathParams,
      {},
      options,
    ),
    config,
  );

export const mutationBusinessBankAccountsControllerUpdateBusiness = (pathParams, options) =>
  mutationFn(
    "patch",
    "/business-bank-accounts/business/{businessStructureBusinessId}",
    pathParams,
    {},
    options,
  );
export const useMutationBusinessBankAccountsControllerUpdateBusiness = (
  pathParams,
  config,
  options,
) =>
  useMutation(
    mutationFn(
      "patch",
      "/business-bank-accounts/business/{businessStructureBusinessId}",
      pathParams,
      {},
      options,
    ),
    config,
  );

export const mutationBusinessBankAccountsControllerDeleteBusiness = (pathParams, options) =>
  mutationFn(
    "delete",
    "/business-bank-accounts/business/{businessStructureBusinessId}",
    pathParams,
    {},
    options,
  );
export const useMutationBusinessBankAccountsControllerDeleteBusiness = (
  pathParams,
  config,
  options,
) =>
  useMutation(
    mutationFn(
      "delete",
      "/business-bank-accounts/business/{businessStructureBusinessId}",
      pathParams,
      {},
      options,
    ),
    config,
  );

export const mutationBusinessBankAccountsControllerDeletePerson = (pathParams, options) =>
  mutationFn(
    "delete",
    "/business-bank-accounts/person/{personType}/{businessStructurePersonId}",
    pathParams,
    {},
    options,
  );
export const useMutationBusinessBankAccountsControllerDeletePerson = (
  pathParams,
  config,
  options,
) =>
  useMutation(
    mutationFn(
      "delete",
      "/business-bank-accounts/person/{personType}/{businessStructurePersonId}",
      pathParams,
      {},
      options,
    ),
    config,
  );

export const queryBusinessBankAccountsControllerGetKybErrors = (options) =>
  queryFn(options)("/business-bank-accounts/kyb-errors");
export const useQueryBusinessBankAccountsControllerGetKybErrors = (config, options) =>
  useQuery(["/business-bank-accounts/kyb-errors"], queryFn(options), config);
useQueryBusinessBankAccountsControllerGetKybErrors.queryKey = "/business-bank-accounts/kyb-errors";

export const queryImportEmployeesControllerCsvUploadUrl = (queryParams, options) =>
  queryFn(options)("/import-empoyees/csv-upload-url", queryParams);
export const useQueryImportEmployeesControllerCsvUploadUrl = (queryParams, config, options) =>
  useQuery(["/import-empoyees/csv-upload-url", queryParams], queryFn(options), config);
useQueryImportEmployeesControllerCsvUploadUrl.queryKey = "/import-empoyees/csv-upload-url";

export const queryImportEmployeesControllerGenerateTemplate = (options) =>
  queryFn(options)("/import-empoyees/generate-template");
export const useQueryImportEmployeesControllerGenerateTemplate = (config, options) =>
  useQuery(["/import-empoyees/generate-template"], queryFn(options), config);
useQueryImportEmployeesControllerGenerateTemplate.queryKey = "/import-empoyees/generate-template";

export const mutationImportEmployeesControllerImportEmployees = (options) =>
  mutationFn("post", "/import-empoyees/import-csv", {}, {}, options);
export const useMutationImportEmployeesControllerImportEmployees = (config, options) =>
  useMutation(mutationFn("post", "/import-empoyees/import-csv", {}, {}, options), config);

export const queryImportEmployeesControllerGetReport = (pathParams, options) =>
  queryFn(options)(
    "/import-empoyees/get-report/private-companies/{companyId}/{fileName}",
    pathParams,
  );
export const useQueryImportEmployeesControllerGetReport = (pathParams, config, options) =>
  useQuery(
    pathParams &&
      pathParams.companyId &&
      pathParams.fileName && [
        "/import-empoyees/get-report/private-companies/{companyId}/{fileName}",
        pathParams,
      ],
    queryFn(options),
    config,
  );
useQueryImportEmployeesControllerGetReport.queryKey =
  "/import-empoyees/get-report/private-companies/{companyId}/{fileName}";

export const queryRepaymentControllerListBalancesDue = (queryParams, options) =>
  queryFn(options)("/repayment/balance-dues", queryParams);
export const useQueryRepaymentControllerListBalancesDue = (queryParams, config, options) =>
  useQuery(["/repayment/balance-dues", queryParams], queryFn(options), config);
useQueryRepaymentControllerListBalancesDue.queryKey = "/repayment/balance-dues";

export const queryRepaymentControllerGetBalanceDue = (pathParams, queryParams, options) =>
  queryFn(options)("/repayment/balance-dues/{balanceDueId}", pathParams, queryParams);
export const useQueryRepaymentControllerGetBalanceDue = (
  pathParams,
  queryParams,
  config,
  options,
) =>
  useQuery(
    pathParams &&
      pathParams.balanceDueId && [
        "/repayment/balance-dues/{balanceDueId}",
        pathParams,
        queryParams,
      ],
    queryFn(options),
    config,
  );
useQueryRepaymentControllerGetBalanceDue.queryKey = "/repayment/balance-dues/{balanceDueId}";

export const queryRepaymentControllerListRepaymentLoans = (queryParams, options) =>
  queryFn(options)("/repayment/loans", queryParams);
export const useQueryRepaymentControllerListRepaymentLoans = (queryParams, config, options) =>
  useQuery(["/repayment/loans", queryParams], queryFn(options), config);
useQueryRepaymentControllerListRepaymentLoans.queryKey = "/repayment/loans";

export const queryRepaymentControllerGetRepaymentLoan = (pathParams, queryParams, options) =>
  queryFn(options)("/repayment/loans/{repaymentLoanId}", pathParams, queryParams);
export const useQueryRepaymentControllerGetRepaymentLoan = (
  pathParams,
  queryParams,
  config,
  options,
) =>
  useQuery(
    pathParams &&
      pathParams.repaymentLoanId && ["/repayment/loans/{repaymentLoanId}", pathParams, queryParams],
    queryFn(options),
    config,
  );
useQueryRepaymentControllerGetRepaymentLoan.queryKey = "/repayment/loans/{repaymentLoanId}";

export const queryRepaymentControllerGetRepaymentLoanPlan = (pathParams, queryParams, options) =>
  queryFn(options)("/repayment/loans/{repaymentLoanId}/plan", pathParams, queryParams);
export const useQueryRepaymentControllerGetRepaymentLoanPlan = (
  pathParams,
  queryParams,
  config,
  options,
) =>
  useQuery(
    pathParams &&
      pathParams.repaymentLoanId && [
        "/repayment/loans/{repaymentLoanId}/plan",
        pathParams,
        queryParams,
      ],
    queryFn(options),
    config,
  );
useQueryRepaymentControllerGetRepaymentLoanPlan.queryKey =
  "/repayment/loans/{repaymentLoanId}/plan";

export const mutationRepaymentControllerPreviewLoan = (options) =>
  mutationFn("post", "/repayment/get-loan-preview", {}, {}, options);
export const useMutationRepaymentControllerPreviewLoan = (config, options) =>
  useMutation(mutationFn("post", "/repayment/get-loan-preview", {}, {}, options), config);

export const mutationRepaymentControllerConfigureRepaymentLoan = (options) =>
  mutationFn("post", "/repayment/configure-loan", {}, {}, options);
export const useMutationRepaymentControllerConfigureRepaymentLoan = (config, options) =>
  useMutation(mutationFn("post", "/repayment/configure-loan", {}, {}, options), config);

export const queryRepaymentControllerListRepaymentTransactions = (queryParams, options) =>
  queryFn(options)("/repayment/transactions", queryParams);
export const useQueryRepaymentControllerListRepaymentTransactions = (
  queryParams,
  config,
  options,
) => useQuery(["/repayment/transactions", queryParams], queryFn(options), config);
useQueryRepaymentControllerListRepaymentTransactions.queryKey = "/repayment/transactions";

export const mutationRepaymentControllerCreateTransaction = (options) =>
  mutationFn("post", "/repayment/transactions", {}, {}, options);
export const useMutationRepaymentControllerCreateTransaction = (config, options) =>
  useMutation(mutationFn("post", "/repayment/transactions", {}, {}, options), config);

export const queryRepaymentControllerListForgoingTransactions = (queryParams, options) =>
  queryFn(options)("/repayment/forgoing-transactions", queryParams);
export const useQueryRepaymentControllerListForgoingTransactions = (queryParams, config, options) =>
  useQuery(["/repayment/forgoing-transactions", queryParams], queryFn(options), config);
useQueryRepaymentControllerListForgoingTransactions.queryKey = "/repayment/forgoing-transactions";

export const mutationExportControllerExportBonuses = (options) =>
  mutationFn("post", "/export/bonuses", {}, {}, options);
export const useMutationExportControllerExportBonuses = (config, options) =>
  useMutation(mutationFn("post", "/export/bonuses", {}, {}, options), config);

export const mutationExportControllerExportChallengePayoutReport = (options) =>
  mutationFn("post", "/export/challenge-payout-report", {}, {}, options);
export const useMutationExportControllerExportChallengePayoutReport = (config, options) =>
  useMutation(mutationFn("post", "/export/challenge-payout-report", {}, {}, options), config);

export const queryHrisIntegrationControllerListHrisIntegrations = (options) =>
  queryFn(options)("/hris-integration");
export const useQueryHrisIntegrationControllerListHrisIntegrations = (config, options) =>
  useQuery(["/hris-integration"], queryFn(options), config);
useQueryHrisIntegrationControllerListHrisIntegrations.queryKey = "/hris-integration";

export const queryHrisIntegrationControllerGetHrisIntegration = (pathParams, options) =>
  queryFn(options)("/hris-integration/{integrationId}", pathParams);
export const useQueryHrisIntegrationControllerGetHrisIntegration = (pathParams, config, options) =>
  useQuery(
    pathParams && pathParams.integrationId && ["/hris-integration/{integrationId}", pathParams],
    queryFn(options),
    config,
  );
useQueryHrisIntegrationControllerGetHrisIntegration.queryKey = "/hris-integration/{integrationId}";

export const mutationHrisIntegrationControllerUpdateHrisIntegration = (pathParams, options) =>
  mutationFn("patch", "/hris-integration/{integrationId}", pathParams, {}, options);
export const useMutationHrisIntegrationControllerUpdateHrisIntegration = (
  pathParams,
  config,
  options,
) =>
  useMutation(
    mutationFn("patch", "/hris-integration/{integrationId}", pathParams, {}, options),
    config,
  );

export const mutationHrisIntegrationControllerDeleteHrisIntegration = (pathParams, options) =>
  mutationFn("delete", "/hris-integration/{integrationId}", pathParams, {}, options);
export const useMutationHrisIntegrationControllerDeleteHrisIntegration = (
  pathParams,
  config,
  options,
) =>
  useMutation(
    mutationFn("delete", "/hris-integration/{integrationId}", pathParams, {}, options),
    config,
  );

export const queryHrisIntegrationControllerGetErrorsReport = (pathParams, options) =>
  queryFn(options)("/hris-integration/{integrationId}/errors-report", pathParams);
export const useQueryHrisIntegrationControllerGetErrorsReport = (pathParams, config, options) =>
  useQuery(
    pathParams &&
      pathParams.integrationId && ["/hris-integration/{integrationId}/errors-report", pathParams],
    queryFn(options),
    config,
  );
useQueryHrisIntegrationControllerGetErrorsReport.queryKey =
  "/hris-integration/{integrationId}/errors-report";

export const mutationHrisIntegrationControllerExchangePublicToken = (pathParams, options) =>
  mutationFn(
    "post",
    "/hris-integration/{integrationId}/exchange-public-token",
    pathParams,
    {},
    options,
  );
export const useMutationHrisIntegrationControllerExchangePublicToken = (
  pathParams,
  config,
  options,
) =>
  useMutation(
    mutationFn(
      "post",
      "/hris-integration/{integrationId}/exchange-public-token",
      pathParams,
      {},
      options,
    ),
    config,
  );

export const mutationHrisIntegrationControllerGenerateLinkToken = (options) =>
  mutationFn("post", "/hris-integration/link-token", {}, {}, options);
export const useMutationHrisIntegrationControllerGenerateLinkToken = (config, options) =>
  useMutation(mutationFn("post", "/hris-integration/link-token", {}, {}, options), config);

export const mutationGroupsControllerCreateGroup = (options) =>
  mutationFn("post", "/groups", {}, {}, options);
export const useMutationGroupsControllerCreateGroup = (config, options) =>
  useMutation(mutationFn("post", "/groups", {}, {}, options), config);

export const queryGroupsControllerListGroups = (queryParams, options) =>
  queryFn(options)("/groups", queryParams);
export const useQueryGroupsControllerListGroups = (queryParams, config, options) =>
  useQuery(["/groups", queryParams], queryFn(options), config);
useQueryGroupsControllerListGroups.queryKey = "/groups";

export const mutationGroupsControllerEditGroupDetails = (pathParams, options) =>
  mutationFn("patch", "/groups/{groupId}", pathParams, {}, options);
export const useMutationGroupsControllerEditGroupDetails = (pathParams, config, options) =>
  useMutation(mutationFn("patch", "/groups/{groupId}", pathParams, {}, options), config);

export const queryGroupsControllerGetGroup = (pathParams, options) =>
  queryFn(options)("/groups/{groupId}", pathParams);
export const useQueryGroupsControllerGetGroup = (pathParams, config, options) =>
  useQuery(
    pathParams && pathParams.groupId && ["/groups/{groupId}", pathParams],
    queryFn(options),
    config,
  );
useQueryGroupsControllerGetGroup.queryKey = "/groups/{groupId}";

export const mutationGroupsControllerDeleteGroup = (pathParams, options) =>
  mutationFn("delete", "/groups/{groupId}", pathParams, {}, options);
export const useMutationGroupsControllerDeleteGroup = (pathParams, config, options) =>
  useMutation(mutationFn("delete", "/groups/{groupId}", pathParams, {}, options), config);

export const mutationGroupsControllerAddGroupMembers = (pathParams, options) =>
  mutationFn("post", "/groups/{groupId}/members", pathParams, {}, options);
export const useMutationGroupsControllerAddGroupMembers = (pathParams, config, options) =>
  useMutation(mutationFn("post", "/groups/{groupId}/members", pathParams, {}, options), config);

export const mutationGroupsControllerRemoveGroupMembers = (pathParams, options) =>
  mutationFn("delete", "/groups/{groupId}/members/{userIds}", pathParams, {}, options);
export const useMutationGroupsControllerRemoveGroupMembers = (pathParams, config, options) =>
  useMutation(
    mutationFn("delete", "/groups/{groupId}/members/{userIds}", pathParams, {}, options),
    config,
  );

export const queryGroupsControllerListGroupMembers = (pathParams, queryParams, options) =>
  queryFn(options)("/groups/{groupId}/group-employees", pathParams, queryParams);
export const useQueryGroupsControllerListGroupMembers = (
  pathParams,
  queryParams,
  config,
  options,
) =>
  useQuery(
    pathParams &&
      pathParams.groupId && ["/groups/{groupId}/group-employees", pathParams, queryParams],
    queryFn(options),
    config,
  );
useQueryGroupsControllerListGroupMembers.queryKey = "/groups/{groupId}/group-employees";

export const queryKeepAdminControllerListCompanies = (options) =>
  queryFn(options)("/keep-admin/companies");
export const useQueryKeepAdminControllerListCompanies = (config, options) =>
  useQuery(["/keep-admin/companies"], queryFn(options), config);
useQueryKeepAdminControllerListCompanies.queryKey = "/keep-admin/companies";

export const queryKeepAdminControllerGetCompanyInfo = (pathParams, options) =>
  queryFn(options)("/keep-admin/companies/{companyId}", pathParams);
export const useQueryKeepAdminControllerGetCompanyInfo = (pathParams, config, options) =>
  useQuery(
    pathParams && pathParams.companyId && ["/keep-admin/companies/{companyId}", pathParams],
    queryFn(options),
    config,
  );
useQueryKeepAdminControllerGetCompanyInfo.queryKey = "/keep-admin/companies/{companyId}";

export const queryKeepAdminControllerListEarlyPayTransactions = (queryParams, options) =>
  queryFn(options)("/keep-admin/early-pay-transactions", queryParams);
export const useQueryKeepAdminControllerListEarlyPayTransactions = (queryParams, config, options) =>
  useQuery(["/keep-admin/early-pay-transactions", queryParams], queryFn(options), config);
useQueryKeepAdminControllerListEarlyPayTransactions.queryKey = "/keep-admin/early-pay-transactions";

export const mutationKeepAdminControllerImpersonate = (options) =>
  mutationFn("post", "/keep-admin/impersonate", {}, {}, options);
export const useMutationKeepAdminControllerImpersonate = (config, options) =>
  useMutation(mutationFn("post", "/keep-admin/impersonate", {}, {}, options), config);
