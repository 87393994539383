import styled from "styled-components";

import { Description, StyledPrimaryBtn } from "pages/Auth/_shared/components/styles";

export const Container = styled.div`
  ${Description} {
    margin-bottom: 24px;
  }
`;

export const FormContainerInner = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${StyledPrimaryBtn} {
    max-width: 100%;
    width: 100%;
  }
`;
