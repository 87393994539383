import { t } from "i18next";

import { BusinessKeyManagerTitle, UserRole } from "types/BETypes";
import { businessKeyManagerRoles } from "constants/shared/businessStructure";

import {
  GetCompanyByIdResponseDto,
  UserProfileResponseDto,
  UserResponseDto,
} from "utils/swagger_react_query";

interface IUser {
  firstName?: string | null;
  lastName?: string | null;
}

export const getName = (user?: IUser | null) => {
  return user ? `${user.firstName} ${user.lastName}` : "";
};

export const getUserRoleName = (role: UserRole | UserResponseDto["lastActiveRole"]) => {
  return t(`common.roles.${role}`, { defaultValue: "-" });
};

export const getHiddenEmail = (email: string) => {
  if (!email) return "";
  const [username, domain] = email.split("@");

  const usernameHidden = username
    .split(".")
    .map((it) => it.charAt(0) + "***")
    .join(".");

  const [subdomain, tld] = domain.split(".");

  const subdomainHidden = subdomain ? subdomain.replace(/-/g, ".").charAt(0) + "***" : "";

  const tldHidden = tld ? tld.charAt(0) + "***" : "";

  return `${usernameHidden}@${subdomainHidden}.${tldHidden}`;
};

export const isWorkerOnboarded = (user?: UserProfileResponseDto | null) => {
  if (!user) return false;

  return !!user?.user?.isOnboardingComplete;
};

export const isAdminOnboarded = (company?: GetCompanyByIdResponseDto | null) => {
  if (!company) return false;

  return !!company.isOnboardingComplete;
};

export const getKeyManagerRole = (role?: BusinessKeyManagerTitle) =>
  businessKeyManagerRoles.find((_role) => _role.value === role) as {
    label: string;
    value: BusinessKeyManagerTitle;
  };
