import { css } from "styled-components";

import { statusBorderRadius, statusHeight, statusPaddingX, statusPaddingY } from "./constants";

export const stringWrapperMixin = css`
  display: inline-block;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const labelMixin = css`
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  white-space: nowrap;
`;

export const labelMixinLarge = css`
  ${labelMixin};
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const withBgMixin = css`
  height: ${statusHeight}px;
  border-radius: ${statusBorderRadius}px;
  padding: ${statusPaddingY}px ${statusPaddingX}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
