export const transactionStatusTranslations = {
  "en-US": {
    //TODO::: Remove later, along with old component
    FUNDING_labels: {
      PENDING: "Pending...",
      CANCELED: "Failed",
      RETURNED: "Returned",
      COMPLETE: "Paid",
    },
    DEPOSIT_labels: {
      PENDING: "Pending...",
      CANCELED: "Failed",
      RETURNED: "Returned",
      COMPLETE: "Deposited",
    },
    PAYMENT_labels: {
      PENDING: "Pending...",
      CANCELED: "Failed",
      RETURNED: "Returned",
      COMPLETE: "Received",
    },
    tooltips: {
      PENDING: "ACH Transfers can take up to 48 hours to clear and post in the account.",
      CANCELED:
        "We are unable to process your payment. Please check your account information and try again.",
      COMPLETE: "Your payment has been processed successfully.",
      RETURNED: "",
    },
  },
};

export default transactionStatusTranslations;
