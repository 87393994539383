import { BonusType, VestingScheduleType } from "types/BETypes";

export const translations = {
  "en-US": {
    default_bonus: {
      title: "Summary",
      bonus_amount_label: "Bonus Amount",
      bonus_amount_taxes_link: "How do taxes work?",
      vesting_schedule_label: "Vesting Schedule",
      vesting_schedule_texts: {
        [VestingScheduleType.EQUALLY]: "Vest Every {{vest_equally_units}}",
        [VestingScheduleType.EQUALLY_AFTER_CLIFF]:
          "Vest {{first_vesting_amount}} after {{first_vesting_value}} {{first_vesting_unit}}. Thereafter vest equally over {{period_value}} {{period_units}}.",
        [VestingScheduleType.VEST_100_PERCENTS_ON_END_DATE]: "Vest 100% at the end",
        [VestingScheduleType.CUSTOM]: "Custom Vesting Schedule",

        [VestingScheduleType.UNIT_EQUALLY]:
          "Vest equally over {{vest_equally_milestones_count}} milestones",
        [VestingScheduleType.UNIT_ON_EACH_UNIT]: "Vest on each unit completed",
        [VestingScheduleType.UNIT_VEST_100_PERCENTS_ON_END_DATE]:
          "Vest 100% upon completion of the goal",
        [VestingScheduleType.UNIT_CUSTOM]: "Custom Vesting",
      },
      start_vesting_date_label: "Start Vesting Date",
      end_vesting_date_label: "End Vesting Date",
      total_vesting_period_label: "Total Vesting Period",
      total_vesting_period_value: "{{count}} {{units}}",
      vesting_term_label: "Vesting Term",
      days_left_label: "Days left:",
      bottom_text: {
        [BonusType.TIME]: "A Time Vesting Bonus is a 0% interest loan.",
        [BonusType.UNIT]: "A Unit Vesting Bonus is a 0% interest loan.",
      },
      range_text: "Range: {{rangeFrom}} to {{rangeTo}}",
      due_date_text: "<1>Due Date:</1> {{dueDate}}",
      goal_label: "Goal",
      goal_value: "Complete {{quantity}} “{{unitName}}” by {{date}}.",
    },
    performance_challenge: {
      title: "Summary",
      range_text: "Range: {{rangeFrom}} to {{rangeTo}}",
      due_date_text: "<1>Due Date:</1> {{dueDate}}",
      max_award_text: "Max reward: <1>{{maxRewardAmount}}</1>",
    },
  },
};

export default translations;
