import { createReducer } from "@reduxjs/toolkit";
import {
  setCompanyData,
  setRouterUserRequestInProgress,
  setSalsa,
  setUserMetaData,
  setUserRewardPoints,
  setWorkerKycOnboardingInProgress,
  updateUserMetaDataLastActiveRole,
} from "store/actions/userMetaData";

import { CompanyResponseDto, UserRewardPointsResDto } from "utils/swagger_react_query";

import { MetaDataState } from "../types";

const initialState: MetaDataState = {
  userData: undefined,
  companyData: null,
  salsaData: {
    salsa: null,
    authToken: null,
  },
  workerKycOnboardingInProgress: false,
  routerUserRequestInProgress: false,
  rewardPointsData: null,
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setUserMetaData, (state, action) => {
      state.userData = action.payload;
    })
    .addCase(updateUserMetaDataLastActiveRole, (state, action) => {
      if (state.userData?.user) state.userData.user.lastActiveRole = action.payload;
    })
    .addCase(setCompanyData, (state, action) => {
      state.companyData = action.payload as CompanyResponseDto;
    })
    .addCase(setSalsa, (state, action) => {
      state.salsaData = action.payload;
    })
    .addCase(setWorkerKycOnboardingInProgress, (state, action) => {
      state.workerKycOnboardingInProgress = action.payload as boolean;
    })
    .addCase(setRouterUserRequestInProgress, (state, action) => {
      state.routerUserRequestInProgress = action.payload as boolean;
    })
    .addCase(setUserRewardPoints, (state, action) => {
      state.rewardPointsData = action.payload as UserRewardPointsResDto;
    });
});

export default reducer;
