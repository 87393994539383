import styled from "styled-components";

import { FormLabelLarge, InputContainer } from "uikit";
import CustomSelect from "uikit/Input/Select";

export const Container = styled.div``;

export const StatementsYearSelectWrapper = styled(InputContainer)`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  ${FormLabelLarge} {
    margin: 0;
  }
`;

export const StatementsYearSelect: typeof CustomSelect = styled(CustomSelect)`
  width: 240px;
  margin-left: 24px;
`;
