import React, { FC } from "react";
import { Trans } from "react-i18next";
import { Formik, FormikProps } from "formik";
import { isEmpty } from "lodash";

import { COMPANY_ESIGN_CONSENT_DISCLOSURE } from "constants/assets";
import { HELP_CENTER, PRIVACY_POLICY, TERMS_AND_CONDITIONS } from "constants/shared";
import {
  PasswordFormSchemasType,
  PasswordFormType,
  passwordFormValidationSchemas,
} from "components/auth";
import PasswordForm from "components/auth/PasswordForm/PasswordForm";

import { FullScreenLoader, StepIndicatorAlt } from "uikit";
import { Step } from "uikit/StepIndicator/types";

import {
  Description,
  ECLabel,
  EmailContainer,
  Footer,
  FooterLinkExternal,
  StyledEmailWithIcon,
  StyledPrimaryBtn,
  TabsContainer,
  TermsAndPrivacyDisclaimer,
  Title,
} from "../_shared/components/styles";
import { employeeSignUpStepsArray } from "./constants";
import { EmployeeSignUpSteps } from "./types";
import { useEmployeeSignUp } from "./useEmployeeSignUp";
import { Container, Section, StyledAuthLayout, TermsAndPrivacyDisclaimerSection } from "./styles";

const EmployeeSignUp: FC = () => {
  const { metadata, actions } = useEmployeeSignUp();
  const translationPrefixCommon = `auth_pages`;
  const translationPrefix = `${translationPrefixCommon}.employee.sign_up_page`;
  const isSignUpCompleted = metadata.getIsSignUpCompleted();
  const shouldShowTabs = !isSignUpCompleted;

  const steps: Step[] = employeeSignUpStepsArray.map((item, index) => ({
    index: index,
  }));

  const getPrimaryBtnText = () => {
    if (metadata.currentStep === EmployeeSignUpSteps.SET_PASSWORD)
      return `${translationPrefixCommon}.shared.components.password_form.button_labels.set_password`;
    return `${translationPrefix}.accept_invitation_btn`;
  };

  const handleFormSubmit = (
    formikProps?: FormikProps<PasswordFormSchemasType["CREATE_PASSWORD"]>,
  ) => {
    if (metadata.currentStep === EmployeeSignUpSteps.SET_PASSWORD && !isEmpty(formikProps)) {
      actions.handleSetPassword(formikProps);
    }
    if (metadata.currentStep === EmployeeSignUpSteps.ACCEPT_INVITATION) {
      actions.handleAcceptInvitation();
    }
  };

  if (metadata.pageLoader) return <FullScreenLoader />;
  return (
    <StyledAuthLayout
      isLoading={metadata.isLoading}
      mainSectionTopContent={
        shouldShowTabs && (
          <TabsContainer>
            <StepIndicatorAlt
              steps={steps}
              currentStep={employeeSignUpStepsArray.indexOf(metadata.currentStep)}
            />
          </TabsContainer>
        )
      }
    >
      <Formik<PasswordFormSchemasType["CREATE_PASSWORD"]>
        initialValues={{ newPassword: "", confirmPassword: "" }}
        validationSchema={passwordFormValidationSchemas.fields["CREATE_PASSWORD"]}
        validateOnChange={metadata.svoc}
        onSubmit={() => {}}
      >
        {(formikProps) => (
          <Container>
            {metadata.currentStep === EmployeeSignUpSteps.ACCEPT_INVITATION && (
              <>
                <Title>
                  <Trans i18nKey={`${translationPrefix}.title`} />
                </Title>
                <Description>
                  <Trans i18nKey={`${translationPrefix}.description`} />
                </Description>

                <Section>
                  <EmailContainer>
                    <ECLabel>
                      <Trans i18nKey={`${translationPrefix}.email_label`} />
                    </ECLabel>
                    <StyledEmailWithIcon email={metadata?.currentUser?.user?.email || ""} />
                  </EmailContainer>

                  <TermsAndPrivacyDisclaimerSection>
                    <TermsAndPrivacyDisclaimer style={{ margin: 0 }}>
                      <Trans
                        i18nKey={`auth_pages.shared.terms_and_policy_disclaimer`}
                        components={{
                          1: (
                            <FooterLinkExternal
                              href={TERMS_AND_CONDITIONS}
                              target="_blank"
                              rel="noreferrer"
                            />
                          ),
                          2: (
                            <FooterLinkExternal
                              href={PRIVACY_POLICY}
                              target="_blank"
                              rel="noreferrer"
                            />
                          ),
                          3: (
                            <FooterLinkExternal
                              href={`${COMPANY_ESIGN_CONSENT_DISCLOSURE}?t=${Date.now()}`}
                              target="_blank"
                              rel="noreferrer"
                            />
                          ),
                        }}
                      />
                    </TermsAndPrivacyDisclaimer>
                  </TermsAndPrivacyDisclaimerSection>
                </Section>
              </>
            )}
            {metadata.currentStep === EmployeeSignUpSteps.SET_PASSWORD && (
              <PasswordForm type={PasswordFormType.CREATE_PASSWORD} />
            )}

            <StyledPrimaryBtn
              type="button"
              onClick={() => handleFormSubmit(formikProps)}
              data-testid="primary-btn"
            >
              <Trans i18nKey={getPrimaryBtnText()} />
            </StyledPrimaryBtn>

            <Footer>
              <FooterLinkExternal href={HELP_CENTER} target="_blank">
                <Trans i18nKey={`buttons.need_help_btn`} />
              </FooterLinkExternal>
            </Footer>
          </Container>
        )}
      </Formik>
    </StyledAuthLayout>
  );
};

export default EmployeeSignUp;
