import { FC, useEffect } from "react";
import { useAppSelector } from "store/hooks";
import { salsaSelector } from "store/selectors";

import { FrameContainer } from "uikit";

import { UserProfileResponseDto } from "utils/swagger_react_query";

import { Container } from "./styles";

interface Props {
  currentUser: UserProfileResponseDto | null | undefined;
  className?: string;
  onSalsaOnboardingComplete: () => void;
}

const SetupPayrollStep: FC<Props> = ({ className, currentUser, onSalsaOnboardingComplete }) => {
  const salsaData = useAppSelector(salsaSelector);
  const { salsa: salsaInstance, authToken: authToken } = salsaData || {};
  const salsaFrameName = "worker-onboarding";
  const salsaFrameId = "onboard-container";
  const frameSelector = `#${salsaFrameId}`;

  useEffect(() => {
    if (currentUser?.user?.userId && salsaData?.salsa) {
      const salsaElement = salsaInstance.elements.create(salsaFrameName, {
        userToken: authToken,
        workerId: currentUser?.user?.salsaWorkerId,
      });

      salsaInstance.elements.mount(salsaElement, frameSelector);
      salsaElement.on("complete", () => {
        onSalsaOnboardingComplete();
      });

      return () => {
        salsaInstance.elements.destroy(salsaElement);
      };
    }
  }, [salsaData?.salsa, currentUser]);

  return (
    <Container className={className}>
      <FrameContainer id={salsaFrameId} />
    </Container>
  );
};

export default SetupPayrollStep;
