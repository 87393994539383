import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import routes from "routes/routes";
import { useAppSelector } from "store/hooks";
import { companyMetadataSelector, salsaSelector } from "store/selectors";

import { showErrorModal } from "helpers";
import { getDeferredEmployeeData } from "helpers/employee/employeeRequests";

import { UserResponseDto } from "utils/swagger_react_query";

const useAdminEditWorker = () => {
  const navigate = useNavigate();
  const { id: workerId } = useParams();
  const salsaData = useAppSelector(salsaSelector);
  const { salsa, authToken } = salsaData || {};
  const currentCompany = useAppSelector(companyMetadataSelector);
  const frameId = "onboarding-container";
  const frameSelector = `#${frameId}`;

  const [isLoading, setLoading] = useState<boolean>(true);
  const [employeeData, setEmployeeData] = useState<UserResponseDto>();

  const redirectToList = () => {
    navigate(routes.PEOPLE);
  };

  const fetchEmployeeInfo = async () => {
    try {
      setLoading(true);
      const data: UserResponseDto = await getDeferredEmployeeData(workerId || "");
      setEmployeeData(data);
    } catch (error) {
      showErrorModal(error, redirectToList);
    } finally {
      setLoading(false);
    }
  };

  const redirectToPeopleList = () => {
    navigate(routes.PEOPLE);
  };

  const handleBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      redirectToPeopleList();
      return;
    }
  };

  useEffect(() => {
    fetchEmployeeInfo();
  }, []);

  useEffect(() => {
    if (employeeData && salsa) {
      const salsaElement = salsa.elements.create("worker-profile", {
        //NOTE:::const salsaElement = salsa.elements.create("onboard-worker", {
        userToken: authToken,
        employerId: currentCompany?.salsaEmployerId,
        workerId: employeeData.salsaWorkerId,
        includeCards: [
          "benefits",
          "compensations",
          "contracts",
          "personal",
          "taxes-setup",
          "time-off",
          "work-locations",
          "worker-setup-checklist",
        ],
      });

      salsa.elements.mount(salsaElement, frameSelector);
      return () => {
        salsa.elements.destroy(salsaElement);
      };
    }
  }, [salsa, employeeData]);

  return {
    isLoading,
    employeeData,
    frameId,
    handleBack,
  };
};

export default useAdminEditWorker;
