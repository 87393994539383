import styled from "styled-components";

import { CardStatus } from "types/CardShipping";
import { CardAdditionalStatus, EComprehensibleCardStatus } from "helpers/assets/bankCards";

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const StatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 6px 8px;
  border-radius: 16px;
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 1.33;

  &:last-of-type {
    margin-left: 16px;
  }

  &:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 0 8px 0 2px;
    background: ${({ theme }) => theme.colors.danger};
  }

  &.${EComprehensibleCardStatus.INACTIVE},
    &.${EComprehensibleCardStatus.CANCELED},
    &.${EComprehensibleCardStatus.EXPIRED} {
    color: ${({ theme }) => theme.colors.danger};
    background: ${({ theme }) => theme.colors.dangerAlt3};

    &:before {
      background: ${({ theme }) => theme.colors.danger};
    }
  }

  &.${EComprehensibleCardStatus.ACTIVE}, &.${EComprehensibleCardStatus.ACTIVE_WITHOUT_PIN} {
    color: ${({ theme }) => theme.colors.success};
    background: ${({ theme }) => theme.colors.successAlt3};

    &:before {
      background: ${({ theme }) => theme.colors.success};
    }
  }

  //NOTE:::Additional status
  &.additional_status_${CardAdditionalStatus.REISSUED} {
    color: ${({ theme }) => theme.colors.warningAlt};
    background: ${({ theme }) => theme.colors.warningAlt3};

    &:before {
      background: ${({ theme }) => theme.colors.warningAlt};
    }
  }

  // &.${CardStatus.PENDING}, &.${CardStatus.IMAGE_PENDING} { // NOT yet present
  //   color: ${({ theme }) => theme.colors.warningAlt};
  //   background: ${({ theme }) => theme.colors.successAlt3};

  //   &:before {
  //     background: ${({ theme }) => theme.colors.success};
  //   }
  // }
`;
