import styled from "styled-components";

import { SectionAlt } from "uikit";

import { ScrollableContainer } from "../../components/styles";

export const FormContainer = styled.form``;

export const ContainerInner = styled(ScrollableContainer)`
  display: flex;
  gap: 32px;
`;

export const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  flex: 1;
`;

export const StyledSectionAlt = styled(SectionAlt)`
  position: relative;
  width: 100%;
`;

export const RightSection = styled.div``;

export const PromoImage = styled.img`
  width: 440px;
  height: 422px;
`;
