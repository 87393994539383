import actions from "store/actions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { companyMetadataSelector, userMetadataSelector } from "store/selectors";

import {
  getSessionId,
  getUser,
  googleAuth,
  login,
  loginWithPassword,
  logout as logoutUser,
  sendMagicLink,
  setSessionId,
} from "services/auth";

import {
  CompanyResponseDto,
  GetCompanyByIdResponseDto,
  queryCompaniesControllerGetCompany,
  UserProfileResponseDto,
} from "utils/swagger_react_query";

interface IUserAndCompanyResult {
  user?: UserProfileResponseDto | null | undefined;
  company?: CompanyResponseDto | null | undefined;
}

type TGetUserAndCompanyMethod = (
  useCache?: boolean,
  withNavigate?: boolean,
  shouldSaveToStore?: boolean,
) => Promise<IUserAndCompanyResult | undefined | null>;

type TGetUserMethod = (
  useCache?: boolean,
  withNavigate?: boolean,
  shouldSaveToStore?: boolean,
) => Promise<UserProfileResponseDto | undefined | null>;

const useAuth = () => {
  const currentUser = useAppSelector(userMetadataSelector);
  const currentCompany = useAppSelector(companyMetadataSelector);
  const dispatch = useAppDispatch();

  const isAuthenticated = () => !!getSessionId();

  const saveUserToStore = async (user?: UserProfileResponseDto) => {
    dispatch(actions.userMetaData.setUserMetaData(user || null));
  };

  const saveCompanyToStore = async (company?: GetCompanyByIdResponseDto) => {
    dispatch(actions.userMetaData.setCompanyData(company || null));
  };

  const saveUserAndCompanyToStore = async (
    user?: UserProfileResponseDto,
    company?: GetCompanyByIdResponseDto,
  ) => {
    saveUserToStore(user);
    saveCompanyToStore(company);
  };

  const getCompanyInfo = async (companyId: string, shouldSaveToStore: boolean = false) => {
    if (!companyId) return { success: false };
    try {
      const fetchCompanyRes = await queryCompaniesControllerGetCompany({ companyId: companyId });
      if (shouldSaveToStore) {
        dispatch(actions.userMetaData.setCompanyData(fetchCompanyRes));
      }
      return { success: true, companyInfo: fetchCompanyRes };
    } catch (error) {
      console.log(error);
      return { success: false };
    }
  };

  const _getCurrentUser: TGetUserAndCompanyMethod = async (
    useCache: boolean = false,
    withNavigate: boolean = false,
    shouldSaveToStore: boolean = true,
  ) => {
    if (!isAuthenticated()) {
      if (withNavigate) {
        logout(false, withNavigate);
        return;
      }
      return;
    } else {
      if (useCache && currentUser && currentCompany)
        return {
          user: currentUser,
          company: currentCompany,
        };
      const userResult = await getUser();
      const companyResult = await getCompanyInfo(userResult?.user?.user?.companyId || "");
      if (shouldSaveToStore) {
        saveUserAndCompanyToStore(userResult?.user, companyResult?.companyInfo);
      }

      return {
        user: userResult.user,
        company: companyResult.companyInfo,
      };
    }
  };

  const getCurrentUser: TGetUserMethod = async (...args) => {
    const result = await _getCurrentUser(...args);
    return result?.user;
  };

  const getCurrentUserAndCompany: TGetUserAndCompanyMethod = async (...args) => {
    const result = await _getCurrentUser(...args);
    return result;
  };

  const logout = async (
    withRequest: boolean = true,
    withNavigate: boolean = true,
    params?: string,
  ) => {
    await logoutUser(withRequest, withNavigate, params);
    dispatch(actions.userMetaData.setUserMetaData(null));
    dispatch(actions.userMetaData.setCompanyData(null));
  };

  return {
    isAuthenticated,
    getCompanyInfo,
    getCurrentUser,
    getCurrentUserAndCompany,
    googleAuth,
    sendMagicLink,
    login,
    loginWithPassword,
    logout,
    setSessionId,
    saveUserAndCompanyToStore,
    saveUserToStore,
    saveCompanyToStore,
  };
};

export default useAuth;
