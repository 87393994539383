import { FC } from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { userMetadataSelector } from "store/selectors";

import { getCompanyDefaultBankAccountBenefitsList } from "components/PayDistribution/constants";
import { isPayDistributionIncludesPartnerAccount } from "components/PayDistribution/helpers";

import { Switch } from "uikit";

import { EarlyPayFormType } from "./validationSchema";
import {
  Container,
  SBDIHeader,
  SBDIIcon,
  SBDITextWithIcon,
  SwitchBenefitsDescriptionContainer,
  SwitchBenefitsDescriptionInner,
  SwitchContainer,
  SwitchTextContainer,
  SwitchTextDescription,
  SwitchTextTitle,
} from "./styles";

const EarlyPayForm: FC = () => {
  const currentUser = useSelector(userMetadataSelector);
  const originTranslationPrefix = "components.company_bank_account_components";
  const translationPrefix = `${originTranslationPrefix}.early_pay_form`;
  const benefitsListTranslationPrefix = `components.pay_distribution.default_company_bank_account_benefits_banner.benefits`;
  const { values, setFieldValue } = useFormikContext<EarlyPayFormType>();
  const benefitsTitleTranslationKey = `${values.isDefaultCompanyBankAccountEnabled ? "benefits_title" : "benefits_title_alt"}`;
  const shouldDisableBankAccountSwitch = isPayDistributionIncludesPartnerAccount(
    currentUser?.user?.defaultPayDistribution,
  );
  const benefitsList = getCompanyDefaultBankAccountBenefitsList(`${benefitsListTranslationPrefix}`);
  const showEarlyPayBenefit = values.isEarlyPayEnabled;

  const onChange = (field: keyof typeof values) => {
    setFieldValue(field, !values[field]);
  };

  return (
    <Container>
      <SwitchBenefitsDescriptionContainer>
        <SwitchContainer className={"switch-container"}>
          <Switch
            checked={!!values.isDefaultCompanyBankAccountEnabled}
            onChange={() => onChange("isDefaultCompanyBankAccountEnabled")}
            disabled={shouldDisableBankAccountSwitch}
            data-testid={"default-company-bank-account-switch"}
          />
          <SwitchTextContainer>
            <SwitchTextTitle>
              <Trans i18nKey={`${translationPrefix}.switch_default_account.title`} />
            </SwitchTextTitle>

            <SwitchTextDescription>
              <Trans i18nKey={`${translationPrefix}.switch_default_account.description`} />
            </SwitchTextDescription>
          </SwitchTextContainer>
        </SwitchContainer>

        <SwitchBenefitsDescriptionInner>
          <SBDIHeader>
            <Trans
              i18nKey={`${translationPrefix}.switch_default_account.${benefitsTitleTranslationKey}`}
            />
          </SBDIHeader>

          {benefitsList.map((item) => {
            if (item.index === 3 && !showEarlyPayBenefit) return;
            return (
              <SBDITextWithIcon key={item.index.toString()}>
                <SBDIIcon>{item.icon}</SBDIIcon>
                {item.text}
              </SBDITextWithIcon>
            );
          })}
        </SwitchBenefitsDescriptionInner>
      </SwitchBenefitsDescriptionContainer>

      <SwitchContainer>
        <Switch
          checked={!!values.isEarlyPayEnabled}
          onChange={() => onChange("isEarlyPayEnabled")}
          data-testid={"early-pay-switch"}
        />
        <SwitchTextContainer>
          <SwitchTextTitle>
            <Trans i18nKey={`${translationPrefix}.switch_early_pay.title`} />
          </SwitchTextTitle>

          <SwitchTextDescription>
            <Trans i18nKey={`${translationPrefix}.switch_early_pay.description`} />
          </SwitchTextDescription>
        </SwitchTextContainer>
      </SwitchContainer>
    </Container>
  );
};

export default EarlyPayForm;
