import { isEmpty } from "lodash";

import { UserRole } from "types/BETypes";

import { UserProfileResponseDto } from "utils/swagger_react_query";

export const getShouldEmployeeCompleteSignUp = (
  user: Partial<UserProfileResponseDto> | null | undefined,
) => {
  if (user?.user?.lastActiveRole !== UserRole.EMPLOYEE) return false;
  const res = !user?.user?.termsAcceptedAt || !user?.isPasswordSet;

  return res;
};

export const getShouldAdminCompleteSignUp = (
  user: Partial<UserProfileResponseDto> | null | undefined,
) => {
  if (![UserRole.ADMIN, UserRole.SUPERADMIN].includes(user?.user?.lastActiveRole as UserRole))
    return false;
  const res = !user?.user?.termsAcceptedAt || !user?.isPasswordSet;
  return res;
};

export const getShouldOwnerCompleteSignUp = (
  user: Partial<UserProfileResponseDto> | null | undefined,
  isCompanyExist: boolean,
) => {
  if (
    !isEmpty(user) &&
    !!user?.user?.lastActiveRole &&
    user?.user?.lastActiveRole !== UserRole.OWNER
  )
    return false;

  const res = !isCompanyExist || !user?.isPasswordSet;
  return res;
};

export const shouldSetPasswordOnly = (user: Partial<UserProfileResponseDto> | null | undefined) => {
  const isSignUpCompleted =
    user?.user?.lastActiveRole === UserRole.OWNER
      ? user?.user?.companyId
      : user?.user?.termsAcceptedAt;
  const res = isSignUpCompleted && !user?.isPasswordSet;
  return res;
};
