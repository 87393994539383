import { PayDistributionRuleType } from "types/BETypes";
import { APPLICATION_NAME } from "constants/systemConstants";
import { EDistributionOptions, PayDistributionWidgetType } from "components/PayDistribution/types";

export const payDistribution = {
  "en-US": {
    company_account_card: {
      title: "Paid Free Bank Account",
      text: "Earn 2.50% interest, get 25% of Net Pay 2 days early, plus ZERO hidden fees. <1>Learn More</1>",
      create_account_button: "Create Account",
    },
    pay_distribution_options: {
      [EDistributionOptions.BANK_ACCOUNT]: "Bank Account",
      [EDistributionOptions.CHECK]: "Paper Check",
    },
    pay_distribution_option_selected: "Selected Method",
    bank_accounts_form: {
      title: "Selected Bank accounts",
      subtitle: "You can select only verified bank accounts",
      payment_order: ["Pay first amount to", "Pay second amount to"],
      payment_order_full: "Pay full amount to",
      payment_order_remaining: "Pay remaining amount to",
      account_selection_label: "Select Bank Account",
      remaining_label: "Remaining",
      pay_rule_options: {
        [PayDistributionRuleType.FIXED_AMOUNT]: "Fixed Amount",
        [PayDistributionRuleType.PERCENTAGE]: "Percentage",
      },
      bank_details: {
        name: "Name",
        account_number: "Account Number",
        type: "Type",
        status: "Status",
      },
      split_pay_button: "Split Pay",
      submit_buttons: {
        [PayDistributionWidgetType.SETTINGS]: "Confirm Pay Distribution",
        [PayDistributionWidgetType.ONBOARDING]: "Finish Setup",
      },
      early_pay: {
        label: "Enroll in Early Pay",
        account_card_enabled: "Early Pay On",
        account_card_disabled: "Early Pay Off",
        description:
          "Yes, I would like to enroll in Early Pay and get 25% of my Net Pay 2 days before payday at ZERO cost.",
        tooltip: `To enable Early Pay, your ${APPLICATION_NAME} bank account must be set as the first bank account, 
          and payment distribution must be percentage-based`,
        modals: {
          activate_early_pay: {
            title: "Activate Early Pay",
            message: `To enable Early Pay, we need to set your ${APPLICATION_NAME} bank account as the first and adjust your payment distribution to a percentage-based method. 
              This change ensures you'll receive your payment 2 days early. Would you like to proceed?`,
          },
          resolve_company_account_duplicates: {
            title: "Resolve Duplicate Accounts",
            message: `You have the same bank account listed in multiple positions. Please remove the duplicates before enabling Early Pay.`,
          },
          change_bank_account: {
            title: "Change Bank Account",
            message: `Your ${APPLICATION_NAME} bank account is currently with Early Pay enabled. 
              If you move it out of the first position, you'll need to choose a new first bank account, and Early Pay will be disabled.<br /><br />
              Please select new first bank account.
              `,
            select_label: "Select Bank Account",
            select_placeholder: "Select",
          },
        },
      },
      errors: {
        id: {
          is_required: "Bank Account is required",
          bank_uniqueness_required:
            "The same bank account can not be used more than once in a pay distribution",
        },
        amount: {
          amount_is_required: "Amount is required",

          percentage: {
            amount_is_too_low: "Amount cannot be less than {{minPercentageAmount}}%",
            overall_amount_is_too_high:
              "Sum of all percentage values cannot exceed {{maxPercentageAmount}}%",
          },
          monetary: {
            amount_is_too_low: "Amount cannot be less than {{minMonetaryAmount}}",
            amount_is_too_high: "Amount cannot exceed {{maxMonetaryAmount}}",
          },
        },
      },
    },
    paper_check_form: {
      title: "Your compensation will be mailed to you as a paper check.",
      message:
        "Switching from Paper Check to Bank Account can provide quicker and more secure access to your funds.",
      submit_buttons: {
        [PayDistributionWidgetType.SETTINGS]: "Confirm Pay Distribution",
        [PayDistributionWidgetType.ONBOARDING]: "Finish Setup",
      },
    },
    default_company_bank_account_benefits_banner: {
      title: `Make ${APPLICATION_NAME} your <1>default paycheck account</1> to take advantage of:`,
      footer_message: `100% of your paycheck will be distributed on ${APPLICATION_NAME} Bank Account`,
      set_default_button: "Set as Default",
      benefits: [
        "2.50% Annual Percentage Yield",
        "Earn reward points on every dollar deposited",
        `Get ${APPLICATION_NAME} 2 Days Early!`,
      ],
    },
    shared_modals: {
      pay_distribution_save_success_modals: {
        [PayDistributionWidgetType.SETTINGS]: {
          title: "Success!",
          message: "You successfully changed pay distribution.",
        },
        [PayDistributionWidgetType.ONBOARDING]: {
          title: "Setup Completed!",
          message:
            "Congratulations on completing the setup! You're all set to get paid. Let's get started!",
        },
      },
      no_accounts_modal: {
        title: "No Additional Verified Bank Accounts",
        message:
          "You currently do not have any additional verified bank accounts to split your payments. Please, add and verify at least one more bank account.",
        submit_button: "Manage Bank Accounts",
      },
    },
  },
};

export default payDistribution;
