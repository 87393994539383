import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormikProps } from "formik";
import { isEmpty } from "lodash";
import routes from "routes/routes";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { EMagicLinkFlow } from "types/AuthFlowTypes";
import { showErrorModal } from "helpers/index";
import { shouldSetPasswordOnly } from "helpers/shared/guardRedirect";
import useAuth from "hooks/useAuth";
import { PasswordFormSchemasType } from "components/auth";

import {
  mutationUsersControllerAcceptTermsAndConditions,
  mutationUsersControllerCreateOrUpdatePassword,
  UserProfileResponseDto,
} from "utils/swagger_react_query";

import { IAuthInvitationSearchParams, useCompleteSignIn } from "../_shared";
import { EmployeeSignUpSteps } from "./types";

export const useEmployeeSignUp = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentUser = useAppSelector(userMetadataSelector);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [svoc, setSvoc] = useState<boolean>(false);

  const [currentStep, setCurrentStep] = useState<EmployeeSignUpSteps>(
    EmployeeSignUpSteps.ACCEPT_INVITATION,
  );
  const { isAuthenticated, getCurrentUser } = useAuth();
  const { actions, metadata } = useCompleteSignIn({
    mode: EMagicLinkFlow.SIGN_UP,
    autoLogin: false,
  });

  const getIsSignUpCompleted = useMemo(
    () => (user?: UserProfileResponseDto | null | undefined) =>
      shouldSetPasswordOnly(user ?? currentUser),
    [currentUser],
  );

  useEffect(() => {
    autoLogin(EMagicLinkFlow.SIGN_UP, getAuthParams());
  }, []);

  const getAuthParams = (): IAuthInvitationSearchParams => {
    return {
      invitationId: searchParams.get("invitationId") || undefined,
      error: searchParams.get("error") || undefined,
    };
  };

  const determineInitialStep = (user: UserProfileResponseDto | null | undefined) => {
    if (getIsSignUpCompleted(user)) {
      setCurrentStep(EmployeeSignUpSteps.SET_PASSWORD);
    } else {
      setCurrentStep(EmployeeSignUpSteps.ACCEPT_INVITATION);
    }
  };

  const autoLogin = async (mode: EMagicLinkFlow, authParams: IAuthInvitationSearchParams) => {
    if (searchParams.size || (searchParams.size && isAuthenticated())) {
      const userDetailsRes = await actions.completeSignIn(
        mode,
        { error: authParams.error, code: authParams.invitationId },
        authParams.invitationId,
      );
      if (!isEmpty(userDetailsRes)) {
        determineInitialStep(userDetailsRes as UserProfileResponseDto);
      }
    } else if (!isAuthenticated()) {
      navigate(routes.SIGN_IN);
    } else {
      const userDetailsRes = await actions.checkUserData(undefined);
      determineInitialStep(userDetailsRes || null);
    }

    setLoading(false);
    actions.setLoading(false);
  };

  const handleSetPassword = async (
    formikProps: FormikProps<PasswordFormSchemasType["CREATE_PASSWORD"]>,
  ) => {
    setSvoc(true);
    const { validateForm, values } = formikProps;
    const errors = await validateForm(values);
    if (!isEmpty(errors)) return;

    try {
      setLoading(true);
      await mutationUsersControllerCreateOrUpdatePassword()({
        newPassword: values.newPassword,
      });
      const userDetails = await getCurrentUser();
      if (userDetails?.user?.userId) {
        actions.redirectByRoleAndSalsaOnboardingStatus(userDetails);
      }
    } catch (error) {
      showErrorModal(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAcceptInvitation = async () => {
    try {
      setLoading(true);
      await mutationUsersControllerAcceptTermsAndConditions()();
      await getCurrentUser();
      setCurrentStep(EmployeeSignUpSteps.SET_PASSWORD);
    } catch (error) {
      console.log(error);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    metadata: {
      isLoading,
      currentUser,
      pageLoader: metadata.isLoading,
      currentStep,
      getIsSignUpCompleted,
      svoc,
    },
    actions: {
      handleAcceptInvitation,
      handleSetPassword,
    },
  };
};
