import PromoImage from "assets/png/promo_woman_alt_4.png";
import styled from "styled-components";

import { Body3, H3, PrimaryButton } from "uikit";

export const Container = styled.div`
  position: relative;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.sectionContainerWarning};
  border: 1px solid ${({ theme }) => theme.colors.sectionBorderWarning};
  border-radius: 8px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background-image: url(${PromoImage});
  background-size: 130px;
  background-repeat: no-repeat;
  background-position: bottom left;
`;

export const BankAccountTemplateSection = styled.div`
  position: relative;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  text-align: left;

  ${PrimaryButton} {
    width: fit-content;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 0 10px 0;
`;

export const Title = styled(H3)`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: 12px;
`;

export const BenefitsList = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 16px;
  margin-bottom: 12px;
`;

export const BLTextWithIcon = styled(Body3)`
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  background-color: ${({ theme }) => theme.colors.contentBg};
  border-radius: 12px;
  padding: 14px;
  line-height: 16.79px;
`;

export const BLIcon = styled.span`
  font-size: 20px;
  margin-right: 8px;
`;

export const FooterMessage = styled(Body3)`
  font-size: ${({ theme }) => theme.size.small};
  color: ${({ theme }) => theme.colors.textInactiveAlt2};
`;
