import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";

import { SyncteraTransactionStatus } from "types/BETypes";

import { SyncteraTransactionDto } from "utils/swagger_react_query";

import { Container, Status } from "./styles";

interface Props {
  className?: string;
  item: Partial<SyncteraTransactionDto>;
}

const TransactionStatusBanking: FC<Props> = ({ className, item }) => {
  const { t } = useTranslation();
  const { dcSign } = item;
  const prefix = "components.transaction_status_banking";

  const getStatusLabel = (status?: SyncteraTransactionStatus) => {
    return (
      <Status className={`${dcSign} ${status}`}>
        <Trans i18nKey={`${prefix}.${status}`} />
      </Status>
    );
  };

  return (
    <Container className={className}>
      <>{getStatusLabel(item.status as SyncteraTransactionStatus)}</>
    </Container>
  );
};

export default TransactionStatusBanking;
