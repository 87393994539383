import { ReactNode, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { generatePath } from "react-router";
import { ArrowIconSmall, ClockAltIcon, CopyIcon, LockIcon, UploadIconSmall } from "assets/svg";
import NoCreditCard from "assets/svg/NoCreditCard";
import { find } from "lodash";
import routes from "routes/routes";
import { useAppSelector } from "store/hooks";
import {
  companyMetadataSelector,
  rewardPointsSelector,
  userMetadataSelector,
} from "store/selectors";

import { AppEvents } from "services/events";
import {
  BankingType,
  KybStatus,
  KycStatus,
  SyncteraAccountBalanceType,
  UserRole,
} from "types/BETypes";
import { CardForm, CardStatus } from "types/CardShipping";
import { HELP_EMAIL } from "constants/shared";
import { hasAdminRights, hasEmployeeRights } from "permissions/helpers/shared";
import { getFormattedSum, getHiddenNumber, showErrorModal } from "helpers";
import { EComprehensibleCardStatus, getComprehensibleCardStatus } from "helpers/assets/bankCards";
import {
  getSortedAndFilteredBusinessBankCards,
  getSortedAndFilteredPersonalBankCards,
  isPhysicalCardActiveOrUnactivated,
} from "helpers/bankCards";
import { isKybPending, isKybRejected } from "helpers/shared/kyb";
import useAuth from "hooks/useAuth";
import { useFetchBusinessOwnersStructure } from "components/BusinessOwnersStructure";
import CompanyCardShippingDetails from "components/CompanyBankAccountComponents/CompanyCardShippingDetails/CompanyCardShippingDetails";
import { ICardholderShippingDetailsButtonComponent } from "components/CompanyBankAccountComponents/CompanyCardShippingDetails/types";
import { VerificationErrorsSection } from "components/CompanyBankAccountComponents/components";
import { CBACard3 } from "components/CompanyBankAccountPromoCards";
import useMFASMS from "components/MultiFactorAuthorization/components/sms/useMFASMS";
import { MFAWidgetType } from "components/MultiFactorAuthorization/types";
import NoAccountState from "components/PaymentMethods/components/NoAccountState";
import PermissionContent from "components/PermissionContent";

import {
  ButtonsContainer,
  ContextMenu,
  SectionAlt,
  SectionInnerRow,
  SectionTitle,
  Switch,
} from "uikit";
import { EModalTypes } from "uikit/Modal";

import {
  BankCardResDto,
  GetCompanyByIdResponseDto,
  KYBErrorResponseDto,
  ListSyncteraTransactionsResDto,
  queryBankCardsControllerGetSyncteraAccount,
  queryBankCardsControllerListBankCards,
  queryBankCardsControllerListSyncteraTransactions,
  queryBusinessBankAccountsControllerGetKybErrors,
  SyncteraAccountDto,
  UserProfileResponseDto,
} from "utils/swagger_react_query";

import BankAccountInfo from "../components/BankAccountInfo";
import PermissionBlocker from "../components/PermissionBlocker";
import {
  canIssueCard,
  canManageCard,
  canTransferFunds,
  hasAdvancedAdminBankingRights,
} from "../permissions";
import { showNoMFAModal } from "../shared/modals";
import { getBankAccountInfoSectionContextMenuOptions } from "./constants";
import { IPathParams } from "./types";
import {
  ACSHIssueCardButton,
  AdminCardsSectionHeader,
  BankAccountButtonsContainer,
  BankAccountInfoTitleAdditionalContent,
  CardButton,
  CardButtonsContainer,
  CardInfo,
  CardInfoContainer,
  CardItem,
  CardNumber,
  CardsContainer,
  CardsEmptyList,
  CardTitle,
  CardUserInfo,
  CardUserInfoContainer,
  CIBottom,
  CompanyCardShippingDetailsContainer,
  CUIInnerContainer,
  CUITitle,
  IssuCardModalCancelButton,
  IssuCardModalSubmitButton,
  IssueCardModal,
  KYBInfoContainer,
  KYBInfoContent,
  KYBInfoContentCopyButton,
  KYBInfoContentEmail,
  KYBInfoContentMessage,
  KYBInfoContentTitle,
  KYBInfoContentTitleIconContainer,
  KYBInfoEmailContainer,
  KybInfoVerificationSectionContainer,
  MonthlyStatementsLinkButton,
  RewardPointsContainer,
  RPAmount,
  RPLabel,
  StyledBankCardStatus,
  StyledTransactionsList,
  TransactionsContainer,
  TransferFundsButton,
} from "./styles";

interface IBankAccountNumber {
  id: string;
  title: string | ReactNode;
  number: string;
  isHidden: boolean;
  onShowToggle?: () => void;
}

interface IBankNumbersState {
  accountNumberHidden: boolean;
  routingNumberHidden: boolean;
}

type IEmptyBankCard = Omit<
  BankCardResDto,
  | "cardBrand"
  | "cardStatus"
  | "type"
  | "statusReason"
  | "creationTime"
  | "embossNameLine1"
  | "expirationMonth"
  | "expirationYear"
  | "isPinSet"
> & {
  isEmptyCard: boolean;
};

const useEmployeeBankingPage = () => {
  const { getCurrentUserAndCompany } = useAuth();

  //NOTE:::User and company data
  const currentUser = useAppSelector(userMetadataSelector);
  const currentCompany = useAppSelector(companyMetadataSelector);
  const rewardPoints = useAppSelector(rewardPointsSelector);

  //NOTE:::User Permissions
  const userIsAdmin = hasAdminRights(currentUser);
  const userIsEmployee = !hasAdminRights(currentUser);
  const userIsAdvancedAdmin = hasAdvancedAdminBankingRights(currentUser);
  const userCanIssueCard = canIssueCard(currentUser);
  const userCanTransferFunds = canTransferFunds(currentUser);

  //NOTE:::Banking type for requests
  const requestsBankingType = userIsEmployee ? BankingType.PERSONAL : BankingType.BUSINESS;

  //NOTE:::Translation Prefixes
  const translationPrefix = `banking_pages.shared.index`;
  const sharedTranslationPrefix = `banking_pages.shared`;

  const [cardsList, setCardsList] = useState<BankCardResDto[] | undefined>(undefined);
  const [kybError, setKybError] = useState<KYBErrorResponseDto[] | undefined>(undefined);
  const [bankAccountInfo, setBankAccountInfo] = useState<SyncteraAccountDto | undefined>(undefined);
  const [transactionsList, setTransactionsList] = useState<
    ListSyncteraTransactionsResDto | undefined
  >(undefined);
  const navigationParams = useLocation().state as IPathParams;

  const [issueCardModalIsOpen, setIssueCardModalIsOpen] = useState<boolean>(false);
  const [showOnlyMyCards, setShowOnlyMyCards] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [requestsLoading, setRequestsLoading] = useState<boolean>(true);
  const [istInitialDataRequestFinished, setInitialDataRequestFinished] = useState<boolean>(true);
  const [bankAccountNumbersState, setBankAccountNumbersState] = useState<IBankNumbersState>({
    accountNumberHidden: true,
    routingNumberHidden: true,
  });
  const [isCreationCompanyBankAccountModalOpen, setIsCreationCompanyBankAccountModalOpen] =
    useState<boolean>(false);

  const kybIsPending = userIsAdmin && isKybPending(currentCompany);
  const kybIsRejected = userIsAdmin && isKybRejected(currentCompany);
  const shouldRequestKybErrors = kybIsRejected;

  const navigate = useNavigate();

  const { fetchData: fetchFactors, factorsData } = useMFASMS({
    widgetType: MFAWidgetType.MISC,
  });

  const { businessOwnersStructure, fetchData: fetchBusinessStructureData } =
    useFetchBusinessOwnersStructure({
      fetchDataOnMount: false,
    });

  const isMFAEnabled = !!factorsData?.some((factor) => factor.isVerified);
  const shouldUserOrderPhysicalCard = !(cardsList || []).some(isPhysicalCardActiveOrUnactivated);

  const hasCompanyAccount = (
    user?: UserProfileResponseDto | null,
    company?: GetCompanyByIdResponseDto | null,
  ) => {
    const _user = user || currentUser;
    const _company = company || currentCompany;
    return (
      (hasEmployeeRights(_user) && _user?.user?.kycStatus === KycStatus.ACCEPTED) ||
      (hasAdminRights(_user) && _company?.kybStatus === KybStatus.ACCEPTED)
    );
  };

  const userHasCompanyAccount = hasCompanyAccount();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const shouldRequestData = userHasCompanyAccount;
    if (!shouldRequestData) {
      setRequestsLoading(false);
      setInitialDataRequestFinished(true);
    } else if (
      !!bankAccountInfo &&
      !!cardsList &&
      !!transactionsList &&
      (userIsEmployee || !!businessOwnersStructure)
    ) {
      setRequestsLoading(false);
      setInitialDataRequestFinished(true);
      if (navigationParams?.showRequestVirtualModal) {
        const hasVirtualCard = find(
          cardsList,
          (item: BankCardResDto) => item.form === CardForm.VIRTUAL,
        );
        if (!hasVirtualCard) {
          showRequestVirtualCardModal();
        }
      }
    }
  }, [bankAccountInfo, cardsList, transactionsList, businessOwnersStructure]);

  const requestBusinessStructureData = async () => {
    await fetchBusinessStructureData();
  };

  const requestRejectedKybData = async () => {
    try {
      setRequestsLoading(true);
      await requestKybErrors();
      await requestBusinessStructureData();
    } catch (error: any) {
      showErrorModal(error);
    } finally {
      setRequestsLoading(false);
    }
  };

  const requestKybErrors = async () => {
    try {
      const result = await queryBusinessBankAccountsControllerGetKybErrors();
      setKybError(result);
    } catch (error: any) {
      showErrorModal(error);
    }
  };

  const requestCardsList = async () => {
    try {
      const bankCardsListRes = await queryBankCardsControllerListBankCards({
        bankingType: requestsBankingType,
      });
      const sortedBankCards = userIsEmployee
        ? getSortedAndFilteredPersonalBankCards(bankCardsListRes.cards)
        : getSortedAndFilteredBusinessBankCards(bankCardsListRes.cards, currentUser?.user?.userId);

      setCardsList(sortedBankCards);
    } catch (error: any) {
      if (
        error?.data?.error === "USER_HAS_NO_PARTNER_BANK_ACCOUNT" ||
        error?.data?.error === "USER_NOT_KYC_VERIFIED"
      ) {
        setCardsList([]);
        setRequestsLoading(false);
      } else {
        showErrorModal(error);
      }
    }
  };

  const requestBankAccountInfo = async () => {
    try {
      const result = await queryBankCardsControllerGetSyncteraAccount({
        bankingType: requestsBankingType,
      });
      setBankAccountInfo(result);
    } catch (error: any) {
      if (
        error?.data?.error === "USER_HAS_NO_PARTNER_BANK_ACCOUNT" ||
        error?.data?.error === "USER_NOT_KYC_VERIFIED"
      ) {
        //NOTE:::Not sure if action is needed
      } else {
        showErrorModal(error);
      }
    }
  };

  const requestTransactionsInfo = async () => {
    try {
      const result = await queryBankCardsControllerListSyncteraTransactions({
        bankingType: requestsBankingType,
      });
      setTransactionsList(result);
    } catch (error: any) {
      if (
        error?.data?.error === "USER_HAS_NO_PARTNER_BANK_ACCOUNT" ||
        error?.data?.error === "USER_NOT_KYC_VERIFIED"
      ) {
        //NOTE:::Not sure if action is needed
      } else {
        showErrorModal(error);
      }
    }
  };

  const redirectToMFA = () => {
    navigate(
      userIsEmployee
        ? routes.EMPLOYEE_SETTINGS_MFA_AUTHENTICATION
        : routes.ADMIN_SETTINGS_MFA_AUTHENTICATION,
    );
  };

  const handleAddAccount = () => {
    setIsCreationCompanyBankAccountModalOpen(true);
  };

  const handleRestartVerification = () => {
    setIsCreationCompanyBankAccountModalOpen(true);
  };

  const handleTransferFunds = () => {
    if (!isMFAEnabled) {
      showNoMFAModal(redirectToMFA);
    } else {
      navigate(routes.BANKING_TRANSFER_FUNDS);
    }
  };

  const handleNavigateToBusinessStructurePage = () => {
    navigate(routes.BANKING_BUSINESS_OWNERSHIP_STRUCTURE);
  };

  const refetchCards = async () => {
    try {
      setRequestsLoading(true);
      await requestCardsList();
    } catch (error) {
      showErrorModal(error);
    } finally {
      setRequestsLoading(false);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    if (shouldRequestKybErrors) {
      await requestRejectedKybData();
    } else if (userHasCompanyAccount) {
      await requestCardsList();
      await requestBankAccountInfo();
      await requestTransactionsInfo();

      if (userIsAdmin) {
        await requestBusinessStructureData();
      }
    }

    setLoading(false);
  };
  const refetchData = async () => {
    try {
      setLoading(true);
      const result = await getCurrentUserAndCompany();
      const shouldRequestKybErrors = !!userIsAdmin && isKybRejected(result?.company);

      if (!hasCompanyAccount(result?.user, result?.company)) {
        setLoading(false);
        return;
      } else if (shouldRequestKybErrors) {
        await requestRejectedKybData();
        return;
      }

      await requestCardsList();
      await requestBankAccountInfo();
      await requestTransactionsInfo();
      await fetchFactors();
    } catch (error) {
      showErrorModal(error);
    } finally {
      setLoading(false);
    }
  };

  //NOTE:::Render Account Info
  const renderBankAccountSection = () => {
    const _translationPrefix = `${translationPrefix}.account_section`;
    const componentTranslationPrefix = `banking_pages.shared.components.bank_account_info`;

    const amount =
      (bankAccountInfo?.balances || []).find(
        (item) => item.type === SyncteraAccountBalanceType.AVAILABLE_BALANCE,
      )?.balance || 0;

    const BANumbers: IBankAccountNumber[] = [
      {
        id: "ba_routing_number_label",
        title: <Trans i18nKey={`${componentTranslationPrefix}.routing_number_label`} />,
        number: bankAccountInfo?.bankRouting || "",
        isHidden: !!bankAccountNumbersState.routingNumberHidden,
        onShowToggle: () => {
          setBankAccountNumbersState({
            ...bankAccountNumbersState,
            routingNumberHidden: !bankAccountNumbersState.routingNumberHidden,
          });
        },
      },
      {
        id: "ba_account_number_label",
        title: <Trans i18nKey={`${componentTranslationPrefix}.account_number_label`} />,
        number: bankAccountInfo?.accountNumber || "",
        isHidden: !!bankAccountNumbersState.accountNumberHidden,
        onShowToggle: () => {
          setBankAccountNumbersState({
            ...bankAccountNumbersState,
            accountNumberHidden: !bankAccountNumbersState.accountNumberHidden,
          });
        },
      },
    ];

    return (
      <BankAccountInfo
        amount={amount}
        items={BANumbers}
        additionalContent={
          <BankAccountButtonsContainer>
            <PermissionBlocker
              id={"transfer_funds_button_blocker"}
              isBlocked={!userCanTransferFunds}
            >
              <TransferFundsButton
                onClick={handleTransferFunds}
                disabled={!amount || !userCanTransferFunds}
                data-testid="transfer_funds_button"
              >
                <UploadIconSmall />
                <Trans i18nKey={`${_translationPrefix}.transfer_funds_button`} />
              </TransferFundsButton>
            </PermissionBlocker>
          </BankAccountButtonsContainer>
        }
        sectionTitleAdditionalContent={
          <BankAccountInfoTitleAdditionalContent>
            <MonthlyStatementsLinkButton
              onClick={() => navigate(routes.BANKING_MONTHLY_STATEMENTS)}
              data-testid="monthly_statements_button"
            >
              <ClockAltIcon />
              <Trans i18nKey={`${_translationPrefix}.monthly_statements_button`} />
            </MonthlyStatementsLinkButton>
            <PermissionContent roles={[UserRole.EMPLOYEE]}>
              <RewardPointsContainer to={routes.EMPLOYEE_BANKING_REWARDS}>
                <RPAmount>
                  {getFormattedSum(rewardPoints?.pointsAmount || 0, { symbol: "", precision: 0 })}
                </RPAmount>
                <RPLabel>
                  <Trans
                    i18nKey={`${_translationPrefix}.reward_points_button`}
                    count={rewardPoints?.pointsAmount || 0}
                  />
                </RPLabel>
              </RewardPointsContainer>
            </PermissionContent>
            <PermissionContent
              roles={[UserRole.EMPLOYEE]}
              elseContent={
                <ContextMenu
                  options={getBankAccountInfoSectionContextMenuOptions(
                    handleNavigateToBusinessStructurePage,
                  )}
                />
              }
            />
          </BankAccountInfoTitleAdditionalContent>
        }
      />
    );
  };

  const redirectToCardDetails = (card: Partial<BankCardResDto> | IEmptyBankCard) => {
    if (!card.form) return;
    if (userIsEmployee) {
      navigate(routes.BANKING_CARDS_EMPLOYEE, { state: { selectedTab: card.form } });
    }
    if (userIsAdmin) {
      if (!card.id) return;
      navigate(
        generatePath(routes.BANKING_CARDS_ADMIN, {
          id: card.id,
        }),
      );
    }
  };

  /* NOTE:::RENDER FUNCTIONS */

  const renderCardButton = (data: Partial<BankCardResDto> | IEmptyBankCard) => {
    const _translationPrefix = `${sharedTranslationPrefix}.components.bank_cards`;
    const buttonsTranslationPrefix = userIsEmployee
      ? `${_translationPrefix}.card_list_buttons.employee`
      : `${_translationPrefix}.card_list_buttons.admin`;
    const { form } = data;
    const cardStatuses = getComprehensibleCardStatus(data);

    let buttonText;

    if (userIsEmployee) {
      buttonText = <Trans i18nKey={`${buttonsTranslationPrefix}.${form}.${cardStatuses.status}`} />;
    } else if (userIsAdmin) {
      buttonText = canManageCard(currentUser, data) ? (
        <Trans i18nKey={`${buttonsTranslationPrefix}.manage_card`} />
      ) : (
        <Trans i18nKey={`${buttonsTranslationPrefix}.view_card`} />
      );
    }

    return (
      <CardButtonsContainer>
        <CardButton
          onClick={() => {
            redirectToCardDetails(data);
          }}
          data-testid={`${CardForm?.[data?.form as CardForm]}_card_action_button`}
        >
          {buttonText}
          <ArrowIconSmall />
        </CardButton>
      </CardButtonsContainer>
    );
  };

  const renderCard = (data: Partial<BankCardResDto> | IEmptyBankCard) => {
    const _translationPrefix = `${sharedTranslationPrefix}.components.bank_cards`;
    const commonTranslationPrefix = `common.bank_cards`;
    const { id, form, lastFour } = data;
    const cardStatuses = getComprehensibleCardStatus(data);
    const showNumber = lastFour && cardStatuses.status !== EComprehensibleCardStatus.EMPTY;
    const showStatus = cardStatuses.status !== EComprehensibleCardStatus.EMPTY;
    const hiddenNumberMask = getHiddenNumber("*".repeat(12), 4);

    return (
      <CardItem key={id}>
        <CardInfoContainer>
          <CardInfo>
            <CardTitle>
              <Trans i18nKey={`${commonTranslationPrefix}.card_titles.${form}`} />
            </CardTitle>
            <CIBottom>
              {!!showNumber && (
                <CardNumber>
                  {hiddenNumberMask} {lastFour}
                </CardNumber>
              )}
              {!!showStatus && <StyledBankCardStatus data={data} shouldShowAdditionalStatus />}
            </CIBottom>
          </CardInfo>
          {userIsAdmin && (
            <CardUserInfoContainer>
              <CUITitle>
                <Trans i18nKey={`${_translationPrefix}.card_owner_title`} />
              </CUITitle>
              <CUIInnerContainer>
                <CardUserInfo
                  avatar={data.ownerInfo?.avatarUrl}
                  firstName={data.ownerInfo?.firstName}
                  lastName={data.ownerInfo?.lastName}
                />
              </CUIInnerContainer>
            </CardUserInfoContainer>
          )}
        </CardInfoContainer>
        {renderCardButton(data)}
      </CardItem>
    );
  };

  const renderKYBVerificationInProgressSection = () => {
    const _translationPrefix = `${translationPrefix}.kyb_verification_section.in_progress`;
    const link = HELP_EMAIL;

    return (
      <KYBInfoContent
        type={EModalTypes.WARNING}
        title={
          <KYBInfoContentTitle>
            <KYBInfoContentTitleIconContainer>
              <LockIcon />
            </KYBInfoContentTitleIconContainer>
            <Trans i18nKey={`${_translationPrefix}.title`} />
          </KYBInfoContentTitle>
        }
        message={
          <>
            <KYBInfoContentMessage>
              <Trans i18nKey={`${_translationPrefix}.message`} />
            </KYBInfoContentMessage>

            <KYBInfoEmailContainer>
              <KYBInfoContentEmail href={`mailto:${link}`}>{link}</KYBInfoContentEmail>
              <KYBInfoContentCopyButton id="kyb_info_content_copy_button" copyContent={link}>
                <CopyIcon />
                <Trans i18nKey={`buttons.copy`} />
              </KYBInfoContentCopyButton>
            </KYBInfoEmailContainer>
          </>
        }
      />
    );
  };

  const renderKYBVerificationFailedSection = () => {
    const _translationPrefix = `${translationPrefix}.kyb_verification_section.failed`;
    const link = HELP_EMAIL;

    return (
      <>
        <KYBInfoContent
          type={EModalTypes.ERROR}
          title={
            <KYBInfoContentTitle>
              <KYBInfoContentTitleIconContainer>
                <LockIcon />
              </KYBInfoContentTitleIconContainer>
              <Trans i18nKey={`${_translationPrefix}.title`} />
            </KYBInfoContentTitle>
          }
          message={
            <>
              <KYBInfoContentMessage>
                {currentCompany?.existsPaidBankAccount ? (
                  <Trans i18nKey={`${_translationPrefix}.message_alt`} />
                ) : (
                  <Trans i18nKey={`${_translationPrefix}.message`} />
                )}
              </KYBInfoContentMessage>
              <KYBInfoEmailContainer>
                <KYBInfoContentEmail href={`mailto:${link}`}>{link}</KYBInfoContentEmail>
                <KYBInfoContentCopyButton id="kyb_info_content_copy_button" copyContent={link}>
                  <CopyIcon />
                  <Trans i18nKey={`buttons.copy`} />
                </KYBInfoContentCopyButton>
              </KYBInfoEmailContainer>
            </>
          }
          mainButton={
            currentCompany?.existsPaidBankAccount
              ? undefined
              : {
                  text: <Trans i18nKey={`${_translationPrefix}.restart_verification_button`} />,
                  onClick: handleRestartVerification,
                  autoWidth: true,
                }
          }
        />
        {!!kybError && !!businessOwnersStructure && (
          <KybInfoVerificationSectionContainer>
            <VerificationErrorsSection
              errors={kybError}
              businessStructure={businessOwnersStructure}
            />
          </KybInfoVerificationSectionContainer>
        )}
      </>
    );
  };

  const renderKYBVerificationInfoSection = () => {
    const _translationPrefix = `${translationPrefix}.kyb_verification_section`;
    return (
      <KYBInfoContainer>
        <SectionAlt>
          <SectionTitle>
            <Trans i18nKey={`${_translationPrefix}.title`} />
          </SectionTitle>
          {kybIsPending && <>{renderKYBVerificationInProgressSection()}</>}
          {kybIsRejected && <>{renderKYBVerificationFailedSection()}</>}
        </SectionAlt>
      </KYBInfoContainer>
    );
  };

  const renderAdminCardsSectionHeader = () => {
    const _translationPrefix = `${translationPrefix}.cards_section`;

    return (
      <AdminCardsSectionHeader>
        <Switch
          checked={showOnlyMyCards}
          onChange={() => {
            setShowOnlyMyCards(!showOnlyMyCards);
          }}
        >
          <Trans i18nKey={`${_translationPrefix}.show_only_my_cards_label`} />
        </Switch>
        <PermissionBlocker id={"issue_card_button_blocker"} isBlocked={!userCanIssueCard}>
          <ACSHIssueCardButton
            onClick={showIssueCardModal}
            data-testid="banking-issue-card-button"
            disabled={!userCanIssueCard}
          >
            <Trans i18nKey={`${_translationPrefix}.issue_card_button`} />
          </ACSHIssueCardButton>
        </PermissionBlocker>
      </AdminCardsSectionHeader>
    );
  };

  const getWorkerCardsList = () => {
    const cardsListModified: any = cardsList ? [...cardsList] : [];

    const shouldShowVirtualCardPlaceholder = !find(
      cardsListModified,
      (item: BankCardResDto) =>
        item.form === CardForm.VIRTUAL &&
        [CardStatus.UNACTIVATED, CardStatus.ACTIVE].includes(item.cardStatus as CardStatus),
    );

    const shouldShowPhysicalCardPlaceholder = !find(
      cardsListModified,
      (item: BankCardResDto) =>
        item.form === CardForm.PHYSICAL &&
        [CardStatus.UNACTIVATED, CardStatus.ACTIVE].includes(item.cardStatus as CardStatus),
    );

    if (shouldShowVirtualCardPlaceholder) {
      cardsListModified?.unshift({
        id: "virtual_card_placeholder",
        form: CardForm.VIRTUAL,
        lastFour: "",
        isEmpty: true,
      });
    }

    if (shouldShowPhysicalCardPlaceholder) {
      cardsListModified?.unshift({
        id: "physical_card_placeholder",
        form: CardForm.PHYSICAL,
        lastFour: "",
        isEmpty: true,
      });
    }

    return cardsListModified || [];
  };

  const getAdminCardsList = () => {
    return showOnlyMyCards
      ? (cardsList || []).filter((item) => item.ownerInfo?.userId === currentUser?.user?.userId)
      : cardsList || [];
  };

  const renderCardsSection = () => {
    const _translationPrefix = `${translationPrefix}.cards_section`;

    const cards = userIsEmployee ? getWorkerCardsList() : getAdminCardsList();

    return (
      <SectionAlt>
        <SectionTitle>
          <SectionInnerRow>
            <Trans i18nKey={`${_translationPrefix}.title`} />
            {!!userIsAdmin && <>{renderAdminCardsSectionHeader()}</>}
          </SectionInnerRow>
        </SectionTitle>
        {cards.length ? (
          <CardsContainer>{cards?.map((item: any) => <>{renderCard(item)}</>)}</CardsContainer>
        ) : (
          <CardsEmptyList
            icon={<NoCreditCard />}
            title={<Trans i18nKey={`${_translationPrefix}.empty_list_title`} />}
            message={<Trans i18nKey={`${_translationPrefix}.empty_list_description`} />}
          />
        )}
      </SectionAlt>
    );
  };

  const renderTransactionsSection = () => {
    const _translationPrefix = `${translationPrefix}.transactions_section`;

    return (
      <SectionAlt>
        <SectionTitle>
          <Trans i18nKey={`${_translationPrefix}.title`} />
        </SectionTitle>
        <TransactionsContainer>
          <StyledTransactionsList
            pendingTransactions={transactionsList?.pendingTransactions || []}
            postedTransactions={transactionsList?.postedTransactions || []}
            isLoading={false}
          />
        </TransactionsContainer>
      </SectionAlt>
    );
  };

  const renderContent = () => {
    if (userIsAdmin && (kybIsPending || kybIsRejected)) {
      return <>{renderKYBVerificationInfoSection()}</>;
    } else if (!userHasCompanyAccount) {
      return userIsAdmin ? (
        <CBACard3 onButtonClick={handleAddAccount} />
      ) : (
        <NoAccountState onCreateAccount={handleAddAccount} />
      );
    } else {
      return (
        <>
          {!requestsLoading && (
            <>
              {renderBankAccountSection()}
              {renderCardsSection()}
              {renderTransactionsSection()}
              {renderIssueCardModal()}
            </>
          )}
        </>
      );
    }
  };

  /* NOTE:::MODALS */
  const closeIssueCardModal = () => {
    setIssueCardModalIsOpen(false);
  };
  const showIssueCardModal = () => {
    setIssueCardModalIsOpen(true);
  };

  const handleIssueCardModalBackClick = () => {
    closeIssueCardModal();
  };

  const handleIssueCardModalSubmit = async (issuedCardForms?: CardForm[]) => {
    closeIssueCardModal();
    showIssueCardSuccessModal(issuedCardForms);
    refetchCards();
  };

  const renderIssueCardModalButtons = (props: ICardholderShippingDetailsButtonComponent) => {
    const _translationPrefix = `${translationPrefix}.modals.issue_card`;
    const { backIsDisabled, submitIsDisabled } = props;

    return (
      <ButtonsContainer>
        <IssuCardModalCancelButton
          disabled={backIsDisabled}
          onClick={handleIssueCardModalBackClick}
          data-testid="issue_card_modal_back_button"
        >
          <Trans i18nKey={"buttons.cancel"} />
        </IssuCardModalCancelButton>
        <IssuCardModalSubmitButton
          type="submit"
          disabled={submitIsDisabled}
          data-testid="issue_card_modal_back_button"
        >
          <Trans i18nKey={`${_translationPrefix}.submit_button`} />
        </IssuCardModalSubmitButton>
      </ButtonsContainer>
    );
  };

  const renderIssueCardModal = () => {
    if (userIsEmployee) return <></>;

    return (
      <IssueCardModal
        className="issue_card_modal"
        isOpen={issueCardModalIsOpen}
        onClose={closeIssueCardModal}
      >
        <CompanyCardShippingDetailsContainer>
          <CompanyCardShippingDetails
            onBackBtnClick={handleIssueCardModalBackClick}
            onSubmitSuccess={handleIssueCardModalSubmit}
            businessOwnersStructure={businessOwnersStructure}
            showCardTemplate={true}
            showCardColderDropdown={true}
            showCardTypeCheckboxes={true}
            buttonsComponent={renderIssueCardModalButtons}
            handleRefetchBusinessOwnersStructure={requestBusinessStructureData}
            bankCardsList={cardsList || []}
          />
        </CompanyCardShippingDetailsContainer>
      </IssueCardModal>
    );
  };

  const showIssueCardSuccessModal = (issuedCardForms?: CardForm[]) => {
    if (!issuedCardForms?.length) return;
    const _translationPrefix = `${translationPrefix}.modals.issue_card_success`;
    const titlePrefix =
      issuedCardForms?.length > 1
        ? `${_translationPrefix}.titles.multiple`
        : `${_translationPrefix}.titles.${issuedCardForms[0]}`;

    AppEvents.emit("SetGlobalModal", {
      type: EModalTypes.SUCCESS,
      isOpen: true,
      title: <Trans i18nKey={titlePrefix} />,
      mainButton: {
        text: <Trans i18nKey={`buttons.done`} />,
        autoWidth: true,
      },
    });
  };

  const showRequestVirtualCardModal = () => {
    const _translationPrefix = `${translationPrefix}.modals.request_virtual_card`;
    AppEvents.emit("SetGlobalModal", {
      type: EModalTypes.SUCCESS,
      isOpen: true,
      title: <Trans i18nKey={`${_translationPrefix}.title`} />,
      message: <Trans i18nKey={`${_translationPrefix}.message`} />,
      mainButton: {
        text: <Trans i18nKey={`${_translationPrefix}.main_button`} />,
        onClick: () => redirectToCardDetails({ form: CardForm.VIRTUAL }),
      },
      secondaryButton: {
        text: <Trans i18nKey={`common.modal.cancel`} />,
      },
    });
  };

  return {
    isLoading,
    istInitialDataRequestFinished,
    isCreationCompanyBankAccountModalOpen,
    requestsLoading,
    translationPrefix,
    cardsList,
    bankAccountInfo,
    currentUser,
    currentCompany,
    factorsData,
    userHasCompanyAccount,
    userIsAdmin,
    userIsEmployee,
    userIsAdvancedAdmin,
    kybIsPending,
    kybIsRejected,
    isMFAEnabled,
    shouldUserOrderPhysicalCard,
    handleAddAccount,
    renderKYBVerificationInfoSection,
    renderBankAccountSection,
    renderTransactionsSection,
    renderContent,
    renderIssueCardModal,
    setIsCreationCompanyBankAccountModalOpen,
    refetchData,
  };
};

export default useEmployeeBankingPage;
