import { FC, ReactNode } from "react";

import { Loader } from "uikit";

import { ChecklistItem } from "./components";
import {
  AdditionalSection,
  AdditionalSectionContainer,
  ASDescription,
  ASFooter,
  ASHeader,
  ASHeaderBottom,
  ASHTop,
  ASMain,
  ASTitle,
  Container,
  CopyrightContainerMobile,
  Futures,
  FuturesContainer,
  FuturesSecondLine,
  LoaderWrapper,
  MainSection,
  MainSectionContainer,
  StyledCopyright,
  StyledLogo,
} from "./styles";

export interface IAuthLayoutProps {
  isLoading?: boolean;
  children?: ReactNode;
  className?: string;
  additionalSection?: {
    title: string;
    description: string;
    checkListItems: string[];
  };
  leftSectionCustomContent?: ReactNode;
  mainSectionTopContent?: ReactNode;
  showLogo?: boolean;
}

const AuthLayout: FC<IAuthLayoutProps> = ({
  additionalSection,
  leftSectionCustomContent,
  children,
  isLoading,
  className,
  mainSectionTopContent,
  showLogo,
}) => {
  const leftSectionClassName = additionalSection?.checkListItems?.length ? "with-checklist" : "";

  return (
    <Container className={className}>
      {(additionalSection || leftSectionCustomContent) && (
        <AdditionalSectionContainer>
          {additionalSection && !leftSectionCustomContent && (
            <AdditionalSection className={leftSectionClassName}>
              <ASHeader>
                <ASHeaderBottom>
                  <ASTitle>{additionalSection.title}</ASTitle>
                  <ASDescription>{additionalSection.description}</ASDescription>
                </ASHeaderBottom>
              </ASHeader>

              {additionalSection?.checkListItems && (
                <ASMain>
                  <FuturesContainer>
                    <Futures key={"first-line"}>
                      {additionalSection.checkListItems.map((item, index) => (
                        <ChecklistItem key={index} label={item} />
                      ))}
                    </Futures>
                  </FuturesContainer>

                  <FuturesContainer>
                    <FuturesSecondLine key={"second-line"}>
                      {additionalSection.checkListItems.map((item, index) => (
                        <ChecklistItem key={index} label={item} />
                      ))}
                    </FuturesSecondLine>
                  </FuturesContainer>
                </ASMain>
              )}

              <ASFooter>
                <StyledCopyright />
              </ASFooter>
            </AdditionalSection>
          )}

          {!additionalSection && leftSectionCustomContent && (
            <AdditionalSection>{leftSectionCustomContent}</AdditionalSection>
          )}
        </AdditionalSectionContainer>
      )}

      <MainSectionContainer>
        <MainSection>
          {mainSectionTopContent}
          {showLogo && (
            <ASHTop>
              <StyledLogo />
            </ASHTop>
          )}

          {isLoading && (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          )}

          {children}
        </MainSection>
      </MainSectionContainer>

      <CopyrightContainerMobile>
        <StyledCopyright />
      </CopyrightContainerMobile>
    </Container>
  );
};

export default AuthLayout;
