import * as yup from "yup";

import { PayDistributionRuleType } from "types/BETypes";

import { DefaultPayDistributionDto } from "utils/swagger_react_query";

import {
  MAX_MONETARY_AMOUNT,
  MAX_PERCENTAGE_AMOUNT,
  MIN_MONETARY_AMOUNT,
  MIN_PERCENTAGE_AMOUNT,
} from "./constants";

const translationPrefix = "components.pay_distribution.bank_accounts_form.errors";
const commonTranslationPrefix = "validationErrors.common";

export const bankItemSchema = yup.object().shape({
  id: yup
    .string()
    .nullable()
    .test(`Account Id is required`, `${translationPrefix}.id.is_required`, function (value) {
      return !!value;
    })
    .test(
      `Account Id must be unique`,
      `${translationPrefix}.id.bank_uniqueness_required`,
      function (value) {
        const rootValues = (this as any).from?.[1]?.value;
        const array: DefaultPayDistributionDto[] = rootValues?.payDistributionRules || [];
        const id = this.parent?.id || "";

        return (array?.filter((item) => item.id === id)?.length || 0) < 2;
      },
    ),
  ruleType: yup.string().required(`${commonTranslationPrefix}.is_required`),
  type: yup.string(),
  amount: yup
    .number()
    .test(`Amount is Required`, `${translationPrefix}.amount.amount_is_required`, function (value) {
      if (this.parent.ruleType === PayDistributionRuleType.REMAINDER) return true;
      return value !== undefined;
    })
    .test(
      `Percentage amount is too low`,
      `${translationPrefix}.amount.percentage.amount_is_too_low`,
      function (value) {
        if (this.parent.ruleType !== PayDistributionRuleType.PERCENTAGE) return true;
        return (value || 0) >= MIN_PERCENTAGE_AMOUNT;
      },
    )
    .test(
      `Total percentage amount is too high`,
      `${translationPrefix}.amount.percentage.overall_amount_is_too_high`,
      function () {
        if (this.parent.ruleType !== PayDistributionRuleType.PERCENTAGE) return true;
        const rootValues = (this as any).from?.[1]?.value;
        const array = rootValues?.payDistributionRules || [];
        const percentageItems: Array<any> = array.filter(
          (item: any) => item.ruleType === PayDistributionRuleType.PERCENTAGE,
        );
        const sum = (percentageItems || []).reduce((curr, next) => curr + (next.amount || 0), 0);
        return (sum || 0) <= MAX_PERCENTAGE_AMOUNT;
      },
    )
    .test(
      `Monetary amount is too low`,
      `${translationPrefix}.amount.monetary.amount_is_too_low`,
      function (value) {
        if (this.parent.ruleType !== PayDistributionRuleType.FIXED_AMOUNT) return true;
        return (value || 0) >= MIN_MONETARY_AMOUNT;
      },
    )
    .test(
      `Monetary amount is too high`,
      `${translationPrefix}.amount.monetary.amount_is_too_high`,
      function (value) {
        if (this.parent.ruleType !== PayDistributionRuleType.FIXED_AMOUNT) return true;
        return (value || 0) <= MAX_MONETARY_AMOUNT;
      },
    ),
});

export const validationSchema = yup.object({
  isEarlyPayEnabled: yup.boolean(),
  payDistributionRules: yup.array().of(bankItemSchema),
});

export type BankItemType = yup.InferType<typeof bankItemSchema>;
export type FormType = yup.InferType<typeof validationSchema>;
