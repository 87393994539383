export const employeeInfo = {
  "en-US": {
    resend_invite_btn: "Resend Invite",
    delete_btn: "Delete",
    invite_btn: "Invite Worker",
    edit_btn: "Edit",
    finish_setup_btn: "Setup Payroll",
  },
};

export default employeeInfo;
