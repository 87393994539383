import { FC, FocusEvent, Key, useState } from "react";
import Countdown, { CountdownRenderProps } from "react-countdown";
import { Trans, useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

import { MFA_SMS_CODE_LENGTH, MFA_SMS_CODE_REFRESH_COOLDOWN_SECONDS } from "constants/mfa";
import { getFieldError } from "helpers";
import { MFAWidgetType } from "components/MultiFactorAuthorization/types";

import { FormLabel, Input, InputContainer } from "uikit";

import {
  InputBesideButtonsContainer,
  InputBesideSubmitButton,
  MainInputContainer,
  MainInputContainerInner,
} from "../../../stepsStyles";
import { ESMSWidgetSteps } from "../../types";
import { IVerifyCodeStepPropsInner } from "../../types";
import { FormType } from "../../validationSchema";
import { Container, ResendCodeContainer, ResendCodeCountDown, ResendCodeLink } from "./styles";

type TProps = Pick<
  IVerifyCodeStepPropsInner,
  "className" | "widgetType" | "onSubmit" | "formSubmitCallback" | "onResend" | "showInputLabel"
> & {
  resendCountdownOnStart?: boolean;
};

const DefaultInput: FC<TProps> = (props) => {
  const {
    className,
    widgetType = MFAWidgetType.MISC,
    resendCountdownOnStart = true,
    onSubmit,
    formSubmitCallback,
    onResend,
    showInputLabel = true,
  } = props;
  const { t } = useTranslation();
  const commonPrefix = `components.multi_factor_authorization.sms`;
  const translationPrefix = `${commonPrefix}.steps.${ESMSWidgetSteps.VERIFY_CODE}`;
  const formikContext = useFormikContext<FormType>();
  const { values, setFieldValue } = formikContext;
  const [resendCountDown, setResendCountDown] = useState<number | undefined>(
    resendCountdownOnStart ? Date.now() + MFA_SMS_CODE_REFRESH_COOLDOWN_SECONDS * 1000 : Date.now(),
  );
  const [countDownKey, setCountDownKey] = useState<Key>(Date.now());

  const handleSubmit = () => {
    formSubmitCallback?.();
    onSubmit?.(formikContext);
  };

  const startCodeCountDown = () => {
    setResendCountDown(Date.now() + MFA_SMS_CODE_REFRESH_COOLDOWN_SECONDS * 1000);
    setCountDownKey(Date.now());
  };

  const handleResend = () => {
    onResend?.(startCodeCountDown);
  };

  const handleCodeInputChange = (e: FocusEvent<HTMLInputElement, Element>) => {
    const value = e.currentTarget.value;
    setFieldValue("code", value.toString().replace(/\D/, ""));
  };

  const resendRenderer = ({ seconds, total }: CountdownRenderProps) => {
    if (!total) {
      return (
        <Trans
          i18nKey={`${translationPrefix}.resend_code_label_default`}
          components={{
            1: <ResendCodeLink onClick={handleResend} data-testid="mfa-resend-code-link-button" />,
          }}
        />
      );
    } else {
      // NOTE:::Render a countdown
      return (
        <Trans
          i18nKey={`${translationPrefix}.resend_code_label_counting`}
          components={{
            1: <ResendCodeCountDown />,
          }}
          values={{
            remainingSeconds: seconds,
          }}
        />
      );
    }
  };

  return (
    <Container className={className}>
      <InputContainer>
        {showInputLabel && (
          <FormLabel>
            <Trans i18nKey={`${translationPrefix}.code_verification_input.label`} />
          </FormLabel>
        )}
        <MainInputContainer className={`${widgetType}`}>
          <MainInputContainerInner>
            <Input
              name="code"
              onChange={handleCodeInputChange}
              maxLength={MFA_SMS_CODE_LENGTH}
              value={values.code}
              error={getFieldError("code", formikContext)}
              data-testid="sms-input-verify-code-step-code-input"
              errorDataTestId="verify-code-step-code-input-error"
              alwaysShowErrorBlock={false}
              placeholder={t(`${translationPrefix}.code_verification_input.placeholder`)}
            />
          </MainInputContainerInner>

          {widgetType === MFAWidgetType.SETUP && (
            <InputBesideButtonsContainer>
              <InputBesideSubmitButton
                data-testid="mfa-sms-verify-code-step-submit-button"
                onClick={handleSubmit}
              >
                <Trans i18nKey={`${translationPrefix}.confirm_button.${widgetType}`} />
              </InputBesideSubmitButton>
            </InputBesideButtonsContainer>
          )}
        </MainInputContainer>
      </InputContainer>

      <ResendCodeContainer>
        <Countdown
          key={countDownKey}
          date={resendCountDown}
          renderer={resendRenderer}
          intervalDelay={0}
        />
      </ResendCodeContainer>
    </Container>
  );
};

export default DefaultInput;
