import styled from "styled-components";

import { PrimaryButton, SecondaryButton, Section } from "uikit";

export const FormContainer = styled.form``;

export const StyledSection = styled(Section)`
  width: 528px;
  margin-bottom: 58px;
  position: relative;
`;

export const StyledSecondaryButton = styled(SecondaryButton)`
  width: fit-content;
`;
export const StyledPrimaryButton = styled(PrimaryButton)`
  width: fit-content;
`;
