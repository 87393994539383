import { t } from "i18next";

import { IAuthLayoutProps } from "layouts/AuthLayout/AuthLayout";

import { AuthFormType } from "./components/AuthForm/types";

const translationPrefix = `auth_pages.shared`;

export const authAdditionalSectionParams = (): IAuthLayoutProps["additionalSection"] => {
  return {
    title: t(`${translationPrefix}.layout_promo_section.${AuthFormType.SIGN_UP}.title`),
    description: t(`${translationPrefix}.layout_promo_section.${AuthFormType.SIGN_UP}.description`),
    checkListItems: [
      t(`${translationPrefix}.layout_promo_section.features.0`),
      t(`${translationPrefix}.layout_promo_section.features.1`),
      t(`${translationPrefix}.layout_promo_section.features.2`),
    ],
  };
};
