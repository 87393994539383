import { UserRole } from "types/BETypes";

import {
  ExtendedUserProfileResponseDto,
  UserProfileResponseDto,
  UserResponseDto,
} from "utils/swagger_react_query";

export const hasAdminRights = (user?: UserProfileResponseDto | null): boolean => {
  if (!user) {
    return false;
  }

  return [UserRole.ADMIN, UserRole.SUPERADMIN, UserRole.OWNER].includes(
    user?.user?.lastActiveRole as UserRole,
  );
};

export const hasEmployeeRights = (user?: UserProfileResponseDto | null): boolean => {
  if (!user) {
    return false;
  }

  return [UserRole.EMPLOYEE].includes(user?.user?.lastActiveRole as UserRole);
};

export const hasOwnerRights = (user?: UserProfileResponseDto | null): boolean => {
  if (!user) {
    return false;
  }

  return [UserRole.OWNER].includes(user?.user?.lastActiveRole as UserRole);
};

export const hasCompanyAdminRole = (
  user?: Partial<UserProfileResponseDto | UserResponseDto> | null,
): boolean => {
  const _user =
    !!user && "user" in user
      ? (user.user as ExtendedUserProfileResponseDto)
      : (user as UserResponseDto);
  if (!_user?.companyRoles) {
    return false;
  }

  return (_user.companyRoles as UserRole[]).some((role) =>
    [UserRole.ADMIN, UserRole.SUPERADMIN, UserRole.OWNER].includes(role as UserRole),
  );
};
