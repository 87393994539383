import * as yup from "yup";

import { commonValidationRules } from "constants/shared/validationRules";

export const sendResetPasswordEmailFormValidationSchema = yup.object().shape({
  email: commonValidationRules.email,
});

export type SendResetPasswordEmailFormSchemaType = yup.InferType<
  typeof sendResetPasswordEmailFormValidationSchema
>;
