import { BankAccountVerifyStatus } from "types/BankAccountVerifyStatus";
import {
  BankAccountType,
  BonusEligibilityStatus,
  BonusStatus,
  BonusTrackingType,
  BonusType,
  CompanyEntityType,
  EBusinessStructureUserStatus,
  ErrorConst,
  ExtensionBonusEligibilityStatus,
  PeriodUnit,
  SalsaWorkerCategoryEnum,
  UserRole,
} from "types/BETypes";
import { CardForm, CardReissueReason } from "types/CardShipping";
import { PaymentReadinessStatus } from "types/user";
import { MAX_INPUT_LENGTH, MAX_INPUT_LENGTH_LONG } from "constants/form";
import { MFA_SMS_CODE_LENGTH } from "constants/mfa";
import { MFAStatus } from "constants/mfa/mfaStatus";
import { HELP_EMAIL } from "constants/shared";
import { APPLICATION_NAME, APPLICATION_PARENT_NAME_FULL } from "constants/systemConstants";
import { CardAdditionalStatus, EComprehensibleCardStatus } from "helpers/assets/bankCards";
import { EComprehensibleUserStatuses } from "helpers/employee/userStatusMap";
import { ExtensionBonusStatuses } from "helpers/index";

import bonusGoalsTranslations from "./common/bonusGoals";
import pagesInfoTranslations from "./common/pagesInfo";
import balanceDueBonusRowTranslations from "./components/balanceDueBonusRow";
import bankAccountComponentsTranslation from "./components/bankAccountComponents";
import bankAccountSectionTranslation from "./components/bankAccountSection";
import bankingDisclosureTranslations from "./components/bankingDisclosure";
import bonusCardTranslations from "./components/bonusCard";
import bonusDetailsFooterTranslations from "./components/bonusDetailsFooter";
import bonusesListTableTranslations from "./components/bonusesListTable";
import bonusStatusTooltipTranslations from "./components/bonusStatusTooltipTranslations";
import bonusSummaryTranslations from "./components/bonusSummary";
import bonusTemplatesListTable from "./components/bonusTemplatesListTable";
import bonusTypeSelectModalTranslations from "./components/bonusTypeSelectModal";
import businessOwnerStructure from "./components/businessOwnerStructure";
import comingSoonModalTranslations from "./components/comingSoonModal";
import companyBankAccountComponents from "./components/companyBankAccountComponents";
import companyBankAccountPromoCardsTranslations from "./components/companyBankAccountPromoCards";
import editBonusFormTranslations from "./components/editBonusFormTranslations";
import employeeGroupsTableTranslations from "./components/employeeGroupsTable";
import employeeInfo from "./components/employeeInfo";
import employeeRepaymentCardsTranslations from "./components/employeeRepaymentCards";
import employeeSelectionTableTranslations from "./components/employeeSelectionTable";
import HRISIntegrationInfoRowTranslations from "./components/HRISIntegrationInfoRow";
import keepFooterTranslations from "./components/keepFooter";
import loanVestingScheduleTranslations from "./components/loanVestingSchedule";
import mainMenuTranslations from "./components/mainMenu";
import multiFactorAuthorizationTranslations from "./components/multiFactorAuthorization";
import navigationCardWithDetailsTranslations from "./components/navigationCardWithDetails";
import navigationPromptTranslations from "./components/navigationPrompt";
import nextMilestoneWidgetTranslations from "./components/nextMilestoneWidget";
import payDistributionTranslations from "./components/payDistribution";
import paymentMethodsTranslations from "./components/paymentMethods";
import performanceChallengeTranslations from "./components/performanceChallenges";
import personalEmailModalTranslations from "./components/personalEmailModal";
import progressBarTranslations from "./components/progressBar";
import repaymentLoanRowTranslations from "./components/repaymentLoanRow";
import rewardPointsDisplayTranslations from "./components/rewardPointsDisplay";
import roleSwitcherTranslations from "./components/roleSwitcher";
import spouseNotificationTranslations from "./components/spouseNotification";
import transactionsListTranslations from "./components/transactionsList";
import transactionStatusTranslations from "./components/transactionStatus";
import transactionStatusBankingTranslations from "./components/transactionStatusBanking";
import transactionTypeBankingTranslations from "./components/transactionTypeBanking";
import vestingScheduleDetailedTranslations from "./components/vestingScheduleDetailed";
import adminSetupPageTranslations from "./pages/adminSetupPage";
import authPages from "./pages/authPages";
import bankingPages from "./pages/bankingPages";
import dashboardPageTranslations from "./pages/dashboardPage";
import employeeDashboardTranslations from "./pages/employeeDashboard";
import employeeSetupPageTranslations from "./pages/employeeSetupPage";
import insufficientPermissionsPageTranslations from "./pages/insufficientPermissionsPage";
import myProfileTranslation from "./pages/myProfile";
import page404Translations from "./pages/page404";
import payDistributionPageTranslations from "./pages/payDistributionPages";
import payPagesTranslations from "./pages/payPages";
import peoplePagesTranslations from "./pages/peoplePages";
import settingsPageTranslations from "./pages/settingsPages";

export const translations = {
  "en-US": {
    translation: {
      common: {
        and: "and",
        day: "Day",
        days: "Days",
        year: "Year",
        years: "Years",
        day_one: "Day",
        day_other: "Days",
        year_one: "Year",
        year_other: "Years",
        bonus_one: "bonus",
        bonus_other: "bonuses",
        input: {
          optional: "Optional",
          search_placeholder: "Search",
          placeholder_base: "Enter {{field}}",
        },
        months: {
          january: "January",
          february: "February",
          march: "March",
          april: "April",
          may: "May",
          june: "June",
          july: "July",
          august: "August",
          september: "September",
          october: "October",
          november: "November",
          december: "December",
        },
        roles: {
          [UserRole.SUPERADMIN]: "Admin",
          [UserRole.ADMIN]: "Admin",
          [UserRole.OWNER]: "Owner",
          [UserRole.EMPLOYEE]: "Worker",
        },
        time_units: {
          [`${PeriodUnit.MONTH}_one`]: "Month",
          [`${PeriodUnit.MONTH}_other`]: "Months",
          [`${PeriodUnit.QUARTER}_one`]: "Quarter",
          [`${PeriodUnit.QUARTER}_other`]: "Quarters",
          [`${PeriodUnit.YEAR}_one`]: "Year",
          [`${PeriodUnit.YEAR}_other`]: "Years",
        },
        user_status: {
          [EComprehensibleUserStatuses.EMPLOYED]: "Employed",
          [EComprehensibleUserStatuses.PENDING_EXIT]: "Pending Exit",
          [EComprehensibleUserStatuses.EXITED]: "Exited",
          [EComprehensibleUserStatuses.IN_DEFAULT]: "In Default",
          [EComprehensibleUserStatuses.PROSPECT]: "Not Invited",
          [EComprehensibleUserStatuses.INVITED]: "Invited",
          [EComprehensibleUserStatuses.NOT_READY]: "Not Ready",
        },
        user_readiness_payment_status: {
          [PaymentReadinessStatus.READY]: "Ready",
          [PaymentReadinessStatus.SETUP_PAY]: "Setup Payroll",
        },
        business_owner_structure_user_status: {
          [EBusinessStructureUserStatus.ACTIVE]: "Active",
          [EBusinessStructureUserStatus.INVITED]: "Invited",
          [EBusinessStructureUserStatus.NOT_INVITED]: "Not Invited",
        },
        worker_employment_type: {
          [SalsaWorkerCategoryEnum.EMPLOYEE]: "Full-time employee",
          [SalsaWorkerCategoryEnum.CONTRACTOR]: "Contractor",
        },
        worker_employment_type_short: {
          [SalsaWorkerCategoryEnum.EMPLOYEE]: "Full-time",
          [SalsaWorkerCategoryEnum.CONTRACTOR]: "Contractor",
        },
        bank_account_types: {
          [BankAccountType.investment]: "Investment",
          [BankAccountType.credit]: "Credit",
          [BankAccountType.depository]: "Depository",
          [BankAccountType.loan]: "Loan",
          [BankAccountType.brokerage]: "Brokerage",
          [BankAccountType.other]: "Other",
        },
        bank_cards: {
          card_titles: {
            [CardForm.PHYSICAL]: "Physical Card",
            [CardForm.VIRTUAL]: "Virtual Card",
          },
          card_statuses: {
            [EComprehensibleCardStatus.ACTIVE]: "Active",
            [EComprehensibleCardStatus.ACTIVE_WITHOUT_PIN]: "Active",
            [EComprehensibleCardStatus.EMPTY]: "",
            [EComprehensibleCardStatus.INACTIVE]: "Inactive",
            [EComprehensibleCardStatus.CANCELED]: "Canceled",
            [EComprehensibleCardStatus.EXPIRED]: "Expired",
          },
          card_additional_statuses: {
            [CardAdditionalStatus.REISSUED]: "Reissued",
          },
        },
        industry: {
          accounting: "Accounting",
          administration_Office_Support: "Administration & Office Support",
          advertising_Arts_Media: "Advertising, Arts & Media",
          banking_Financial_Services: "Banking & Financial Services",
          call_Centre_Customer_Service: "Call Centre & Customer Service",
          community_Services_Development: "Community Services & Development",
          consulting_Strategy: "Consulting & Strategy",
          design_Architecture: "Design & Architecture",
          education_Training: "Education & Training",
          engineering: "Engineering",
          farming_Animals_Conservation: "Farming, Animals & Conservation",
          government_Defence: "Government & Defense",
          healthcare_Medical: "Healthcare & Medical",
          hospitality_Tourism: "Hospitality & Tourism",
          human_Resources_Recruitment: "Human Resources & Recruitment",
          information_Communication_Technology: "Information & Communication Technology",
          insurance_Superannuation: "Insurance & Superannuation",
          legal: "Legal",
          manufacturing_Transport_Logistics: "Manufacturing, Transport & Logistics",
          marketing_Communications: "Marketing & Communications",
          mining_Resources_Energy: "Mining, Resources & Energy",
          real_Estate_Property: "Real Estate & Property",
          retail_Consumer_Products: "Retail & Consumer Products",
          sales: "Sales",
          science_Technology: "Science & Technology",
          sport_Recreation: "Sport & Recreation",
          trades_Services: "Trades & Services",
          other: "Other",
        },
        company_types: {
          [CompanyEntityType.CORPORATION]: "Corporation",
          [CompanyEntityType.LLC]: "LLC",
          [CompanyEntityType.PARTNERSHIP]: "Partnership",
          [CompanyEntityType.SOLE_PROPRIETORSHIP]: "Sole Proprietorship",
          [CompanyEntityType.S_CORPORATION]: "S Corporation",
        },
        back: "back",
        copied: "Copied!",
        modal: {
          okay: "Okay",
          continue: "Continue",
          submit: "Submit",
          cancel: "Cancel",
          are_you_sure: "Are you sure?",
          done: "Done",
          yes: "Yes",
          no: "No",
        },
        buttons: {
          cancel: "Cancel",
        },

        bonusGoals: bonusGoalsTranslations["en-US"],
        pagesInfo: pagesInfoTranslations["en-US"],
      },
      buttons: {
        save: "Save",
        cancel: "Cancel",
        submit: "Submit",
        delete: "Delete",
        next: "Next",
        edit: "Edit",
        confirm: "Confirm",
        continue: "Continue",
        close: "Close",
        back: "Back",
        okay: "Okay",
        yes: "Yes",
        no: "No",
        ok: "OK",
        done: "Done",
        apply: "Apply",
        need_help_btn: "Need Help?",
        no_thanks: "No, Thanks",
        skip: "Skip",
        get_started: "Get Started",
        copy: "Copy",
        got_it: "Got it",
        finish: "Finish",
        download: "Download",
        activate: "Activate",
        remove: "Remove",
        do_it_later: "Do it later",
        save_changes: "Save changes",
      },
      errors: {
        defaultTitle: "Error",
        defaultMessage:
          "Something went wrong. If this continues please reach out to {{helpEmail}}.",
        salsaError: "Salsa import failed.",
        INTERNAL_ERROR:
          "Something went wrong. If this continues please reach out to {{helpEmail}}.",
        VALIDATION_ERROR: "Validation error. Please try again.",
        NO_AUTH_TOKEN: "Something went wrong. If this continues please reach out to {{helpEmail}}.",
        NO_AUTH_STATE: "Something went wrong. If this continues please reach out to {{helpEmail}}.",
        TOKEN_NOT_VERIFIED: "The link you followed has expired. Please try to sign in again.",
        SESSION_EXPIRED: "Session expired.",
        AUTH_CODE_EXPIRED: "The link you followed has expired.",
        USER_NOT_FOUND:
          "Something went wrong. If this continues please reach out to {{helpEmail}}.",
        INSUFFICIENT_PERMISSIONS: "You do not have permissions to perform this action.",
        USER_BELONGS_TO_COMPANY: "USER_BELONGS_TO_COMPANY",
        EMAILS_SHOULD_NOT_BE_SAME: "EMAILS_SHOULD_NOT_BE_SAME",
        COMPANY_NOT_FOUND:
          "Something went wrong. If this continues please reach out to {{helpEmail}}.",
        EMAIL_BELONGS_TO_COMPANY: "User with this email already exists",
        USER_ACCOUNT_WAS_DISABLED: "This account was disabled.",
        USER_ALREADY_EXISTS: "User already exists.",
        EMPLOYEE_IS_IN_EXIT_STATE: "This User was terminated from your Company.",
        EMAIL_IS_MISSING: "The link you followed has expired.",
        LINK_TOKEN_EXPIRED: "The link you followed has expired.",
        LEGAL_INFO_CANNOT_BE_UPDATED:
          "After agreement is signed, legal information cannot be updated. Please contact support at <1>{{helpEmail}}</1> if you need any help.",
        EMAIL_ALREADY_TAKEN: "This email is taken by another User",
        EMAIL_IS_USED_AS_PERSONAL:
          "This email is used as Employee personal email.<br/>Employee primary email is {{primaryEmail}}.<br/>We can send invitation to Employee primary email.",
        USER_CANNOT_RECOVER_ACCOUNT: "Sorry, you can not recover your account",
        USER_DOES_NOT_EXIST_WITH_PRIMARY_EMAIL: "User with this primary email does not exist!",
        USER_CANNOT_LOGIN_WITH_PERSONAL_EMAIL:
          "User with this primary email does not exist. If you want to log in with your personal email please click <2>“Recover your account”</2> button",
        EXTERNAL_ACCOUNT_INVALID_CREDENTIALS:
          "Your bank account credentials have changed. Please connect your bank account again.",
        BAD_USER_STATUS: "Login error. Ask your manager to invite you to the system.",
        AMOUNT_CANNOT_BE_ZERO: "Amount cannot be zero",
        FAILED_TO_CREATE_TRANSACTION: `Transaction failed. Please check your bank account connection or contact the Keep team at ${HELP_EMAIL} to address this error.`,
        NETWORK_CONNECTION_LOST: "Network connection lost. Please try again",
        DUPLICATE_ACCOUNT: "This account is already connected. Please try another one.",
        USER_NOT_KYC_VERIFIED:
          "Unable to complete the transfer. Please contact <1>{{helpEmail}}</1> for more details.",
        COMPANY_DOES_NOT_HAVE_BONUSES: "No bonuses to export.",
        PHONE_NUMBER_VALIDATION_ERROR: "Invalid phone number",
        TOO_MANY_REQUESTS: "Too many requests. Try again later.",
        IMPORTING_ONLY_EXAMPLE_RECORD:
          "You are trying to upload CSV Template file with example Worker information. Please upload your CSV file with Workers information and try again.",
        SYNCTERA_ERROR:
          "It seems that there is a problem with the chosen payment method. Please try again later or contact us at <1>{{helpEmail}}</1>",
        [ErrorConst.COMPANY_DOMAIN_NOT_FOUND]: "COMPANY_DOMAIN_NOT_FOUND",
        [ErrorConst.COMPANY_DOMAIN_ALREADY_EXISTS]: "This domain is already added and verified.",
        [ErrorConst.COMPANY_DOMAIN_ALREADY_EXISTS_BUT_NOT_VERIFIED]:
          "This domain is already added. Please, verify it.",
        [ErrorConst.COMPANY_DOMAIN_BELONGS_TO_ANOTHER_COMPANY]:
          "Sorry email with this domain cannot be used.",
        [ErrorConst.COMPANY_DOMAIN_VERIFICATION_LINK_EXPIRED]:
          "The link you followed has expired. Please try to sign in again.",
        [ErrorConst.PROHIBITED_DOMAIN]: "Sorry email with this domain is restricted",
        [ErrorConst.USER_HAS_PROSPECT_STATUS]:
          "We can not find your user account in the system. Please contact your employer to resolve the issue.",
        [ErrorConst.IDP_PROSPECT_USER_LOGIN_ERROR]:
          "We can not find your user account in the system. Please contact your employer to resolve the issue.",
        [ErrorConst.ADMIN_ALREADY_INVITED]: "Admin is already invited",
        [ErrorConst.ADMIN_ALREADY_ACTIVE]: "Admin is already active",
        [ErrorConst.ADMIN_ALREADY_ACTIVE_KYB]: `This User is already active. Please, ask {{fullName}} to log in and accept disclosures.`,
        [`${[ErrorConst.INVALID_CREDENTIALS]}_SIGN_IN`]:
          "Invalid credentials. If you have not created an account please click on create account.",
        [`${[ErrorConst.INVALID_CREDENTIALS]}_SIGN_UP`]:
          "Invalid credentials. If you have an account already please click on log in.",
        [ErrorConst.WORKER_HAS_PAYROLL_RUN]: `The worker can't be deleted because he is a part of a payroll run. Please contact ${HELP_EMAIL} for more details.`,
        [ErrorConst.MISSING_BANK_ACCOUNT_DETAILS]:
          "Something went wrong. If this continues please reach out to {{helpEmail}}.",
        [ErrorConst.INVALID_PASSWORD]: "Invalid password.",

        //NOTE:::BE doesn't return this error
        [ErrorConst.BANK_ACCOUNT_LIMIT_EXCEEDED]: `You’ve reached the accounts limit. If you need to select a new account for your payroll run, please contact us at {{helpEmail}}.`,

        //NOTE:::MFA
        MFA_FACTOR_NOT_FOUND: "Factor was not found",
        MFA_FACTOR_NOT_VERIFIED: "Factor is not verified",
        MFA_FACTOR_ALREADY_VERIFIED: "Factor is already verified",
        INVALID_MFA_CODE: `Invalid code. Please check the ${MFA_SMS_CODE_LENGTH}-digit verification code and try again.`,
        MFA_REQUIRED: "Multifactor authorization is required",
        MFA_NOT_REQUIRED: "Multifactor authorization is not required",
        MFA_CHALLENGE_EXPIRED: "Verification code has expired",
      },
      validationErrors: {
        common: {
          wrong_format: "Wrong {{field}} format",
          is_required: "{{field}} is required",
          too_long: `{{field}} must not exceed {{maxInputLength}} characters`,
          invalid_symbols: "{{field}} should consist of A-z, 0-9, and (. ,‘-)",
          min_number_value: "{{field}} should be greater than or equal to {{minValue}}",
          max_number_value: "{{field}} should be less than or equal to {{maxValue}}",
          max_decimal_places: "{{field}} should not have more than {{maxDecimal}} decimal places",
          invalid_value: "Invalid {{field}}",
        },
        ssn: {
          is_required: "Social Security Number is required",
          wrong_format: "Wrong Social Security Number format",
        },
        start_date: {
          is_required: "Start date is required",
          invalid_date: "Invalid Date.",
        },
        birth_date: {
          is_required: "Date of Birth is required",
          invalid_date: "Invalid Date.",
        },
        address: {
          is_required: "Address is required",
          too_long: `Address must not exceed ${MAX_INPUT_LENGTH_LONG} characters`,
        },
        city: {
          is_required: "City is required",
          too_long: `City must not exceed ${MAX_INPUT_LENGTH} characters`,
        },
        suite: {
          too_long: `Suite must not exceed ${MAX_INPUT_LENGTH} characters`,
        },
        email: {
          is_required: "Email is required",
          wrong_format: "Wrong e-mail format",
          too_long: `Email must not exceed ${MAX_INPUT_LENGTH} characters`,
        },
        phone: {
          phone_wrong_format: "Wrong phone format",
          phone_required: "Phone number is required",
          phone_size: "Phone must be exactly 12 characters",
        },
        employment_type: {
          is_required: "Employment type is required",
        },
        job_title: {
          is_required: "Title is required",
          too_long: `Job title must not exceed ${MAX_INPUT_LENGTH} characters`,
        },
        department: {
          too_long: `Department must not exceed ${MAX_INPUT_LENGTH_LONG} characters`,
        },
        company_name: {
          is_required: "Company Name is required",
          too_long: `Company Name must not exceed ${MAX_INPUT_LENGTH} characters`,
        },
        company_url: {
          is_required: "Company URL is required",
          too_long: `Company URL must not exceed ${MAX_INPUT_LENGTH} characters`,
        },
        industry: {
          is_required: "Industry is required",
        },
        number_of_employees: {
          is_required: "Number of Employees is required",
        },
        service_agreement_title: {
          is_required: "Title is required",
          too_long: `Title must not exceed ${MAX_INPUT_LENGTH} characters`,
        },
        companyEntityType: {
          is_required: "Company Entity Type is required",
        },
        require_payment_of: {
          is_required: "Require payment of is required",
          can_not_be_higher: "Required Amount Due can not be higher than the Balance Amount Due",
        },
        image: {
          invalid_file_type: "Invalid file type",
        },
        url: {
          wrong_format: "Wrong URL format",
        },
      },
      error_layout: {
        logout: "Log Out",
      },
      copyright: {
        copyright: `© 2025 ${APPLICATION_PARENT_NAME_FULL} Technologies Inc. All rights reserved.`,
        terms_conditions: "Terms & Conditions",
        privacy_policy: "Privacy Policy",
        e_sign_agreement: "eSign Agreement",
      },

      //NOTE:::Pages
      admin_setup_page: adminSetupPageTranslations["en-US"],
      banking_pages: bankingPages["en-US"],
      auth_pages: authPages["en-US"],
      employee_setup_page: employeeSetupPageTranslations["en-US"],
      insufficient_permissions_page: insufficientPermissionsPageTranslations["en-US"],
      page_404: page404Translations["en-US"],
      pay_distribution_page: payDistributionPageTranslations["en-US"],
      dashboard_page: dashboardPageTranslations["en-US"],
      employee_dashboard: employeeDashboardTranslations["en-US"],
      pay_page: payPagesTranslations["en-US"],
      people_page: peoplePagesTranslations["en-US"],
      my_profile: myProfileTranslation["en-US"],
      loan_details_page: {
        title: "Loan details",
      },
      settings_pages: settingsPageTranslations["en-US"],

      bonus_status_labels: {
        default: "",
        [BonusType.TIME]: {
          [`${BonusStatus.ACTIVE}_one`]: "Active Bonus",
          [`${BonusStatus.DRAFT}_one`]: "Draft Bonus",
          [`${BonusStatus.PENDING}_one`]: "Pending Bonus",
          [`${BonusStatus.DECLINED}_one`]: "Declined Bonus",
          [`${BonusStatus.COMPLETED}_one`]: "Vested Bonus",
          [`${BonusStatus.ACCEPTED}_one`]: "Accepted Bonus",
          [`${BonusStatus.VOIDED}_one`]: "Voided Bonus",
          [`${BonusStatus.VESTING_STOPPED}_one`]: "Vesting Stopped",
          [`${ExtensionBonusStatuses.PAST_DUE}_one`]: "Past Due Date",

          [`${BonusStatus.ACTIVE}_other`]: "Active Bonuses",
          [`${BonusStatus.DRAFT}_other`]: "Draft Bonuses",
          [`${BonusStatus.PENDING}_other`]: "Pending Bonuses",
          [`${BonusStatus.DECLINED}_other`]: "Declined Bonuses",
          [`${BonusStatus.COMPLETED}_other`]: "Vested Bonuses",
          [`${BonusStatus.ACCEPTED}_other`]: "Accepted Bonuses",
          [`${BonusStatus.VOIDED}_other`]: "Voided Bonuses",
          [`${BonusStatus.VESTING_STOPPED}_other`]: "Vesting Stopped",
          [`${ExtensionBonusStatuses.PAST_DUE}_other`]: "Past Due Date",

          short: {
            [BonusStatus.ACTIVE]: "Active",
            [BonusStatus.DRAFT]: "Draft",
            [BonusStatus.PENDING]: "Pending",
            [BonusStatus.DECLINED]: "Declined",
            [BonusStatus.COMPLETED]: "Vested",
            [BonusStatus.ACCEPTED]: "Accepted",
            [BonusStatus.VOIDED]: "Voided",
            [BonusStatus.VESTING_STOPPED]: "Stopped",
            [ExtensionBonusStatuses.PAST_DUE]: "Past Due Date",
            [ExtensionBonusStatuses.BLANK]: "Blank",
          },
        },
        [BonusType.UNIT]: {
          [`${BonusStatus.ACTIVE}_one`]: "Active Bonus",
          [`${BonusStatus.DRAFT}_one`]: "Draft Bonus",
          [`${BonusStatus.PENDING}_one`]: "Pending Bonus",
          [`${BonusStatus.DECLINED}_one`]: "Declined Bonus",
          [`${BonusStatus.COMPLETED}_one`]: "Vested Bonus",
          [`${BonusStatus.ACCEPTED}_one`]: "Accepted Bonus",
          [`${BonusStatus.VOIDED}_one`]: "Voided Bonus",
          [`${BonusStatus.VESTING_STOPPED}_one`]: "Vesting Stopped",
          [`${ExtensionBonusStatuses.PAST_DUE}_one`]: "Past Due Date",

          [`${BonusStatus.ACTIVE}_other`]: "Active Bonuses",
          [`${BonusStatus.DRAFT}_other`]: "Draft Bonuses",
          [`${BonusStatus.PENDING}_other`]: "Pending Bonuses",
          [`${BonusStatus.DECLINED}_other`]: "Declined Bonuses",
          [`${BonusStatus.COMPLETED}_other`]: "Vested Bonuses",
          [`${BonusStatus.ACCEPTED}_other`]: "Accepted Bonuses",
          [`${BonusStatus.VOIDED}_other`]: "Voided Bonuses",
          [`${BonusStatus.VESTING_STOPPED}_other`]: "Vesting Stopped",
          [`${ExtensionBonusStatuses.PAST_DUE}_other`]: "Past Due Date",

          short: {
            [BonusStatus.ACTIVE]: "Active",
            [BonusStatus.DRAFT]: "Draft",
            [BonusStatus.PENDING]: "Pending",
            [BonusStatus.DECLINED]: "Declined",
            [BonusStatus.COMPLETED]: "Vested",
            [BonusStatus.ACCEPTED]: "Accepted",
            [BonusStatus.VOIDED]: "Voided",
            [BonusStatus.VESTING_STOPPED]: "Stopped",
            [ExtensionBonusStatuses.PAST_DUE]: "Past Due Date",
            [ExtensionBonusStatuses.BLANK]: "Blank",
          },
        },
        [BonusType.CHALLENGE]: {
          [`${BonusStatus.ACTIVE}_one`]: "Active Bonus",
          [`${BonusStatus.DRAFT}_one`]: "Draft Bonus",
          [`${BonusStatus.COMPLETED}`]: "Goal Achieved",
          [`${BonusStatus.NOT_COMPLETED}`]: "Incomplete",
          [`${ExtensionBonusStatuses.PAST_DUE}`]: "Past Due Date",
          [`${BonusStatus.VESTING_STOPPED}_one`]: "Vesting Stopped", //NOTE:::TODO::: For some reason, we have VESTING_STOPPED challenges. Investigate later
          [BonusStatus.VOIDED]: "Voided",

          [`${BonusStatus.ACTIVE}_other`]: "Active Bonuses",
          [`${BonusStatus.DRAFT}_other`]: "Draft Bonuses",
          [`${BonusStatus.VESTING_STOPPED}_other`]: "Vesting Stopped", //NOTE:::TODO::: For some reason, we have VESTING_STOPPED challenges. Investigate later

          short: {
            [BonusStatus.ACTIVE]: "Active",
            [BonusStatus.DRAFT]: "Draft",
            [BonusStatus.COMPLETED]: "Goal Achieved",
            [BonusStatus.NOT_COMPLETED]: "Incomplete",
            [ExtensionBonusStatuses.PAST_DUE]: "Past Due Date",
            [BonusStatus.VOIDED]: "Voided",
            [BonusStatus.VESTING_STOPPED]: "Vesting Stopped",
          },
        },
      },
      bonus_eligibility_status_labels: {
        [BonusEligibilityStatus.LIVE]: "Live",
        [BonusEligibilityStatus.INACTIVE]: "Inactive",
        [BonusEligibilityStatus.DRAFT]: "Draft",
        [ExtensionBonusEligibilityStatus.PAST_DUE]: "Past Due",
      },
      bonus_tracking_type_labels: {
        [BonusTrackingType.INDIVIDUAL]: "Individual",
        [BonusTrackingType.TEAM]: "Team",
      },
      card_reissue_reasons: {
        [CardReissueReason.EXPIRATION]: "Expiration",
        [CardReissueReason.LOST]: "Lost",
        [CardReissueReason.STOLEN]: "Stolen",
      },
      components: {
        business_owner_structure: businessOwnerStructure["en-US"],
        mfa_status: {
          [MFAStatus.ACTIVE]: "Active",
          [MFAStatus.INACTIVE]: "Inactive",
          default_label: "Default",
        },
        accordion: {
          show: "Show",
          hide: "Hide",
        },
        pending_exit_tooltip: {
          title: "User Pending Exit",
          message:
            "This user has been marked as exiting the company and cannot receive a new bonus. If you would like to assign a new bonus, please move the employee back into an active status.",
          message_short: "This user has been marked as exiting the company.",
        },
        how_taxes_work: {
          text: "How do taxes work?",
          tooltip:
            "<1>How do taxes work?</1><2>The amount you enter here is the gross pre-tax amount. Taxes should be set aside by the employer to pay the IRS on behalf of the employee at each vesting milestone.</2>",
        },
        empty_list: {
          empty_search_list: {
            message:
              "<1>Sorry.</1> <2>Your search produced zero results.<br/>Please try again.</2>",
          },
          empty_workers_list: {
            message: `<2>Please add your workers via uploading a CSV file or add individually.</2> <br /><br />
            <2>Once added, you may invite them to enroll in ${APPLICATION_NAME} Payroll.</2>`,
          },
        },
        help_phone: {
          text: "Questions? Talk to a Keep Onboarding Specialist",
          phone: "555-555-5555",
        },
        account_menu: {
          info: "Name, title, email, phone",
          log_out: "Log out",
        },
        vesting_schedule: {
          cell_title: "Vested Milestone - <1>{{vestingUnit}}</1> {{vestingNumber}}",
        },
        bonus_legal_result: {
          title: "Bonus summary",
          show_more: "Show more...",
          show_less: "Show less",
        },
        contact_info: {
          address: "Address",
          ssn: "Social Security Number",
          phone: "Phone",
          date_of_birth: "Date Of Birth",
        },
        need_help_widget: {
          title: "Need help?",
          message: "Not a problem. For questions about your compensation, please reach out to",
          footer_message: "For general questions, please visit the",
          help_center_link: "Keep help center.",
        },
        safe_and_secure: {
          title: "Safe & Secure Transfer Guarantee",
          text: "Keep ensures that your money transfer is safe and secure. Keep uses the highest levels of bank security. Keep uses Plaid, a secure 3rd party service to connect your bank and transfer funds.",
        },
        bank_account_select: {
          add_new_bank: "Add New Bank",
          verify_message: "Bank pending verification.",
        },
        bank_account_info_long: {
          account_number: "Account Number",
          account_type: "Account Type",
          verify_btn: "Verify Account",
          reconnect_btn: "Reconnect Account",
          pending_verification_link: "Pending Verification",
          bank_name_placeholder: "No Bank Name",
          verification_status: {
            [BankAccountVerifyStatus.VERIFICATION_FAILED]: "Verification Failed",
            [BankAccountVerifyStatus.VERIFICATION_EXPIRED]: "Verification Expired",
          },
        },
        confirm_action: {
          title: "Are you sure?",
          ok: "OK",
          cancel: "Cancel",
        },

        dashboard_bonus_card: {
          reasons: {
            termination: "Exit from company",
            stopped_unit: "Unit Vesting Bonus",
          },
          balance_due_label: "Balance Due",
          bonus_amount_label: "Total Bonus Amount",
          view_payment_options_btn: "View Payment Options",
        },
        schedule_repayment_plan_modal: {
          title: "Special Payment Plan",
          message_1: `A ${APPLICATION_NAME} representative is here to help set up your 1 or 3 year Special Payment Plan and answer any questions you may have.`,
          message_2: "Please Schedule time to configure your plan.",
          schedule_btn: "Schedule a call",
        },
        balance_due_with_date: {
          balance_due: "Balance Due",
          loan_repayment_plan:
            "<1>{{specialPaymentPlanLength}} {{years}} Repayment Plan:</1> {{remainingDays}} {{days}} Remaining.",
          repayment_period_text: "Your <1>Repayment Period</1> ends {{repaymentDate}}",
          repayment_period_tooltip:
            "Your {{days}} day Deferred Interest payback period ends {{repaymentDate}}. In the event you have a balance at the end of the payback period, you will be asked to convert your balance due into a 1 year (5% interest) or 3 year (7% interest) repayment loan. In the event you do not configure your repayment loan and sign the contract before that date, your balance will be deemed “In-default” and the full amount will be due immediately.",
        },

        employee_repayment_period_info_frame: {
          repayment_period_message:
            "Repayment Period Ends: <1>{{repaymentPeriodEndDate}}</1><br/><2>{{daysUntilEndRepaymentPeriod}} remaining</2>",
          repayment_loan_message:
            "<1>Exited</1> - {{years}} Repayment Loan<br/>({{daysUntilEndLoanPeriod}} remaining)",
        },
        validation_modal: {
          title: "Sorry",
          text: " This employee is <1>{{status}}</1>.<br />Therefore you will be unable to complete this action.",
          [EComprehensibleUserStatuses.PENDING_EXIT]: "pending exit",
          [EComprehensibleUserStatuses.EXITED]: "exited",
          messages: {
            [EComprehensibleUserStatuses.PENDING_EXIT]:
              "This employee will no longer be with the company after",
            [EComprehensibleUserStatuses.EXITED]:
              "This employee is no longer with the company since",
          },
        },
        create_termination_error_modal: {
          title: "Sorry",
          text: "This user is the Account Owner for the Keep Account. Therefore, you will be unable to end the Employment and remove this user from the Keep Account without contacting Keep Customer Support.",
          email: `${HELP_EMAIL}`,
        },
        image_uploader: {
          add_btn: "Add",
          photo: "Photo",
          logo: "Logo",
          remove_btn: "Remove",
          optional: "Optional",
          your_logo: "Your Logo",
        },
        docu_sign_preview_modal: {
          generate_contract_btn: "Generate Contract",
          service_agreement_title: "View Technologies Platform Agreement",
          bonus_contract_title: "View Contract",
          service_agreement_message:
            "You are about to be redirected to DocuSign to review Technologies Platform Agreement.",
          bonus_contract_message: "You are about to be redirected to review Bonus Agreement.",
          primaryBtn: "Open Link",
          secondaryBtn: "Cancel",
        },
        contact_info_changed_modal: {
          title: "Employee information updated",
          confirm_button: "Yes, this information is correct",
          cancel_button: "No, this information is incorrect",
        },
        contact_info_changed_incorrect_modal: {
          title: "Please update your information.",
          message: `
                If you continue to have problems, please contact:
                <1></>
              `,
          main_button: "Copy Email Address",
        },

        bonus_details_status_info: {
          title_pending: "The below bonus is awaiting contract acceptance from the employee.",
          title_declined: "The below bonus has been declined by the employee.",
          title_accepted: "The below bonus has been accepted and is awaiting funding.",
          text_pending: "Bonus Invitation sent {{sentDate}} from {{fullName}}",
          text_declined: "Bonus Invitation sent {{sentDate}} from {{fullName}}",
          text_accepted: "Bonus Invitation accepted on {{acceptedDate}}",
        },
        restricted_bonus_creation: {
          title: "Cash Plan Limitation",
          message:
            "<1>Unfortunately</1> we are unable to provide vesting cash plans to employees that live in the state of <2>{{state}}</2> at this time. <br /><br />Keep is actively pursuing our {{state}} lending license and will be providing the functionality very soon. We will notify you via email when this becomes available.",
          message_alt:
            "<1>Unfortunately</1> we are unable to provide vesting cash plans to employees that live in the state of <2>{{state}}</2> at this time. Keep is actively pursuing our {{state}} lending license and will be providing the functionality very soon. We will notify you via email when this becomes available.",
        },

        company_bank_account_components: companyBankAccountComponents["en-US"],
        bank_account_components: bankAccountComponentsTranslation["en-US"],
        bank_account_section: bankAccountSectionTranslation["en-US"],
        bonuses_list_table: bonusesListTableTranslations["en-US"],
        bonus_templates_list_table: bonusTemplatesListTable["en-US"],
        navigation_card_with_details: navigationCardWithDetailsTranslations["en-US"],
        pay_distribution: payDistributionTranslations["en-US"],
        payment_methods: paymentMethodsTranslations["en-US"],
        performance_challenge: performanceChallengeTranslations["en-US"],
        bonus_summary: bonusSummaryTranslations["en-US"],
        bonus_card: bonusCardTranslations["en-US"],
        employee_info: employeeInfo["en-US"],
        progress_bar: progressBarTranslations["en-US"],
        banking_disclosure: bankingDisclosureTranslations["en-US"],
        coming_soon_modal: comingSoonModalTranslations["en-US"],
        company_bank_account_promo_cards: companyBankAccountPromoCardsTranslations["en-US"],
        role_switcher: roleSwitcherTranslations["en-US"],
        reward_points_display: rewardPointsDisplayTranslations["en-US"],
        employee_selection_table: employeeSelectionTableTranslations["en-US"],
        employee_groups_table: employeeGroupsTableTranslations["en-US"],
        bonus_status_tooltip: bonusStatusTooltipTranslations["en-US"],
        edit_bonus_form: editBonusFormTranslations["en-US"],
        vesting_schedule_detailed: vestingScheduleDetailedTranslations["en-US"],
        employee_repayment_cards: employeeRepaymentCardsTranslations["en-US"],
        main_menu: mainMenuTranslations["en-US"],
        bonus_type_select_modal: bonusTypeSelectModalTranslations["en-US"],
        navigation_prompt: navigationPromptTranslations["en-US"],
        transactions_list: transactionsListTranslations["en-US"],
        transaction_status: transactionStatusTranslations["en-US"],
        next_milestone_widget: nextMilestoneWidgetTranslations["en-US"],
        repayment_loan_row: repaymentLoanRowTranslations["en-US"],
        balance_due_bonus_row: balanceDueBonusRowTranslations["en-US"],
        loan_vesting_schedule: loanVestingScheduleTranslations["en-US"],
        HRIS_integration_info_row: HRISIntegrationInfoRowTranslations["en-US"],
        bonus_details_footer: bonusDetailsFooterTranslations["en-US"],
        keep_footer: keepFooterTranslations["en-US"],
        spouse_notification: spouseNotificationTranslations["en-US"],
        transaction_status_banking: transactionStatusBankingTranslations["en-US"],
        transaction_type_banking: transactionTypeBankingTranslations["en-US"],
        personal_email_modal: personalEmailModalTranslations["en-US"],
        multi_factor_authorization: multiFactorAuthorizationTranslations["en-US"],
      },
    },
  },
  nl: {
    translation: {
      errors: {
        defaultTitle: "Error NL",
        defaultMessage: "NL Something went wrong.",
      },
    },
  },
};

export default translations;
