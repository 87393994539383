import { KycStatus } from "types/BETypes";

import { UserProfileResponseDto } from "utils/swagger_react_query";

export const isKycAccepted = (user?: Partial<UserProfileResponseDto> | null | undefined) => {
  return user?.user?.kycStatus === KycStatus.ACCEPTED && user?.user?.hasPersonalBankAccount;
};

export const isKycRejected = (user?: Partial<UserProfileResponseDto> | null | undefined) => {
  return user?.user?.kycStatus === KycStatus.REJECTED;
};

export const isKycPending = (user?: Partial<UserProfileResponseDto> | null | undefined) => {
  return (
    user?.user?.kycStatus === KycStatus.PENDING ||
    user?.user?.kycStatus === KycStatus.REVIEW ||
    user?.user?.kycStatus === KycStatus.PROVISIONAL
  );
};
