import * as yup from "yup";

import {
  PasswordFormSchemasType,
  PasswordFormType,
  passwordFormValidationSchemas,
} from "components/auth";

import { ContactDetailsFormTypeValidationSchema } from "../_shared";
import { AdminSignUpSteps } from "./types";

export const adminSignUpValidationSchema = yup.object({
  [AdminSignUpSteps.SET_PASSWORD]: passwordFormValidationSchemas.fields[
    PasswordFormType.CREATE_PASSWORD
  ] as yup.ObjectSchema<PasswordFormSchemasType["CREATE_PASSWORD"]>,
  [AdminSignUpSteps.CONTACT_DETAILS]: ContactDetailsFormTypeValidationSchema,
});

export type AdminSignUpSchemaFormType = yup.InferType<typeof adminSignUpValidationSchema>;
