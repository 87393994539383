//NOTE:::USER METADATA
export const USER_METADATA_DEFAULT_DATA = "USER_METADATA_DEFAULT_DATA";
export const USER_METADATA_SET_METADATA = "USER_METADATA_SET_METADATA";
export const USER_METADATA_UPDATE_LAST_ACTIVE_ROLE = "USER_METADATA_UPDATE_LAST_ACTIVE_ROLE";
export const USER_METADATA_UPDATE_PERSONAL_EMAIL = "USER_METADATA_UPDATE_PERSONAL_EMAIL";

//NOTE:::COMPANY METADATA
export const SET_COMPANY_DATA = "SET_COMPANY_DATA";

//NOTE:::SALSA
export const SET_SALSA = "SET_SALSA";

//NOTE:::WORKER KYC ONBOARDING
export const SET_WORKER_KYC_ONBOARDING_IN_PROGRESS = "SET_WORKER_KYC_ONBOARDING_IN_PROGRESS";

//NOTE:::USER REQUEST IN ROUTE
export const SET_ROUTER_USER_REQUEST_IN_PROGRESS = "SET_ROUTER_USER_REQUEST_IN_PROGRESS";

//NOTE:::USER'S REWARD POINTS
export const SET_USER_REWARD_POINTS = "SET_USER_REWARD_POINTS";
