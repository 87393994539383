import { useState } from "react";
import { useSelector } from "react-redux";
import { FormikProps } from "formik";
import { isEmpty, isNull } from "lodash";
import { userMetadataSelector } from "store/selectors";

import { PayDistributionRuleType, PayDistributionType } from "types/BETypes";
import { isWorkerOnboarded } from "helpers";
import { getErrorMessageFromResponse } from "helpers/shared/errors";
import useAuth from "hooks/useAuth";
import { isPayDistributionIncludesPartnerAccount } from "components/PayDistribution/helpers";

import {
  mutationUsersControllerUpdateDefaultPayDistribution,
  mutationUsersControllerUpdateEarlyPay,
  UpdateDefaultPayDistributionReqDto,
} from "utils/swagger_react_query";

import { EarlyPayFormType } from "./components";
import { setIsDefaultCompanyBankAccountEnabledToLocalStorage } from "./helpers";
import { IUseEarlyPayParams } from "./types";

type IOnSubmitParams = Pick<FormikProps<EarlyPayFormType>, "values" | "validateForm">;

export const useEarlyPay = ({ onSubmitSuccess }: IUseEarlyPayParams) => {
  const { getCurrentUser } = useAuth();
  const currentUser = useSelector(userMetadataSelector);
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const initValues: EarlyPayFormType = {
    isEarlyPayEnabled: isNull(currentUser?.user?.isEarlyPayEnabled)
      ? true
      : currentUser?.user?.isEarlyPayEnabled,
    isDefaultCompanyBankAccountEnabled:
      isPayDistributionIncludesPartnerAccount(currentUser?.user?.defaultPayDistribution) || true,
  };

  const handleSubmitEarlyPay = async ({ values, validateForm }: IOnSubmitParams) => {
    try {
      const isOnboardingCompleted = isWorkerOnboarded(currentUser);
      setIsSubmitting(true);
      const errors = await validateForm(values);
      if (!isEmpty(errors)) return;

      if (isOnboardingCompleted) {
        if (values.isDefaultCompanyBankAccountEnabled) {
          const payload: UpdateDefaultPayDistributionReqDto = {
            isEarlyPayEnabled: !!values?.isEarlyPayEnabled,
            payDistributionRules: [
              {
                type: PayDistributionType.PARTNER_ACCOUNT,
                ruleType: PayDistributionRuleType.REMAINDER,
              },
            ],
          };
          await mutationUsersControllerUpdateDefaultPayDistribution()(payload);
        } else {
          await mutationUsersControllerUpdateEarlyPay()({
            isEarlyPayEnabled: !!values.isEarlyPayEnabled,
          });
        }
      } else if (!isOnboardingCompleted) {
        await mutationUsersControllerUpdateEarlyPay()({
          isEarlyPayEnabled: !!values.isEarlyPayEnabled,
        });
        setIsDefaultCompanyBankAccountEnabledToLocalStorage(
          !!values.isDefaultCompanyBankAccountEnabled,
        );
      }

      await getCurrentUser();
      onSubmitSuccess?.();
    } catch (error) {
      setErrorMessage(getErrorMessageFromResponse(error));
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    isSubmitting,
    errorMessage,
    initValues,
    handleSubmitEarlyPay,
  };
};
