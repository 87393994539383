import { Trans } from "react-i18next";

export const companyDefaultBankAccountBenefitsNumber = 3;
export const getCompanyDefaultBankAccountBenefitsList = (translationPrefix: string) => {
  const icons = [`\u{1F911}`, `\u{1F4B5}`, `\u{1F389}`];
  const list = Array.from({ length: companyDefaultBankAccountBenefitsNumber }, (value, index) => {
    return {
      index: index + 1,
      icon: icons[index],
      text: <Trans i18nKey={`${translationPrefix}.${index}`} />,
    };
  });

  return list;
};
