import styled from "styled-components";

import { PaymentReadinessStatus } from "types/user";

import { statusBorderRadius, statusHeight, statusPaddingX, statusPaddingY } from "../constants";
import { labelMixin, labelMixinLarge, stringWrapperMixin } from "../styles";

export const Container = styled.div`
  display: flex;
  width: fit-content;
`;

export const IconContainer = styled.div`
  margin-left: 8px;
  display: flex;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
  }

  &.${PaymentReadinessStatus.SETUP_PAY} {
    svg path {
      fill: ${({ theme }) => theme.colors.userPaymentReadinessStatusSetupPayLabel};
    }
  }
`;

export const Label = styled.span<{ withBg: boolean; clickable: boolean }>`
  ${({ withBg }) => (withBg ? labelMixin : stringWrapperMixin)};
  ${({ clickable }) => clickable && `cursor: pointer;`};
  ${({ withBg }) =>
    withBg &&
    `
      height: ${statusHeight}px;
      border-radius: ${statusBorderRadius}px;
      padding: ${statusPaddingY}px ${statusPaddingX}px;
      display: flex;
      justify-content: center;
      align-items: center;
    `};

  &.${PaymentReadinessStatus.READY} {
    color: ${({ theme }) => theme.colors.userPaymentReadinessStatusReadyLabel};
    background-color: ${({ withBg, theme }) =>
      withBg ? theme.colors.userPaymentReadinessStatusReadyBg : "none"};
  }

  &.${PaymentReadinessStatus.SETUP_PAY} {
    ${({ withBg }) => withBg && labelMixinLarge};
    color: ${({ theme }) => theme.colors.userPaymentReadinessStatusSetupPayLabel};
    background-color: ${({ withBg, theme }) =>
      withBg ? theme.colors.userPaymentReadinessStatusSetupPayBg : "none"};

    ${({ clickable, theme }) =>
      clickable &&
      `
      &:hover {
        color: ${theme.colors.userPaymentReadinessStatusSetupPayLabelHover};
        
        svg path {
          fill: ${theme.colors.userPaymentReadinessStatusSetupPayLabelHover};
        }
    }
    `};
  }
`;
