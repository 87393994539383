import { FC, useMemo } from "react";
import { Trans } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { PayDistributionType } from "types/BETypes";
import { PayDistributionWidgetType } from "components/PayDistribution/types";

import { ButtonsContainer, FullScreenLoader, ModalContent, Section } from "uikit";
import { EModalTypes } from "uikit/Modal";

import DefaultCompanyBankAccountBenefitsBanner from "../DefaultCompanyBankAccountBenefitsBanner";
import { IWidgetProps } from "./types";
import useCheckForm from "./useCheckForm";
import { Container } from "./styles";

const CheckForm: FC<IWidgetProps> = (props) => {
  const currentUser = useAppSelector(userMetadataSelector);
  const { className } = props;
  const translationPrefix = `components.pay_distribution.paper_check_form`;
  const { isLoading, renderBackButton, renderSubmitButton } = useCheckForm(props);

  const renderBanner = useMemo(() => {
    const shouldShowBanner = () => {
      if (props.widgetType === PayDistributionWidgetType.ONBOARDING) {
        return currentUser?.user?.hasPersonalBankAccount;
      } else {
        return (
          currentUser?.user?.defaultPayDistribution?.[0].type === PayDistributionType.CHECK &&
          currentUser?.user.hasPersonalBankAccount
        );
      }
    };
    if (!shouldShowBanner()) return;

    return (
      <DefaultCompanyBankAccountBenefitsBanner
        onSetDefault={props.onSetCompanyBankAccountAsDefault}
      />
    );
  }, [currentUser]);

  return (
    <Container className={className}>
      {!!isLoading && <FullScreenLoader />}
      <Section>
        <ModalContent
          type={EModalTypes.CARD}
          title={<Trans i18nKey={`${translationPrefix}.title`} />}
          message={<Trans i18nKey={`${translationPrefix}.message`} />}
          children={renderBanner}
        />
      </Section>

      <ButtonsContainer>
        {renderBackButton()}
        {renderSubmitButton()}
      </ButtonsContainer>
    </Container>
  );
};

export default CheckForm;
