import { FC } from "react";

import { Container, Icon, Label } from "./styles";

interface IProps {
  label: string;
  className?: string;
}

export const ChecklistItem: FC<IProps> = ({ label, className }) => {
  return (
    <Container>
      <Icon />

      <Label>{label}</Label>
    </Container>
  );
};
