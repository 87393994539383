import { FC } from "react";

import DashboardLayout from "layouts/DashboardLayout";
import { NavigationCardWithDetailsAlt } from "components/NavigationCardWithDetails";

import useSettingsMFAPage from "./useSecuritySettingPage";
import { OptionsSection } from "./styles";

interface IProps {}

const SecuritySettingPage: FC<IProps> = () => {
  const { metadata, pageData } = useSettingsMFAPage();
  const { cardsList } = pageData;

  return (
    <DashboardLayout showTitle showBreadcrumbs>
      <OptionsSection>
        {cardsList.map((option) => {
          return <NavigationCardWithDetailsAlt {...option} key={option.id} />;
        })}
      </OptionsSection>
    </DashboardLayout>
  );
};

export default SecuritySettingPage;
