import { FC } from "react";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { UserRole } from "types/BETypes";

import { IVerticalStepListItem } from "./types";
import {
  Container,
  ListItem,
  ListItemDescription,
  ListItemNumber,
  ListItemNumberContainer,
  ListItemNumberContainerInner,
  ListItemTextContainer,
  ListItemTitle,
} from "./styles";

interface IProps {
  className?: string;
  items: IVerticalStepListItem[];
  stylesRole?: UserRole;
}

const VerticalStepsList: FC<IProps> = ({ className, items, stylesRole }) => {
  const currentUser = useAppSelector(userMetadataSelector);
  const _role = stylesRole || currentUser?.user?.lastActiveRole;
  const _className = `${_role} ${className}`;
  return (
    <Container className={_className}>
      {items.map((item) => (
        <ListItem>
          <ListItemNumberContainer>
            <ListItemNumberContainerInner>
              <ListItemNumber>{item.index}</ListItemNumber>
            </ListItemNumberContainerInner>
          </ListItemNumberContainer>

          <ListItemTextContainer>
            <ListItemTitle>{item.title}</ListItemTitle>
            <ListItemDescription>{item.description}</ListItemDescription>
          </ListItemTextContainer>
        </ListItem>
      ))}
    </Container>
  );
};

export default VerticalStepsList;
