import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import routes from "routes/routes";
import { useAppSelector } from "store/hooks";
import { companyMetadataSelector, userMetadataSelector } from "store/selectors";

import { ErrorConst, MFAFactorType, PayDistributionType, UserRole } from "types/BETypes";
import { showErrorModal } from "helpers";
import { isPhysicalCardActiveOrUnactivated } from "helpers/bankCards";
import { shouldFetchInternalBankAccounts } from "helpers/shared/bankAccounts";
import { isKybPending } from "helpers/shared/kyb";
import { useBankAccountLink } from "hooks";
import useAuth from "hooks/useAuth";
import { useDefaultBankAccount } from "hooks/useDefaultBankAccount";
import { useInternalBankAccounts } from "hooks/useInternalBankAccounts";
import { useKycStatusModals } from "components/CompanyBankAccountComponents/employee/KYCVerification";
import { EKycResponseType } from "components/CompanyBankAccountComponents/employee/KYCVerification/types";
import useMFASMS from "components/MultiFactorAuthorization/components/sms/useMFASMS";
import { MFAWidgetType } from "components/MultiFactorAuthorization/types";

import { SyncteraAccountDto } from "utils/swagger_react_query";

export interface IEmployeeBankAccountsPageLocationState {
  shouldOpenPlaidModalOnMount?: boolean;
  shouldOpenCompanyAccountModalOnMount?: boolean;
}

export const useBankAccountsPage = () => {
  const translationPrefix = "settings_pages.employee.bank_accounts_page";
  const { showModal } = useKycStatusModals();

  const navigate = useNavigate();
  const location = useLocation()?.state as unknown as IEmployeeBankAccountsPageLocationState;
  const currentUser = useAppSelector(userMetadataSelector);
  const currentCompany = useAppSelector(companyMetadataSelector);
  const { getCompanyInfo } = useAuth();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isBankAccountConnectionMethodModalOpen, setBankAccountConnectionMethodModalOpen] =
    useState<boolean>(false);
  const [isCreationCompanyBankAccountModalOpen, setIsCreationCompanyBankAccountModalOpen] =
    useState<boolean>(false);

  const refetchDataOnDefaultBankAccountChange = async () => {
    await getCompanyInfo(currentCompany?.companyId || "", true);
    await bankAccountLink.actions.refetchBankAccountsList(false);
    if (shouldFetchInternalBankAccounts(currentUser, currentCompany)) {
      await internalBankAccountsData.initFetch(false);
    }
  };

  const bankAccountLink = useBankAccountLink({
    loadingCallback: setLoading,
    shouldFetchBankAccountsListOnMount: false,
    handleDetermineDefaultBankAccount: (companyInfo, bankAccountsList) =>
      determineDefaultBankAccountId(undefined, bankAccountsList, companyInfo),
  });
  const internalBankAccountsData = useInternalBankAccounts({
    setIsLoading: setLoading,
    shouldFetchDataOnMount: false,
    fetchBankCards: true,
  });
  const { bankCards, companyBankAccount } = internalBankAccountsData;
  const hasBankCards = !!internalBankAccountsData.bankCards.length;
  const shouldUserOrderPhysicalCard = !internalBankAccountsData.bankCards.some(
    isPhysicalCardActiveOrUnactivated,
  );

  const { defaultBankAccountId, handleSetDefaultBankAccount, determineDefaultBankAccountId } =
    useDefaultBankAccount({
      loadingCallback: setLoading,
      onChangeDefaultBankAccountCallback: refetchDataOnDefaultBankAccountChange,
    });

  const { factorsData, fetchData } = useMFASMS({
    widgetType: MFAWidgetType.MISC,
  });
  const isMFAEnabled = !!factorsData?.some(
    (factor) => factor.type === MFAFactorType.SMS && factor.isVerified,
  );

  useEffect(() => {
    if (
      currentUser &&
      (isEmpty(bankAccountLink?.data?.bankAccounts) || isEmpty(companyBankAccount))
    ) {
      initFetch();
    }

    if (location?.shouldOpenPlaidModalOnMount) {
      bankAccountLink.actions.openModal();
      window.history.replaceState({}, "");
    } else if (location?.shouldOpenCompanyAccountModalOnMount) {
      setIsCreationCompanyBankAccountModalOpen(true);
      window.history.replaceState({}, "");
    }
  }, []);

  const initFetch = async () => {
    try {
      setLoading(true);
      const externalBankCards = await bankAccountLink.actions.refetchBankAccountsList(false);
      let internalBankAccountRes: SyncteraAccountDto | undefined = undefined;
      if (shouldFetchInternalBankAccounts(currentUser, currentCompany)) {
        const res = await internalBankAccountsData.initFetch();
        internalBankAccountRes = res?.bankAccountRes;
        await fetchData();
      }

      determineDefaultBankAccountId(internalBankAccountRes, externalBankCards);
    } catch (error: any) {
      if (error?.data?.error !== ErrorConst.USER_HAS_NO_PARTNER_BANK_ACCOUNT) {
        showErrorModal(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const onBankInUseClick = () => {
    navigate(routes.EMPLOYEE_SETTINGS_PAY_DISTRIBUTION);
  };

  const onPaidBankBannerBtnClick = () => {
    const _isKybPending = isKybPending(currentCompany);
    if (_isKybPending) return showModal({ type: EKycResponseType.VERIFICATION_IS_TAKING_TOO_LONG });
    setIsCreationCompanyBankAccountModalOpen(true);
  };

  const handleBackBtnClick = () => {
    if (currentUser?.user?.lastActiveRole === UserRole.EMPLOYEE)
      return navigate(routes.EMPLOYEE_SETTINGS);

    return navigate(routes.ADMIN_SETTINGS);
  };

  const handleChooseMethod = (method: PayDistributionType) => {
    if (method === PayDistributionType.PARTNER_ACCOUNT) {
      setBankAccountConnectionMethodModalOpen(false);
      setIsCreationCompanyBankAccountModalOpen(true);
    }

    if (method === PayDistributionType.EXTERNAL_ACCOUNT) {
      bankAccountLink.actions.openModal();
    }
  };

  const handleOpenChooseMethodModal = () => {
    if (!companyBankAccount?.id) {
      setBankAccountConnectionMethodModalOpen(true);
      return;
    }
    bankAccountLink.actions.openModal();
  };

  return {
    metadata: {
      isLoading,
      currentUser,
      currentCompany,
      translationPrefix,
      hasBankCards,
      shouldUserOrderPhysicalCard,
      isMFAEnabled,
    },
    pageData: {
      externalBankAccounts: bankAccountLink.data,
      companyBankAccount,
      defaultBankAccountId,
      bankCards,
      factorsData,
    },
    actions: {
      externalBankAccounts: { ...bankAccountLink.actions },
      onBankInUseClick,
      handleBackBtnClick,
      handleChooseMethod,
      handleOpenChooseMethodModal,
      onPaidBankBannerBtnClick,
      initFetch,
      handleSetDefaultBankAccount,
    },
    modals: {
      bankAccountConnectionMethodModal: {
        isBankAccountConnectionMethodModalOpen,
        setBankAccountConnectionMethodModalOpen,
      },
      companyBankAccountCreation: {
        isCreationCompanyBankAccountModalOpen,
        setIsCreationCompanyBankAccountModalOpen,
      },
    },
  };
};
