import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div``;

export const ChainContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;

export const ChainSeparator = styled.div`
  svg {
    width: 8px;
    height: 8px;
    path {
      fill: black;
    }
  }
`;

export const ChainLink = styled(NavLink)`
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.breadcrumbsText};
  line-height: 1.3;
`;

export const ChainText = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.breadcrumbsTextDisabled};
`;

export const ChainLinkContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;

  &:last-of-type {
    ${ChainSeparator} {
      display: none;
    }
  }
`;
