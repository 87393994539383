import { Trans } from "react-i18next";
import { ArrowIconSmall } from "assets/svg";

import { UserRole } from "types/BETypes";
import {
  BusinessOwnersStructureContextProvider,
  BusinessOwnersStructureStep,
  BusinessOwnersStructureWidgetType,
} from "components/BusinessOwnersStructure";
import { ReviewCertifyStep } from "components/BusinessOwnersStructure/steps/ReviewCertifyStep";
import MFASMS from "components/MultiFactorAuthorization/components/sms/MFASMSSetup";
import { MFAWidgetType } from "components/MultiFactorAuthorization/types";

import { Loader } from "uikit";
import { EModalTypes } from "uikit/Modal";

import { BusinessInformation } from "../admin";
import CompanyCardShippingDetails from "../CompanyCardShippingDetails";
import { PromoSection } from "../components/PromoSection";
import { renderResponseSection } from "../components/renderElements";
import { HDescription, Header, HTitle, StepText } from "../components/styles";
import EarlyPay from "../employee/EarlyPay";
import KYCVerification from "../employee/KYCVerification";
import {
  COMPANY_BANK_ACCOUNT_CREATION_ADMIN_STEPS,
  COMPANY_BANK_ACCOUNT_CREATION_EMPLOYEE_STEPS,
  getStepIndicators,
} from "./constants";
import { ICompanyBankAccountCreationModalProps } from "./types";
import { useCompanyBankAccountCreationModal } from "./useCompanyBankAccountCreationModal";
import {
  BackBtn,
  BankAccountSetupCompletedSection,
  BtnsWrapper,
  BtnsWrapperOuter,
  ButtonsGroup,
  Container,
  MFAWidgetContainer,
  ModalHeader,
  SkipBtn,
  StyledUIModal,
  StyleStepIndicator,
  SubmitBtn,
} from "./styles";

const CompanyBankAccountCreationModal: React.FC<ICompanyBankAccountCreationModalProps> = (
  props,
) => {
  const translationPrefixOrigin = `components.company_bank_account_components`;
  const stepsIndicatorsPrefix = `${translationPrefixOrigin}.shared.steps.list`;

  const { isOpen, hideHeader } = props;
  const { metadata, pageData, actions } = useCompanyBankAccountCreationModal(props);
  const { kyc, earlyPay, cardShippingDetails, mfa, promoSection, businessInfo, kyb } = pageData;
  const { currentStep } = metadata.step;
  const { currentUser } = metadata;

  const shouldShowTotalStepsCount = !pageData.isBankAccountSetupCompletedSectionVisible;

  const getMfaStepBtnTitle = () => {
    if (pageData.isBankAccountSetupCompletedSectionVisible) return "buttons.finish";

    return "buttons.continue";
  };

  const renderSuccessCompletedSections = () => {
    if (pageData.isBankAccountSetupCompletedSectionVisible) {
      const _prefix = `${translationPrefixOrigin}.bank_account_setup_completed_section`;
      return (
        <BankAccountSetupCompletedSection>
          {renderResponseSection({
            title: <Trans i18nKey={`${_prefix}.title`} />,
            message: <Trans i18nKey={`${_prefix}.message`} />,
            iconType: EModalTypes.SUCCESS,
            dataTestId: `bank-account-setup-completed-response-${EModalTypes.SUCCESS}`,
          })}

          <BtnsWrapperOuter>
            <BtnsWrapper className="center">
              <SubmitBtn
                onClick={actions.handleFinishedSetupSectionBtnClick}
                type={"button"}
                data-testid="company-bank-account-creation-modal-submit-button"
              >
                <Trans i18nKey="buttons.done" />
              </SubmitBtn>
            </BtnsWrapper>
          </BtnsWrapperOuter>
        </BankAccountSetupCompletedSection>
      );
    }

    if (cardShippingDetails.cardSuccessfullyOrdered) {
      const _prefix = `${translationPrefixOrigin}.physical_card_order_success_modal`;
      return (
        <BankAccountSetupCompletedSection>
          {renderResponseSection({
            title: <Trans i18nKey={`${_prefix}.title`} />,
            iconType: EModalTypes.SUCCESS,
            dataTestId: `physical-card-ordering-completed`,
          })}
          <BtnsWrapperOuter>
            <BtnsWrapper className="center">
              <SubmitBtn
                onClick={actions.handleFinishedSetupSectionBtnClick}
                type={"button"}
                data-testid={""}
              >
                <Trans i18nKey="buttons.done" />
              </SubmitBtn>
            </BtnsWrapper>
          </BtnsWrapperOuter>
        </BankAccountSetupCompletedSection>
      );
    }

    return <></>;
  };

  const renderEmployeeContent = () => (
    <Container>
      {currentStep === COMPANY_BANK_ACCOUNT_CREATION_EMPLOYEE_STEPS.KYC_VERIFICATION && (
        <KYCVerification
          currentUser={currentUser}
          onBackBtnClick={actions.handleBackBtnClick}
          onKybStatusSectionPrimaryBtnClick={kyc.handleKycStatusSectionPrimaryBtnClick}
          onKybSubmitSuccess={kyc.onSuccessSubmit}
        />
      )}

      {currentStep === COMPANY_BANK_ACCOUNT_CREATION_EMPLOYEE_STEPS.EARLY_PAY && (
        <EarlyPay
          onSubmitSuccess={earlyPay.onSuccessSubmit}
          onBackBtnClick={actions.handleBackBtnClick}
        />
      )}

      {currentStep === COMPANY_BANK_ACCOUNT_CREATION_EMPLOYEE_STEPS.SHIPPING_DETAILS && (
        <CompanyCardShippingDetails
          onSubmitSuccess={cardShippingDetails.onSubmitSuccess}
          onBackBtnClick={actions.handleBackBtnClick}
          showCardTemplate
        />
      )}

      {currentStep === COMPANY_BANK_ACCOUNT_CREATION_EMPLOYEE_STEPS.MFA_CONFIGURATION && (
        <>
          <Header>
            <HTitle>
              <Trans i18nKey={`${translationPrefixOrigin}.mfa_widget.title`} />
            </HTitle>
            <HDescription>
              <Trans i18nKey={`${translationPrefixOrigin}.mfa_widget.description`} />
            </HDescription>
          </Header>

          <MFAWidgetContainer>
            <MFASMS
              widgetType={MFAWidgetType.SETUP}
              onSubmitSuccess={mfa.onSuccessSubmit}
              defaultPhone={currentUser?.user?.phone}
            />
          </MFAWidgetContainer>

          <BtnsWrapperOuter>
            <BtnsWrapper>
              <BackBtn
                onClick={actions.handleBackBtnClick}
                type={"button"}
                id={"cbacm-mfa-form-cancel-button"}
                data-testid={"cbacm-mfa-form-cancel-button"}
              >
                <Trans i18nKey="buttons.cancel" />
              </BackBtn>

              <ButtonsGroup>
                {!(
                  pageData.isBankAccountSetupCompletedSectionVisible || pageData.mfa.isFinished
                ) && (
                  <SkipBtn onClick={mfa.onSkip} data-testid={"cbacm-mfa-skip-button"}>
                    <Trans i18nKey={`${translationPrefixOrigin}.shared.steps.skip_button`} />
                  </SkipBtn>
                )}
                <SubmitBtn
                  type={"button"}
                  id={"cbacm-mfa-submit-button"}
                  data-testid={"cbacm-mfa-submit-button"}
                  disabled={!pageData.mfa.isFinished}
                  onClick={mfa.onPrimaryBtnClick}
                >
                  <Trans i18nKey={getMfaStepBtnTitle()} />
                  <ArrowIconSmall />
                </SubmitBtn>
              </ButtonsGroup>
            </BtnsWrapper>
          </BtnsWrapperOuter>
        </>
      )}
    </Container>
  );

  const renderAdminContent = () => (
    <Container>
      {currentStep === COMPANY_BANK_ACCOUNT_CREATION_ADMIN_STEPS.BUSINESS_INFO && (
        <BusinessInformation
          onSubmitSuccess={businessInfo.onSuccessSubmit}
          onBackBtnClick={actions.handleBackBtnClick}
          businessStructure={pageData.kyb.businessOwnersStructure}
        />
      )}

      <BusinessOwnersStructureContextProvider
        isLoading={kyb.isBusinessStructureLoading}
        refetchBusinessOwnersStructure={actions.fetchBusinessStructureData}
        businessOwnersStructure={kyb.businessOwnersStructure}
        setBusinessOwnersStructure={kyb.handleSetBusinessStructure}
        showResponseTypeSectionOnMount={kyb.shouldShowKybResponseTypeSectionOnMount}
      >
        {currentStep === COMPANY_BANK_ACCOUNT_CREATION_ADMIN_STEPS.COMPANY_STRUCTURE && (
          <>
            <Header>
              <HTitle>
                <Trans i18nKey={`${translationPrefixOrigin}.admin.business_structure.title`} />
              </HTitle>
              <HDescription>
                <Trans
                  i18nKey={`${translationPrefixOrigin}.admin.business_structure.description`}
                />
              </HDescription>
            </Header>

            <BusinessOwnersStructureStep
              widgetType={BusinessOwnersStructureWidgetType.SETUP}
              onBackClick={actions.handleBackBtnClick}
              onPrimaryBtnClick={kyb.handleBusinessStructurePrimaryBtnClick}
              onKybStatusSectionPrimaryBtnClick={kyb.handleKybStatusSectionPrimaryBtnClick}
              emphasizedContainer
            />
          </>
        )}

        {currentStep === COMPANY_BANK_ACCOUNT_CREATION_ADMIN_STEPS.REVIEW_CERTIFY && (
          <>
            <Header>
              <HTitle>
                <Trans i18nKey={`${translationPrefixOrigin}.admin.review_and_certify.title`} />
              </HTitle>
              <HDescription>
                <Trans
                  i18nKey={`${translationPrefixOrigin}.admin.review_and_certify.description`}
                />
              </HDescription>
            </Header>
            <ReviewCertifyStep
              onBackClick={actions.handleBackBtnClick}
              onBusinessInfoEditClick={kyb.handleEditBusinessInfoBtnClick}
              onBusinessOwnersEditClick={kyb.handleEditBusinessOwnersBtnClick}
              onKybStatusSectionPrimaryBtnClick={kyb.handleKybStatusSectionPrimaryBtnClick}
              onKybSuccess={kyb.handleKybSubmitSuccess}
            />
          </>
        )}
      </BusinessOwnersStructureContextProvider>

      {currentStep === COMPANY_BANK_ACCOUNT_CREATION_ADMIN_STEPS.SHIPPING_DETAILS && (
        <CompanyCardShippingDetails
          onBackBtnClick={actions.handleBackBtnClick}
          onSubmitSuccess={cardShippingDetails.onSubmitSuccess}
          businessOwnersStructure={pageData.kyb.businessOwnersStructure}
          bankCardsList={pageData.bankCardsList}
          handleRefetchBusinessOwnersStructure={actions.fetchBusinessStructureData}
          showCardTemplate
          showCardColderDropdown
          showCardTypeCheckboxes
        />
      )}

      {currentStep === COMPANY_BANK_ACCOUNT_CREATION_ADMIN_STEPS.MFA_CONFIGURATION && (
        <>
          <Header>
            <HTitle>
              <Trans i18nKey={`${translationPrefixOrigin}.mfa_widget.title`} />
            </HTitle>
            <HDescription>
              <Trans i18nKey={`${translationPrefixOrigin}.mfa_widget.description`} />
            </HDescription>
          </Header>

          <MFAWidgetContainer>
            <MFASMS
              widgetType={MFAWidgetType.SETUP}
              onSubmitSuccess={mfa.onSuccessSubmit}
              defaultPhone={currentUser?.user?.phone}
            />
          </MFAWidgetContainer>

          <BtnsWrapperOuter>
            <BtnsWrapper>
              <BackBtn
                onClick={actions.handleBackBtnClick}
                type={"button"}
                id={"cbacm-mfa-form-cancel-button"}
                data-testid={"cbacm-mfa-form-cancel-button"}
              >
                <Trans i18nKey="buttons.cancel" />
              </BackBtn>

              <ButtonsGroup>
                {!(
                  pageData.isBankAccountSetupCompletedSectionVisible || pageData.mfa.isFinished
                ) && (
                  <SkipBtn onClick={mfa.onSkip} data-testid={"cbacm-mfa-skip-button"}>
                    <Trans i18nKey={`${translationPrefixOrigin}.shared.steps.skip_button`} />
                  </SkipBtn>
                )}
                <SubmitBtn
                  type={"button"}
                  id={"cbacm-mfa-submit-button"}
                  data-testid={"cbacm-mfa-submit-button"}
                  disabled={!pageData.mfa.isFinished}
                  onClick={mfa.onPrimaryBtnClick}
                >
                  <Trans i18nKey={getMfaStepBtnTitle()} />
                  <ArrowIconSmall />
                </SubmitBtn>
              </ButtonsGroup>
            </BtnsWrapper>
          </BtnsWrapperOuter>
        </>
      )}
    </Container>
  );

  const stepsList = getStepIndicators(
    currentUser?.user?.lastActiveRole as UserRole,
    stepsIndicatorsPrefix,
    mfa.isFinished,
  );

  const renderContent = () => {
    return currentUser?.user?.lastActiveRole === UserRole.EMPLOYEE
      ? renderEmployeeContent()
      : renderAdminContent();
  };

  return (
    <StyledUIModal
      isOpen={isOpen}
      showCloseButton={false}
      className={`${promoSection.showPromoSection ? "small" : ""} `}
    >
      {metadata.isPageLoading ? (
        <Loader />
      ) : promoSection.showPromoSection ? (
        <PromoSection
          onBackClick={promoSection.handlePromoSectionBackBtnClick}
          onGetStartedClick={promoSection.handleShowPromoSection}
        />
      ) : (
        <>
          {!hideHeader && (
            <ModalHeader>
              {currentStep && <StyleStepIndicator steps={stepsList} currentStep={currentStep} />}

              {shouldShowTotalStepsCount && (
                <StepText>
                  <Trans
                    i18nKey={`${translationPrefixOrigin}.shared.steps.steps_current_total_text`}
                    values={{
                      currentStep: currentStep,
                      totalSteps: stepsList?.length,
                    }}
                  />
                </StepText>
              )}
            </ModalHeader>
          )}

          {pageData.isBankAccountSetupCompletedSectionVisible
            ? renderSuccessCompletedSections()
            : renderContent()}
        </>
      )}
    </StyledUIModal>
  );
};

export default CompanyBankAccountCreationModal;
