import { Envelope } from "assets/svg";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledIcon = styled(Envelope)`
  margin-right: 16px;
  min-width: 19px;
`;

export const Text = styled.p`
  display: inline-block;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 26px;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;
