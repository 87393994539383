import { CardForm, CardReissueReason } from "types/CardShipping";
import { EStatesShort } from "constants/shared";
import { CompanyCardShippingDetailsFormType } from "components/CompanyBankAccountComponents/CompanyCardShippingDetails";

import {
  BankCardResDto,
  ExtendedUserProfileResponseDto,
  IssueBankCardReqDto,
} from "utils/swagger_react_query";

export const reissueCardFormValuesToRequestPayload = (
  cardId: string,
  cardForm: CardForm,
  reason: CardReissueReason,
  shippingDetails?: CompanyCardShippingDetailsFormType,
): IssueBankCardReqDto => {
  const result: IssueBankCardReqDto = {
    form: cardForm,
    reissuedFromId: cardId,
    reissueReason: reason,
    shipping: shippingDetails
      ? {
          address: {
            addressLine1: shippingDetails.address,
            addressLine2: shippingDetails.suite || undefined,
            city: shippingDetails.city,
            state: shippingDetails.state.value as EStatesShort,
            postalCode: shippingDetails.zip,
            countryCode: "US",
          },
          recipientFirstName: shippingDetails.firstName,
          recipientLastName: shippingDetails.lastName,
          phoneNumber: shippingDetails.phone,
        }
      : undefined,
  };

  return result;
};

export const convertCardDetailsToUserResponseDto = (
  user: BankCardResDto,
): Partial<ExtendedUserProfileResponseDto> => {
  const result: Partial<ExtendedUserProfileResponseDto> = {
    ...user.ownerInfo,
    address: user.shipping?.address?.addressLine1 || "",
    suite: user.shipping?.address?.addressLine2 || "",
    city: user.shipping?.address?.city || "",
    state: user.shipping?.address?.state as EStatesShort,
    zip: user.shipping?.address?.postalCode || "",
  };

  return result;
};
