import { FC } from "react";
import { Trans } from "react-i18next";

import SelectOption from "types/SelectOption";
import DashboardLayout from "layouts/DashboardLayout";

import { FormLabelLarge, SectionAlt, SectionInner } from "uikit";

import { StatementsList } from "./components";
import { useMonthlyStatements } from "./useMonthlyStatements";
import { Container, StatementsYearSelect, StatementsYearSelectWrapper } from "./styles";

const MonthlyStatements: FC = () => {
  const translationPrefix = `banking_pages.shared.monthly_statements_page`;
  const { data, metadata, actions } = useMonthlyStatements();

  return (
    <DashboardLayout showResponsiveMenu={true}>
      <Container>
        <SectionAlt>
          <StatementsYearSelectWrapper>
            <FormLabelLarge>
              <Trans i18nKey={`${translationPrefix}.statements_year_label`} />
            </FormLabelLarge>

            <StatementsYearSelect<SelectOption<string>>
              value={{
                value: data.selectedYear,
                label: data.selectedYear,
              }}
              onChange={(value) => actions.handleYearChange(value as SelectOption<string>)}
              fieldDataTestId={"statements-year-select"}
              options={data.availableYears}
              isSearchable={true}
              alwaysShowErrorBlock={false}
            />
          </StatementsYearSelectWrapper>

          <SectionInner>
            <StatementsList
              data={data.monthlyStatements}
              isLoading={metadata.isLoading}
              onDownloadClick={actions.handleDownloadClick}
            />
          </SectionInner>
        </SectionAlt>
      </Container>
    </DashboardLayout>
  );
};

export default MonthlyStatements;
