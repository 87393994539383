import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";

import EmptyWorkersList from "components/EmptyList/EmptyWorkersList";

import { FullScreenLoader, TableList } from "uikit";

import { UserShortResponseDto } from "utils/swagger_react_query";

import { IProps } from "./types";
import useWorkersList from "./useWorkersList";
import {
  AddEmployeeBtn,
  Container,
  HLeftContent,
  HRightContent,
  OpenCsvImportModalBtn,
  StyledSearchInput,
  TableContainer,
  THeaderSection,
} from "./styles";

{
  /*NOTE::: Standalone component, with minimal quantity of external props */
}

const WorkersList: FC<IProps> = (props) => {
  const translationPrefix = `people_page.index`;
  const { type } = props;

  const {
    data,
    isLoading,
    isTableLoading,
    paginationProps,
    getColumns,
    handleOpenCsvImportModal,
    openAddWorkerForm,
    renderWorkerActionsElements,
  } = useWorkersList(props);

  const { t } = useTranslation();
  const columns = getColumns(type);

  return (
    <Container>
      {!!isLoading && <FullScreenLoader />}
      <TableContainer className={`${type}`}>
        <TableList<UserShortResponseDto>
          data={data}
          isLoading={isTableLoading}
          columns={columns}
          emptyListComponent={<EmptyWorkersList showIcon={false} />}
          additionalHeaderContent={
            <THeaderSection>
              <HLeftContent>
                <StyledSearchInput
                  value={paginationProps.searchValue}
                  onChange={paginationProps.onSearch}
                  placeholder={t(`${translationPrefix}.search_placeholder`)}
                />
              </HLeftContent>

              <HRightContent>
                <OpenCsvImportModalBtn onClick={handleOpenCsvImportModal}>
                  <Trans i18nKey={`${translationPrefix}.add_worker_modal.uploadEmployeeListBtn`} />
                </OpenCsvImportModalBtn>

                <AddEmployeeBtn onClick={openAddWorkerForm}>
                  <Trans
                    i18nKey={`${translationPrefix}.add_worker_modal.addIndividualEmployeeBtn`}
                  />
                </AddEmployeeBtn>
              </HRightContent>
            </THeaderSection>
          }
          paginationProps={{
            onPageChange: paginationProps.onPageChange,
            totalCount: paginationProps.totalCount || 0,
            limit: paginationProps.perPage,
            page: paginationProps.page,
          }}
          footerColumns={[
            {
              className: "userInfo",
              label: <Trans i18nKey={`${translationPrefix}.table.footer.people`} />,
              value: (paginationProps.totalCount || 0).toString(),
            },
          ]}
        />
      </TableContainer>

      {renderWorkerActionsElements()}
    </Container>
  );
};

export default WorkersList;
