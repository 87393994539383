import styled from "styled-components";

import AuthLayout from "layouts/AuthLayout";

import { BoxMixin } from "../_shared/components/styles";

export const StyledAuthLayout = styled(AuthLayout)``;

export const Container = styled.div``;

export const Section = styled.div``;

export const TermsAndPrivacyDisclaimerSection = styled.div`
  ${BoxMixin}
`;
