import { FC } from "react";

import IconProps from "./IconProps";

const MailUpIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_14321_25349)">
      <path
        d="M10 15.8334H4.16667C3.72464 15.8334 3.30072 15.6578 2.98816 15.3453C2.67559 15.0327 2.5 14.6088 2.5 14.1667V5.83341C2.5 5.39139 2.67559 4.96746 2.98816 4.6549C3.30072 4.34234 3.72464 4.16675 4.16667 4.16675H15.8333C16.2754 4.16675 16.6993 4.34234 17.0118 4.6549C17.3244 4.96746 17.5 5.39139 17.5 5.83341V10.4167"
        stroke="#17AACF"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.8333 18.3333V13.3333"
        stroke="#17AACF"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.3333 15.8333L15.8333 13.3333L13.3333 15.8333"
        stroke="#17AACF"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.5 5.83325L10 10.8333L17.5 5.83325"
        stroke="#17AACF"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_14321_25349">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default MailUpIcon;
