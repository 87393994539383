import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import routes from "routes/routes";

import { ICheckEmailPageLocationState, useSingIn } from "../_shared";

export const useCheckEmailPage = () => {
  const navigate = useNavigate();
  const locationParams = useLocation() as unknown as { state: ICheckEmailPageLocationState };

  const {
    actions: { handleSendMagicLink },
    metadata: { isLoading },
  } = useSingIn(locationParams?.state?.magicLinkFlow);

  useEffect(() => {
    if (!locationParams?.state) navigate(routes.SIGN_IN);
  }, [locationParams]);

  const onResendEmail = () => handleSendMagicLink(locationParams?.state?.email, false);

  return {
    metadata: {
      isLoading,
      flow: locationParams?.state?.magicLinkFlow,
      email: locationParams?.state?.email || "",
    },
    actions: {
      onResendEmail,
    },
  };
};
