import { isNull } from "lodash";

import { UserProfileResponseDto } from "utils/swagger_react_query";

import { isDefaultCompanyBankAccountEnabledLocalStorageKey } from "./constants";

export const getIsEarlyPayStepCompleted = (
  currentUser: UserProfileResponseDto | null | undefined,
): boolean => {
  const isCompleted = !isNull(currentUser?.user?.isEarlyPayEnabled);

  return isCompleted;
};

export const setIsDefaultCompanyBankAccountEnabledToLocalStorage = (value: boolean) => {
  localStorage.setItem(isDefaultCompanyBankAccountEnabledLocalStorageKey, JSON.stringify(value));
};
export const getIsDefaultCompanyBankAccountEnabledFromLocalStorage = () => {
  const value = localStorage.getItem(isDefaultCompanyBankAccountEnabledLocalStorageKey);
  return value ? JSON.parse(value) : false;
};
export const deleteIsDefaultCompanyBankAccountEnabledFromLocalStorage = () => {
  localStorage.removeItem(isDefaultCompanyBankAccountEnabledLocalStorageKey);
};
