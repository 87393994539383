import { FC } from "react";
import routes from "routes/routes";

import { Description, Icon, Title, Wrapper } from "./styles";

const ResponsiveAlert: FC = () => {
  const pagesWithResponsive = [
    routes.SIGN_IN,
    routes.SIGN_UP,
    routes.SIGN_IN_COMPLETE,
    routes.SIGN_UP_COMPLETE,
    routes.CHECK_EMAIL,
    routes.EMPLOYEE_SIGN_UP,
    routes.ADMIN_SIGN_UP,
  ];
  if (pagesWithResponsive.includes(location.pathname as routes)) return <></>;

  return (
    <Wrapper>
      <div>
        <Icon />
      </div>
      <div>
        <Title>We are working on it</Title>
        <Description>
          A version for mobile phones is currently under development. To access all the features of
          the Paid platform, please use the PC version.
        </Description>
      </div>
    </Wrapper>
  );
};

export default ResponsiveAlert;
