import SelectOption from "types/SelectOption";
import { EComprehensibleUserStatuses } from "helpers/employee/userStatusMap";

export const ComprehensibleUserStatusOptions: SelectOption<EComprehensibleUserStatuses>[] = [
  {
    label: `common.user_status.${EComprehensibleUserStatuses.EMPLOYED}`,
    value: EComprehensibleUserStatuses.EMPLOYED,
  },

  {
    label: `common.user_status.${EComprehensibleUserStatuses.PENDING_EXIT}`,
    value: EComprehensibleUserStatuses.PENDING_EXIT,
  },

  {
    label: `common.user_status.${EComprehensibleUserStatuses.EXITED}`,
    value: EComprehensibleUserStatuses.EXITED,
  },

  /*TODO:::   {
    label: `common.user_status.${EComprehensibleUserStatuses.IN_DEFAULT}`,
    value: EComprehensibleUserStatuses.IN_DEFAULT,
  }, */
];
