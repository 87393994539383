import { LogoFull } from "assets/svg";
import styled, { keyframes } from "styled-components";

import { breakpoint } from "helpers/shared/breakpoint";

import { H1 } from "uikit";

import { Copyright } from "./components";

const FuturesLineMobileHeight = 42;
const minHeight = 565;

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  box-sizing: border-box;

  ${breakpoint("xs", "md")`
    align-items: flex-start;
    flex-direction: column;
    justify-content: start;

  `}
`;

export const CopyrightContainerMobile = styled.div`
  ${breakpoint("md")`
    display: none;
  `}
  margin: 0 auto 24px auto;
  min-height: 120px;
  max-width: 480px;
  width: 100%;
`;

export const StyledCopyright = styled(Copyright)``;

export const AdditionalSectionContainer = styled.div`
  background: ${({ theme }) => theme.colors.authLayoutBg};
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;

  /* ${breakpoint("md", "lg")`
    gap: 24px;
  `}

  ${breakpoint("xs", "md")`
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;
  
  `} */
`;

export const AdditionalSection = styled.div`
  width: 400px;
  min-height: 490px;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.emptyListBg};

  ${breakpoint("md", "lg")`
    &.with-checklist {
      min-height: 590px;
    }
  `}

  ${breakpoint("lg", "xl")`
    width: 345px;
  `}

  ${breakpoint("md", "lg")`
    min-width: 302px;
    max-width: 400px;
    width: 100%;
  `}

  ${breakpoint("xs", "md")`
    min-height: 120px;
    max-width: 480px;
    min-width: 343px;
    width: 100%;

  `}
`;

export const ASHeader = styled.div`
  margin-bottom: 24px;

  ${breakpoint("md", "lg")`
    margin-bottom: 56px;
  `}

  ${breakpoint("xs", "md")`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  `}
`;

export const ASHeaderBottom = styled.div`
  ${breakpoint("xs", "md")`
    display: flex;
    flex-direction: column;
    width: 100%;
  `}
`;

export const ASTitle = styled(H1)`
  font-size: ${({ theme }) => theme.size.titleLarger};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 48px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.primaryText};

  ${breakpoint("md", "lg")`
  font-size: ${({ theme }) => theme.size.subtitleLarge};
  line-height: 28px;
  margin-bottom: 12px;

  `}
  ${breakpoint("xs", "md")`
  font-size: ${({ theme }) => theme.size.caption};
  line-height: 16px;
  margin-bottom: 4px;
  `}
`;

export const ASDescription = styled.p`
  font-size: ${({ theme }) => theme.size.subtitleLarge};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.primaryText};

  ${breakpoint("md", "lg")`
  line-height: 24px;
  font-size: ${({ theme }) => theme.size.body1};
  `}
  ${breakpoint("xs", "md")`
  line-height: 12px;
  font-size: ${({ theme }) => theme.size.small};
  width: 100%;
  `}
`;

export const ASMain = styled.div`
  flex: 1;
  margin-bottom: 24px;

  ${breakpoint("xs", "md")`
    display: flex;
    flex: 0;
    margin: 0;

    position: absolute;
    top: 90px;
    left: 0;
    width: 100%;
    height: ${FuturesLineMobileHeight}px;
  `}
`;

const scroll = keyframes`
  0% {
     transform: translate3d(100%, 0, 0);
  }
  100% {
     transform : translate3d(-106%, 0, 0);
  }
`;

const scroll2 = keyframes`
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform : translate3d(-106%, 0, 0);
  }
`;

export const FuturesContainer = styled.div`
  ${breakpoint("xs", "md")`
    overflow: hidden;
    display: flex;
    align-items: center;
    position: absolute;
    width: 100%;
    justify-content: flex-end;
    height: ${FuturesLineMobileHeight}px;
  `}
`;

export const Futures = styled.div`
  ${breakpoint("xs", "md")`
    display: flex;
    animation: ${scroll} 12s linear infinite both;
  `}
`;

export const FuturesSecondLine = styled.div`
  display: none;

  ${breakpoint("xs", "md")`
    display: flex;
    animation: ${scroll2} 12s linear infinite both;
    animation-delay: 6s;
  `}
`;

export const ASFooter = styled.div`
  flex: 0;

  ${breakpoint("xs", "md")`
    display: none;    
  `}
`;

export const MainSectionContainer = styled.div`
  background: linear-gradient(to right, rgba(90, 187, 187, 0.1), rgba(249, 99, 49, 0.1));
  flex: 1.3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
`;

export const MainSection = styled.div`
  width: 540px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.contentBg};
  border: 1px solid ${({ theme }) => theme.colors.contentBorderAlt};
  box-sizing: border-box;
  position: relative;
  padding: 40px;

  ${breakpoint("md", "lg")`
    min-width: 400px;
    max-width: 480px;
    width: 100%;
    padding: 20px;
  `}

  ${breakpoint("xs", "md")`
    max-width: 480px;
    width: 100%;
    min-height: 343px;
    padding: 20px;
  `}
`;

export const ASHTop = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
`;

export const StyledLogo = styled(LogoFull)`
  ${breakpoint("xs", "md")`
    width: 96px;
    height: 42px;
    margin-bottom: 0;
    margin-right: 16px;

  `}
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.contentBg};
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  border-radius: inherit;
`;
