import styled from "styled-components";

import { Body1, Body2, Body3 } from "uikit";

export const Container = styled.div`
  width: 100%;
`;

export const SwitchContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.contentBg};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.contentBorder};
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SwitchTextContainer = styled.div``;

export const SwitchTextTitle = styled(Body2)`
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const SwitchTextDescription = styled(Body3)`
  color: ${({ theme }) => theme.colors.textAlt};
`;

export const SwitchBenefitsDescriptionContainer = styled.div`
  position: relative;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.sectionContainerWarning};
  border: 1px solid ${({ theme }) => theme.colors.sectionBorderWarning};
  border-radius: 8px;
  margin-bottom: 16px;

  div.switch-container {
    position: relative;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
  }
`;

export const SwitchBenefitsDescriptionInner = styled.div`
  padding: 16px;
  border-top: none;
  position: relative;
`;

export const SBDIHeader = styled(Body1)`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 16px;
`;

export const SBDITextWithIcon = styled(Body3)`
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 18px;
  line-height: 16.8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const SBDIIcon = styled.span`
  font-size: 20px;
  margin-right: 8px;
`;
